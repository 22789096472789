import Logo from "shared/Logo/Logo";
import SocialsList1 from "shared/SocialsList1/SocialsList1";
import { CustomLink } from "data/types";
import React from "react";
import { accountService } from "_services/account.service"
import { useTranslation } from 'react-i18next';

export interface WidgetFooterMenu {
  id: string;
  title: string;
  menus: CustomLink[];
}

const Footer: React.FC = () => {
  const { t } = useTranslation();
  const isUserLogged = accountService.isLogged();

  
const widgetMenusLogged: WidgetFooterMenu[] = [
  {
    id: "5",
    title: "Navigation",
    menus: [
      { href: t('lienHome'), label: t('creerVoyage') },
      { href: t('lienMesVoyages'), label: t('mesVoyages') },
      { href: t('lienAide'), label: t('aide') },
      { href: t('lienGuides'), label: t('guides') },
      { href: "https://blog.tripkygo.com", label: "Blog" },
      { href: t('lienContact'), label: t('contact') },
    ],
  },
  {
    id: "1",
    title: t('destinationsPopulaires'),
    menus: [
      { href: "/voyages/pays/Etats-Unis%20d'Amérique", label: "Etats-Unis" },
      { href: "/voyages/pays/Mexique", label: "Mexique" },
      { href: "/voyages/pays/Grèce", label: "Grèce" },
      { href: "/voyages/pays/Japon", label: "Japon" },
      { href: "/voyages/pays/Canada", label: "Canada" },
      { href: "/voyages/pays/Thaïlande", label: "Thaïlande" },
    ],
  },
  {
    id: "2",
    title: "Réservation",
    menus: [
      { href: "https://www.booking.com/flights/index.fr.html?aid=2040377", label: t('reserverAvion')},
      { href: "https://www.booking.com/index.html?aid=2040377", label: t('reserverHebergement') },
      { href: "https://www.getyourguide.fr/?partner_id=SX74NSV", label: t('reserverActivite') },
      { href: "https://www.discovercars.com/?a_aid=tripkygo", label: t('louerVoiture') },
    ],
  },
  {
    id: "2",
    title: "Informations",
    menus: [
      { href: t('lienAPropos'), label: t('aPropos') },
      { href: t('lienNewsletter'), label: t('newsletter') },
      { href: t('lienMentionsLegales'), label: t('mentionsLegales') },
    ],
  },
];

const widgetMenus: WidgetFooterMenu[] = [
  {
    id: "5",
    title: "Navigation",
    menus: [
      { href: t('lienHome'), label: t('creerVoyage') },
      { href: t('lienVoyagesPublics'), label: t('voyagesPublics') },
      { href: t('lienAide'), label: t('aide') },
      { href: t('lienGuides'), label: t('guides') },
      { href: "https://blog.tripkygo.com", label: "Blog" },
      { href: t('lienContact'), label: t('contact') },
    ],
  },
  {
    id: "1",
    title: t('destinationsPopulaires'),
    menus: [
      { href: "/voyages/pays/Etats-Unis%20d'Amérique", label: "Etats-Unis" },
      { href: "/voyages/pays/Mexique", label: "Mexique" },
      { href: "/voyages/pays/Grèce", label: "Grèce" },
      { href: "/voyages/pays/Japon", label: "Japon" },
      { href: "/voyages/pays/Canada", label: "Canada" },
      { href: "/voyages/pays/Thaïlande", label: "Thaïlande" },
    ],
  },
  {
    id: "2",
    title: "Réservation",
    menus: [
      { href: "https://www.booking.com/flights/index.fr.html?aid=2040377", label: t('reserverAvion')},
      { href: "https://www.booking.com/index.html?aid=2040377", label: t('reserverHebergement') },
      { href: "https://www.getyourguide.fr/?partner_id=SX74NSV", label: t('reserverActivite') },
      { href: "https://www.discovercars.com/?a_aid=tripkygo", label: t('louerVoiture') },
    ],
  },
  {
    id: "2",
    title: "Informations",
    menus: [
      { href: t('lienAPropos'), label: t('aPropos') },
      { href: t('lienNewsletter'), label: t('newsletter') },
      { href: t('lienMentionsLegales'), label: t('mentionsLegales') },
    ],
  },
];

  const renderWidgetMenuItem = (menu: WidgetFooterMenu, index: number) => {
    
    return (
      <div key={index} className="text-sm">
        <h2 className="font-semibold text-neutral-700 dark:text-neutral-200">
          {menu.title}
        </h2>
        <ul className="mt-5 space-y-4">
          {menu.menus.map((item, index) => (
            <li key={index}>
              <a
                key={index}
                className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white"
                href={item.href}
              >
                {item.label}
              </a>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div className="nc-Footer relative py-8 lg:py-16 border-t border-neutral-200 dark:border-neutral-700">
      <div className="container grid grid-cols-2 gap-y-10 gap-x-5 sm:gap-x-8 md:grid-cols-4 lg:grid-cols-5 lg:gap-x-10 ">
        <div className="grid grid-cols-4 gap-5 col-span-2 md:col-span-4 lg:md:col-span-1 lg:flex lg:flex-col">
          <div className="col-span-2 md:col-span-1">
            <Logo />
          </div>
          <div className="col-span-2 flex items-center md:col-span-3">
            <SocialsList1 className="flex items-center space-x-3 lg:space-x-0 lg:flex-col lg:space-y-2.5 lg:items-start" />
          </div>
        </div>
        {isUserLogged && widgetMenusLogged.map(renderWidgetMenuItem)}
        {!isUserLogged && widgetMenus.map(renderWidgetMenuItem)}
      </div>
    </div>
  );
};

export default Footer;