import React, { FC, useContext, useEffect, useState, useRef, useCallback, Fragment } from "react";
import { TaxonomyType, GeoapifyType, FeatureGeoapifyType } from "data/types";
import BtnLikeVoyageIcon from "components/BtnLikeVoyageIcon/BtnLikeVoyageIcon";
import CardLieu from "components/CardLieu/CardLieu";
import CardLieuModif from "components/CardLieuModif/CardLieuModif";
import CardTrajet from "components/CardTrajet/CardTrajet";
import CardTrajetModif from "components/CardTrajetModif/CardTrajetModif";
import CardNuit from "components/CardNuit/CardNuit";
import CardNuitModif from "components/CardNuitModif/CardNuitModif";
import ItemsSlider from 'components/ItemsSlider';
import ConvertisseurMonnaie from 'components/ConvertisseurMonnaie';
import ModalGetYourGuide from 'components/ModalGetYourGuide';
import ModalBooking from 'components/ModalBooking';
import ModalDiscoverCars from 'components/ModalDiscoverCars';
import ModalKiwi from 'components/ModalKiwiNew';
import ModalBookingVols from 'components/ModalBookingVols';
import ModalStatistiques from 'components/ModalStatistiques';
import ModalContenuBagage from 'components/ModalContenuBagage';
import ModalImport from 'components/ModalImport';
import ModalExport from 'components/ModalExport';
import ModalIntegration from 'components/ModalIntegration';
import ModalPartageRS from 'components/ModalPartageRS';
import ModalEditionPDF from 'components/ModalEditionPDF';
import ButtonCircleSmall from "shared/Button/ButtonCircleSmall";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonPrimarySmall from "shared/Button/ButtonPrimarySmall";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import ButtonSquare from "shared/Button/ButtonSquare";
import BtnOutils from "components/BtnOutils";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Textarea from "shared/Textarea/Textarea";
import Select from "shared/Select/Select";
import { useTranslation } from 'react-i18next';
import produce from 'immer';

import { useDrag, useDrop, DndProvider, DropTargetMonitor } from 'react-dnd';
import { HTML5Backend } from "react-dnd-html5-backend";
import { voyageService } from '_services/voyage.service';
import {apiKeyGeoapify} from '_config/constantes'; 
import { useNavigate } from "react-router-dom";
import { JourneeType, EventType, PecType, WikipediaType } from "data/types";
import { Dialog, Transition } from "@headlessui/react";
import { Tooltip }  from "react-tooltip";
import 'react-tooltip/dist/react-tooltip.css';
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import DOMPurify from 'dompurify';
import Axios from '../../_services/caller.service';
import AppContext from 'AppContext';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

export interface PlanificateurProps {
  className?: string;
  itemClassName?: string;
  categories?: TaxonomyType[];
  categoryCardType?: "card3" | "card4" | "card5";
  itemPerRow?: 4 | 5;
  sliderStyle?: "style1" | "style2";
  uniqueClassName: string;
  voyageKey: string;
  emailPartage: string;
  codePartage: string;
  isModif: boolean;
  onChangeModif: () => void;
  mailKo: boolean;
  eventCategoriesPage: JourneeType[];
  id_pays: string;
  nbJours: number;
  visibilite: string;
  etatVoyage: number;
  nb_voyageurs: number;
  monnaie: string;
  symbole_monnaie: string,
  numDroit: number; 
  setEventCategoriesPage: (updatedCategories :any) => void;
  username: string;
  affichageBudget: string;
  nb_likes: number;
  liked: string;
  onMajLike: () => void;
  onMajDislike: () => void;
  verrouillageModif: () => Promise<number>;
  isFini: boolean;
}

interface DraggableCardProps {
  index: number; // Use index instead of id
  id_journee:number;
  onDrop: (dragIndex: number, hoverIndex: number, draggedIdJournee:number) => void;
  children?: React.ReactNode;
  eventCategories: JourneeType[];
}

const DraggableCard: FC<DraggableCardProps> = ({ index, id_journee, onDrop, children, eventCategories }) => {
  const [{ isDragging }, drag] = useDrag({
    type: 'CARD',
    item: { index, id_journee },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  
  const ref = useRef<HTMLDivElement>(null);


  const [, drop] = useDrop({
    accept: 'CARD',
    drop: (item: any, monitor: DropTargetMonitor) => {
      const dragIndex = item.index;
      const hoverIndex = index;
      const draggedIdJournee = item.id_journee;

      // Check if the dropped item is not the last event of the day
      if (draggedIdJournee !== null && isNotLastEventOfDay(draggedIdJournee, dragIndex)) {
        onDrop(dragIndex, hoverIndex, draggedIdJournee);
      } else {
        alert("Vous ne pouvez pas déplacer cet élément de fin de journée !");
      }
    },
  });

  const isNotLastEventOfDay = (idJournee: number, eventIndex: number): boolean => {
    const day = eventCategories.find((day) => day.id_journee === idJournee);

    if (day) {
      const isLastEvent = eventIndex === day.evenements.length - 1;
      const isTrajetLendemain = (day.evenements[eventIndex].typeEv==="T" && day.evenements[eventIndex].invisible2 === "O");
        
          if (isLastEvent) {
            return false;
          }
        
          if (isTrajetLendemain) {
            const lastEvent = day.evenements[day.evenements.length - 1];
            // Ajouter des conditions supplémentaires ici pour vérifier si le dernier événement a le typeEv=N et invisible1=O
            if (lastEvent.typeEv === 'N' && lastEvent.invisible1 === 'O') {
              return false;
            }
          }
    }
    return true;
  };

  useEffect(() => {
    drag(drop(ref));
  }, []); // Effectuera cette opération une seule fois au montage initial

  return (
    <div ref={ref} style={{ opacity: isDragging ? 0.5 : 1 }}>
      {children}
    </div>
  );
};


const Planificateur: FC<PlanificateurProps> = ({
  categoryCardType = "card3",
  voyageKey,
  emailPartage,
  codePartage,
  isModif,
  onChangeModif,
  mailKo,
  eventCategoriesPage,
  id_pays,
  nbJours,
  visibilite,
  etatVoyage,
  nb_voyageurs,
  monnaie,
  symbole_monnaie,
  numDroit,
  setEventCategoriesPage,
  username,
  affichageBudget,
  nb_likes,
  liked,
  onMajLike,
  onMajDislike,
  verrouillageModif,
  isFini
}) => {
  
  const { t } = useTranslation();

  const [eventCategories, setEventCategories] = useState<JourneeType[]>([]);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  
  const [selectedEvent, setSelectedEvent] = useState<EventType | null>(null);
  const [selectedEventIndex, setSelectedEventIndex] = useState<number>(0);
  const [selectedJournee, setSelectedJournee] = useState<number | null>(null);
  const [listePec, setListePec] = useState<PecType[]>([]);
  const [journeesLoc, setJourneesLoc] = useState<any[]>([]);
  const [locationTrouve, setLocationTrouve] = useState<boolean>(false);


  const [lien, setLien] = useState("");
  const [lienEnregistre, setLienEnregistre] = useState(true);
  const [etatLien, setEtatLien] = useState("");
  const [lienModifie, setLienModifie] = useState("");
  const [motifLien, setMotifLien] = useState("");
  const [isModalLienOpen, setIsModalLienOpen] = useState(false);

  const [blocNotes, setBlocNotes] = useState("");
  const [isModalBlocNotesOpen, setIsModalBlocNotesOpen] = useState(false);

  const [lieu1, setLieu1] = useState("");
  const [lieu2, setLieu2] = useState("");
  const [latitude1, setLatitude1] = useState("");
  const [latitude2, setLatitude2] = useState("");
  const [longitude1, setLongitude1] = useState("");
  const [longitude2, setLongitude2] = useState("");
  const [isModalCoordOpen, setIsModalCoordOpen] = useState(false);

  const [isModalPOIOpen, setIsModalPOIOpen] = useState(false);
  
  const [isModalConvertisseurOpen, setIsModalConvertisseurOpen] = useState(false);
  const [isModalGetYourGuideOpen, setIsModalGetYourGuideOpen] = useState(false);
  const [isModalKiwiOpen, setIsModalKiwiOpen] = useState(false);
  const [isModalBookingVolsOpen, setIsModalBookingVolsOpen] = useState(false);
  const [isModalBookingOpen, setIsModalBookingOpen] = useState(false);
  const [isModalDiscoverCarsOpen, setIsModalDiscoverCarsOpen] = useState(false);
  const [isModalStatistiquesOpen, setIsModalStatistiquesOpen] = useState(false);
  const [isModalContenuBagageOpen, setIsModalContenuBagageOpen] = useState(false);
  const [isModalImportOpen, setIsModalImportOpen] = useState(false);
  const [isModalExportOpen, setIsModalExportOpen] = useState(false);
  const [isModalIntegrationOpen, setIsModalIntegrationOpen] = useState(false);
  const [isModalPartageOpen, setIsModalPartageOpen] = useState(false);
  const [isModalEditionPDFOpen, setIsModalEditionPDFOpen] = useState(false);

  const [confirm, setConfirm] = useState("");
  const [confirmLien, setConfirmLien] = useState("");
  const [isModalConfirmOpen, setIsModalConfirmOpen] = useState(false);

  const [isModalCopieOpen, setIsModalCopieOpen] = useState(false);
  const [POIList, setPOIList] = useState<Array<GeoapifyType>>([]);

  const [placeNom, setPlaceNom] = useState("");
  const [infoLatitude, setInfoLatitude] = useState("");
  const [infoLongitude, setInfoLongitude] = useState("");
  const [infoAdresse, setInfoAdresse] = useState("");
  const [infoHoraires, setInfoHoraires] = useState("");
  const [infoEquipements, setInfoEquipements] = useState("");
  const [infoSite, setInfoSite] = useState("");
  const [infoTelephone, setInfoTelephone] = useState("");
  const [infoContact, setInfoContact] = useState("");
  const [infoSource, setInfoSource] = useState("");
  const [isModalInfoOpen, setIsModalInfoOpen] = useState(false);

  const [nbNuits, setNbNuits] = useState<number>(0);

  const [typeEvenementForModal, setTypeEvenementForModal] = useState("");
  const [idEvenementForModal, setIdEvenementForModal] = useState<number>(0);
  const [idJourneeForModal, setIdJourneeForModal] = useState<number>(0);

  const [numPages, setNumPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);

  const [statNbEtapes, setStatNbEtapes] = useState("");
  const [statBudget, setStatBudget] = useState("");
  const [statDistance, setStatDistance] = useState("");
  const [statDistanceAvion, setStatDistanceAvion] = useState("");
  const [statDistanceBateau, setStatDistanceBateau] = useState("");
  const [statDistanceTrain, setStatDistanceTrain] = useState("");
  const [statDistanceBus, setStatDistanceBus] = useState("");
  const [statDistanceTaxi, setStatDistanceTaxi] = useState("");
  const [statDistanceVoiture, setStatDistanceVoiture] = useState("");
  const [statDistanceMoto, setStatDistanceMoto] = useState("");
  const [statDistanceVelo, setStatDistanceVelo] = useState("");
  const [statDistancePied, setStatDistancePied] = useState("");

  const [sanitizedHTML, setSanitizedHTML] = useState("");
  const [loadingPOI, setLoadingPOI] = useState<boolean>(false);
  const [aucunPOI, setAucunPOI] = useState<boolean>(false);
  const itemsPerPage = 5;
  const [startIndex, setStartIndex] = useState(0);

  const [latitudePOI, setLatitudePOI] = useState("");
  const [longitudePOI, setLongitudePOI] = useState("");
  const [placeIdPOI, setPlaceIdPOI] = useState("");
  const [libelleLieuPOI, setLibelleLieuPOI] = useState("");
  const [ajoutActivitePOI, setAjoutActivitePOI] = useState<boolean>(false);
  const [ajoutLieuPOI, setAjoutLieuPOI] = useState<boolean>(false);

  const [nbLikes, setNbLikes] = useState<number>(nb_likes || 0);

  const [isAdding, setIsAdding] = useState(false);
  const [isEchange, setIsEchange] = useState(false);
  const [isCopie, setIsCopie] = useState(false);
  

      // Fonction pour obtenir la valeur d'un cookie
      const getCookie = (name: string) => {
        const cookieValue = document.cookie
          .split('; ')
          .find(row => row.startsWith(name))
          ?.split('=')[1];
        return cookieValue || '';
      };
  

  const [isLiked, setIsLiked] = useState(() => {

    if(liked === 'O')
    {
      return true;
    }
    else
    {
      // Vérifie si le cookie existe
      const cookieName = `tripkygoLike${voyageKey}`;
      const cookieValue = getCookie(cookieName);
  
      // Si le cookie existe et a la valeur 'O', met à jour isLiked à true
      if (cookieValue === 'O') {
       return true;
      }
    }
    return false;
  });


  const toggleModif = () => {
    onChangeModif();
  };

        
   // Fonction de gestion du clic sur le bouton like
   const handleLikeClick = useCallback(async (e: any) => {
    var etatLike=isLiked;
  
    setNbLikes((prevNbLikes) => (etatLike ? prevNbLikes - 1 : prevNbLikes + 1));

    // Mise à jour de l'état local
    setIsLiked((prevIsLiked) => !prevIsLiked);
  
    if(!etatLike)
    {
      // Ajout du cookie
      const cookieName = `tripkygoLike${voyageKey}`;
      document.cookie = `${cookieName}=O; path=/`;
  
      const xsrfToken = localStorage.getItem('xsrfToken');
  
      onMajLike(); 

        try{
          const response = await voyageService.likeVoyage({ id_voyage: voyageKey }, {
            headers: {
              'X-XSRF-Token': xsrfToken,
            },
          })
          
        } catch (error: any) {
          console.error("Error like voyage :", error);
        } finally {
        
        }
  
    }
    else
    {
      // Suppression du cookie
    const cookieName = `tripkygoLike${voyageKey}`;
    document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
  
    const xsrfToken = localStorage.getItem('xsrfToken');

    onMajDislike(); 

      try{
        const response = await voyageService.dislikeVoyage(voyageKey, {
          headers: {
            'X-XSRF-Token': xsrfToken,
          },
        })
        
      } catch (error: any) {
        console.error("Error dislike voyage:", error);
      } finally {
      
      }
  
    }
  
    
  }, [isLiked, voyageKey]);
  
  
  const handleNextClick = () => {
    if (startIndex + itemsPerPage < POIList.length) {
      setStartIndex(startIndex + itemsPerPage);
    }
  };

  const handlePrevClick = () => {
    if (startIndex - itemsPerPage >= 0) {
      setStartIndex(startIndex - itemsPerPage);
    }
  };

  type OnAddEvenementType = (idJournee: number, idEvenement: number, libelleLieu: string, description: string, latitude: string, longitude: string) => number;

  
    function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
      setNumPages(numPages);
      setPageNumber(1);
    }
  
    const changePage = (offset: number) => {
      setPageNumber((prevPageNumber) => prevPageNumber + offset);
    };
  
    const previousPage = () => {
      changePage(-1);
    };
  
    const nextPage = () => {
      changePage(1);
    };  

    const handleSelectCopieNuit = (e: React.ChangeEvent<HTMLSelectElement>) => {
      setNbNuits(parseInt(e.target.value, 10));
    };

  let navigate=useNavigate();

 /* const handleElementChange = useCallback(
    (journeeId: number, eventId: number, typeEvenement: string, valeur: string, typeElement: string) => {

      setEventCategories((prevCategories) => {
        return prevCategories.map((day) => {
          if (day.id_journee === journeeId) {
            return {
              ...day,
              evenements: day.evenements.map((event) => {
                if (
                  (typeEvenement === 'lieu' && event.typeEv === 'L' && event.id === eventId) ||
                  (typeEvenement === 'trajet' && event.typeEv === 'T' && event.id === eventId) ||
                  (typeEvenement === 'nuit' && event.typeEv === 'N' && event.id === eventId)
                ) {
                  if (typeElement === 'libelle1') {
                    return { ...event, libelle1: valeur };
                  } else if (typeElement === 'libelle2') {
                    return { ...event, libelle2: valeur };
                  } else if (typeElement === 'prix') {
                    return { ...event, prix: valeur };
                  } else if (typeElement === 'paiement') {
                    return { ...event, paiement: valeur };
                  } else if (typeElement === 'acompte_hebergement') {
                    return { ...event, acompte_hebergement: valeur };
                  } else if (typeElement === 'date_paiement_hebergement') {
                    return { ...event, date_paiement_hebergement: valeur };
                  } else if (typeElement === 'debut') {
                    return { ...event, debut: valeur };
                  } else if (typeElement === 'fin') {
                    return { ...event, fin: valeur };
                  } else if (typeElement === 'compagnie') {
                    return { ...event, compagnie: valeur };
                  } else if (typeElement === 'ref') {
                    return { ...event, ref: valeur };
                  } else if (typeElement === 'distance') {
                    return { ...event, distance: valeur };
                  } else if (typeElement === 'duree') {
                    return { ...event, duree: valeur };
                  } else if (typeElement === 'invisible1') {
                    return { ...event, invisible1: valeur };
                  } else if (typeElement === 'invisible2') {
                    return { ...event, invisible2: valeur };
                  } else if (typeElement === 'type') {
                    return { ...event, type: valeur };
                  } else if (typeElement === 'moment') {
                    return { ...event, moment: valeur };
                  } else if (typeElement === 'rattachement') {
                    return { ...event, rattachement: valeur };
                  } else if (typeElement === 'image') {
                    return { ...event, image: valeur };
                  } else if (typeElement === 'lat') {
                    return { ...event, lat: valeur };
                  } else if (typeElement === 'longi') {
                    return { ...event, longi: valeur };
                  } else if (typeElement === 'lat2') {
                    return { ...event, lat2: valeur };
                  } else if (typeElement === 'longi2') {
                    return { ...event, longi2: valeur };
                  } else if (typeElement === 'place') {
                    return { ...event, place: valeur };
                  } else if (typeElement === 'latModif') {
                    return { ...event, latModif: valeur };
                  } else if (typeElement === 'longiModif') {
                    return { ...event, longiModif: valeur };
                  } else if (typeElement === 'lat2Modif') {
                    return { ...event, lat2Modif: valeur };
                  } else if (typeElement === 'longi2Modif') {
                    return { ...event, longi2Modif: valeur };
                  } else if (typeElement === 'document') {
                    return { ...event, document: valeur };
                  } else if (typeElement === 'lien') {
                    return { ...event, lien: valeur };
                  } else if (typeElement === 'bloc') {
                    return { ...event, bloc: valeur };
                  } else if (typeElement === 'pays') {
                    return { ...event, pays: valeur };
                  } else if (typeElement === 'nom_pays') {
                    return { ...event, nom_pays: valeur };
                  }
                }
                return event;
              }),
            };
          }
          return day;
        });
      });

    },
    [] // dependencies, update if needed
  );
*/

const handleElementChange = useCallback(
  (journeeId: number, eventId: number, typeEvenement: string, valeur: string, typeElement: string) => {
    setEventCategories((prevCategories) =>
      produce(prevCategories, (draft) => {
        const day = draft.find((day) => day.id_journee === journeeId);
        if (day) {
          const event = day.evenements.find((event) => {
            return (
              (typeEvenement === 'lieu' && event.typeEv === 'L' && event.id === eventId) ||
              (typeEvenement === 'trajet' && event.typeEv === 'T' && event.id === eventId) ||
              (typeEvenement === 'nuit' && event.typeEv === 'N' && event.id === eventId)
            );
          });

          if (event) {
            (event as any)[typeElement] = valeur;
          }
        }
      })
    );
  },
  [] // dependencies, update if needed
);

  const handlePecChange = useCallback(
    (idPec: string, idRest :string) => {

      setEventCategories((prevCategories) => {
        return prevCategories.map((day) => {
            return {
              ...day,
              evenements: day.evenements.map((event) => {
                if (event.typeEv === 'T' && event.location === "O" && event.moment === "P" && event.rattachement === idRest) 
                {
                    return { ...event, rattachement: "" };
                }
                else if(idPec !== '' && event.typeEv === 'T' && event.location === "O" && event.moment === "P" && event.id === Number(idPec)) 
                {
                  return { ...event, rattachement: idRest };
                }
                return event;
              }),
            };
        });
      });
    },
    [] // dependencies, update if needed
  );

  const handleSuppLocation = useCallback(
    (idLocation: number) => {

      setEventCategories((prevCategories) => {
        return prevCategories.map((day) => {
            return {
              ...day,
              evenements: day.evenements.map((event) => {
                if (event.typeEv === 'T' && event.location === "O" && event.moment === "P" && event.rattachement === idLocation.toString()) 
                {
                    return { ...event, rattachement: "" };
                }
                else if(event.typeEv === 'T' && event.location === "O" && event.moment === "R" && event.rattachement === idLocation.toString()) 
                {
                  return { ...event, rattachement: "", type: "" };
                }
                return event;
              }),
            };
        });
      });

    },
    [] // dependencies, update if needed
  );

  const handleChangeTypeLocation = useCallback(
    (idRattach: string, nouvType: string) => {

      setEventCategories((prevCategories) => {
        return prevCategories.map((day) => {
            return {
              ...day,
              evenements: day.evenements.map((event) => {
                if (event.typeEv === 'T' && event.location === "O" && event.moment === "P" && nouvType === "P" && event.id === Number(idRattach))
                {
                    return { ...event, rattachement: "" };
                }
                else if(event.typeEv === 'T' && event.location === "O" && event.moment === "R" && nouvType === "R" && event.id === Number(idRattach))
                {
                  return { ...event, rattachement: "", type: "" };
                }
                return event;
              }),
            };
        });
      });

    },
    [] // dependencies, update if needed
  );


  useEffect(() => {
    
    if (JSON.stringify(eventCategories) !== JSON.stringify(eventCategoriesPage)) {
      setEventCategories(eventCategoriesPage);
      setLoading(false); 
    }
  }, [eventCategoriesPage]);

  useEffect(() => {
    if (!loading) {
      // Mettre à jour eventCategoriesPage lorsque eventCategories change
      setEventCategoriesPage(eventCategories);
    }
  }, [eventCategories]);
  
    
  useEffect(() => {

    const MajPec = async () => {

    const updatedListePec = eventCategories.flatMap((day) =>
    day.evenements
      .filter(
        (event) =>
          event.location === 'O' &&
          event.moment === 'P'
      )
      .map((event) => ({
        date_journee: day.date_journee,
        id_journee: day.id_journee,
        id_evenement: event.id, 
        type_transport: event.type || "",
        rattachement: event.rattachement || "",
        debut: event.debut || ""
      }))
  );
  setListePec(updatedListePec);

  const listeJourneesLoc = eventCategories.map((day) => ({
    date_journee: day.date_journee,
    id_journee: day.id_journee,
    car: false,
    motorcycle: false,
    bicycle: false,
  }))

  setLocationTrouve(false);

  await updatedListePec.forEach((pec: any, index: number) => {

    if(pec.rattachement && pec.rattachement !== "" &&
       pec.type_transport && pec.type_transport !== "")
       {

          var dayDeb = eventCategories.find((day) => day.id_journee === pec.id_journee);

          if(dayDeb)
          {
            var i=dayDeb.num_jour-1;
            var trouve=false;
            var dayFin = null;
            while(i<eventCategories.length && !trouve)
            {

              for (let k = 0; !trouve && k < eventCategories[i].evenements.length; k++)
              {
                if(eventCategories[i].evenements[k].typeEv === "T" && eventCategories[i].evenements[k].moment && eventCategories[i].evenements[k].moment === "R" 
                && eventCategories[i].evenements[k].id.toString() === pec.rattachement)
                {
                
                  dayFin = eventCategories[i];
                  trouve=true;
                }
              }
              i++;
            }

            if(dayFin !== null)
            {
              for (let j = dayDeb.num_jour-1; dayFin && j <= dayFin.num_jour - 1; j++)
              {
                setLocationTrouve(true);
                switch(pec.type_transport) {
                  case 'car':
                    listeJourneesLoc[j].car=true;
                    break;
                    case 'bicycle':
                      listeJourneesLoc[j].bicycle=true;
                      break;
                      case 'motorcycle':
                        listeJourneesLoc[j].motorcycle=true;
                        break;
                }
              }
            }
          }   
       }
  })

  setJourneesLoc(listeJourneesLoc);
  }

  MajPec();
}, [eventCategories]);

  const openModal = (idJournee: number, event: EventType, eventIndex: number) => {

    setSelectedEvent(event);
    setSelectedEventIndex(eventIndex);
    setSelectedJournee(idJournee);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedEvent(null);
    setSelectedEventIndex(0);
    setSelectedJournee(null);
    setIsModalOpen(false);
  };

  const openModalLien = (typeEvenement: string, idEvenement: number) => {
    setTypeEvenementForModal(typeEvenement);
    setIdEvenementForModal(idEvenement);
    setIsModalLienOpen(true);

    eventCategories.forEach((day, index) => {
      day.evenements.forEach((event, eventIndex) => {

          if (((typeEvenement === "lieu" && event.typeEv === "L") || (typeEvenement === "trajet" && event.typeEv === "T") || (typeEvenement === "nuit" && event.typeEv === "N")) && event.id === idEvenement) {
              setIdJourneeForModal(day.id_journee);
          }
      });
  });
  };

  const closeModalLien = () => {
    setLien("");
    setEtatLien("");
    setLienModifie("");
    setMotifLien("");
    setTypeEvenementForModal("");
    setIdEvenementForModal(0);
    setIdJourneeForModal(0);
    setIsModalLienOpen(false);
    setIdJourneeForModal(0);
  };

  const openModalBlocNotes = (typeEvenement: string, idEvenement: number) => {
    setTypeEvenementForModal(typeEvenement);
    setIdEvenementForModal(idEvenement);
    setIsModalBlocNotesOpen(true);
    
    eventCategories.forEach((day, index) => {
      day.evenements.forEach((event, eventIndex) => {
     
          if (((typeEvenement === "lieu" && event.typeEv === "L") || (typeEvenement === "trajet" && event.typeEv === "T") || (typeEvenement === "nuit" && event.typeEv === "N")) && event.id === idEvenement) {
              setIdJourneeForModal(day.id_journee);
          }
      });
  });
  };

  const closeModalBlocNotes = () => {
    setBlocNotes("");
    setTypeEvenementForModal("");
    setIdEvenementForModal(0);
    setIdJourneeForModal(0);
    setIsModalBlocNotesOpen(false);
  };

  const openModalConvertisseur = () => {
    setIsModalConvertisseurOpen(true);
  };

  const closeModalConvertisseur = () => {
    setIsModalConvertisseurOpen(false);
  };

  const openModalGetYourGuide = (typeEvenement: string, idEvenement: number) => {
    setTypeEvenementForModal(typeEvenement);
    setIdEvenementForModal(idEvenement);
    setIsModalGetYourGuideOpen(true);
  };

  const closeModalGetYourGuide = () => {
    setLatitude1("");
    setLatitude2("");
    setLongitude1("");
    setLongitude2("");
    setTypeEvenementForModal("");
    setIdEvenementForModal(0);
    
    setIsModalGetYourGuideOpen(false);
  };

  const openModalBooking = (typeEvenement: string, idEvenement: number) => {
    setTypeEvenementForModal(typeEvenement);
    setIdEvenementForModal(idEvenement);
    setIsModalBookingOpen(true);
  };

  const closeModalBooking = () => {
    setLatitude1("");
    setLatitude2("");
    setLongitude1("");
    setLongitude2("");
    setTypeEvenementForModal("");
    setIdEvenementForModal(0);

    setIsModalBookingOpen(false);
  };

  const openModalDiscoverCars = (typeEvenement: string, idEvenement: number) => {
    setTypeEvenementForModal(typeEvenement);
    setIdEvenementForModal(idEvenement);
    setIsModalDiscoverCarsOpen(true);
  };

  const closeModalDiscoverCars = () => {
    setLatitude1("");
    setLatitude2("");
    setLongitude1("");
    setLongitude2("");
    setTypeEvenementForModal("");
    setIdEvenementForModal(0);

    setIsModalDiscoverCarsOpen(false);
  };

  const openModalKiwi = (typeEvenement: string, idEvenement: number) => {
    setTypeEvenementForModal(typeEvenement);
    setIdEvenementForModal(idEvenement);
    setIsModalKiwiOpen(true);
  };

  const closeModalKiwi = () => {
    setTypeEvenementForModal("");
    setIdEvenementForModal(0);

    setIsModalKiwiOpen(false);
  };

  const openModalBookingVols = (typeEvenement: string, idEvenement: number) => {
    setTypeEvenementForModal(typeEvenement);
    setIdEvenementForModal(idEvenement);
    setIsModalBookingVolsOpen(true);
  };

  const closeModalBookingVols = () => {
    setTypeEvenementForModal("");
    setIdEvenementForModal(0);

    setIsModalBookingVolsOpen(false);
  };
  
  const openModalStatistiques = () => {
    setIsModalStatistiquesOpen(true);
  };

  const closeModalStatistiques = () => {
    setIsModalStatistiquesOpen(false);
  };
  
  const openModalContenuBagage = () => {
    setIsModalContenuBagageOpen(true);
  };

  const closeModalContenuBagage = () => {
    setIsModalContenuBagageOpen(false);
  };

  const closeModalImport = () => {
    setIsModalImportOpen(false);
  };

  const openModalImport = () => {
    setIsModalImportOpen(true);
  };

  const closeModalIntegration = () => {
    setIsModalIntegrationOpen(false);
  };

  const openModalIntegration = () => {
    setIsModalIntegrationOpen(true);
  };

  const closeModalPartage = () => {
    setIsModalPartageOpen(false);
  };

  const openModalPartage = () => {
    setIsModalPartageOpen(true);
  };

  const closeModalExport = () => {
    setIsModalExportOpen(false);
  };

  const openModalExport = () => {
    setIsModalExportOpen(true);
  };

  const openModalEditionPDF = () => {
    setIsModalEditionPDFOpen(true);
  };

  const closeModalEditionPDF = () => {
    setIsModalEditionPDFOpen(false);
  };

  const openModalCoord = (typeEvenement: string, idEvenement: number) => {
    setTypeEvenementForModal(typeEvenement);
    setIdEvenementForModal(idEvenement);
    setIsModalCoordOpen(true);
  };

  const closeModalCoord = () => {
    setLatitude1("");
    setLatitude2("");
    setLongitude1("");
    setLongitude2("");
    setTypeEvenementForModal("");
    setIdEvenementForModal(0);
    setIsModalCoordOpen(false);
  };

  const openModalPOI = (idEvenement: number) => {
    setIdEvenementForModal(idEvenement);
    setStartIndex(0);
    setLoadingPOI(true);
    setAucunPOI(false);
    setPOIList([]);
    setIsModalPOIOpen(true);
      
    setLatitudePOI("");
    setLongitudePOI("");
    setLibelleLieuPOI("");
    setPlaceIdPOI("");
    setAjoutActivitePOI(false);
    setAjoutLieuPOI(false);
  };

  const closeModalPOI = () => {
    setIdEvenementForModal(0);
    setIdJourneeForModal(0);
    setIsModalPOIOpen(false);
    
    setLatitudePOI("");
    setLongitudePOI("");
    setLibelleLieuPOI("");
    setPlaceIdPOI("");
    setAjoutActivitePOI(false);
    setAjoutLieuPOI(false);
  };

  const openModalConfirm = (typeEvenement: string, idEvenement: number) => {
    setTypeEvenementForModal(typeEvenement);
    setIdEvenementForModal(idEvenement);
    setIsModalConfirmOpen(true);

    eventCategories.forEach((day, index) => {
      day.evenements.forEach((event, eventIndex) => {

          if (((typeEvenement === "lieu" && event.typeEv === "L") || (typeEvenement === "trajet" && event.typeEv === "T") || (typeEvenement === "nuit" && event.typeEv === "N")) && event.id === idEvenement) {
              setIdJourneeForModal(day.id_journee);
          }
      });
  });
  };

  const closeModalConfirm = () => {
    setConfirm("");
    setTypeEvenementForModal("");
    setIdEvenementForModal(0);
    setIdJourneeForModal(0);
    setIsModalConfirmOpen(false);
  };

  const openModalCopie = (idEvenement: number) => {
    setTypeEvenementForModal("nuit");
    setIdEvenementForModal(idEvenement);
    setIsModalCopieOpen(true);
  };

  const closeModalCopie = () => {
    setTypeEvenementForModal("");
    setIdEvenementForModal(0);
    setIsModalCopieOpen(false);
  };

  const openModalInfo = () => {
    setIsModalInfoOpen(true);
  }

  const closeModalInfo = () => {
    setInfoAdresse("");
    setInfoHoraires("");
    setInfoEquipements("");
    setInfoSite("");
    setInfoTelephone("");
    setInfoContact("");
    setInfoSource("");
    setPlaceNom("");
    setInfoLatitude("");
    setInfoLongitude("");
    setIsModalInfoOpen(false);
  }
  
  const handleAddActivitePOI = async () =>
  {
      const day = eventCategories.find((day) => day.id_journee === idJourneeForModal);
      let foundEvent: EventType | null = null;

      if (day) {
        const index = day.evenements.findIndex((event: EventType) => event.id === idEvenementForModal);
    
        if (index !== -1) {
          foundEvent = day.evenements[index];
        } 
      }

      var champActivite="";

      if(foundEvent !== null)
      {
        if(foundEvent.libelle2 !== null && foundEvent.libelle2 !== '')
        {
            champActivite=foundEvent.libelle2+'\n'+libelleLieuPOI;
        }
        else
        {
            champActivite=libelleLieuPOI;
        }
  
        if(champActivite.length >300)
        {
            alert('Le champ "Activités/visites" de cette étape est complet (maximum 300 caractères), veuillez créer une nouvelle étape.');
        }
        else
        {
           //enregistrement de la modification
           voyageService.modifierEvenement(voyageKey, {
            typeEvenement: 'lieu',
            idEvenement: idEvenementForModal,
            colonne: "activites",
            contenu: champActivite,
            conservation: false
          })
          .then((response: any) => {
 
            handleElementChange(idJourneeForModal, idEvenementForModal, 'lieu', champActivite, 'libelle2');

            setAjoutActivitePOI(true);
            setAjoutLieuPOI(false);
          })
          .catch((error: any) =>
          {
            if (error.response?.status === 401) {
              alert("Erreur lors de l'enregistrement de la modification, vous n'êtes plus connecté(e).")
              localStorage.removeItem('xsrfToken');
              navigate('/login');
            }
            else
            {
              alert("Erreur lors de l'enregistrement de la modification de l'évenement");
            }
          });
        }
      }
  }

  const handleAddLieuPOI = async () =>
  {
    const resultat = await handleAddEvenement(idJourneeForModal, idEvenementForModal, libelleLieuPOI, "", latitudePOI, longitudePOI, placeIdPOI);  

    if(resultat === 0)
    {
      setAjoutActivitePOI(false);
      setAjoutLieuPOI(true);
    }
  }  
    
  const handleAddEvenement = (idJournee: number, idEvenement: number, libelleLieu: string, description: string, latitude: string, longitude: string, placeId: string): number  => {
  
    const modifVoyage = async () => {
    try {

      setIsAdding(true);

      const day = eventCategories.find((day) => day.id_journee === idJournee);
      let foundEvent: EventType | null = null;
      let eventIndex: number = -1;

      if (day) {
        const index = day.evenements.findIndex((event: EventType) => event.id === idEvenement);
    
        if (index !== -1) {
          eventIndex=index;
          foundEvent = day.evenements[index];
        } 
      }

      const type='lieu';
  
        if (foundEvent !== null && eventIndex !== null && eventIndex !== -1) {
          
          // Effectuez la requête API pour ajouter un événement
            voyageService.ajouterEvenement(voyageKey, {
                type,
                journee: idJournee,
                idEvenement: foundEvent.id,
                typeEvenement: foundEvent.typeEv
            })
          .then((response: any) => {

            const apiResult = response.data;
      
            // Vérifiez si la réponse de l'API est "Erreur"
            if (apiResult !== "Erreur") {
 
              closeModal();
      
              setEventCategories((prevCategories) => {
                // Create a deep copy of the previous categories
                const updatedCategories = prevCategories.map((day) => {
                  if (day.id_journee === idJournee) {
                    // Create a copy of the 'evenements' array for immutability
                    const newEvenements = [...day.evenements];
              
                    // Insert the new event at the correct index
                    newEvenements.splice(eventIndex, 0, {
                      typeEv: 'L',
                      id: apiResult.message, // Use API result as event ID
                      ordre: foundEvent?.ordre || 0,
                      libelle1: "",
                      libelle2: "",
                      prix: "",
                      paiement: "",
                      lat: "",
                      longi: "",
                      place: "",
                      bloc: "",
                      type: '',
                      lien: "",
                      etat_lien: "",
                      lien_modifie: "",
                      ref: "",
                      compagnie: "",
                      debut: "",
                      fin: "",
                      lat2: "",
                      longi2: "",
                      distance: "",
                      duree: "",
                      location: '',
                      moment: '',
                      rattachement: "",
                      invisible1: "",
                      invisible2: "",
                      date_paiement_hebergement: "",
                      acompte_hebergement: "",
                      image: "",
                      document: ""
                    });
              
                    // Return a new object with the updated evenements array
                    return {
                      ...day,
                      evenements: newEvenements,
                    };
                  }
                  return day; // No changes for other days
                });
              
                return updatedCategories;
              });
              
    
                  } else {
                    console.error("Erreur lors de la requête API");
                    return -1
                    // Gérez l'erreur, affichez un message, etc.
                  }
    
                  const idNouvLieu=apiResult.message ? parseInt(apiResult.message, 10) : 0;

            
                  if (idNouvLieu !== 0) {
                    // Utilisez await pour attendre que modifierLieu soit terminé
                    modifierLieu(idJournee, idNouvLieu, libelleLieu, description, latitude, longitude, placeId);
                    setIsAdding(false);
                    return 0;
                  }
                  else
                  {
                    setIsAdding(false);
                  }
            })
          .catch((error: any) => {
            setIsAdding(false);
            if (error.response?.status === 401) {
              alert("Erreur lors de l'ajout d'évenement, vous n'êtes plus connecté(e).")
              localStorage.removeItem('xsrfToken');
              navigate('/login');
            }
            else
            {
              alert("Erreur lors de l'ajout d'évenement");
              return -1;
            }
            console.error("Erreur lors de l'ajout d'évenement:", error);
          });
      
          } 
          else
          {
            setIsAdding(false);
          }
    } catch (error) {
      setIsAdding(false);
      console.error("Une erreur s'est produite :", error);
      alert("Une erreur s'est produite lors de la manipulation des événements.");
      return -1;
    }
  }

    const processModifVoyage = async () => {
        
      const verrouillageResultat = await verrouillageModif();
  
      if (verrouillageResultat === 1) {
        modifVoyage();
      }
    };

    processModifVoyage();

    return 0;
  };  

  const modifierLieu = (idJournee : number, idEvenementNouv : number , libelleLieu : string, description : string, latitude : string, longitude : string, placeId : string) => {

      handleElementChange(idJournee, idEvenementNouv, 'lieu', libelleLieu, 'libelle1');
      handleElementChange(idJournee, idEvenementNouv, 'lieu', latitude, 'lat');
      handleElementChange(idJournee, idEvenementNouv, 'lieu', longitude, 'longi');
      handleElementChange(idJournee, idEvenementNouv, 'lieu', placeId, 'place');

      if(description !== null && description !== "")
      {
        handleElementChange(idJournee, idEvenementNouv, 'lieu', description, 'libelle2');
      }
         
      //enregistrement de la modification
      voyageService.modifierEvenement(voyageKey, {
        typeEvenement: 'lieu',
        idEvenement: idEvenementNouv,
        colonne: "libelle_lieu",
        contenu: libelleLieu,
        colonne1: 'lat_lieu',
        colonne2: 'long_lieu',
        contenu1: latitude,
        contenu2: longitude,
        placeId: placeId
      })
      .then((response: any) => {

        
        if(description !== null && description !== "")
        {
          //enregistrement de la modification
          voyageService.modifierEvenement(voyageKey, {
            typeEvenement: 'lieu',
            idEvenement: idEvenementNouv,
            colonne: "activites",
            contenu: description,
            conservation: false
          })
          .then((response: any) => {

            
          })
          .catch((error: any) =>
          {
            if (error.response?.status === 401) {
              alert("Erreur lors de l'enregistrement de la modification, vous n'êtes plus connecté(e).")
              localStorage.removeItem('xsrfToken');
              navigate('/login');
            }
            else
            {
              alert("Erreur lors de l'enregistrement de la modification de l'évenement");
            }
          });
        }
      })
      .catch((error: any) =>
      {
        if (error.response?.status === 401) {
          alert("Erreur lors de l'enregistrement de la modification, vous n'êtes plus connecté(e).")
          localStorage.removeItem('xsrfToken');
          navigate('/login');
        }
        else
        {
          alert("Erreur lors de l'enregistrement de la modification de l'évenement");
        }
      });
  };

  const addEvenement = async (type: string) => {

    
    const modifVoyage = async () => {
      try {

    setIsAdding(true);

    if (selectedEvent && selectedJournee) {
      
      // Effectuez la requête API pour ajouter un événement
        voyageService.ajouterEvenement(voyageKey, {
            type,
            journee: selectedJournee,
            idEvenement: selectedEvent.id,
            typeEvenement: selectedEvent.typeEv
        })
      .then((response: any) => {

        const apiResult = response.data;

        // Vérifiez si la réponse de l'API est "Erreur"
        if (apiResult !== "Erreur") {

          // Fermez la modale
          closeModal();

          setEventCategories((prevCategories) => {
            // Trouvez la journée correspondante dans la liste
            const updatedCategories = prevCategories.map((day) => {
              if (day.id_journee === selectedJournee) {

                const newEvenements = [...day.evenements];

                // Ajoutez la CardLieuModifie au-dessus du bon événement
                newEvenements.splice(selectedEventIndex, 0, {
                  typeEv: type === 'lieu' || type === 'repas' ? 'L' : type === 'trajet' || type === 'location' ? 'T' : '',
                  id: apiResult.message, // Utilisez le résultat de l'API comme ID de l'événement
                  ordre: selectedEvent.ordre,
              libelle1: "",
              libelle2: "",
              prix: "",
              paiement: "",
              lat: "",
              longi: "",
              place: "",
              bloc: "",
              type: type === 'repas' ? 'O' : '',
              lien: "",
              etat_lien: "",
              lien_modifie: "",
              ref: "",
              compagnie: "",
              debut: "",
              fin: "",
              lat2: "",
              longi2: "",
              distance: "",
              duree: "",
              location: type === 'location' ? 'O' : '',
              moment: type === 'location' ? 'P' : '',
              rattachement: "",
              invisible1: "",
              invisible2: "",
              date_paiement_hebergement: "",
              acompte_hebergement: "",
              image: "",
              document: ""
            });
                    // Return a new object with the updated evenements array
                    return {
                      ...day,
                      evenements: newEvenements,
                    };
                    }
                    return day;
                  });
        
                      return updatedCategories;
                });
                setIsAdding(false);
              } else {
                setIsAdding(false);
                console.error("Erreur lors de la requête API");
                // Gérez l'erreur, affichez un message, etc.
              }

      })
      .catch((error: any) => {
        setIsAdding(false);

        if (error.response?.status === 401) {
          alert("Erreur lors de l'ajout d'évenement, vous n'êtes plus connecté(e).")
          localStorage.removeItem('xsrfToken');
          navigate('/login');
        }
        else
        {
          alert("Erreur lors de l'ajout d'évenement");
        }
        console.error("Erreur lors de l'ajout d'évenement:", error);
      });
  
      } 
      else
      {
        setIsAdding(false);
      }
    } catch (error) {
      console.error(error);
      alert("Erreur lors de la modification !");
    }
  };

  const processModifVoyage = async () => {
    
    const verrouillageResultat = await verrouillageModif();

    if (verrouillageResultat === 1) {
      modifVoyage();
    }
  };

  processModifVoyage();
  }

  const addEvenementLendemain = async (type: string, idJournee: number, idEvenement: number) => {
    const modifVoyage = async () => {
      try {
        setIsAdding(true);
  
        // Effectuez la requête API pour ajouter un événement
        voyageService.ajouterEvenement(voyageKey, {
          type: "trajet",
          journee: idJournee,
          idEvenement,
          typeEvenement: type,
          lendemain: "O"
        })
          .then((response: any) => {
            const apiResult = response.data;
  
            // Vérifiez si la réponse de l'API est "Erreur"
            if (apiResult !== "Erreur") {
              setEventCategories((prevCategories) => {
                // Créer une copie des catégories pour éviter les mutations directes
                const updatedCategories = prevCategories.map((day) => {
                  if (day.id_journee === idJournee) {
  
                    // Créez une copie du tableau des événements
                    const updatedEvenements = [...day.evenements];
  
                    // Ajoutez la CardLieuModifie au-dessus du bon événement
                    updatedEvenements.splice(0, 0, {
                      typeEv: "T",
                      id: apiResult.message, // Utilisez le résultat de l'API comme ID de l'événement
                      ordre: 1,
                      libelle1: "",
                      libelle2: "",
                      prix: "",
                      paiement: "",
                      lat: "",
                      longi: "",
                      place: "",
                      bloc: "",
                      type: "",
                      lien: "",
                      etat_lien: "",
                      lien_modifie: "",
                      ref: "",
                      compagnie: "",
                      debut: "",
                      fin: "",
                      lat2: "",
                      longi2: "",
                      distance: "",
                      duree: "",
                      location: "",
                      moment: "",
                      rattachement: "",
                      invisible1: "O",
                      invisible2: "",
                      date_paiement_hebergement: "",
                      acompte_hebergement: "",
                      image: "",
                      document: ""
                    });
  
                    // Retourner une copie de l'objet avec les événements mis à jour
                    return {
                      ...day,
                      evenements: updatedEvenements,
                    };
                  }
                  return day;
                });
  
                return updatedCategories;
              });
  
              setIsAdding(false);
            } else {
              setIsAdding(false);
              console.error("Erreur lors de la requête API");
            }
          })
          .catch((error: any) => {
            setIsAdding(false);
            if (error.response?.status === 401) {
              alert("Erreur lors de l'ajout d'évenement, vous n'êtes plus connecté(e).");
              localStorage.removeItem('xsrfToken');
              navigate('/login');
            } else {
              alert("Erreur lors de l'ajout d'évenement");
            }
            console.error("Erreur lors de l'ajout d'évenement:", error);
          });
      } catch (error) {
        console.error(error);
        alert("Erreur lors de la modification !");
      }
    };
  

  const processModifVoyage = async () => {
    
    const verrouillageResultat = await verrouillageModif();

    if (verrouillageResultat === 1) {
      modifVoyage();
    }
  };

  processModifVoyage();
  }
        
        const moveCard = async (dragIndex: number, hoverIndex: number, draggedIdJournee: number | null, id_journee: number) => {

          const modifVoyage = async () => {
            try {
          if (draggedIdJournee !== null) {

            const updatedCategories = [...eventCategories];
            const dragDayIndex = updatedCategories.findIndex((day) => day.id_journee === draggedIdJournee);
            const dropDayIndex = updatedCategories.findIndex((day) => day.id_journee === id_journee);
            const dragCard = updatedCategories[dragDayIndex]?.evenements[dragIndex];
            const dropCard = updatedCategories[dropDayIndex]?.evenements[hoverIndex];

            if (dragCard && dropCard) {
              const dropIdJournee = id_journee;

              let typeEvenement="";
              let idEvenement=dragCard.id;
              let typeEvenementSuivant="";
              let idEvenementSuivant=dropCard.id;
  
              switch (dragCard.typeEv) {
                  case 'L':
                      typeEvenement="lieu";
                    break;
                  case 'T':
                      typeEvenement="trajet";
                    break;
                }
  
                switch (dropCard.typeEv) {
                  case 'L':
                      typeEvenementSuivant="lieu";
                    break;
                  case 'T':
                      typeEvenementSuivant="trajet";
                    break;
                    case 'N':
                      typeEvenementSuivant="nuit";
                    break;
                }

          const isNotLastEventOfDay = (idJournee: number, eventIndex: number): boolean => {
                  const day = eventCategories.find((day) => day.id_journee === idJournee);
                  return day ? eventIndex < day.evenements.length - 1 : false;
          };

          if(dropIdJournee===draggedIdJournee 
            && (typeEvenementSuivant === 'nuit' || !isNotLastEventOfDay(dropIdJournee, hoverIndex)))
          {
            alert('Vous ne pouvez pas déplacer cet élément en fin de journée !')
          }
          else
          {

         // Make a copy of the categories array
        const updatedCategoriesCopy = updatedCategories.map(category => ({
          ...category,
          evenements: [...category.evenements]
        }));

        // Remove the card from its initial position
        updatedCategoriesCopy[dragDayIndex].evenements.splice(dragIndex, 1);

        updatedCategoriesCopy[dropDayIndex].evenements.splice(hoverIndex, 0, dragCard);
                  
        setEventCategories(updatedCategoriesCopy);


           // Effectuez la requête API pour déplacer un événement
            voyageService.deplacerEvenement(voyageKey, {
              type: typeEvenement,
                typeSuivant: typeEvenementSuivant,
                id: idEvenement,
                idSuivant: idEvenementSuivant
          })
        .then((response: any) => {

           if (response.ok) {
            const apiResult = response.data;

      
            // Vérifiez si la réponse de l'API est "Erreur"
            if (apiResult === "Erreur") {
                alert("Erreur : le déplacement n'a pas pu être enregistré. Veuillez actualiser la page.");   
                  } 
                } 
            })
            .catch((error: any) => {
              if (error.response?.status === 401) {
                alert("Erreur lors du deplacement de l'évenement, vous n'êtes plus connecté(e).")
                localStorage.removeItem('xsrfToken');
                navigate('/login');
              }
              else
              {
                alert("Erreur lors du deplacement de l'évenement");
              }
              console.error("Erreur lors de du deplacement de l'évenement:", error);
            });
           }          
          }
        }

      } catch (error) {
        console.error(error);
        alert("Erreur lors de la modification !");
      }
    };
  
    const processModifVoyage = async () => {
      
      const verrouillageResultat = await verrouillageModif();
  
      if (verrouillageResultat === 1) {
        modifVoyage();
      }
    };
  
    processModifVoyage();
      };
        
      const shouldCallSuppressionClick = (day: any, eventIndex: number) => {
        const isLastEvent = eventIndex === day.evenements.length - 1;
      
        if (isLastEvent) {
          return false;
        }

      
        return true;
      };
      

      const removeEventFromState = (journeeId: number, eventId: number, typeEvenement: string) => {
        setEventCategories((prevCategories) => {
          return prevCategories.map((day) => {
            if (day.id_journee === journeeId) {
              return {
                ...day, // Create a new day object
                evenements: day.evenements.filter((event) => {
                  // Ajoutez la condition de filtrage basée sur le typeEvenement
                  if (typeEvenement === 'lieu' && event.typeEv === 'L') {
                    return event.id !== eventId;
                  } else if (typeEvenement === 'trajet' && event.typeEv === 'T') {
                    return event.id !== eventId;
                  } else if (typeEvenement === 'nuit' && event.typeEv === 'N') {
                    return event.id !== eventId;
                  }
                  return true; // Si le typeEvenement ne correspond pas, incluez l'événement
                })
              };
            }
            return day;
          });
        });
      };
      
      
        const handleSuppressionClick = async (typeEvenement: string, idEvenement: number) => {
          const modifVoyage = async () => {
            try {
              const isConfirmed = window.confirm(
            "Êtes-vous sûr de vouloir définitivement supprimer cet élément ?"
          );
      
          // Vérifiez si l'utilisateur a confirmé
          if (isConfirmed) {

          voyageService.supprimerEvenement(voyageKey, {
            typeEvenement,
            idEvenement
          })
          .then((response: any) => {
          
            if(response.data.message === 'ok')
            {
              removeEventFromState(response.data.idJournee, idEvenement, typeEvenement);

              if(response.data.idNuit !== '')
              {
                //on reaffiche la nuit
                handleElementChange(response.data.idJournee, response.data.idNuit, "nuit", "N", "invisible1");
        
              }
              if(typeEvenement === "trajet")
              {
                handleSuppLocation(idEvenement);
              }
            }
            else
            {
              alert("Erreur lors de la suppression d'évenement");
            }

          })
          .catch((error: any) => {

            if (error.response?.status === 401) {
              alert("Erreur lors de la suppression d'évenement, vous n'êtes pas le créateur du voyage.")
              navigate('/');
            }
            else
            {
              alert("Erreur lors de la suppression d'évenement");
            }
          })
        }
      } catch (error) {
        console.error(error);
        alert("Erreur lors de la modification !");
      }
      };
    
      const processModifVoyage = async () => {
    
      const verrouillageResultat = await verrouillageModif();
    
      if (verrouillageResultat === 1) {
        modifVoyage();
      }
      };
    
      processModifVoyage();
      }; 

        const handleLienClick = async (typeEvenement: string, idEvenement: number) => {

          voyageService.getLien(voyageKey, typeEvenement, idEvenement, emailPartage, codePartage)
          .then((response: any) => {
          
            if(response.data.message === 'ok')
            {
              setLien(response.data.lien);
              setEtatLien(response.data.etat_lien);
              setLienModifie(response.data.lien_modifie);
              setMotifLien(response.data.motif_lien);
              openModalLien(typeEvenement, idEvenement);
            }
            else
            {
              alert("Erreur lors de la récupération du lien");
            }

          })
          .catch((error: any) => {

            if (error.response?.status === 401) {
              alert("Erreur lors de la récupération du lien, vous n'êtes pas connecté(e) avec le compte du créateur du voyage.")
              navigate('/');
            }
            else
            {
              alert("Erreur lors de la récupération du lien");
            }
          })
      }; 

      const handleBlocNotesClick = async (typeEvenement: string, idEvenement: number) => {

        voyageService.getBlocNotes(voyageKey, typeEvenement, idEvenement, emailPartage, codePartage)
        .then((response: any) => {

        
          if(response.data.message === 'ok')
          {
            setBlocNotes(response.data.bloc_notes);
            openModalBlocNotes(typeEvenement, idEvenement);
          }
          else
          {
            alert("Erreur lors de la récupération du bloc-notes");
          }

        })
        .catch((error: any) => {

          if (error.response?.status === 401) {
            alert("Erreur lors de la récupération du bloc-notes, vous n'êtes pas connecté(e) avec le compte du créateur du voyage.")
            navigate('/');
          }
          else
          {
            alert("Erreur lors de la récupération du bloc-notes");
          }
        })
    };
    
    const handleCoordClick = async (typeEvenement: string, idEvenement: number) => {
      setPOIList([]);
      voyageService.getCoordonnees(voyageKey, typeEvenement, idEvenement)
      .then((response: any) => {

        if(response.data.message === 'ok')
        {
          setIdJourneeForModal(response.data.idJournee);
          setLieu1(response.data.lieu1);
          setLieu2(response.data.lieu2);
          setLatitude1(response.data.latitude1);
          setLatitude2(response.data.latitude2);
          setLongitude1(response.data.longitude1);
          setLongitude2(response.data.longitude2);
          openModalCoord(typeEvenement, idEvenement);
        }
        else
        {
          alert("Erreur lors de la récupération des coordonées");
        }

      })
      .catch((error: any) => {

        if (error.response?.status === 401) {
          alert("Erreur lors de la récupération des coordonnées, vous n'êtes pas connecté(e) avec le compte du créateur du voyage.")
          navigate('/');
        }
        else
        {
          alert("Erreur lors de la récupération des coordonnées");
        }

      })
  };

  function apiWikiData(idWikidata: string): Promise<GeoapifyType> {
    return new Promise(function(resolve, reject) {
        var url='https://www.wikidata.org/w/api.php?action=wbgetentities&ids='+idWikidata+'&format=json&origin=*';
      fetch(url)
      .then(response => {
        // check if the call was successful
        if (response.ok) {
          response.json().then(data => resolve(data as GeoapifyType));
        } else {
          response.json().then(data => reject(data));
        }
      });
    });
  }

  const handlePOIClick = async (idEvenement: number) => {

    setPOIList([]);
    setLoadingPOI(true);
    setSanitizedHTML("");
    setAucunPOI(false);

    voyageService.getCoordonnees(voyageKey, 'lieu', idEvenement)
          .then((response: any) => {

            if(response.data.message === 'ok')
            {
              
              setIdJourneeForModal(response.data.idJournee);

              openModalPOI(idEvenement);
              var coordonnees=response.data.longitude1+','+response.data.latitude1;

              if(coordonnees !== '')
              {
                var url = 'https://api.geoapify.com/v2/places?categories=tourism.attraction,tourism.sights,entertainment.museum,entertainment.zoo,entertainment.planetarium,entertainment.aquarium,entertainment.theme_park,entertainment.water_park,leisure.park&filter=circle:'+coordonnees+',10000&bias=proximity:'+coordonnees+'&limit=150&lang=fr&apiKey='+apiKeyGeoapify;


                fetch(url)
                //fetch(url, requestOptions)
                .then(response => {
                    // check if the call was successful
                    if (response.ok) {
                      response.json().then(data => {
 
                        if(data.features.length > 0)
                        {
                          var maxElements=100;
                          var compteur=0;
                          var oldName="";
                          var exclusion=false;
                          data.features.forEach((item: GeoapifyType) => {
              
                              if(item.properties.name !== undefined && item.properties.address_line1 !== undefined && item.properties.name !== 'Fontaine' && item.properties.name.indexOf('Fontaine Wallace') === -1)
                              {
                                  exclusion=false;
                                  for(var i=0;i <item.properties.details.length;i++)
                                  {
                                      if(item.properties.details[i] === 'details.artwork') 
                                      {
                                          exclusion=true;
                                      }
                                      
                                      if(!exclusion && item.properties.details[i] === 'details.wiki_and_media' && item.properties.name !== oldName) 
                                      {
                                          if(compteur < maxElements)
                                          {
                                              compteur++;
                                             oldName=item.properties.name; 
                                              setPOIList((prevPOIList) => [...prevPOIList, item]);

                                          }
                                      }
                                  }
                              }
                          });
                          setLoadingPOI(false); 
                        }
                        else
                        {
                          setLoadingPOI(false); 
                          setAucunPOI(true);
                        }
                       
                      });
                    } else {
                      response.json().then(data => {
                        alert("Erreur lors de la récupération des points d'intérêt");
                      });
                    }
                  })
                .catch(error => {
                  console.log("error fetch routematrix", error);
                });
                
                }
        
          
            }
            else
            {
   
              alert("Erreur lors de la récupération des donnees");
            }

          })
          .catch((error: any) => {

            if (error.response?.status === 401) {
              alert("Erreur lors de la récupération des données, vous n'êtes pas connecté(e) avec le compte du créateur du voyage.")
              navigate('/');
            }
            else
            {
              alert("Erreur lors de la récupération des données");
            }

          })
};

const handleInfosPOIClick = (index: number) => {

  const nomPOI=POIList[index].properties.address_line1;
  const countryCode=POIList[index].properties.country_code;
  const placeId=POIList[index].properties.place_id;

  setSanitizedHTML("");

  setAjoutActivitePOI(false);
  setAjoutLieuPOI(false);

  var url = 'https://api.geoapify.com/v2/place-details?id='+placeId+'&lang=fr&apiKey='+apiKeyGeoapify;
            
                fetch(url)
                //fetch(url, requestOptions)
                .then(response => {
                    // check if the call was successful
                    if (response.ok) {
                      response.json().then(data => onShowPOI(data, nomPOI, countryCode));
                    } else {
                      response.json().then(data => {
                        alert("Erreur lors de la récupération des points d'intérêt");
                      });
                    }
                  })
                .catch(error => {
                  console.log("error fetch infos POI detail", error);
                });
                    

}


function onShowPOI(data: FeatureGeoapifyType, nomPOI: string, countryCode: string) {
  //$('#boutonAjoutActivite').removeAttr("disabled");
 // $('#boutonAjoutEtape').removeAttr("disabled");
 
 
 setSanitizedHTML("");

   let poi="";
   let libellePoi="";

   if(data.features[0].properties.name_international !== undefined && data.features[0].properties.name_international !== null)
   {
     if(data.features[0].properties.name_international.fr !== undefined && data.features[0].properties.name_international.fr !== null)
     {
         libellePoi=data.features[0].properties.name_international.fr;
     }
     else if(data.features[0].properties.name_international.en !== undefined && data.features[0].properties.name_international.en !== null)
     {
         libellePoi=data.features[0].properties.name_international.en;
     }
  }
  if(libellePoi === '' )
  {
   if(data.features[0].properties.name !== undefined)
   {
     libellePoi=data.features[0].properties.name;
   }
   else
   {
       libellePoi=nomPOI;
   }
  }
  if(libellePoi !== '' )
  {
     libellePoi=libellePoi.charAt(0).toUpperCase() + libellePoi.substring(1);
  }

   let latitude=data.features[0].properties.lat;
   let longitude=data.features[0].properties.lon;

   setLatitudePOI(latitude);
   setLongitudePOI(longitude);
   setLibelleLieuPOI(libellePoi);
   setPlaceIdPOI(data.features[0].properties.place_id);

   if(data.features[0].properties.wiki_and_media !== undefined && data.features[0].properties.wiki_and_media !== null)
   {
     var idWikidata=data.features[0].properties.wiki_and_media.wikidata;

     if(idWikidata !== undefined)
     {

         apiWikiData(idWikidata).then(dataWiki => affichagePOI(dataWiki,idWikidata, countryCode));
     }
     else
     {
         if(data.features[0].properties.wiki_and_media.wikipedia !== undefined)
         {
             const chars = data.features[0].properties.wiki_and_media.wikipedia.split(':');
             let langue=chars[0];
             let titreWiki=chars[1];
             poi = "";
           
             apiWikipediaPhoto(langue, titreWiki).then(dataWikipediaPhoto => {
             
                 if(dataWikipediaPhoto.query.pages[0].thumbnail !== undefined && dataWikipediaPhoto.query.pages[0].thumbnail !== null)
                 {
                     let largeur=200;
                     if(dataWikipediaPhoto.query.pages[0].thumbnail.height > dataWikipediaPhoto.query.pages[0].thumbnail.width)
                     {
                         largeur=100;
                     }

                     let tabImage=dataWikipediaPhoto.query.pages[0].thumbnail.source.split('/');

                     let nomImage=tabImage[tabImage.length-2];
                     let creditsImage="https://commons.wikimedia.org/wiki/File:"+nomImage;

                     apiWikipediaPhotoCredits(nomImage).then(dataPhotoCredits => {
                         let credits='';
                         var keys = Object.keys(dataPhotoCredits.query.pages);
                         var pageId = parseInt(keys[0]);

                     if(pageId !== -1 && dataPhotoCredits.query.pages[pageId].imageinfo !== undefined)
                     {   
                         credits='';
                         if(dataPhotoCredits.query.pages[pageId].imageinfo[0].extmetadata.AttributionRequired !== undefined && dataPhotoCredits.query.pages[pageId].imageinfo[0].extmetadata.AttributionRequired.value === 'true')
                             {

                                 if(dataPhotoCredits.query.pages[pageId].imageinfo[0].extmetadata.Credit !== undefined)
                                 {
                                     credits+="Source : ";
                                     const regex = /, includes:\n<\/p>\n<ul class="gallery mw-gallery-traditional">.*<\/ul>/gis;
                                     const regex2 = /<ul class="gallery mw-gallery-traditional">.*<\/ul>/gis;
                                     credits=dataPhotoCredits.query.pages[pageId].imageinfo[0].extmetadata.Credit.value.replace(regex,'</p>');
                                     credits=credits.replace(regex2,'');
                                 }
                                 if(dataPhotoCredits.query.pages[pageId].imageinfo[0].extmetadata.Artist !== undefined)
                                 {
                                     if(credits !== '')
                                     {
                                         credits+=" - "
                                     }
                                     credits+="Auteur : "+dataPhotoCredits.query.pages[pageId].imageinfo[0].extmetadata.Artist.value+" ";
                                 }
                                 if(dataPhotoCredits.query.pages[pageId].imageinfo[0].extmetadata.UsageTerms !== undefined)
                                 {
                                     
                                     if(dataPhotoCredits.query.pages[pageId].imageinfo[0].extmetadata.LicenseUrl !== undefined)
                                     {
                                         if(credits !== '')
                                         {
                                             credits+=" - "
                                         }
                                         credits+="Licence : <a target=_blank rel='noreferrer' href="+dataPhotoCredits.query.pages[pageId].imageinfo[0].extmetadata.LicenseUrl.value+">"+dataPhotoCredits.query.pages[pageId].imageinfo[0].extmetadata.UsageTerms.value+"</a>";
                                     }
                                     else
                                     {
                                         if(credits !== '')
                                         {
                                             credits+=" - "
                                         }
                                         credits+="Licence : "+dataPhotoCredits.query.pages[pageId].imageinfo[0].extmetadata.UsageTerms.value;
                                     }

                                 }   
                             }
                         
                         let elementsImage = '<div><a href='+creditsImage+' target="_blank" rel="noreferrer" title="Crédits photo"><img width='+largeur+' style="padding-bottom:6px;" src='+dataWikipediaPhoto.query.pages[0].thumbnail.source+' /></a></div><div style="font-size:5px;line-height:8px;padding-bottom:9px;">'+credits+'</div>';

                         apiWikipedia(langue, titreWiki).then(dataWikipedia => {
                         let contenuWikipedia="";
                         if(dataWikipedia.query.pages[0].extract !== undefined)
                         {
                             contenuWikipedia=dataWikipedia.query.pages[0].extract;
                         }
                         poi = '<h4>'+dataWikipedia.query.pages[0].title+'</h4>';
                         poi += elementsImage;
                         poi += '<p style="font-size:11px;line-height:15px;">'+contenuWikipedia+' (...) <a target="_blank" rel="noreferrer" href="https://'+langue+'.wikipedia.org/wiki/'+titreWiki+'" style="font-size:11px;line-height:15px;">Lire la suite sur Wikipedia</a>.</p><p style="font-size:8px;line-height:20px;">Données issues des projets collaboratifs <a style="font-size:8px;line-height:20px;" href="https://www.wikidata.org/wiki/Wikidata:Introduction/fr" target="_blank" rel="noreferrer">Wikidata</a> et <a style="font-size:8px;line-height:20px;" href="https://fr.wikipedia.org/wiki/Wikip%C3%A9dia:Accueil_principal" target="_blank" rel="noreferrer">Wikipedia</a>.</p>';
                         setSanitizedHTML(DOMPurify.sanitize(poi));

                         });

                     }
                     else
                     {
                         poi += '<h4>'+libellePoi+'</h4><p style="font-size:11px">Aucune information disponible.</p>';         
                     }
                     setSanitizedHTML(DOMPurify.sanitize(poi));

                     });

                 }
                 else
                 {
                     poi += '<h4>'+libellePoi+'</h4><p style="font-size:11px">Aucune information disponible.</p>';         
                 }
                 setSanitizedHTML(DOMPurify.sanitize(poi));

                 }
             )
             .catch( error => {
               poi += '<h4>'+libellePoi+'</h4><p style="font-size:11px">Aucune information disponible.</p>'
               setSanitizedHTML(DOMPurify.sanitize(poi));

             });
             
         }
         else
         {
             poi += '<h4>'+libellePoi+'</h4><p style="font-size:11px">Aucune information disponible.</p>'; 
         }
     }
  }
  else
  {
     poi += '<h4>'+libellePoi+'</h4><p style="font-size:11px">Aucune information disponible.</p>'; 
  }

  setSanitizedHTML(DOMPurify.sanitize(poi));

 }

 function affichagePOI(data: GeoapifyType, idWikidata: string , countryCode: string) {
  let poi = "";
  let titreWiki="";
  let langue="";
  let wikiTrouve=false;
  let wikiPays=countryCode+"wiki";

  if(data.entities[idWikidata].sitelinks["frwiki"] !== undefined && data.entities[idWikidata].sitelinks["frwiki"] !== null)
  {
      titreWiki=data.entities[idWikidata].sitelinks["frwiki"].title;
      langue='fr';
      wikiTrouve=true;
  }
  else if(data.entities[idWikidata].sitelinks["enwiki"] !== undefined && data.entities[idWikidata].sitelinks["enwiki"] !== null)
  {
      titreWiki=data.entities[idWikidata].sitelinks["enwiki"].title;
      langue='en';
      wikiTrouve=true;
  }
  else if(data.entities[idWikidata].sitelinks["eswiki"] !== undefined && data.entities[idWikidata].sitelinks["eswiki"] !== null)
  {
      titreWiki=data.entities[idWikidata].sitelinks["eswiki"].title;
      langue='es';
      wikiTrouve=true;
  }
  else if(data.entities[idWikidata].sitelinks[wikiPays] !== undefined && data.entities[idWikidata].sitelinks[wikiPays] !== null)
  {
      titreWiki=data.entities[idWikidata].sitelinks[wikiPays].title;
      langue=countryCode;
      wikiTrouve=true;
  }
  else if(data.entities[idWikidata].sitelinks["ptwiki"] !== undefined && data.entities[idWikidata].sitelinks["ptwiki"] !== null)
  {
      titreWiki=data.entities[idWikidata].sitelinks["ptwiki"].title;
      langue='pt';
      wikiTrouve=true;
  }
  else if(data.entities[idWikidata].sitelinks["itwiki"] !== undefined && data.entities[idWikidata].sitelinks["itwiki"] !== null)
  {
      titreWiki=data.entities[idWikidata].sitelinks["itwiki"].title;
      langue='it';
      wikiTrouve=true;
  }
  else if(data.entities[idWikidata].sitelinks["dewiki"] !== undefined && data.entities[idWikidata].sitelinks["dewiki"] !== null)
  {
      titreWiki=data.entities[idWikidata].sitelinks["dewiki"].title;
      langue='de';
      wikiTrouve=true;
  }
  else if(data.entities[idWikidata].labels["fr"] !== undefined && data.entities[idWikidata].labels["fr"] !== null)
  {
      titreWiki=data.entities[idWikidata].labels["fr"].value;
      langue='fr';
  }
  else if(data.entities[idWikidata].labels["en"] !== undefined && data.entities[idWikidata].labels["en"] !== null)
  {
      titreWiki=data.entities[idWikidata].labels["en"].value;
      langue='en';
  }
  else if(data.entities[idWikidata].labels[wikiPays] !== undefined && data.entities[idWikidata].labels[wikiPays] !== null)
  {
      titreWiki=data.entities[idWikidata].labels[wikiPays].value;
      langue=countryCode;
  }
  else if(data.entities[idWikidata].labels["es"] !== undefined && data.entities[idWikidata].labels["es"] !== null)
  {
      titreWiki=data.entities[idWikidata].labels["es"].value;
      langue='es';
  }
  else if(data.entities[idWikidata].labels["pt"] !== undefined && data.entities[idWikidata].labels["pt"] !== null)
  {
      titreWiki=data.entities[idWikidata].labels["pt"].value;
      langue='pt';
      wikiTrouve=true;
  }
  else if(data.entities[idWikidata].labels["it"] !== undefined && data.entities[idWikidata].labels["it"] !== null)
  {
      titreWiki=data.entities[idWikidata].labels["it"].value;
      langue='it';
      wikiTrouve=true;
  }
  else if(data.entities[idWikidata].labels["de"] !== undefined && data.entities[idWikidata].labels["de"] !== null)
  {
      titreWiki=data.entities[idWikidata].labels["de"].value;
      langue='de';
      wikiTrouve=true;
  }

  poi = "";

  if(wikiTrouve)
  {
      apiWikipediaPhoto(langue, titreWiki).then(dataWikipediaPhoto => {
      
          if(dataWikipediaPhoto.query.pages[0].thumbnail !== undefined && dataWikipediaPhoto.query.pages[0].thumbnail !== null)
          {
              let largeur=200;
              if(dataWikipediaPhoto.query.pages[0].thumbnail.height > dataWikipediaPhoto.query.pages[0].thumbnail.width)
              {
                  largeur=100;
              }
              let tabImage=dataWikipediaPhoto.query.pages[0].thumbnail.source.split('/');
              let nomImage = "";
                  if(tabImage[tabImage.length-2].length > 4 && tabImage[tabImage.length-1].indexOf(tabImage[tabImage.length-2]))
                  {
                      nomImage=tabImage[tabImage.length-2];
                  }
                  else
                  {
                      nomImage=tabImage[tabImage.length-1];
                  }
                  let creditsImage="https://commons.wikimedia.org/wiki/File:"+nomImage;
                  apiWikipediaPhotoCredits(nomImage).then(dataPhotoCredits => {
                    
                      let credits='';
                      var keys = Object.keys(dataPhotoCredits.query.pages);
                      var pageId = parseInt(keys[0]);

                      if(pageId !== -1 && dataPhotoCredits.query.pages[pageId].imageinfo !== undefined)
                      {
                          credits='';
                          if(dataPhotoCredits.query.pages[pageId].imageinfo[0].extmetadata.AttributionRequired !== undefined && dataPhotoCredits.query.pages[pageId].imageinfo[0].extmetadata.AttributionRequired.value === 'true')
                          {

                              if(dataPhotoCredits.query.pages[pageId].imageinfo[0].extmetadata.Credit !== undefined)
                              {
                                  credits+="Source : ";
                                  const regex = /, includes:\n<\/p>\n<ul class="gallery mw-gallery-traditional">.*<\/ul>/gis;
                                  const regex2 = /<ul class="gallery mw-gallery-traditional">.*<\/ul>/gis;
                                  credits=dataPhotoCredits.query.pages[pageId].imageinfo[0].extmetadata.Credit.value.replace(regex,'</p>');
                                  credits=credits.replace(regex2,'');
                              
                              }
                              if(dataPhotoCredits.query.pages[pageId].imageinfo[0].extmetadata.Artist !== undefined)
                              {
                                  if(credits !== '')
                                  {
                                      credits+=" - "
                                  }
                                  credits+="Auteur : "+dataPhotoCredits.query.pages[pageId].imageinfo[0].extmetadata.Artist.value+" ";
                              }
                              if(dataPhotoCredits.query.pages[pageId].imageinfo[0].extmetadata.UsageTerms !== undefined)
                              {
                                  
                                  if(dataPhotoCredits.query.pages[pageId].imageinfo[0].extmetadata.LicenseUrl !== undefined)
                                  {
                                      if(credits !== '')
                                      {
                                          credits+=" - "
                                      }
                                      credits+="Licence : <a target=_blank rel='noreferrer' href="+dataPhotoCredits.query.pages[pageId].imageinfo[0].extmetadata.LicenseUrl.value+">"+dataPhotoCredits.query.pages[pageId].imageinfo[0].extmetadata.UsageTerms.value+"</a>";
                                  }
                                  else
                                  {
                                      if(credits !== '')
                                      {
                                          credits+=" - "
                                      }
                                      credits+="Licence : "+dataPhotoCredits.query.pages[pageId].imageinfo[0].extmetadata.UsageTerms.value;
                                  }

                              }   
                          }

                          let elementsImage = '<div><a href='+creditsImage+' target="_blank" rel="noreferrer" rel="noreferrer" title="Crédits photo"><img width='+largeur+' style="padding-bottom:6px;" src='+dataWikipediaPhoto.query.pages[0].thumbnail.source+' /></a></div><div class="sourcePhotoWiki" style="font-size:5px;line-height:8px;padding-bottom:9px;">'+credits+'</div>';
                          
                          apiWikipedia(langue, titreWiki).then(dataWikipedia => {
                          let contenuWikipedia="";
                          if(dataWikipedia.query.pages[0].extract !== undefined)
                          {
                              contenuWikipedia=dataWikipedia.query.pages[0].extract;
                          }
                          poi = '<h4>'+dataWikipedia.query.pages[0].title+'</h4>';
                          poi += elementsImage;
                          poi += '<p style="font-size:11px;line-height:15px;">'+contenuWikipedia+' (...) <a style="font-size:11px;line-height:15px;" target="_blank" rel="noreferrer" href="https://'+langue+'.wikipedia.org/wiki/'+titreWiki+'">Lire la suite sur Wikipedia</a>.</p><p style="font-size:8px;line-height:20px;">Données issues des projets collaboratifs <a style="font-size:8px;line-height:20px;" href="https://www.wikidata.org/wiki/Wikidata:Introduction/fr" target="_blank">Wikidata</a> et <a style="font-size:8px;line-height:20px;" href="https://fr.wikipedia.org/wiki/Wikip%C3%A9dia:Accueil_principal" target="_blank">Wikipedia</a>.</p>';
 
                          setSanitizedHTML(DOMPurify.sanitize(poi));
                        });
                      }
                      else
                      {   
                          let elementsImage = '<div><a href='+creditsImage+' target="_blank" rel="noreferrer" title="Crédits photo"><img width='+largeur+' style="padding-bottom:6px;" src='+dataWikipediaPhoto.query.pages[0].thumbnail.source+' /></a></div>';
                          
                          apiWikipedia(langue, titreWiki).then(dataWikipedia => {
                          let contenuWikipedia="";
                          if(dataWikipedia.query.pages[0].extract !== undefined)
                          {
                              contenuWikipedia=dataWikipedia.query.pages[0].extract;
                          }
                          poi = '<h4>'+dataWikipedia.query.pages[0].title+'</h4>';
                          poi += elementsImage;
                          poi += '<p style="font-size:11px;line-height:15px;">'+contenuWikipedia+' (...) <a style="font-size:11px;line-height:15px;" target="_blank" rel="noreferrer" href="https:/'+langue+'.wikipedia.org/wiki/'+titreWiki+'">Lire la suite sur Wikipedia</a>.</p><p style="font-size:8px;line-height:20px;">Données issues des projets collaboratifs <a style="font-size:8px;line-height:20px;" href="https://www.wikidata.org/wiki/Wikidata:Introduction/fr" target="_blank" rel="noreferrer">Wikidata</a> et <a style="font-size:8px;line-height:20px;" href="https://fr.wikipedia.org/wiki/Wikip%C3%A9dia:Accueil_principal" target="_blank" rel="noreferrer">Wikipedia</a>.</p>';
                        
                          setSanitizedHTML(DOMPurify.sanitize(poi));
                          }); 
                      }
                  
                  });
          }
          else
          {
              apiWikipedia(langue, titreWiki).then(dataWikipedia => {
                  let contenuWikipedia="";
                  if(dataWikipedia.query.pages[0].extract !== undefined)
                  {
                      contenuWikipedia=dataWikipedia.query.pages[0].extract;
                      poi = '<h4>'+dataWikipedia.query.pages[0].title+'</h4>';
                      poi += '<p style="font-size:11px;line-height:15px;">'+contenuWikipedia+' (...) <a style="font-size:11px;line-height:15px;" target="_blank" rel="noreferrer" href="https://'+langue+'.wikipedia.org/wiki/'+titreWiki+'">Lire la suite sur Wikipedia</a>.</p><p style="font-size:8px;line-height:20px;">Données issues des projets collaboratifs <a style="font-size:8px;line-height:20px;" href="https://www.wikidata.org/wiki/Wikidata:Introduction/fr" target="_blank" rel="noreferrer">Wikidata</a> et <a style="font-size:8px;line-height:20px;" href="https://fr.wikipedia.org/wiki/Wikip%C3%A9dia:Accueil_principal" target="_blank" rel="noreferrer">Wikipedia</a>.</p>';
                  }
                  else
                  {
                      titreWiki=titreWiki.charAt(0).toUpperCase() + titreWiki.substring(1);
                      titreWiki=titreWiki.replace(/_/g, " ");
                      poi += '<h4>'+titreWiki+'</h4><p style="font-size:11px">Aucune information disponible.</p>';         
                  
                  }

                  setSanitizedHTML(DOMPurify.sanitize(poi));
              });}
          
          }
      )
      .catch( error => {
          titreWiki=titreWiki.charAt(0).toUpperCase() + titreWiki.substring(1);
          titreWiki=titreWiki.replace(/_/g, " ");
          poi += '<h4>'+titreWiki+'</h4><p style="font-size:11px">Aucune information disponible.</p>';});
 
          setSanitizedHTML(DOMPurify.sanitize(poi));
  }
  else
  {
      let description='';
      if(data.entities[idWikidata].descriptions !== undefined && data.entities[idWikidata].descriptions !== null)
      {
          if(data.entities[idWikidata].descriptions["fr"] !== undefined && data.entities[idWikidata].descriptions["fr"] !== null)
          {
              description=data.entities[idWikidata].descriptions["fr"].value;
          }
          else if(data.entities[idWikidata].descriptions["en"] !== undefined && data.entities[idWikidata].descriptions["en"] !== null)
          {
              description=data.entities[idWikidata].descriptions["en"].value;
          }
          else if(data.entities[idWikidata].descriptions[countryCode] !== undefined && data.entities[idWikidata].descriptions[countryCode] !== null)
          {
              description=data.entities[idWikidata].descriptions[countryCode].value;
          }
      }
      if(titreWiki !== undefined && titreWiki !== null)
      {
        titreWiki=titreWiki.charAt(0).toUpperCase() + titreWiki.substring(1);
        titreWiki=titreWiki.replace(/_/g, " ");  
      }
      
      if(description !== '')
      {
          description=description.charAt(0).toUpperCase() + description.substring(1);
          poi += '<h4>'+titreWiki+'</h4><p style="font-size:11px">'+description+'</p>'; 
      }
      else
      {
          poi += '<h4>'+titreWiki+'</h4><p style="font-size:11px">Aucune information disponible.</p>'; 
      }        
  }

  setSanitizedHTML(DOMPurify.sanitize(poi));
//  document.getElementById("divPoi").className="col-12 col-lg-7 col-md-7";
}

function apiWikipediaPhoto(langue: string, titreWikipedia: string) {
  return new Promise<WikipediaType>(function(resolve, reject) {
      var url='https://'+langue+'.wikipedia.org/w/api.php?action=query&format=json&formatversion=2&prop=pageimages|pageterms&piprop=thumbnail&pithumbsize=600&titles='+titreWikipedia+'&origin=*';
      var myHeaders = new Headers();
      myHeaders.append("Origin", "*");
      fetch(url, {mode: 'cors', headers: myHeaders})
        .then(response => {
          // check if the call was successful
          if (response.ok) {
            response.json().then(data => resolve(data));
          } else {
            response.json().then(data => reject(data));
          }
        })
        .catch(error => console.log('Authorization failed : ' + error.message));
        
  });
}

function apiWikipediaPhotoCredits(nomPhoto: string) {
  return new Promise<WikipediaType>(function(resolve, reject) {
      var url='https://commons.wikimedia.org/w/api.php?action=query&format=json&origin=*&withCredentials=false&titles=File:'+nomPhoto+'&prop=imageinfo&iiprop=metadata%7Ccommonmetadata%7Cextmetadata';
      fetch(url)
        .then(response => {
          // check if the call was successful
          if (response.ok) {
            response.json().then(data => resolve(data));
          } else {
            response.json().then(data => reject(data));
            
          }
          
        });
  });
}


function apiWikipedia(langue: string, titreWikipedia: string) {
  return new Promise<WikipediaType>(function(resolve, reject) {
      var url='https://'+langue+'.wikipedia.org/w/api.php?action=query&prop=extracts&exintro=true&exsentences=2&exlimit=1&titles='+titreWikipedia+'&explaintext=1&formatversion=2&format=json&origin=*';
      fetch(url)
        .then(response => {
          // check if the call was successful
          if (response.ok) {
            response.json().then(data => resolve(data));
          } else {
            response.json().then(data => reject(data));
          }
        });
  });
}


function getDistancePOI(item: GeoapifyType) {
  let distancePOI=0;
  let distancePOILib="";
  if(item.properties.distance !== null && item.properties.distance !== undefined  && item.properties.distance !== 0)
  {
    distancePOI=item.properties.distance;
    if(distancePOI>999)
    {
        distancePOI=distancePOI/1000;
        distancePOILib=distancePOI.toFixed(1)+" km";
    }
    else
    {
        distancePOILib=distancePOI+" m";
    }
    distancePOILib=" à "+distancePOILib;
  }
  return distancePOILib;
}

function getCategoriePOI(item: GeoapifyType) {
  let categories = "";
  let categorie = "";

  for(var i=0;i <item.properties.categories.length;i++)
  {
    categories+=item.properties.categories[i];
  }
  if(categories.indexOf('entertainment.planetarium') !== -1)
  {
      categorie="Planétarium";
  }
  else if(categories.indexOf('entertainment.zoo') !== -1)
  {
      categorie="Zoo";
  }
  else if(categories.indexOf('entertainment.aquarium') !== -1)
  {
      categorie="Aquarium";
  }
  else if(categories.indexOf('entertainment.theme_park') !== -1)
  {
      categorie="Parc à thème";
  }
  else if(categories.indexOf('entertainment.water_park') !== -1)
  {
      categorie="Parc aquatique";
  }
  else if(categories.indexOf('national_park') !== -1)
  {
      categorie="Parc national";
  }
  else if(categorie==="" && categories.indexOf('entertainment.museum') !== -1)
  {
      categorie="Musée";
  }
  else if(categorie==="" && categories.indexOf('tourism.sights') !== -1)
  {
      categorie="Site touristique";
  }
  else if(categorie==="" && categories.indexOf('tourism.attraction') !== -1)
  {
      categorie="Attraction touristique";
  }
  else if(categories.indexOf('leisure.park') !== -1)
  {
      categorie="Parc";
  }

  return categorie;
}


  const handleConfirmClick = async (typeEvenement: string, idEvenement: number) => {

    if(etatVoyage !== 0)
    {
    voyageService.getConfirm(voyageKey, typeEvenement, idEvenement, emailPartage, codePartage)
    .then((response: any) => {
      
      if(response.data.message === 'ok')
      {
        
        openModalConfirm(typeEvenement, idEvenement);

        let imageURL: string | undefined;

        setConfirmLien(response.data.confirm);
        if(response.data.confirm !== "")
        {

          Axios.get(response.data.confirm, { responseType: 'blob' })
          .then((response) => {
            const blob = new Blob([response.data], { type: 'image/jpeg' });
            imageURL = URL.createObjectURL(blob);

            setConfirm(imageURL);
    
          })
          .catch((error) => console.error('Erreur lors du chargement de l\'image', error));
        }
        else
        {
          setConfirm("");
        }
    
        return () => {
          if (imageURL) {
            URL.revokeObjectURL(imageURL); // Révoquez l'URL seulement lorsque le composant est démonté
          }
        };
      }
      else
      {
        alert("Erreur lors de la récupération de la confirmation");
      }

    })
    .catch((error: any) => {

      if (error.response?.status === 401) {
        alert("Erreur lors de la récupération du document, vous n'êtes pas connecté(e) avec le compte du créateur du voyage.")
        navigate('/');
      }
      else
      {
        alert("Erreur lors de la récupération du document");
      }

    })
  }
  else
  {
    alert("Vous devez être connecté(e) à un compte pour enregistrer une confirmation de réservation.");
  }
};
     
const downloadPDF = () => {
  const link = document.createElement('a');
  link.href = confirm; // L'URL blob
  link.download = 'document.pdf'; // Nom du fichier à télécharger
  link.click(); // Simuler le clic pour déclencher le téléchargement
};

const handleConfirmDelete = async () => {
  
  const fileName = confirmLien.split("/confirmation/")[1];

  try {

    voyageService.supprimerConfirm(voyageKey, fileName)
  .then((response: any) => {

    setConfirm("");
    setConfirmLien("");

    handleElementChange(idJourneeForModal, idEvenementForModal, typeEvenementForModal, "", 'document');

 })
  .catch((error: any) => {
    if (error.response?.status === 401) {
      alert("Erreur lors de la suppression de la confirmation de réservation, vous n'êtes plus connecté(e).")
      localStorage.removeItem('xsrfToken');
      navigate('/login');
    }
    else
    {
      alert("Erreur lors de la suppression de la confirmation de réservation");
    }
    console.error('Error maj image:', error);
  });

    // Optionally, update the user's profile data with the new avatar filename
    //await saveChanges("avatar", fileName);
  } catch (error) {
    console.error("Error uploading file:", error);
    alert("Erreur lors du téléchargement du fichier.");
  }
};

const handleConfirmChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
const fileInput = event.target;
const file = fileInput.files?.[0];

if (file) {
  // Check file type
  if (!/\/(jpeg|jpg|pdf)$/.test(file.type)) {
    alert("Veuillez sélectionner un fichier JPEG, JPG ou PDF.");
    fileInput.value = ""; // Clear the file input
    return;
  }

  // Check file size
  const maxSize = 8 * 1024 * 1024; // 8MB
  if (file.size > maxSize) {
    alert("La taille du fichier ne doit pas dépasser 8 Mo.");
    fileInput.value = ""; // Clear the file input
    return;
  }

  // Assuming you have some way to get the user's identifiant
  const fileName = `document_${typeEvenementForModal}_${idEvenementForModal}.${file.type.split("/")[1]}`;

  // Handle file upload to the server or save it locally
  try {
    // Example using the Fetch API to upload the file (modify as needed)
    const formData = new FormData();
    formData.append("confirm", file, fileName);

    voyageService.uploadConfirm(voyageKey, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    })
  .then((response: any) => {

    let imageURL: string | undefined;

        setConfirmLien(response.data.confirmInfo.url);

        handleElementChange(idJourneeForModal, idEvenementForModal, typeEvenementForModal, response.data.confirmInfo.url, 'document');

        if(response.data.confirmInfo.url !== "")
        {
    
          Axios.get(response.data.confirmInfo.url, { responseType: 'blob' })
          .then((response) => {

            const blob = new Blob([response.data], { type: 'image/jpeg' });
            imageURL = URL.createObjectURL(blob);

            setConfirm(imageURL);
    
          })
          .catch((error) => console.error('Erreur lors du chargement de l\'image', error));
        }
        else
        {
          setConfirm("");
        }
    
        return () => {
          if (imageURL) {
            URL.revokeObjectURL(imageURL); // Révoquez l'URL seulement lorsque le composant est démonté
          }
        };

 })
  .catch((error: any) => {
    if (error.response?.status === 401) {
      alert("Erreur lors de l'enregistrement du document, vous n'êtes plus connecté(e).")
      localStorage.removeItem('xsrfToken');
      navigate('/login');
    }
    else
    {
      alert("Erreur lors de l'enregistrement du document");
    }
    console.error('Error maj document:', error);
  });

    // Optionally, update the user's profile data with the new avatar filename
    //await saveChanges("avatar", fileName);
  } catch (error) {
    console.error("Error uploading file:", error);
    alert("Erreur lors du téléchargement du fichier.");
  }
}
};

      const handleAddTrajetLendemain = async (idJournee: number) => {
        const modifVoyage = async () => {
          try {
        const day = eventCategories.find((day) => day.id_journee === idJournee);

        if(day)
        {
          var numJour = day.num_jour;
          const daySuivant= eventCategories.find((day) => day.num_jour === numJour+1);

          if(daySuivant)
          {
            var idJourneeSuiv=daySuivant.id_journee;
            var idEvenement=daySuivant.evenements[0].id;
            var typeEv=daySuivant.evenements[0].typeEv;
  
            addEvenementLendemain(typeEv, idJourneeSuiv, idEvenement);
          }

        }
        
      } catch (error) {
        console.error(error);
        alert("Erreur lors de la modification !");
      }
    };
    
        const processModifVoyage = async () => {
          
          const verrouillageResultat = await verrouillageModif();
      
          if (verrouillageResultat === 1) {
            modifVoyage();
          }
        };
    
        processModifVoyage();

    };  

    const handleDeleteTrajetLendemain = async (idJournee: number, idTrajet: number) => {

      const modifVoyage = async () => {
        try {
      const day = eventCategories.find((day) => day.id_journee === idJournee);

      if(day)
      {
        var numJour = day.num_jour;
        const daySuivant= eventCategories.find((day) => day.num_jour === numJour+1);

        if(daySuivant)
        {
          var idJourneeSuiv=daySuivant.id_journee;

          removeEventFromState(idJourneeSuiv, idTrajet, "trajet");
        }

      }
  } catch (error) {
    console.error(error);
    alert("Erreur lors de la modification !");
  }
};

    const processModifVoyage = async () => {
      
      const verrouillageResultat = await verrouillageModif();
  
      if (verrouillageResultat === 1) {
        modifVoyage();
      }
    };

    processModifVoyage();
  };  

  const handleSaveCoord = () => {

    if(validationCoordonneesGPS(latitude1, longitude1) && validationCoordonneesGPS(latitude2, longitude2))
    {

    voyageService.enregistrerCoordonnees(voyageKey, {
      latitude1,
      latitude2,
      longitude1,
      longitude2,
      typeEvenement: typeEvenementForModal,
      idEvenement: idEvenementForModal 
    })
    .then((response: any) => {
    
      if(response.data.message !== 'ok')
      {

        alert("Erreur lors de l'enregistrement des coordonnées");
      }
      else
      {
        alert("Enregistrement effectué !");
        handleElementChange(idJourneeForModal, idEvenementForModal, typeEvenementForModal, latitude1, "lat");
        handleElementChange(idJourneeForModal, idEvenementForModal, typeEvenementForModal, longitude1, "longi");
        handleElementChange(idJourneeForModal, idEvenementForModal, typeEvenementForModal, latitude2, "lat2");
        handleElementChange(idJourneeForModal, idEvenementForModal, typeEvenementForModal, longitude2, "longi2");
        handleElementChange(idJourneeForModal, idEvenementForModal, typeEvenementForModal, latitude1, "latModif");
        handleElementChange(idJourneeForModal, idEvenementForModal, typeEvenementForModal, longitude1, "longiModif");
        handleElementChange(idJourneeForModal, idEvenementForModal, typeEvenementForModal, latitude2, "lat2Modif");
        handleElementChange(idJourneeForModal, idEvenementForModal, typeEvenementForModal, longitude2, "longi2Modif");
        
      }

    })
    .catch((error: any) => {

      if (error.response?.status === 401) {
        alert("Erreur lors de l'enregistrement des coordonnées', vous n'êtes pas connecté(e) avec le compte du créateur du voyage.")
        navigate('/');
      }
      else
      {
        alert("Erreur lors de l'enregistrement des coordonnées");
      }
 
    })
  }
};


  const handleSaveBlocNotes = (valeur: string) => {
          setBlocNotes(valeur);

          var newBlocNotes=valeur.replace(/\n/g, "<br>");
          newBlocNotes=valeur.replace(/\r/g, "<br/>");
  
          voyageService.enregistrerBlocNotes(voyageKey, {
            blocNotes: newBlocNotes,
            typeEvenement: typeEvenementForModal,
            idEvenement: idEvenementForModal 
          })
          .then((response: any) => {
            handleElementChange(idJourneeForModal, idEvenementForModal, typeEvenementForModal, newBlocNotes, 'bloc');

            if(response.data.message !== 'ok')
            {
              alert("Erreur lors de l'enregistrement du bloc-notes");
            }

          })
          .catch((error: any) => {

            if (error.response?.status === 401) {
              alert("Erreur lors de l'enregistrement du bloc-notes', vous n'êtes pas connecté(e) avec le compte du créateur du voyage.")
              navigate('/');
            }
            else
            {
              alert("Erreur lors de l'enregistrement du bloc-notes");
            }
       
          })
  };

  const handleDisplayBlocNotes = useCallback((valeur: string) => {
    console.log('deb handleDisplayBlocNotes');
    setBlocNotes(valeur);
    console.log('fin handleDisplayBlocNotes');
  }, []);

  const handleDisplayLatitude1 = (valeur: string) => {
    setLatitude1(valeur);
  }

  const handleDisplayLatitude2 = (valeur: string) => {
    setLatitude2(valeur);
  }

  const handleDisplayLongitude1 = (valeur: string) => {
    setLongitude1(valeur);
  }

  const handleDisplayLongitude2 = (valeur: string) => {
    setLongitude2(valeur);
  }

  const handleDisplayLien = (valeur: string) => {
    setLienEnregistre(false);
    setLien(valeur);
  }

  const handleSaveLien = () => {
    const newLien=lien.replace(/ /g,'');
    setLien(newLien);

    if(newLien === '' || validationLien(newLien))
    {

          voyageService.enregistrerLien(voyageKey, {
            lien: newLien,
            typeEvenement: typeEvenementForModal,
            idEvenement: idEvenementForModal 
          })
          .then((response: any) => {
            
            if(response.data.message === 'ok')
            {
              setLienEnregistre(true);
              if(newLien !== "")
              {
                alert("Lien enregistré");
              }
              else
              {
                alert("Lien supprimé");  
              }

              handleElementChange(idJourneeForModal, idEvenementForModal, typeEvenementForModal, newLien, 'lien');
              
            }
            else
            {
              alert("Erreur lors de l'enregistrement du lien");
            }

          })
          .catch((error: any) => {

            if (error.response?.status === 401) {
              alert("Erreur lors de l'enregistrement du lien', vous n'êtes pas connecté(e) avec le compte du créateur du voyage.")
              navigate('/');
            }
            else
            {
              alert("Erreur lors de l'enregistrement du lien");
            }
          })
    }
    else
    {
      alert("Votre lien est incorrect, veuillez le vérifier !\n\nUn lien doit commencer par http:// ou https:// et correspondre au format attendu.");
    }
  };

const validationLien = (lienSaisi: string) => {
  var expReg = new RegExp("^((http|https)://){1}(www[.])?([a-zA-Z0-9]|-)+");

  if (expReg.test(lienSaisi.toLowerCase()))
  {
    return true;
  }
  else
  {
    return false;
  }
};


const validationCoordonneesGPS = (lat: string, lon: string) => {

  if(lat === '' && lon === '')
  {
    return true
  }
  else if((lat === '' && lon !== '') || (lat !== '' && lon === ''))
  {
    alert("Il faut remplir la latitude et la longitude");
    return false;
  }
  else
  {
    const isLatitude = isFinite(Number(lat)) && Math.abs(Number(lat)) <= 90;
    const isLongitude =isFinite(Number(lon)) && Math.abs(Number(lon)) <= 180;
    if(!isLatitude)
    {
      alert("La latitude doit être comprise entre -90 et 90");
      return false;
    }
    else if(!isLongitude)
    {
      alert("La longitude doit être comprise entre -180 et 180");
      return false;
    }
    else
    {
      return true;
    }
  }
}
const echangerJournees = async (idJournee1: number, idJournee2: number) => {
  const modifVoyage = async () => {
    try {
      await voyageService.echangerJournees(voyageKey, {
        jour1: idJournee1,
        jour2: idJournee2
      });

      const index1 = eventCategories.findIndex(day => day.id_journee === idJournee1);
      const index2 = eventCategories.findIndex(day => day.id_journee === idJournee2);
      
      // Swap the days in the array
      if (index1 !== -1 && index2 !== -1) {
        // Create a shallow copy of the eventCategories array
        const newEventCategories = [...eventCategories];
      
        // Create deep copies of the day objects to avoid direct mutations
        const day1 = { ...newEventCategories[index1] };
        const day2 = { ...newEventCategories[index2] };
      
        // Swap the date_journee and num_jour properties
        const tempDateJournee = day1.date_journee;
        const tempNumJour = day1.num_jour;
      
        day1.date_journee = day2.date_journee;
        day1.num_jour = day2.num_jour;
      
        day2.date_journee = tempDateJournee;
        day2.num_jour = tempNumJour;
      
        // Swap the days in the copied array
        newEventCategories[index1] = day2;
        newEventCategories[index2] = day1;
      
        // Set the new array in the state
        setEventCategories(newEventCategories);
      }      

      setIsEchange(false);

    } catch (error: any) {
      console.log("error", error);
      setIsEchange(false);

      if (error.response?.status === 401) {
        alert("Erreur lors de l'échange, vous n'êtes pas le créateur du voyage.");
        navigate('/');
      } else {
        alert("Erreur lors de l'échange");
      }
    }
  };

  const processModifVoyage = async () => {
    const verrouillageResultat = await verrouillageModif();

    if (verrouillageResultat === 1) {
      setIsEchange(true);
      modifVoyage();
    }
  };

  processModifVoyage();
};


function isFeatureGeoapifyType(data: unknown): data is FeatureGeoapifyType {
  return (
    typeof data === 'object' &&
    data !== null &&
    'features' in data &&
    Array.isArray((data as FeatureGeoapifyType).features)
  );
}

const handleInfoClick = (placeId: string, placeNom: string, latitude: string, longitude: string) => {

  if(placeId !=null && placeId !== '' && placeId !== 'x')
  {
  /* Create a new promise and send geocoding request */
  let currentPromiseReject: ((reason?: any) => void) | null = null;

    /* Create a new promise and send geocoding request */
    var promise = new Promise((resolve, reject) => {
      currentPromiseReject = reject;

      var url = `https://api.geoapify.com/v2/place-details?id=${placeId}&lang=fr&apiKey=${apiKeyGeoapify}`;

      fetch(url)
        .then(response => {
          // check if the call was successful
          if (response.ok) {
            response.json().then(data => resolve(data));
          } else {
            response.json().then(data => reject(data));
          }
        });
    });

    promise.then((data: unknown) => {
      if (isFeatureGeoapifyType(data)) {
   
    /* For each item in the results */
    data.features.forEach((feature, index) => {

      if(feature.properties.address_line1 !== undefined && feature.properties.address_line1 !== null && feature.properties.address_line1 !== '')
      {
        if(placeNom === feature.properties.address_line1)
        {
          setInfoAdresse(feature.properties.address_line2);
        }
        else
        {
          setInfoAdresse(feature.properties.formatted);
        }
      }
      if(feature.properties.opening_hours !== undefined &&  feature.properties.opening_hours !== null && feature.properties.opening_hours !== '')
      {
        const regex = /;/gi;
        setInfoHoraires(feature.properties.opening_hours.replace(regex,"<br/>"));
        
      }
      if(feature.properties.facilities !== undefined &&  feature.properties.facilities !== null )
      {

        var equipements="";

        if( feature.properties.facilities.wheelchair)
        {
          equipements='<i class="las la-wheelchair"></i> ';
        }
        if( feature.properties.facilities.internet_access)
        {
          equipements+='<i class="las la-wifi"></i> ';
        }
        if( feature.properties.facilities.toilets)
        {
          equipements+='<i class="las la-restroom"></i> ';
        }
        if( feature.properties.facilities.swimming_pool)
        {
          equipements+='<i class="las la-swimming-pool"></i> ';
        }
        if( feature.properties.facilities.dogs)
        {
          equipements+='<i class="las la-wifi"></i> ';
        }
        if( feature.properties.facilities.air_conditioning)
        {
          equipements+='<i class="las la-snowflake"></i> ';
        }
        setInfoEquipements(equipements);
      }
      if(feature.properties.website !== undefined &&  feature.properties.website !== null && feature.properties.website !== '')
      {
        setInfoSite(feature.properties.website);
      }
      if(feature.properties.contact !== undefined && feature.properties.contact!== null)
      {
        if(feature.properties.contact.phone !== undefined && feature.properties.contact.phone !== null && feature.properties.contact.phone !== '')
        {
          var tabTel=feature.properties.contact.phone.split(";");
          setInfoTelephone(tabTel[0]);
          
        }
        if(feature.properties.contact.email !== undefined &&  feature.properties.contact.email !== null && feature.properties.contact.email !== '')
        {
          
          setInfoContact(feature.properties.contact.email);
        }
      }

      setInfoSource('Fourni par <a target="_blank" rel="noreferrer" href="https://www.geoapify.com/">Geoapify</a> | <a target="_blank" rel="noreferrer" href="'+feature.properties.datasource.url+'">'+feature.properties.datasource.attribution+'</a>');

      setPlaceNom(placeNom);
      setInfoLatitude(latitude);
      setInfoLongitude(longitude);
      openModalInfo();
    });
   }
  });
}
}

const handleCopierClick = (idEvenement: number) => {

  openModalCopie(idEvenement);

}

const handleRechercheClick = (typeEvenement: string, idEvenement: number) => {

  voyageService.getCoordonnees(voyageKey, typeEvenement, idEvenement)
      .then((response: any) => {
        
        if(response.data.message === 'ok')
        {
          setIdJourneeForModal(response.data.idJournee);
          setLieu1(response.data.lieu1);
          setLieu2(response.data.lieu2);
          setLatitude1(response.data.latitude1);
          setLatitude2(response.data.latitude2);
          setLongitude1(response.data.longitude1);
          setLongitude2(response.data.longitude2);

          if(typeEvenement === "lieu")
          {
            eventCategories.forEach((day) => {
              day.evenements.forEach((event) => {
                if(event.typeEv === "L" && event.id === idEvenement)
                {
                  if(event.type !== "O")
                  {
                    openModalGetYourGuide(typeEvenement, idEvenement);
                  }
                  else
                  {
                   //
                  }
                }
                 });
           });
          }
          else if(typeEvenement === "trajet")
          {
            eventCategories.forEach((day) => {
               day.evenements.forEach((event) => {
                 if(event.typeEv === "T" && event.id === idEvenement)
                 {
                   if(event.location === "O")
                   {
                    openModalDiscoverCars(typeEvenement, idEvenement);
                   }
                   else
                   {
                    
                     if(event.type !== null && (event.type === 'bus' || event.type === 'train'))
                     {
                      //openModalKiwi(typeEvenement, idEvenement);
                      openModalBookingVols(typeEvenement, idEvenement); 
                     }
                     else
                     {
                      openModalBookingVols(typeEvenement, idEvenement);                     
                     }
                   }
                 }
                  });
            });
          }
          else if(typeEvenement === "nuit")
          {
            openModalBooking(typeEvenement, idEvenement);
          }
        }
        else
        {
          alert("Erreur lors de la récupération des coordonées");
        }

      })
      .catch((error: any) => {

        if (error.response?.status === 401) {
          alert("Erreur lors de la récupération des coordonnées, vous n'êtes pas connecté(e) avec le compte du créateur du voyage.")
          navigate('/');
        }
        else
        {
          alert("Erreur lors de la récupération des coordonnées");
        }

      })

}

const handleCopierNuit = () => {

  if(nbNuits > 0)
  {
    setIsCopie(true);
    
  voyageService.copierNuit(voyageKey, {
    idEvenement: idEvenementForModal,
    nbNuits: nbNuits
  })
  .then((response: any) => {

    console.log("response",response);

// Trouvez l'index de l'événement source dans chaque journée
let sourceEventIndex = -1;
let sourceJourneeIndex = -1;

eventCategories.some((journee, journeeIndex) => {
  sourceEventIndex = journee.evenements.findIndex(
    (event) => event.typeEv === 'N' && event.id === idEvenementForModal
  );

  if (sourceEventIndex !== -1) {
    sourceJourneeIndex = journeeIndex;
    return true; // Pour sortir de la boucle dès que l'événement source est trouvé
  }

  return false;
});

// Vérifiez si l'événement source a été trouvé
if (sourceEventIndex !== -1 && sourceJourneeIndex !== -1) {
  const sourceEvent = eventCategories[sourceJourneeIndex].evenements[sourceEventIndex];



  // Parcourez les N journées suivantes et copiez les attributs
  
  for (let i = 1; i <= nbNuits-1; i++) {
    const targetJourneeIndex = sourceJourneeIndex + i;

    // Vérifiez si l'indice de la journée cible est valide
    if (targetJourneeIndex < eventCategories.length) {
      const targetEventIndex = eventCategories[targetJourneeIndex].evenements.findIndex(
        (event) => event.typeEv === 'N'
      );

      // Vérifiez si l'événement cible a été trouvé dans la journée cible
      if (targetEventIndex !== -1) {
        // Copy the day (including its events) before modifying it
        setEventCategories((prevCategories) => {
          return prevCategories.map((day, index) => {
            if (index === targetJourneeIndex) {
              const updatedEvenements = day.evenements.map((event, eventIndex) => {
                if (eventIndex === targetEventIndex) {
                  // Create a copy of the targetEvent and update its properties
                  return {
                    ...event,
                    libelle1: sourceEvent.libelle1,
                    libelle2: sourceEvent.libelle2,
                    prix: sourceEvent.prix,
                    paiement: sourceEvent.paiement,
                    date_paiement_hebergement: sourceEvent.date_paiement_hebergement,
                    acompte_hebergement: sourceEvent.acompte_hebergement,
                    lat: sourceEvent.lat,
                    lat2: sourceEvent.lat2,
                    longi: sourceEvent.longi,
                    longi2: sourceEvent.longi2,
                    image: sourceEvent.image,
                    pays: sourceEvent.pays,
                    nom_pays: sourceEvent.nom_pays,
                  };
                }
                return event; // Return unmodified events
              });
      
              // Return a new day object with updated events
              return {
                ...day,
                evenements: updatedEvenements,
              };
            }
            return day; // Return unmodified days
          });
        });
      }
      
      }
    }
  }
  setIsCopie(false);
  closeModalCopie();
  })
  .catch((error: any) => {
    setIsCopie(false);

    console.log("error",error);

    if (error.response?.status === 401) {
      alert("Erreur lors de la copie de la nuit, vous n'êtes pas le créateur du voyage.")
      navigate('/');
    }
    else if (error.response?.status === 410 && error.response.data.message !== undefined) {
      alert(error.response.data.message);
    }
    else
    {
      alert("Erreur lors de la copie");
    }
  })
  }
  else
  {
    alert("Vous devez sélectionner le nombre de nuits dans la liste déroulante.");
  }
}

const handlePublicClick = () => {
  if(window.confirm(
    t('alertVoyagePublic')
  ))
  {
    voyageService.modifierVoyage({
      id_voyage: voyageKey,
      visibilite: "prive"
  })
      .then((response) => {
      if (response.data.message !== undefined && response.data.message !== null && response.data.message === 'ok') {
          window.location.reload();
      } else {
          // Gérer le cas où idVoyage est 0 ou null
      }
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          alert("Erreur lors du changement de visibilité, vous n'êtes plus connecté(e).")
          localStorage.removeItem('xsrfToken');
          navigate('/login');
        }
        else
        {
          alert("Erreur lors du changement de visiblité");
        }

      });

  }
}


const handlePriveClick = () => {
  if(username && username !== '' && username !== 'null')
  {
    if(window.confirm(
     t('alertVoyagePrive')
    ))
    {
      voyageService.modifierVoyage({
        id_voyage: voyageKey,
        visibilite: "public"
    })
        .then((response) => {
        if (response.data.message !== undefined && response.data.message !== null && response.data.message === 'ok') {
            window.location.reload();
        } else {
            // Gérer le cas où idVoyage est 0 ou null
        }
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            alert("Erreur lors du changement de visibilité, vous n'êtes plus connecté(e).")
            localStorage.removeItem('xsrfToken');
            navigate('/login');
          }
          else
          {
            alert("Erreur lors du changement de visiblité");
          }
  
        });
  
    }
  }
  else
  {
    alert("Pour rendre un voyage public, vous devez remplir un nom d'utilisateur dans votre profil.");
  }
}

const handleConvertisseurClick = () => {
  if(etatVoyage !== 0)
  {
    openModalConvertisseur();
  }
  else
  {
    alert("Vous devez être connecté(e) à un compte pour utiliser les outils !");
  }
}


const handleStatistiquesClick = async () => {
  if(etatVoyage !== 0)
  {
    try {
      
      const response = await voyageService.getStatistiques(voyageKey, emailPartage, codePartage);

      if(response.data.budget !== 0)
      {
        var budgetCal=Math.round(response.data.budget/nb_voyageurs/nbJours);
        setStatBudget(budgetCal.toString());
      }
      else
      {
        setStatBudget("0");
      }

      setStatNbEtapes(response.data.nbEtapes);
      setStatDistance(response.data.distance);
      setStatDistanceAvion(response.data.distanceAvion);
      setStatDistanceBateau(response.data.distanceBateau);
      setStatDistanceTrain(response.data.distanceTrain);
      setStatDistanceBus(response.data.distanceBus);
      setStatDistanceTaxi(response.data.distanceTaxi);
      setStatDistanceVoiture(response.data.distanceVoiture);
      setStatDistanceMoto(response.data.distanceMoto);
      setStatDistanceVelo(response.data.distanceVelo);
      setStatDistancePied(response.data.distancePied);
    
      
      openModalStatistiques();

    } catch (error: any) {
  
      if (error.response?.status === 401) {
        localStorage.removeItem('xsrfToken');
      }
      console.error("Error fetching data:", error);
      navigate('/');
    }
    
  }
  else
  {
    alert("Vous devez être connecté(e) à un compte pour utiliser les outils !");
  }
}

const handleContenuBagageClick = async () => {
  if(etatVoyage !== 0)
  {
      openModalContenuBagage(); 
  }
  else
  {
    alert("Vous devez être connecté(e) à un compte pour utiliser les outils !");
  }
}


const handleIntegrationClick = async () => {
  if(etatVoyage !== 0)
  {
      openModalIntegration(); 
  }
  else
  {
    alert("Vous devez être connecté(e) à un compte pour utiliser les outils !");
  }
}

const handlePartageClick = async () => {
  if(etatVoyage !== 0)
  {
      openModalPartage(); 
  }
  else
  {
    alert("Vous devez être connecté(e) à un compte pour utiliser les outils !");
  }
}


const handleImportClick = async () => {
  if(etatVoyage !== 0)
  {
      openModalImport(); 
  }
  else
  {
    alert("Vous devez être connecté(e) à un compte pour utiliser les outils !");
  }
}



const handleExportClick = async () => {
  if(etatVoyage !== 0)
  {
      openModalExport(); 
  }
  else
  {
    alert("Vous devez être connecté(e) à un compte pour utiliser les outils !");
  }
}

const handleEditionPDFClick = async () => {
  if(etatVoyage !== 0)
  {
    openModalEditionPDF(); 
  }
  else
  {
    alert("Vous devez être connecté(e) à un compte pour utiliser les outils !");
  }
}

  return (
    <DndProvider backend={HTML5Backend}>

{ (numDroit === 1 || numDroit === 0) ? (
  <div className={`flex items-center justify-between space-x-5 mt-0 mb-10 ${ visibilite === "public" ? "boutonsOutils" : ""}`}>
<div className="flex-grow"> {/* Cette division prendra tout l'espace disponible */}
    <div className="text-xxs md:text-xs text-center relative">
      <div>
        <button
          onClick={toggleModif}
          className={`w-10 h-6 rounded-full p-1 transition-colors ${
            isModif ? 'bg-rosetrip-500' : 'bg-gray-300'
          }`}
        >
          <span className={`rounded-full block w-4 h-4 bg-white shadow-md transform transition-transform ${isModif ? 'translate-x-full' : ''}`} />
        </button>
      </div>
      <div className="pt-2">
        {isModif ? (
          <span>{t('modeModif')}</span> 
        ) : (
          <span>{t('modeConsult')}</span> 
        )}
        &nbsp;
      </div>
    </div>
  </div>

  <div className="flex space-x-5">
    <BtnOutils 
      onConvertisseurClick={handleConvertisseurClick}
      onStatistiquesClick={handleStatistiquesClick} 
      onContenuBagageClick={handleContenuBagageClick}
      onImportClick={handleImportClick}
      onExportClick={handleExportClick}
      visibilite={visibilite}
      onIntegrationClick={visibilite && visibilite === 'public' ? handleIntegrationClick : () => {}}
      onPartageClick={visibilite && visibilite === 'public' ? handlePartageClick : () => {}}
      onEditionPDFClick={handleEditionPDFClick}
    />
    { etatVoyage !== 0 && (
      <>
        { visibilite === "public" ? (
          <>
          <ButtonSecondary 
          sizeClass="px-2 py-2 sm:px-2"
          fontSize = "text-sm sm:text-sm font-medium"
          onClick={handlePublicClick}>
            <i className="las la-lock-open text-md"></i>
            <span className="ml-1">Public</span>
          </ButtonSecondary>
          <div className="items-center flex">
            <BtnLikeVoyageIcon nbLikes={nbLikes} isLiked={isLiked} className="absolute z-[1]" onLikeClick={handleLikeClick} />
          </div>
        </>
        ) : (
          <>
          <ButtonSecondary 
          sizeClass="px-2 py-2 sm:px-2"
          fontSize = "text-sm sm:text-sm font-medium"
          onClick={(username && username !== '' && username !== 'null') ? handlePriveClick : () => alert("Pour rendre un voyage public, vous devez remplir un nom d'utilisateur dans votre profil.")}>
            <i className="las la-lock text-md"></i>
            <span className="ml-1">{t('prive')}</span>
          </ButtonSecondary>
          <div className="items-center flex">
          </div>
          </>
        )}
      </>
    )}
  </div>
</div>
)  :  visibilite === "public" ? (
  <div className={`flex items-center justify-between space-x-5 mt-0 mb-10 ${ visibilite === "public" ? "boutonsOutils" : ""}`}>
<div className="flex-grow"> 
</div>
  <div className="flex space-x-5 ">
          <ButtonSecondary onClick={handlePartageClick} 
          sizeClass="px-2 py-2 sm:px-2"
          fontSize = "text-sm sm:text-sm font-medium">
            <i className="las la-share-alt text-md"></i>
            <span className="ml-1">{t('partage')}</span>
          </ButtonSecondary>
          <div className="items-center flex">
            <BtnLikeVoyageIcon nbLikes={nbLikes} isLiked={isLiked} className="absolute  z-[1]" onLikeClick={handleLikeClick} />
          </div>
  </div>
</div>
) : (null)}
{ numDroit === 0 && (
    <div className="sticky texteGras items-center text-center space-x-5 mb-6 pb-2 text-xs md:text-sm lg:text-sm xl:text-sm ">
       <p><i className="las la la-warning"></i> Pour ne pas perdre votre voyage et le rendre privé, pensez à vous <a href="/login" className="pointeurRose"><u>connecter</u></a> ou à <a href="/signup" className="pointeurRose"><u>créer un compte</u></a> !</p>
    </div>
  )}
{ numDroit === 1 && mailKo && (
  <div className="sticky texteGras items-center text-center space-x-5 mb-6 text-xs md:text-sm lg:text-sm xl:text-sm ">
    <p><i className='las la la-warning'></i> L'email utilisé pour créer votre <a href='/account'><u>compte</u></a> est erroné, veuillez <a href='/contact'><u>nous contacter</u></a> pour le rectifier !</p>
  </div>
)}

    <ItemsSlider title="" nomClass="item-slider-container">
        
    {eventCategories &&
              eventCategories.map((day, index) => (
                <div key={index}>
                  <>
                    <div className={`text-center ${!isModif ? "mb-3" : ""}`}>
                      <h2 className="champJour">{t('jour')} {day.num_jour}</h2>
                      <h2 className={`champDate mt-2 ${(isModif && index >= eventCategories.length - 2) ? 'mb-8' : 'mb-2'}`}>{day.date_journee}</h2>
                      { !isModif && locationTrouve && (<span>
                        { journeesLoc[index] && journeesLoc[index].car && (
                          <i className={`las la-car text-xl text-purple-500`}></i>
                        )}
                        { journeesLoc[index] && journeesLoc[index].motorcycle && (
                          <i className={`las la-motorcycle text-xl text-purple-500`}></i>
                        )}
                        { journeesLoc[index] && journeesLoc[index].bicycle && (
                          <i className={`las la-bicycle text-xl text-purple-500`}></i>
                        )}
                      </span>
                      )}
                      { isModif && index < eventCategories.length-2 && (
                      <div className="grid grid-cols-2 md:grid-cols-2 gap-2 md:gap-5">
                        <div></div>
                        <div data-tooltip-id="myTooltip"
                              data-tooltip-content={t('cliquezPourEchanger')}
                              data-tooltip-place="bottom"
                              className="flecheEchange">
                                <i onClick={() => echangerJournees(day.id_journee, eventCategories[index+1].id_journee)}
                                   className="iconeTransport las la-exchange-alt"></i>
                        </div>
                        <Tooltip id="myTooltip" />
                      </div>
                      )}
                    </div>
                    {day.evenements.map((event, eventIndex) => (

                      <div key={eventIndex} 
                      className={`${ (isModif 
                                   || (event.typeEv === "L" && (( event.libelle1 !== null && event.libelle1 !== "") || ( event.libelle2 !== null && event.libelle2 !== "") || (event.prix !== null && Number(event.prix.replace("","0"))) >0 ))
                                   || (event.typeEv === 'T' && (( event.libelle1 !== null && event.libelle1 !== "") || ( event.libelle2 !== null && event.libelle2 !== "") 
                                      || (event.prix !== null && Number(event.prix.replace("","0")) >0 ) || ( event.type !== null && event.type !== "")
                                      || ( event.debut !== null && event.debut !== "")  || ( event.fin !== null && event.fin !== "")))
                                  || (event.typeEv === 'N' && event.invisible1 !== "O" && (( event.libelle1 !== null && event.libelle1 !== "") || ( event.libelle2 !== null && event.libelle2 !== "") || (event.prix !== null && Number(event.prix.replace("","0"))) >0 )))
                                  ? "py-1" : ""} text-center`}>
                        {isModif ? (
                        <DraggableCard key={event.id} index={eventIndex} id_journee={day.id_journee} eventCategories={eventCategories} onDrop={(dragIndex, hoverIndex, draggedIdJournee) => moveCard(dragIndex, hoverIndex, draggedIdJournee, day.id_journee)}>
                         {event.typeEv !== "N" || (event.typeEv === "N" && event.invisible1 !== 'O') ? (
                            <ButtonCircleSmall onClick={() => openModal(day.id_journee, event, eventIndex)}>+</ButtonCircleSmall>
                         ) : null }
                        {event.typeEv === "L" ? (
                          <CardLieuModif
                          id="CARD"
                          index={`lieu-${event.id}`}
                          key={`lieu-${event.id}`}
                          symbole_monnaie={symbole_monnaie}
                          author={{
                            id: 1,
                            firstName: "Alric",
                            lastName: "Truelock",
                            displayName: event.libelle1 || "",
                                              email: "atruelock0@skype.com",
                                              avatar: event.image || '',
                                              bgImage: "https://images.pexels.com/photos/4064835/pexels-photo-4064835.jpeg?auto=compress&cs=tinysrgb&w=500",
                                              count: 40,
                                              href: "/author",
                                              desc: "There’s no stopping the tech giant. Apple now opens its 100th store in China.There’s no stopping the tech giant.",
                                              jobName: "Author Job",
                                              libelle2: event.libelle2 || '',
                                              prix: event.prix || '',
                                              paiement: event.paiement || '',
                                              type: event.type || '',
                                              lien: event.lien || '',
                                              lien_modifie: event.lien_modifie || '',
                                              etat_lien: event.etat_lien || '',
                                              lat: event.lat || '',
                                              longi: event.longi || '',
                                              document: event.document || '',
                                              bloc: event.bloc || ''
                                            }}
                           onSuppressionClick={handleSuppressionClick}
                           onLienClick={handleLienClick}
                           id_pays={id_pays}
                           idVoyage={voyageKey}
                           etatVoyage={etatVoyage}
                           onDisplayElementChange={handleElementChange}
                           idJournee={day.id_journee}
                           onBlocNotesClick={handleBlocNotesClick}
                           onCoordClick={handleCoordClick}
                           onConfirmClick={handleConfirmClick}
                           onPOIClick={handlePOIClick}
                           onRechercheClick={handleRechercheClick}
                          />
                        ) : event.typeEv === 'T' ? (
                        <CardTrajetModif
                        id="CARD"
                        index={`trajet-${event.id}`}
                          key={`trajet-${event.id}`}
                          symbole_monnaie={symbole_monnaie}
                        author={{
                          id: 1,
                          firstName: "Alric",
                          lastName: "Truelock",
                          displayName: event.libelle1 || '',
                          email: "atruelock0@skype.com",
                          avatar:event.image || '',
                          bgImage: "https://images.pexels.com/photos/4064835/pexels-photo-4064835.jpeg?auto=compress&cs=tinysrgb&w=500",
                          count: 40,
                          href: "/author",
                          desc: "There’s no stopping the tech giant. Apple now opens its 100th store in China.There’s no stopping the tech giant.",
                          jobName: "Author Job",
                          libelle2: event.libelle2 || '',
                          prix: event.prix || '',
                          type: event.type || '',
                          debut: event.debut || '',
                          fin: event.fin || '',
                          compagnie: event.compagnie || '',
                          ref: event.ref || '',
                          location: event.location || '',
                          paiement: event.paiement || '',
                          distance: event.distance || '',
                          duree: event.duree || '',
                          lat: event.lat || '',
                          longi: event.longi || '',
                          lat2: event.lat2 || '',
                          longi2: event.longi2 || '',
                          invisible1: event.invisible1 || '',
                          invisible2: event.invisible2 || '',
                          rattachement: event.rattachement || '',
                          moment: event.moment || '',
                          lien: event.lien || '',
                          lien_modifie: event.lien_modifie || '',
                          etat_lien: event.etat_lien || '',
                          latModif: event.latModif || '',
                          longiModif: event.longiModif || '',
                          lat2Modif: event.lat2Modif || '',
                          longi2Modif: event.longi2Modif || '',
                          document: event.document || '',
                          bloc: event.bloc || ''
                                            
                        }}
                        idJournee={day.id_journee}
                        id_pays={id_pays}
                        onSuppressionClick={shouldCallSuppressionClick(day, eventIndex) ? handleSuppressionClick : undefined}
                        idVoyage={voyageKey}
                        onDisplayElementChange={handleElementChange}
                        onAddTrajetLendemain={handleAddTrajetLendemain}
                        onDeleteTrajetLendemain={handleDeleteTrajetLendemain}
                        listePecPlanificateur={listePec}
                        onDisplayPecChange={handlePecChange}
                        onChangeTypeLocation={handleChangeTypeLocation}
                        onLienClick={handleLienClick}
                        onBlocNotesClick={handleBlocNotesClick}
                        onCoordClick={handleCoordClick}
                        onConfirmClick={handleConfirmClick}
                        onRechercheClick={handleRechercheClick}
                        />
                      ) : event.typeEv === 'N' ? (
                        <CardNuitModif
                        index={`nuit-${event.id}`}
                        key={`nuit-${event.id}`}
                        symbole_monnaie={symbole_monnaie}
                        author={{
                          id: 1,
                          firstName: "Alric",
                          lastName: "Truelock",
                          displayName: event.libelle1 || '',
                          email: "atruelock0@skype.com",
                          avatar:  event.image || '',
                          bgImage: "https://images.pexels.com/photos/4064835/pexels-photo-4064835.jpeg?auto=compress&cs=tinysrgb&w=500",
                          count: 40,
                          href: "/author",
                          desc: "There’s no stopping the tech giant. Apple now opens its 100th store in China.There’s no stopping the tech giant.",
                          jobName: "Author Job",
                          libelle2: event.libelle2 || '',
                          prix: event.prix || '',
                          paiement: event.paiement || '',
                          date_paiement_hebergement: event.date_paiement_hebergement || '',
                          acompte_hebergement: event.acompte_hebergement || '',
                          invisible1: event.invisible1 || '',
                          lien: event.lien || '',
                          lien_modifie: event.lien_modifie || '',
                          etat_lien: event.etat_lien || '',
                          lat: event.lat || '',
                          longi: event.longi || '',
                          lat2: event.lat2 || '',
                          longi2: event.longi2 || '',
                          document: event.document || '',
                          bloc: event.bloc || ''
                                            
                        }}
                        id_pays={id_pays}
                        idVoyage={voyageKey}
                        etatVoyage={etatVoyage}
                        onDisplayElementChange={handleElementChange}
                        idJournee={day.id_journee}
                        onLienClick={handleLienClick}
                        onBlocNotesClick={handleBlocNotesClick}
                        onCoordClick={handleCoordClick}
                        onConfirmClick={handleConfirmClick}
                        onCopierClick={handleCopierClick}
                        onRechercheClick={handleRechercheClick}
                        />
                      ) : null}
  </DraggableCard>
                      ) : null
                      }

                      {!isModif ? (
                      (event.typeEv === "L" && (( event.libelle1 !== null && event.libelle1 !== "") || ( event.libelle2 !== null && event.libelle2 !== "") || (event.prix !== null && Number(event.prix.replace("","0"))) >0 ) ? (
                      <CardLieu
                      index={`lieu-${event.id}`}
                      key={`lieu-${event.id}`}
                      symbole_monnaie={symbole_monnaie}
                      author={{
                        id: 1,
                        firstName: "Alric",
                        lastName: "Truelock",
                        displayName: event.libelle1 || "",
                                          email: "atruelock0@skype.com",
                                          avatar: event.image || '',
                                          bgImage: "https://images.pexels.com/photos/4064835/pexels-photo-4064835.jpeg?auto=compress&cs=tinysrgb&w=500",
                                          count: 40,
                                          href: "/author",
                                          desc: "There’s no stopping the tech giant. Apple now opens its 100th store in China.There’s no stopping the tech giant.",
                                          jobName: "Author Job",
                                          libelle2: event.libelle2 || '',
                                          prix: event.prix || '',
                                          type: event.type || '',
                                          paiement: event.paiement || '',
                                          lien: event.lien || '',
                                          lien_modifie: event.lien_modifie || '',
                                          etat_lien: event.etat_lien || '',
                                          document: event.document || '',
                                          bloc: event.bloc || '',
                                          lat: event.lat || '',
                                          longi: event.longi || '',
                                          place_id: event.place|| ''
                                          
                                        }}
                                        onBlocNotesClick={handleBlocNotesClick}
                                        onConfirmClick={handleConfirmClick}
                                        onInfoClick={handleInfoClick}
                                        onRechercheClick={handleRechercheClick}
                                        numDroit={numDroit}
                                        
                      />
                    ) : event.typeEv === 'T' && (( event.libelle1 !== null && event.libelle1 !== "") || ( event.libelle2 !== null && event.libelle2 !== "") 
                        || (event.prix !== null && Number(event.prix.replace("","0")) >0 ) || ( event.type !== null && event.type !== "")
                        || ( event.debut !== null && event.debut !== "")  || ( event.fin !== null && event.fin !== "")) ? (
                    <CardTrajet
                    index={`trajet-${event.id}`}
                    key={`trajet-${event.id}`}
                    symbole_monnaie={symbole_monnaie}
                    author={{
                      id: 1,
                      firstName: "Alric",
                      lastName: "Truelock",
                      displayName: event.libelle1 || '',
                      email: "atruelock0@skype.com",
                      avatar: event.image || '',
                      bgImage: "https://images.pexels.com/photos/4064835/pexels-photo-4064835.jpeg?auto=compress&cs=tinysrgb&w=500",
                      count: 40,
                      href: "/author",
                      desc: "There’s no stopping the tech giant. Apple now opens its 100th store in China.There’s no stopping the tech giant.",
                      jobName: "Author Job",
                      libelle2: event.libelle2 || '',
                      prix: event.prix || '',
                      type: event.type || '',
                      debut: event.debut || '',
                      fin: event.fin || '',
                      compagnie: event.compagnie || '',
                      ref: event.ref || '',
                      location: event.location || '',
                      distance: event.distance || '',
                      duree: event.duree || '',
                      moment: event.moment || '',
                      paiement: event.paiement || '',
                      lien: event.lien || '',
                      lien_modifie: event.lien_modifie || '',
                      etat_lien: event.etat_lien || '',
                      document: event.document || '',
                      bloc: event.bloc || '',
                      lat: event.lat || '',
                      longi: event.longi || '',
                      lat2: event.lat2 || '',
                      longi2: event.longi2 || ''
                      
                                            
                    }}
                    listePecPlanificateur={listePec}                 
                    onBlocNotesClick={handleBlocNotesClick}
                    onConfirmClick={handleConfirmClick}
                    onRechercheClick={handleRechercheClick}
                    numDroit={numDroit}
                   
                    />
                    ) : event.typeEv === 'N' && event.invisible1 !== "O" && (( event.libelle1 !== null && event.libelle1 !== "") || ( event.libelle2 !== null && event.libelle2 !== "") || (event.prix !== null && Number(event.prix.replace("","0"))) >0 ) ? (
                    <CardNuit
                    index={`nuit-${event.id}`}
                    key={`nuit-${event.id}`}
                    symbole_monnaie={symbole_monnaie}
                    author={{
                      id: 1,
                      firstName: "Alric",
                      lastName: "Truelock",
                      displayName: event.libelle1 || '',
                      email: "atruelock0@skype.com",
                      avatar: event.image || '',
                      bgImage: "https://images.pexels.com/photos/4064835/pexels-photo-4064835.jpeg?auto=compress&cs=tinysrgb&w=500",
                      count: 40,
                      href: "/author",
                      desc: "There’s no stopping the tech giant. Apple now opens its 100th store in China.There’s no stopping the tech giant.",
                      jobName: "Author Job",
                      libelle2: event.libelle2 || '',
                      prix: event.prix || '',
                      paiement: event.paiement || '',
                      date_paiement_hebergement: event.date_paiement_hebergement || '',
                      acompte_hebergement: event.acompte_hebergement || '',
                      lien: event.lien || '',
                      lien_modifie: event.lien_modifie || '',
                      etat_lien: event.etat_lien || '',
                      document: event.document || '',
                      bloc: event.bloc || '',
                      lat: event.lat || '',
                      longi: event.longi || '',
                      lat2: event.lat2 || '',
                      longi2: event.longi2 || '',
                      place_id: event.place|| ''
                                            
                    }}          
                    onBlocNotesClick={handleBlocNotesClick}
                    onConfirmClick={handleConfirmClick}
                    onInfoClick={handleInfoClick}
                    onRechercheClick={handleRechercheClick}
                    numDroit={numDroit}
                    />
                    ) : null )) : null}

                    </div>
                  

                  ))}
                </>
              </div>
            ))}


{isModalOpen && (
        <Transition appear show={isModalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={closeModal}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block py-8 w-full md:w-3/4 lg:w-2/3 xl:w-1/2">
                <div className="inline-flex pb-2 flex-col w-full md:w-3/4 lg:w-2/3 xl:w-3/5 text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <h3
                      className="text-lg font-medium leading-6 text-gray-900 dark:text-neutral-200"
                      id="headlessui-dialog-title-70"
                    >
                      {t('questionAjout')}
                    </h3>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={closeModal} />
                    </span>
                  </div>
                  <div className="px-8 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
                      <div
                        onClick={() => addEvenement("lieu")}
                        className="bouton flex items-center py-2.5 sm:py-4 lg:py-5 space-x-5 lg:space-x-8"
                      >
                        <i
                          className={`text-4xl las la-map-marker`}
                        ></i>
                        <span>{t('lieu')}</span>
                      </div>
                      <div
                        onClick={() => addEvenement("repas")}
                        className="bouton flex items-center py-2.5 sm:py-4 lg:py-5 space-x-5 lg:space-x-8"
                      >
                        <i
                          className={`text-4xl la la-pizza-slice`}
                        ></i>
                        <span>{t('repas')}</span>
                      </div>
                      <div
                        onClick={() => addEvenement("trajet")}
                        className="bouton flex items-center py-2.5 sm:py-4 lg:py-5 space-x-5 lg:space-x-8"
                      >
                        <i
                          className={`text-4xl las la-plane`}
                        ></i>
                        <span>{t('trajet')}</span>
                      </div>
                      <div
                        onClick={() => addEvenement("location")}
                        className="bouton flex items-center py-2.5 sm:py-4 lg:py-5 space-x-5 lg:space-x-8"
                      >
                        <i
                          className={`text-4xl las la-car-alt`}
                        ></i>
                        <span>{t('location')}</span>
                      </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
      )}
      
  {isModalCopieOpen && (
        <Transition appear show={isModalCopieOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={closeModalCopie}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block py-8 w-full md:w-3/4 lg:w-2/3 xl:w-1/2">
                <div className="inline-flex pb-2 flex-col w-full md:w-3/4 lg:w-2/3 xl:w-3/5 text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <h3
                      className="text-lg font-medium leading-6 text-gray-900 dark:text-neutral-200"
                      id="headlessui-dialog-title-70"
                    >
                      Copie de l'hébergement
                    </h3>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={closeModalCopie} />
                    </span>
                  </div>     
                      <div className="px-8 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
                      <div className="mt-5 w-full text-center">
                      <span className="text-medium">Indiquez le nombre total de nuits que vous passerez dans cet hébergement :</span>
                        
                        <Select  onChange={handleSelectCopieNuit}>
                          <option key={0} value={0}>Nombre de nuits</option>
                        {[...Array(nbJours-2)].map((_, index) => (
                          <option key={index + 2} value={index + 2}>
                            {index + 2} nuits
                          </option>
                        ))}
                        </Select>
                        <ButtonPrimary className="mt-3 mb-5" onClick={handleCopierNuit}>Copier</ButtonPrimary>
                      
                      </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
      )}
      
      {isModalInfoOpen && (
        <Transition appear show={isModalInfoOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={closeModalInfo}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block py-8 w-full md:w-3/4 lg:w-2/3 xl:w-1/2">
                <div className="inline-flex pb-2 flex-col w-full md:w-3/4 lg:w-2/3 xl:w-3/5 text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <h3
                      className="text-lg font-medium leading-6 text-gray-900 dark:text-neutral-200"
                      id="headlessui-dialog-title-70"
                    >
                      Informations
                    </h3>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={closeModalInfo} />
                    </span>
                  </div>
                  <div className="px-8 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
                  <div
                      
                        className="text-center py-2.5 sm:py-4 lg:py-5 space-x-5 lg:space-x-8"
                      >
                        
                        <span>{placeNom}</span>
                      </div>
                      <div
                      
                        className="bouton flex items-center py-2.5 sm:py-4 lg:py-5 space-x-5 lg:space-x-8"
                      >

                        <a target="_blank" rel="noreferrer" href={`https://www.google.com/maps/dir//${infoLatitude},${infoLongitude}/`}><i className={`text-3xl las la-map-marker`}></i></a>
                        <a target="_blank" rel="noreferrer" href={`https://www.google.com/maps/dir//${infoLatitude},${infoLongitude}/`} style={{ fontSize: '13px' }}
                        >{infoAdresse}</a>
                      </div>
                      
                      { infoSite !== "" && (
                      <div
                       
                        className="bouton flex items-center py-2.5 sm:py-4 lg:py-5 space-x-5 lg:space-x-8"
                      >
                        
                        <a target="_blank" rel="noreferrer" href={infoSite}><i className={`text-3xl las la-globe`}></i></a>
                        <a target="_blank" rel="noreferrer" href={infoSite} style={{ fontSize: '13px' }}
                        >{infoSite}</a>
                      </div>
                      )}
                      { infoTelephone !== "" && (
                      <div
                      
                        className="bouton flex items-center py-2.5 sm:py-4 lg:py-5 space-x-5 lg:space-x-8"
                      >
                        <a target="_blank" rel="noreferrer" href={`tel:${infoTelephone}`}><i className={`text-3xl las la-phone`}></i></a>
                        <a target="_blank" rel="noreferrer" href={`tel:${infoTelephone}`}>
                          {infoTelephone}
                        </a>
                      </div>
                      )}
                      { infoContact !== "" && (
                      <div
                        
                        className="bouton flex items-center py-2.5 sm:py-4 lg:py-5 space-x-5 lg:space-x-8"
                      >
                        <a target="_blank" rel="noreferrer" href={`mailto:${infoContact}`}><i className={`text-3xl las la-envelope`}></i></a>
                        <a target="_blank" rel="noreferrer" href={`mailto:${infoContact}`} style={{ fontSize: '13px' }}>
                          {infoContact}
                        </a>
                      </div>
                      )}
                      { infoHoraires !== "" && (
                      <div
                        
                        className="bouton flex items-center py-2.5 sm:py-4 lg:py-5 space-x-5 lg:space-x-8"
                      >
                        <p><i className={`text-3xl las la-clock`}></i></p>
                        <span className="text-sm" dangerouslySetInnerHTML={{ __html: infoHoraires }}></span>
                      
                      </div>
                      )}
                      { infoEquipements !== "" && (
                      <div
                      
                        className="text-center py-2.5 sm:py-4 lg:py-5 space-x-5 lg:space-x-8"
                      >
  
                        <span className="text-3xl" dangerouslySetInnerHTML={{ __html: infoEquipements }}></span>

                      </div>
                      )}
                      <div
                       
                        className="text-center py-2.5 sm:py-4 lg:py-5 space-x-5 lg:space-x-8"
                      >
                       <span className="text-xxs" dangerouslySetInnerHTML={{ __html: infoSource }}></span>
                      </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
      )}

{isModalLienOpen && (
        <Transition appear show={isModalLienOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={closeModalLien}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block py-8 w-full md:w-3/4 lg:w-2/3 xl:w-1/2">
                <div className="inline-flex pb-2 flex-col w-full md:w-3/4 lg:w-2/3 xl:w-3/5 text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <h3
                      className="text-lg font-medium leading-6 text-gray-900 dark:text-neutral-200"
                      id="headlessui-dialog-title-70"
                    >
                      Ajout/modification d'un lien
                    </h3>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={closeModalLien} />
                    </span>
                  </div>
                  <div className="px-8 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
                      <div className="mt-5 w-full text-center">
                        <h2 className={`text-base font-medium`}>
                          <input
                            type="text"
                            value={lien}
                            onChange={(e) => handleDisplayLien(e.target.value)}
                            className="champAutocomplete"
                            placeholder="Saisir le lien"
                            maxLength={2000}
                            //onBlur={handleBlurVille}
                            //onFocus={handleFocusVille}
                          />
                        </h2>
                        <ButtonPrimary className="mt-3 mb-5" onClick={handleSaveLien}>Enregistrer</ButtonPrimary>
                        <div className="text-left mb-5">
                          {lienEnregistre && lien !== null && lien !== "" && (
                          <h3 className="text-sm mb-1"><span className="font-semibold">Etat : </span>{etatLien === "R" ? "lien refusé" : etatLien === "V" ? "lien validé" : etatLien === "M" ? "lien validé après modification par nos services" : "validation en cours"}</h3>
                          )}
                          {lienEnregistre && (etatLien === "R" || etatLien === "M") && motifLien !== null && motifLien !== "" && (
                          <h3 className="text-sm mb-1"><span className="font-semibold">Motif : </span>{motifLien}</h3>
                          )}
                           {lienEnregistre && etatLien === "M" && lienModifie !== null && lienModifie !== "" && (
                          <h3 className="text-sm mb-1"><span className="font-semibold">Lien pour les autres utilisateurs (voyages publics ou partagés) : </span>{lienModifie}</h3>
                           )}
                          <div className="text-xxs mt-2">Pour les voyages publics ou partagés, les liens ne seront visibles par les autres utilisateurs qu'après validation par nos services (sous 48h maximum).</div>
                        </div>
                        
                      </div>
                      <div className="flex items-center py-2.5 sm:py-4 lg:py-5 space-x-5 lg:space-x-8">
                         <div>
                           <div className="text-xs font-semibold">
                            <i className="las la la-warning"></i> Les identifiants d'affiliation présents dans les liens pourront être supprimés ou remplacés par nos services durant la phase de validation.
                           </div>
                          <div className="text-xxs text-center mt-3">
                            Affichez ou masquez les liens de vos voyages publics dans les paramètres de confidentialité de votre compte.
                          </div>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
      )}

{isModalBlocNotesOpen && (
        <Transition appear show={isModalBlocNotesOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={closeModalBlocNotes}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block py-8 w-full md:w-3/4 lg:w-2/3 xl:w-1/2">
                <div className="inline-flex pb-2 flex-col w-full md:w-3/4 lg:w-2/3 xl:w-3/5 text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <h3
                      className="text-lg font-medium leading-6 text-gray-900 dark:text-neutral-200"
                      id="headlessui-dialog-title-70"
                    >
                        { isModif 
                        ? "Ajout/modification du bloc-notes"
                        : t('blocNotes')
                        }
                    </h3>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={closeModalBlocNotes} />
                    </span>
                  </div>
                  <div className="px-8 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
                      <div className="mt-5 mb-3 w-full text-center">
                           <TextareaAutogrow
                            maxLength={800}
                            className="mt-1.5 text-sm text-center"
                            value={blocNotes}
                            onChange={(e) => {
                              if (isModif) {
                                handleDisplayBlocNotes(e.target.value);
                              }
                            }}
                            onBlur={(e) => {
                              if (isModif) {
                                handleSaveBlocNotes(e.target.value);
                              }
                            }}
                            readOnly={!isModif}
                            placeholder="Notez vos idées ou informations complémentaires pour cette étape..."
                          /> 
                      </div>
                      <div className="flex items-center py-2.5 sm:py-3 lg:py-3 space-x-5 lg:space-x-8">
                        <span className="text-xxs w-full text-left">
                        Les informations saisies dans les blocs-notes ne sont visibles que par le créateur du voyage et lors d'un partage de voyage si l'option a été sélectionnée.
                        </span>
                      </div>

                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
      )}

 {isModalConfirmOpen && (
        <Transition appear show={isModalConfirmOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={closeModalConfirm}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block py-8 w-full md:w-3/4 lg:w-2/3 xl:w-1/2">
                <div className="inline-flex pb-2 flex-col w-full md:w-3/4 lg:w-2/3 xl:w-3/5 text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <h3
                      className="text-lg font-medium leading-6 text-gray-900 dark:text-neutral-200"
                      id="headlessui-dialog-title-70"
                    >
                      { isModif
                      ? "Ajout/modification d'une confirmation de réservation"
                      : "Confirmation de réservation"
                      }
                    </h3>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={closeModalConfirm} />
                    </span>
                  </div>
                  <div className="px-8 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
                      
                        
                      { !confirm && !isFini && (
                      <div className="mt-5 w-full text-center">
                        <form encType="multipart/form-data">
                        <input
                          name='confirm'
                          type="file"
                          className="cursor-pointer"
                          accept="image/jpeg, image/jpg, application/pdf"
                          onChange={handleConfirmChange}
                        />
                        </form>

                        <div className="text-xxs text-center mt-3">
                          .jpeg, .jpg et .pdf acceptés, 8 Mo max
                        </div>
                        <div className="mt-5 flex justify-center"> 
                              <p className="text-xxs pb-3"><i className="las la la-warning couleurRose text-sm texteGras"></i> Les documents seront supprimés 1 an après la date de fin du voyage.<br/>Si vous souhaitez les conserver, penser à générer une edition PDF de l'itinéraire depuis "Mes voyages".</p>
                        </div>
                      </div>
                        )}

                      { !confirm && isFini && (
                      <div className="mt-5 mb-5 w-full text-center">
                      <p>Vous ne pouvez pas ajouter de confirmation de réservation à un voyage dont la date de fin est passée.</p>
                     </div>
                        )}

                    {confirm && (
                          <div className="mt-2 w-full text-center">
                            <div className="mt-5 flex justify-center"> 
                              <p className="text-xxs pb-3"><i className="las la la-warning couleurRose text-sm texteGras"></i> Les documents seront supprimés 1 an après la date de fin du voyage.<br/>Si vous souhaitez les conserver, penser à générer une edition PDF de l'itinéraire depuis "Mes voyages".</p>
                            </div>
                            {confirmLien.endsWith('.pdf') ? (
                              <div>
                                <a onClick={downloadPDF} target="_blank" rel="noopener noreferrer">
                                <i  style={{ fontSize: '2em' }} className="pointeurRose las la-file-pdf"></i>
                              </a>
                              <div>
                              <button type="button" disabled={pageNumber <= 1} onClick={previousPage}>
                                  <i className="las la-arrow-left" style={{ fontSize: '1.5em' }}></i>
                                </button>
                                <button type="button" disabled={pageNumber >= numPages} onClick={nextPage}>
                                <i className="las la-arrow-right" style={{ fontSize: '1.5em' }}></i>
                                </button>
                               </div>
                              <p style={{ fontSize: '0.8em' }}>
                                 Page {pageNumber} sur {numPages}
                               </p>
                               <div className="pdf-container">
                               
                               <Document file={confirm} onLoadSuccess={onDocumentLoadSuccess} className="pdf-document">
                                 <Page pageNumber={pageNumber} scale={0.5} /> 
                               </Document>
                               
                             </div>
                             
                              </div>
                             
                            ) : (
                              <img
                                src={`${confirm}`}
                                alt="Confirmation de réservation"
                              />
                            )}
                            { isModif && (
                            <ButtonPrimary className="mt-3 mb-5" onClick={handleConfirmDelete}>
                              Supprimer
                            </ButtonPrimary>
                            )}
                          
                          </div>
                        )}
                        
                      
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
      )}

{isModalCoordOpen && (
        <Transition appear show={isModalCoordOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={closeModalCoord}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block py-8 w-full md:w-3/4 lg:w-2/3 xl:w-1/2">
                <div className="inline-flex pb-2 flex-col w-full md:w-3/4 lg:w-2/3 xl:w-3/5 text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <h3
                      className="text-lg font-medium leading-6 text-gray-900 dark:text-neutral-200"
                      id="headlessui-dialog-title-70"
                    >
                      Ajout/modification des coordonnées GPS
                    </h3>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={closeModalCoord} />
                    </span>
                  </div>
                  
                  
                  { lieu1 !== "" && lieu1 !== null && (
                  <div className="px-8 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
                      <div
                        className="text-center items-center py-2.5 sm:py-4 lg:py-5 space-x-5 lg:space-x-8"
                      >
                        <span>{lieu1}</span>
                      </div>
                  </div>
                  )}

                  <div className="px-8 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
                      <div className="flex justify-center items-center mb-2">
                        { lieu1 !== "" && lieu1 !== null && (
                          <>
                          
                          <input
                            type="text"
                            value={latitude1}
                            onChange={(e) => handleDisplayLatitude1(e.target.value)}
                            className="text-center"
                            placeholder="Latitude"
                            maxLength={20}
                            
                          />
                       
                        <input
                          type="text"
                          value={longitude1}
                          onChange={(e) => handleDisplayLongitude1(e.target.value)}
                          className="text-center"
                          placeholder="Longitude"
                          maxLength={20}
                          
                        />
                      
                      </>
                        )}
                        
                      </div>
                  </div>
                  
                  { lieu2 !== "" && lieu2 !== null && (
                  <div className="px-8 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
                      <div
                        className="text-center items-center py-2.5 sm:py-4 lg:py-5 space-x-5 lg:space-x-8"
                      >
                        <span>{lieu2}</span>
                      </div>
                  </div>
                  )}

                  <div className="px-8 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
                      <div className="flex justify-center items-center mb-2">

                    { lieu2 !== "" && lieu2 !== null && (
                      <>
                        
                          <input
                            type="text"
                            value={latitude2}
                            onChange={(e) => handleDisplayLatitude2(e.target.value)}
                            className="text-center"
                            placeholder="Latitude"
                            maxLength={20}
                            
                          />
                      
                      
                        <input
                          type="text"
                          value={longitude2}
                          onChange={(e) => handleDisplayLongitude2(e.target.value)}
                          className="text-center"
                          placeholder="Longitude"
                          maxLength={20}
                          
                        />
                    
                      </>
                        )}
                        
                      </div>
                      
                  </div>

                  <div className="mt-2 px-8 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
                      <div
                        className="text-center items-center py-2 sm:py-2 lg:py-2 space-x-5 lg:space-x-8"
                      >
                        <ButtonPrimary onClick={handleSaveCoord}>Enregistrer</ButtonPrimary>
                      </div>
                  </div>
                  
                  <div className="px-8 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
                      <div className="flex justify-center items-center mb-6">

                      </div>

                      <div className="flex items-center text-center py-2.5 sm:py-4 lg:py-5 space-x-5 lg:space-x-8">
                         <div>
                         
                          <div className="text-xxs text-center mt-2">
                          Les coordonnées peuvent également être ajoutées et modifiées depuis la carte.
                          </div>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
      )}

{isModalPOIOpen && (
        <Transition appear show={isModalPOIOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={closeModalPOI}
        >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
          </Transition.Child>

          <span className="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>

          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block py-8 w-full md:w-4/5 lg:w-3/4 xl:w-2/3">
              <div className="inline-flex pb-2 flex-col w-full text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                  <h3
                    className="text-lg font-medium leading-6 text-gray-900 dark:text-neutral-200"
                    id="headlessui-dialog-title-70"
                  >
                    Points d'intérêt à proximité de l'étape
                  </h3>
                  <span className="absolute left-3 top-3">
                    <ButtonClose onClick={closeModalPOI} />
                  </span>
                </div>
                <div className="px-8 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
              
                    <div className="items-center py-2.5 space-x-5 lg:space-x-8 text-center">
                      { loadingPOI && (
                        <div>
                          Recherche en cours, veuillez patienter...
                        </div>
                      )}
                      { !loadingPOI && aucunPOI && (
                        <div>
                          Aucun point d'intérêt trouvé à proximité
                        </div>
                      )}
                      <div className="grid grid-cols-2 gap-3 text-left mt-5">
                        <div>
                          {POIList.slice(startIndex, startIndex + itemsPerPage).map((item, index) => (
                            <ButtonSquare key={index} onClick={() => handleInfosPOIClick(startIndex + index)}>
                              <span className="text-sm font-medium texteGras">{item.properties.name}</span>
                              <span className="text-xxs font-small">{getDistancePOI(item)}</span><br />
                              <span className="text-xxs font-small">{getCategoriePOI(item)}</span>
                            </ButtonSquare>
                          ))}
                          <div className="text-center grid grid-cols-2 gap-2">
                            <div>
                            {startIndex > 0 && (
                              <ButtonPrimarySmall className="w-full" onClick={handlePrevClick}>&#8592;</ButtonPrimarySmall>                           
                            )}
                            </div>
                            <div>
                            {startIndex + itemsPerPage < POIList.length && (
                                <ButtonPrimarySmall className="w-full" onClick={handleNextClick}>&#8594;</ButtonPrimarySmall>
                            )}
                            </div>
                          </div>
                          { !loadingPOI && POIList.length > 0 && (                                                      
                            <div className="text-center">
                              <span className="text-xxs">Fourni par <a className="texteGras" target="_blank" rel="noreferrer" href="https://www.geoapify.com/">Geoapify</a></span>
                            </div>
                          )}
                        </div>
                        <div>
                          <div className="text-center imagePOI" dangerouslySetInnerHTML={{ __html: sanitizedHTML }}></div>
                          { sanitizedHTML !== "" && !ajoutLieuPOI && !ajoutActivitePOI && (
                            <div className="text-center">
                              <ButtonPrimarySmall className="w-full" onClick={handleAddActivitePOI}>Ajouter aux activités de l'étape</ButtonPrimarySmall>
                              <ButtonPrimarySmall className="w-full" onClick={handleAddLieuPOI}>Créer une nouvelle étape</ButtonPrimarySmall>
                            </div>
                          )}
                          { sanitizedHTML !== "" && ajoutActivitePOI && (
                            <div className="text-center mt-5">
                              <span className="couleurRose">Ajouté aux activités de l'étape !</span>
                            </div>
                          )}
                          { sanitizedHTML !== "" && ajoutLieuPOI && (
                            <div className="text-center mt-5">
                              <span className="couleurRose">Lieu créé !</span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
 )}

{isModalConvertisseurOpen && (
       <ConvertisseurMonnaie onConvertisseurCloseModal={closeModalConvertisseur} />
       )}

{isModalGetYourGuideOpen && (
       <ModalGetYourGuide 
       onGetYourGuideCloseModal={closeModalGetYourGuide} 
       lieu={lieu1}
       latitude={latitude1}
       longitude={longitude1}
       />
       )}

{isModalBookingOpen && (
       <ModalBooking
       onBookingCloseModal={closeModalBooking} 
       ville={lieu1}
       latitude={latitude1}
       longitude={longitude1}
       hebergement={lieu2}
       latitudeHeb={latitude2}
       longitudeHeb={longitude2}
       />
       )}

{isModalDiscoverCarsOpen && (
       <ModalDiscoverCars
       onDiscoverCarsCloseModal={closeModalDiscoverCars} 
       ville={lieu1}
       latitude={latitude1}
       longitude={longitude1}
       />
       )}

{isModalKiwiOpen && (
       <ModalKiwi
       onKiwiCloseModal={closeModalKiwi} 
       idEvenement={idEvenementForModal}
       nbVoyageurs={nb_voyageurs}
       voyageKey={voyageKey}
       />
       )}


{isModalBookingVolsOpen && (
       <ModalBookingVols
       onBookingVolsCloseModal={closeModalBookingVols} 
       idEvenement={idEvenementForModal}
       nbVoyageurs={nb_voyageurs}
       voyageKey={voyageKey}
       />
       )}

{isModalStatistiquesOpen && (
       <ModalStatistiques 
        onStatistiquesCloseModal={closeModalStatistiques} 
        nbEtapes={statNbEtapes}
        budget={statBudget}
        nbJours={nbJours}
        monnaie={symbole_monnaie}
        distance={statDistance}
        distanceAvion={statDistanceAvion}
        distanceBateau={statDistanceBateau}
        distanceTrain={statDistanceTrain}
        distanceBus={statDistanceBus}
        distanceTaxi={statDistanceTaxi}
        distanceVoiture={statDistanceVoiture}
        distanceMoto={statDistanceMoto}
        distanceVelo={statDistanceVelo}
        distancePied={statDistancePied}
        />
       )}

{isModalContenuBagageOpen && (
       <ModalContenuBagage 
       onContenuBagageCloseModal={closeModalContenuBagage} 
       idVoyage={voyageKey}
        />
       )}

{isModalIntegrationOpen && (
       <ModalIntegration 
       onIntegrationCloseModal={closeModalIntegration} 
       idVoyage={voyageKey}
       affichageBudget={affichageBudget}
        />
    )}

{isModalPartageOpen && (
       <ModalPartageRS  onClose={closeModalPartage}
       keyVoyage={Number(voyageKey)}
       onIntegrationClick={visibilite && visibilite === 'public' ? handleIntegrationClick : () => {}}
        />
    )}

{isModalImportOpen && (
       <ModalImport 
       onImportCloseModal={closeModalImport} 
       idVoyage={voyageKey}
       nbJours={nbJours}
       eventCategories={eventCategories}
       onAddEvenement={handleAddEvenement as OnAddEvenementType}
        />
    )}


{isModalExportOpen && (
       <ModalExport 
       onExportCloseModal={closeModalExport} 
       idVoyage={voyageKey}
        />
       )}

{ isModalEditionPDFOpen && (
        <ModalEditionPDF isCreateur={true} isCoCreateur={true} onClose={closeModalEditionPDF} keyVoyage={Number(voyageKey)}
        partageConfirmations='O'
        partagePrix='O'
        partageBlocNotes='O'
        />
      )}

{isAdding && (
  <Transition appear show={true} as={Fragment}>
    <Dialog
      as="div"
      className="fixed inset-0 z-[100] overflow-y-auto" // Plus élevé pour l'attente
      onClose={() => {}} // Pas de fermeture pour la modale d'attente
    >
      <div className="min-h-screen flex items-center justify-center px-4 text-center">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-60" />
        </Transition.Child>
        
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0 scale-95"
        >
          <div
            className="inline-block transition-all transform rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl px-8 py-4"
          >
            <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-neutral-200">
              {t('ajoutEnCours')}
            </h3>
          </div>
          
        </Transition.Child>
      </div>
    </Dialog>
  </Transition>
)}

{isEchange && (
  <Transition appear show={true} as={Fragment}>
    <Dialog
      as="div"
      className="fixed inset-0 z-[100] overflow-y-auto" // Plus élevé pour l'attente
      onClose={() => {}} // Pas de fermeture pour la modale d'attente
    >
      <div className="min-h-screen flex items-center justify-center px-4 text-center">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-60" />
        </Transition.Child>
        
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0 scale-95"
        >
          <div
            className="inline-block transition-all transform rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl px-8 py-4"
          >
            <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-neutral-200">
              {t('echangeEnCours')}
            </h3>
          </div>
          
        </Transition.Child>
      </div>
    </Dialog>
  </Transition>
)}

{isCopie && (
  <Transition appear show={true} as={Fragment}>
    <Dialog
      as="div"
      className="fixed inset-0 z-[100] overflow-y-auto" // Plus élevé pour l'attente
      onClose={() => {}} // Pas de fermeture pour la modale d'attente
    >
      <div className="min-h-screen flex items-center justify-center px-4 text-center">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-60" />
        </Transition.Child>
        
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0 scale-95"
        >
          <div
            className="inline-block transition-all transform rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl px-8 py-4"
          >
            <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-neutral-200">
              {t('copieEnCours')}
            </h3>
          </div>
          
        </Transition.Child>
      </div>
    </Dialog>
  </Transition>
)}

  </ItemsSlider>
  </DndProvider>
  );
};

const TextareaAutogrow: React.FC<React.TextareaHTMLAttributes<HTMLTextAreaElement>> = (props) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const handleInput = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = `${Math.max(textarea.scrollHeight, 40)}px`; // Ajustez 60 en fonction de vos besoins pour définir une hauteur minimale
    }
  };

  useEffect(() => {
    handleInput();

  }, []); // Utilisation d'un tableau de dépendances vide pour que cela s'exécute une seule fois après le rendu initial


  useEffect(() => {

    // Si libelle2 est vide, définissez la hauteur initiale sur 2 lignes
    if (props.defaultValue === "" && textareaRef.current) {
      textareaRef.current.style.height = '40px'; // Ajustez la hauteur initiale selon vos besoins
    }
  }, [props.defaultValue]);
  
  return <Textarea ref={textareaRef} onInput={handleInput} {...props} />;
};

export default Planificateur;
