import React, { FC, useEffect, useState, useCallback } from "react";
import { TaxonomyType } from "data/types";
import CardNuitResumeIntegre from "components/CardNuitResumeIntegre/CardNuitResumeIntegre";
import ItemsSlider from 'components/ItemsSlider';
import ModalLieuxResume from 'components/ModalLieuxResume';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from "react-dnd-html5-backend";
import { useNavigate } from "react-router-dom";
import { JourneeType, EventType, PecType } from "data/types";
import 'react-tooltip/dist/react-tooltip.css';
import { useTranslation } from 'react-i18next';

interface GroupVille {
  libelle1: string;
  lat: string | null;
  longi: string | null;
  firstDay: string;
  firstDayId: number;
  image?: string;
  nights: EventType[];
}

interface GroupNuits {
  pays: string;
  nom_pays: string;
  groupVilles: GroupVille[];
}

interface Flags {
  [key: string]: string | undefined;
}

export interface PlanificateurResumeIntegreProps {
  className?: string;
  itemClassName?: string;
  categories?: TaxonomyType[];
  categoryCardType?: "card3" | "card4" | "card5";
  itemPerRow?: 4 | 5;
  sliderStyle?: "style1" | "style2";
  uniqueClassName: string;
  voyageKey: string;
  emailPartage: string;
  codePartage: string;
  mode: string;
  eventCategoriesPage: JourneeType[];
  id_pays: string;
  libelles_pays: string;
  nbJours: number;
  visibilite: string;
  etatVoyage: number;
  nb_voyageurs: number;
  monnaie: string;
  symbole_monnaie: string,
  numDroit: number; 
  username: string;
}


const PlanificateurResumeIntegre: FC<PlanificateurResumeIntegreProps> = ({
  categoryCardType = "card3",
  voyageKey,
  emailPartage,
  codePartage,
  mode,
  eventCategoriesPage,
  id_pays,
  libelles_pays,
  nbJours,
  visibilite,
  etatVoyage,
  nb_voyageurs,
  monnaie,
  symbole_monnaie,
  numDroit,
  username
}) => {
  const { t } = useTranslation();
  
  const [eventCategories, setEventCategories] = useState<JourneeType[]>([]);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  
  const [selectedEvent, setSelectedEvent] = useState<EventType | null>(null);
  const [selectedEventIndex, setSelectedEventIndex] = useState<number>(0);
  const [selectedJournee, setSelectedJournee] = useState<number | null>(null);
  const [listePec, setListePec] = useState<PecType[]>([]);

  const [isModalLieuxResumeOpen, setIsModalLieuxResumeOpen] = useState(false);

  const [typeEvenementForModal, setTypeEvenementForModal] = useState("");
  const [idEvenementForModal, setIdEvenementForModal] = useState<number>(0);
  const [idJourneeForModal, setIdJourneeForModal] = useState<number>(0);

  const [groupedNights, setGroupedNights] = useState<any[]>([]);
  const [flags, setFlags] = useState<Flags>({});
  const [lieuxVisites, setLieuxVisites] = useState<Array<string>>([]);

  let navigate=useNavigate();

  const handleElementChange = useCallback(
    (journeeId: number, eventId: number, typeEvenement: string, valeur: string, typeElement: string) => {
      setEventCategories((prevCategories) => {
        return prevCategories.map((day) => {
          if (day.id_journee === journeeId) {
            return {
              ...day,
              evenements: day.evenements.map((event) => {
                if (
                  (typeEvenement === 'lieu' && event.typeEv === 'L' && event.id === eventId) ||
                  (typeEvenement === 'trajet' && event.typeEv === 'T' && event.id === eventId) ||
                  (typeEvenement === 'nuit' && event.typeEv === 'N' && event.id === eventId)
                ) {
                  if (typeElement === 'libelle1') {
                    return { ...event, libelle1: valeur };
                  } else if (typeElement === 'libelle2') {
                    return { ...event, libelle2: valeur };
                  } else if (typeElement === 'prix') {
                    return { ...event, prix: valeur };
                  } else if (typeElement === 'paiement') {
                    return { ...event, paiement: valeur };
                  } else if (typeElement === 'acompte_hebergement') {
                    return { ...event, acompte_hebergement: valeur };
                  } else if (typeElement === 'date_paiement_hebergement') {
                    return { ...event, date_paiement_hebergement: valeur };
                  } else if (typeElement === 'debut') {
                    return { ...event, debut: valeur };
                  } else if (typeElement === 'fin') {
                    return { ...event, fin: valeur };
                  } else if (typeElement === 'compagnie') {
                    return { ...event, compagnie: valeur };
                  } else if (typeElement === 'ref') {
                    return { ...event, ref: valeur };
                  } else if (typeElement === 'distance') {
                    return { ...event, distance: valeur };
                  } else if (typeElement === 'duree') {
                    return { ...event, duree: valeur };
                  } else if (typeElement === 'invisible1') {
                    return { ...event, invisible1: valeur };
                  } else if (typeElement === 'invisible2') {
                    return { ...event, invisible2: valeur };
                  } else if (typeElement === 'type') {
                    return { ...event, type: valeur };
                  } else if (typeElement === 'moment') {
                    return { ...event, moment: valeur };
                  } else if (typeElement === 'rattachement') {
                    return { ...event, rattachement: valeur };
                  } else if (typeElement === 'image') {
                    return { ...event, image: valeur };
                  } else if (typeElement === 'lat') {
                    return { ...event, lat: valeur };
                  } else if (typeElement === 'longi') {
                    return { ...event, longi: valeur };
                  } else if (typeElement === 'lat2') {
                    return { ...event, lat2: valeur };
                  } else if (typeElement === 'longi2') {
                    return { ...event, longi2: valeur };
                  } else if (typeElement === 'place') {
                    return { ...event, place: valeur };
                  } else if (typeElement === 'latModif') {
                    return { ...event, latModif: valeur };
                  } else if (typeElement === 'longiModif') {
                    return { ...event, longiModif: valeur };
                  } else if (typeElement === 'lat2Modif') {
                    return { ...event, lat2Modif: valeur };
                  } else if (typeElement === 'longi2Modif') {
                    return { ...event, longi2Modif: valeur };
                  } else if (typeElement === 'document') {
                    return { ...event, document: valeur };
                  } else if (typeElement === 'lien') {
                    return { ...event, lien: valeur };
                  } else if (typeElement === 'bloc') {
                    return { ...event, bloc: valeur };
                  } else if (typeElement === 'pays') {
                    return { ...event, pays: valeur };
                  } else if (typeElement === 'nom_pays') {
                    return { ...event, nom_pays: valeur };
                  }
                }
                return event;
              }),
            };
          }
          return day;
        });
      });
    },
    [] // dependencies, update if needed
  );

  

  useEffect(() => {

    if (JSON.stringify(eventCategories) !== JSON.stringify(eventCategoriesPage)) {
      setEventCategories(eventCategoriesPage);
      setLoading(false); 
    }
  }, [eventCategoriesPage]);

  useEffect(() => {
    const groups: GroupNuits[] = [];
    let currentGroupPays: GroupNuits | null = null;
    let currentGroupVille: GroupVille | null = null;

    eventCategories.forEach((day) => {
      day.evenements.forEach((evenement) => {

        if ((evenement.libelle1 === "Nuit en transport" || evenement.libelle1 === "Night in transport" || (evenement.nom_pays !== null && evenement.nom_pays !== ''))
          && evenement.typeEv === 'N') {

          const paysNom = evenement.nom_pays || ( 
            !currentGroupPays && (evenement.libelle1 === "Nuit en transport" || evenement.libelle1 === "Night in transport") ? t('trajet')
            : currentGroupPays && (evenement.libelle1 === "Nuit en transport" || evenement.libelle1 === "Night in transport") && currentGroupPays.nom_pays !== t('nuitsIncompletes') ? currentGroupPays.nom_pays 
            : currentGroupPays && (evenement.libelle1 === "Nuit en transport" || evenement.libelle1 === "Night in transport") && currentGroupPays.nom_pays === t('nuitsIncompletes') ? t('trajet')
            :  t('nuitsIncompletes')
          );

          const codePays = evenement.pays || "";

          if (!currentGroupPays || currentGroupPays.nom_pays !== paysNom) {
            currentGroupPays = {
              nom_pays: paysNom,
              pays: codePays,
              groupVilles: []
            };
            groups.push(currentGroupPays);
            currentGroupVille = null; // Reset the current city group
          }

          const villeNom = evenement.libelle1 || t('cliquezCompleter');

          if (!currentGroupVille || currentGroupVille.libelle1 !== villeNom) {
            currentGroupVille = {
              libelle1: villeNom,
              lat: evenement.lat,
              longi: evenement.longi,
              firstDay: day.date_journee.split(' ')[1],
              firstDayId: day.id_journee,
              image: evenement.image || '',
              nights: [evenement]
            };
            currentGroupPays.groupVilles.push(currentGroupVille);
          } else {
            currentGroupVille.nights.push(evenement);
          }

          if (!currentGroupVille.image && evenement.image) {
            currentGroupVille.image = evenement.image;
          }
        }
      });
    });

    setGroupedNights(groups);
  }, [eventCategories]);
  
  useEffect(() => {
    groupedNights.forEach(group => {
      if (group.pays && !flags[group.pays]) {
        apiRestcountries(group.pays)
          .then(data => {
            setFlags(prevFlags => ({
              ...prevFlags,
              [group.pays]: data[0]?.flag || ''
            }));
          })
          .catch(error => {
            console.error(`Error fetching flag for ${group.pays}:`, error);
            setFlags(prevFlags => ({
              ...prevFlags,
              [group.pays]: ''
            }));
          });
      }
    });
  }, [groupedNights, flags]);
    
  useEffect(() => {
    const updatedListePec = eventCategories.flatMap((day) =>
    day.evenements
      .filter(
        (event) =>
          event.location === 'O' &&
          event.moment === 'P'
      )
      .map((event) => ({
        date_journee: day.date_journee,
        id_journee: day.id_journee,
        id_evenement: event.id, 
        type_transport: event.type || "",
        rattachement: event.rattachement || "",
        debut: event.debut || ""
      }))
  );

  setListePec(updatedListePec);

  }, [eventCategories]);

  function apiRestcountries(codePays: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = `https://restcountries.com/v3.1/alpha/${codePays}`;
      fetch(url)
        .then(response => {
          if (response.ok) {
            response.json().then(data => resolve(data));
          } else {
            response.json().then(error => reject(error));
          }
        })
        .catch(error => reject(error));
    });
  }
  

  if (loading) {
    return <div className="text-center mt-20 mb-20"><h1 className="text-xl">{t('chargementVoyage')}</h1></div>;
  }

  const openModal = (idJournee: number, event: EventType, eventIndex: number) => {

    setSelectedEvent(event);
    setSelectedEventIndex(eventIndex);
    setSelectedJournee(idJournee);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedEvent(null);
    setSelectedEventIndex(0);
    setSelectedJournee(null);
    setIsModalOpen(false);
  };

  
 
  const openModalLieuxResume = () => {
    setIsModalLieuxResumeOpen(true);
  };

  const closeModalLieuxResume = () => {
    setIsModalLieuxResumeOpen(false);
  };

const handleLieuxResumeClick = async (nuits: EventType[]) => {

    try {

      var listeLieuxVisites: string[] = [];
      var listeLieuxVisitesTemp="";

      nuits.forEach((nuit) => {
        eventCategories.forEach((day) => {
          if(day.date_journee && day.date_journee !== '')
          {
            listeLieuxVisitesTemp=day.date_journee.split(' ')[1] + " :";
          }

          day.evenements.forEach((evenement) => {
            if(evenement.typeEv === "L" && (evenement.type === null || evenement.type === ""))
            {
              if(evenement.libelle1 !== null && evenement.libelle1 !== "")
              {
                listeLieuxVisitesTemp+=" "+evenement.libelle1 + " - ";
              }
            }
            if(evenement.typeEv === "N")
            {
              if(evenement.id === nuit.id)
              {

                  if(listeLieuxVisitesTemp.length === 12)
                  {
                    listeLieuxVisites.push(listeLieuxVisitesTemp + " Aucun lieu renseigné");
                  }
                  else
                  {
                    listeLieuxVisites.push(listeLieuxVisitesTemp.substring(0, listeLieuxVisitesTemp.length-3));
                  }
                  listeLieuxVisitesTemp="";
              }
              else
              {
                listeLieuxVisitesTemp="";
              }
            }
            
          });
        });
      });

      setLieuxVisites(listeLieuxVisites);
    
      openModalLieuxResume();

    } catch (error: any) {
  
      if (error.response?.status === 401) {
        localStorage.removeItem('xsrfToken');
      }
      console.error("Error fetching data:", error);
      navigate('/');
    }
}

  return (
    <DndProvider backend={HTML5Backend}>

    { groupedNights.length === 0 
    ? (<div className="mt-4 mb-20 text-sm text-center sticky">Aucune ville localisée n'est présente pour les éléments nuits</div>)
    :
    (<ItemsSlider title="" nomClass="item-slider-container" classDay="day-item-integre" classSlider="item-slider-integre"> 
    {groupedNights.map((group: any, index: number) => (
        <div key={index} className="text-center">
          <h2 className={`champJourIntegre mb-8} ${group.nom_pays === t('nuitsIncompletes') ? "couleurRose" : ""}`}>{group.nom_pays} {flags[group.pays] && <span>{flags[group.pays]}</span>}</h2>
          {group.groupVilles.map((ville: any, villeIndex: number) => {
            const cardContent = (
              <CardNuitResumeIntegre
                key={villeIndex}
                libelle={ville.libelle1}
                jour={ville.firstDay}
                nbNuits={ville.nights.length}
                image={ville.image}
                pays={group.pays}
                isModif={false}
                onLieuxResumeClick={() => handleLieuxResumeClick(ville.nights)}
              />
            );

            return (
              cardContent
            );
          })}
        </div>
      ))}


{isModalLieuxResumeOpen && (
       <ModalLieuxResume 
        onLieuxResumeCloseModal={closeModalLieuxResume} 
        lieux={lieuxVisites}
        />
       )}

  </ItemsSlider>
  )}
  </DndProvider>
  );
};

export default PlanificateurResumeIntegre;
