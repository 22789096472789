import React, { FC, useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import { contactService } from '_services/contact.service';
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ReCAPTCHA from "react-google-recaptcha";
import AppContext from 'AppContext';
import { useTranslation } from 'react-i18next';

export interface PageContactProps {
  className?: string;
}

const getObfuscatedEmail = () => {

  var part1 = "contact";
  var part2 = Math.pow(2,6);
  var part4 = "tripkygo.com";
  var part5 = part1 + String.fromCharCode(part2) + part4;

      return part5;
}; 


const PageContact: FC<PageContactProps> = ({ className = "" }) => {

  const { t } = useTranslation();

  const info = [
    {
      title: "Paris, FRANCE <i class='text-xl las la-map-marker'></i>",
      link: ""
    },
    {
      title: `${getObfuscatedEmail()} <i class='text-xl las la-envelope'></i>`,
      link: `mailto:${getObfuscatedEmail()}?subject=Contact&body=`
    },
    {
      title: "Instagram <i class='text-xl lab la-instagram'></i>",
      link: `https://instagram.com/tripkygo${t('_en')}`
    },
    {
      title: "Facebook <i class='text-xl lab la-facebook'></i>",
      link: `https://facebook.com/tripkygo${t('En')}`
    },
    {
      title: "X (Twitter) <i class='text-xl lab la-twitter'></i>",
      link: `https://x.com/tripkygo${t('_en')}`
    },
    {
      title: "Youtube <i class='text-xl lab la-youtube'></i>",
      link: "https://youtube.com/@tripkygo"
    },
  ];

  const [nom, setNom] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [sujet, setSujet] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [entryTime, setEntryTime] = useState<Date | null>(null); 
  const [captchaToken, setCaptchaToken] = useState<string | null>(null);
  const { setVoyageInfo, setAfficheMenuMobile } = useContext(AppContext);

  useEffect(() => {
    setEntryTime(new Date());
    setVoyageInfo(null);
    setAfficheMenuMobile(true);
  }, []); 


  const handleCaptchaChange = (token: string | null) => {
    setCaptchaToken(token);
  };
    
  const clickLien = (url: string) => {
    if(url !== "")
    {
      window.open(url, '_blank');
    }

  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  }

  const handleNomChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNom(event.target.value);
  }

  const handleSujetChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSujet(event.target.value);
  }

  const handleMessageChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(event.target.value);
  }

  const validateEmail = (mail: string) => {
    //var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const re = /^(?=[a-zA-Z0-9._%+-]{1,64}@)([a-zA-Z0-9._%+-]+)@([a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
    return re.test(mail);
  }

  const envoyerMessage = () => {

    if(nom === '')
    {
      alert(t('alertNom'));
    }
    else if(email === '' || !validateEmail(email)) 
    {
      alert(t('alertEmail'));
    }
    else if(sujet === '')
    {
      alert(t('alertSujet'));
    }
    else if(message === '' || message.length < 30)
    {
      alert(t('alertMessage'));
    }
    else
    {
      if (!captchaToken) {
        alert(t('alertRecaptcha'));
        return;
      }

      if (entryTime) {
        const currentTime = new Date(); 
        const timeDifference = (currentTime.getTime() - entryTime.getTime()) / 1000;
  
        if (timeDifference < 8) {
          alert(t('alertSaisieRapide'));
          return; 
        }
      }
  
      contactService.contacter({
        email: email, 
        nom: nom,
        sujet: sujet,
        message: message
      })
        .then((response: any) => {
          alert("Message envoyé !\nNous y répondrons dans les plus brefs délais.");
          window.location.reload();
        })
        .catch((error: any) => {
        
          alert("Erreur lors de l'envoi du message.");

        });
    }

  }
 
  return (
    <div
      className={`nc-PageContact overflow-hidden ${className}`}
      data-nc-id="PageContact"
    >
      <Helmet>
        <title>Contact</title>
      </Helmet>
      <div className="mb-24 lg:mb-32 ">
        <h2 className="my-8 sm:my-10 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Contact
        </h2>
        <div className="text-center mb-8"><p>{t('introContact')}</p></div>
        
        <div className="container mx-auto">
          <div className="flex-shrink-0 grid grid-cols-1 sm:grid-cols-2 gap-12 ">
            
            <div>
              <div className="grid grid-cols-1 gap-6" >
                <label className="block">
                  <Label>{t('nom')}</Label>

                  <Input
                    onChange={handleNomChange}
                    placeholder=""
                    type="text"
                    className="mt-1"
                    maxLength={50}
                  />
                </label>
                <label className="block">
                  <Label>Email</Label>

                  <Input
                    onChange={handleEmailChange}
                    type="email"
                    placeholder=""
                    className="mt-1"
                    maxLength={50}
                  />
                </label>
                <label className="block">
                  <Label>{t('sujet')}</Label>

                  <Input
                    onChange={handleSujetChange}
                    placeholder=""
                    type="text"
                    className="mt-1"
                    maxLength={30}
                  />
                </label>
                <label className="block">
                  <Label>Message</Label>

                  <Textarea className="mt-1" rows={6} maxLength={500} onChange={handleMessageChange}/>
                </label>
                <div className="ml-5 mt-5">
                    <ReCAPTCHA
                      sitekey="6Le6IGMaAAAAALCXjuRzgBKxIsm9cxa5akWkvExA" 
                      onChange={handleCaptchaChange}
                    />
                  </div>
                <div>
                  <ButtonPrimary type="submit" onClick={envoyerMessage}>{t('envoyer')}</ButtonPrimary>
                </div>
              </div>
            </div>
            <div className="max-w-sm space-y-8 text-right mt-10">
              {info.map((item, index) => (
                <div key={index} onClick={() => clickLien(item.link)} className="pointeurRose">
                  <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                    <span dangerouslySetInnerHTML={{ __html: item.title }}></span>
                  </h3>

                </div>
              ))}
             
            </div>
          </div>
        </div>
      </div>
</div>
  );
};

export default PageContact;
