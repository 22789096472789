import React, { FC, useEffect, useContext, useState } from "react";
import CommonLayout from "./CommonLayout";
import { Helmet } from "react-helmet";
import AppContext from 'AppContext';
import { useTranslation } from 'react-i18next';
import { InformationCookiesMarketing } from 'shared/InformationCookiesMarketing/InformationCookiesMarketing';

declare var Booking: any;

export interface ReservationAvionProps {
  className?: string;
}

const ReservationAvion: FC<ReservationAvionProps> = ({ className = "" }) => {
  const { t } = useTranslation();

  const { setVoyageInfo, setAfficheMenuMobile } = useContext(AppContext);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [cookiePartenaires, setCookiePartenaires] = useState<boolean>(true);

  // Fonction pour obtenir la valeur d'un cookie
  const getCookie = (name: string) => {
    const cookieValue = document.cookie
      .split('; ')
      .find(row => row.startsWith(name))
      ?.split('=')[1];
    return cookieValue || '';
  };

  useEffect(() => {
    setVoyageInfo(null);
    setAfficheMenuMobile(false);
  }, []);

  const handleInfoClick = () => {
    alert(
      t('infoBooking')
    );
  };

useEffect(() => {
  const cookieValue = getCookie("tripkygoPartenaires");

  // Si le cookie existe et a la valeur 'O', met à jour isLiked à true
  if (cookieValue && cookieValue === 'O') {

    setCookiePartenaires(true);
    const script = document.createElement('script');
    script.src = 'https://www.booking.com/affiliate/prelanding_sdk';
    script.onload = () => {
      if (typeof Booking !== 'undefined' && Booking.AffiliateWidget) {
        new Booking.AffiliateWidget({
          iframeSettings: {
            selector: 'bookingAffiliateWidget_edb02e38-7ca4-4d1f-9477-d75e107f0c37',
            responsive: true,
          },
         widgetSettings: {
          
          },
        });
        
      } else {
        console.error("Le script du widget affilié Booking n'a pas été chargé ou est indéfini.");
      }
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };

}
else
{
  setCookiePartenaires(false);
}
  
}, [])

  return (
    <div className={`nc-ReservationAvion ${className}`} data-nc-id="ReservationAvion">
      <Helmet>
        <title>{t('reservation')}</title>
      </Helmet>
      <CommonLayout>
        <div className="text-center">
           <h1 className="font-medium leading-6 text-gray-900 dark:text-neutral-300 mb-6">
                  {t('reserverAvions')} <a href='https://www.booking.com/flights/index.fr.html?aid=2040377' rel="noreferrer" target="_blank"><u>Booking.com</u></a>
                  <sup>
                    <button type="button" onClick={handleInfoClick}>
                      <i className="las la-info"></i>
                    </button>
                  </sup>
                </h1>
            
                {!cookiePartenaires && (
            <InformationCookiesMarketing/>
          )}

<div id="bookingAffiliateWidget_edb02e38-7ca4-4d1f-9477-d75e107f0c37" className="bg-white">&nbsp;</div>
               
        </div>
      </CommonLayout>
    </div>
  );
};

export default ReservationAvion;
