import React, { Fragment, useState, useContext, useEffect } from "react";
import { Dialog, Tab, Transition } from "@headlessui/react";
import { MagnifyingGlassIcon, GlobeAltIcon } from "@heroicons/react/24/outline";
import { XMarkIcon, CheckIcon } from "@heroicons/react/24/solid";
import ButtonSubmit from "./ButtonSubmit";
import { useTimeoutFn } from "react-use";
import VoyageNomadeSearchForm from "./(voyage-nomade-search-form)/VoyageNomadeSearchForm";
import VoyageSedentaireSearchForm from "./(voyage-sedentaire-search-form)/VoyageSedentaireSearchForm";
import ModalIncontournables from "../ModalIncontournables";
import CarsSearchForm from "./(car-search-form)/CarsSearchForm";
import FlightSearchForm from "./(flight-search-form)/FlightSearchForm";
import { useNavigate } from "react-router-dom";
import { Destination } from "./(voyage-nomade-search-form)/PaysInputMobile";
import { voyageService } from '_services/voyage.service';
import AppContext from 'AppContext';
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Lottie from 'react-lottie';
import animationData from '../../shared/loading.json';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';

const HeroSearchForm2Mobile = () => {
  const { t } = useTranslation();

  const { voyageInfo } = useContext(AppContext);

  const [showModal, setShowModal] = useState(false);
  const [tabMenu, setTabMenu] = useState<string[]>([]);
  const [selectedDestinations, setSelectedDestinations] = useState<Destination[]>([]);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [guestAdultsInputValue, setGuestAdultsInputValue] = useState<number>(0);
  const [monnaie, setMonnaie] = useState<string | null>(null);
  const [symboleMonnaie, setSymboleMonnaie] = useState<string | null>(null);
  const [initSelectedDestinations, setSelectedInitDestinations] = useState<Destination[]>([]);
  const [initStartDate, setInitStartDate] = useState<Date | null>(null);
  const [initEndDate, setInitEndDate] = useState<Date | null>(null);
  const [initGuestAdultsInputValue, setInitGuestAdultsInputValue] = useState<number>(0);
  const [initMonnaie, setInitMonnaie] = useState<string | null>(null);
  const [initSymboleMonnaie, setInitSymboleMonnaie] = useState<string | null>(null);
  const [isModalDecalageOpen, setIsModalDecalageOpen] = useState(false);
  const [typeModalDecalage, setTypeModalDecalage] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingModif, setIsLoadingModif] = useState<boolean>(false);
  const [bookingChecked, setBookingChecked] = useState<boolean>(true);
// Détection de Safari
const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

// Détection de Firefox
const isFirefox = /firefox/i.test(navigator.userAgent);

// Détection d'un appareil mobile (iOS ou Android)
const isMobile = /Mobi|Android/i.test(navigator.userAgent);
  
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const [codePaysClicked, setCodePaysClicked] = useState<string>("");
  const [isModalIncontournablesOpen, setIsModalIncontournablesOpen] = useState(false);
  const [dateEntreePlanif, setDateEntreePlanif] = useState<Date>(moment().tz('Europe/Paris').toDate());


  const navigate = useNavigate(); 

  const handleClickPays = (codePays: string) => {
    setCodePaysClicked(codePays);
    closeModal();
    setIsModalIncontournablesOpen(true);
  }

  const closeModalIncontournables = () => {
    setIsModalIncontournablesOpen(false);
  }

  const openModalDecalage = (type: number) => {
    setTypeModalDecalage(type);
    setIsModalDecalageOpen(true);
  };

  const closeModalDecalage = () => {
    setTypeModalDecalage(0);
    setIsModalDecalageOpen(false);
  };


  // FOR RESET ALL DATA WHEN CLICK CLEAR BUTTON
  const [showDialog, setShowDialog] = useState(false);
  let [, , resetIsShowingDialog] = useTimeoutFn(() => setShowDialog(true), 1);
  //
  function closeModal() {
    setShowModal(false);
  }

  function openModal() {
    setShowModal(true);
  }

  const convertStringToDate = (dateString: string): Date | null => {
    if (!dateString) return null;
  
    const [day, month, year] = dateString.split('/').map(Number);
    return new Date(year, month - 1, day); // Month is 0-indexed
  };

  const verrouillageModif = async () => {
    
    if (voyageInfo?.etatVoyage === 1) {
  
      try {
        const response = await voyageService.verrouillerVoyage(voyageInfo?.id_voyage);

  
        if (response.data.message === 'ok') {
          if (response.data.dateVerrou !== undefined && response.data.dateVerrou !== null) {
            const dateVerrou = new Date(response.data.dateVerrou);
            
            if (dateVerrou > dateEntreePlanif) {
              alert('Le voyage a pu être modifié par un co-créateur, nous allons donc actualiser la page pour prendre en compte les éventuels changements.\n\nVous pourrez ensuite reprendre votre activité.');
              window.location.reload();
              return 9;  // Retourne une valeur qui interrompt la suite du processus
            } else {
              return 1;  // OK, continue
            }
          } else {
            return 1;  // OK, continue
          }
        } else {
          alert(response.data.alert);
          return 0;
        }
      } catch (error: any) {
        if (error.response?.status === 401) {
          alert("Erreur lors de la modification, vous devez être connecté.");
          return 9;
          navigate("/login");
        } else if (error.response?.status === 406 && error.response.data.message !== undefined) {
          console.log(error.response);
          alert(error.response.data.message);
          return 9;
        } else {
          alert("Erreur lors de la modification.");
          return 9;
        }
      }
    } else {
      return 1;  // OK, continue
    }
  };

  useEffect( () => {

    if(voyageInfo)
    {

      setInitStartDate(convertStringToDate(voyageInfo.date_deb_formate));
      setInitEndDate(convertStringToDate(voyageInfo.date_fin_formate));
      setInitGuestAdultsInputValue(voyageInfo.nb_voyageurs);

      var tabDestinations : Destination[] = [];
      for(var i=0; i<voyageInfo.id_pays.split("_").length; i++)
      {

        tabDestinations.push({
          code_pays: voyageInfo.id_pays.split("_")[i],
          pays: voyageInfo.libelles_pays.split("_")[i],
          ville: voyageInfo.ville,
          place_id: '',
          latitude: '',
          longitude: '',
        }) 
      }
 
      setSelectedInitDestinations(tabDestinations);
      setInitMonnaie(voyageInfo.monnaie);
      setInitSymboleMonnaie(voyageInfo.symbole_monnaie);

      setStartDate(convertStringToDate(voyageInfo.date_deb_formate));
      setEndDate(convertStringToDate(voyageInfo.date_fin_formate));
      setGuestAdultsInputValue(voyageInfo.nb_voyageurs);
      setSelectedDestinations(tabDestinations);
      setMonnaie(voyageInfo.monnaie);
      setSymboleMonnaie(voyageInfo.symbole_monnaie);

      if(!voyageInfo.ville)
      {
        setTabMenu([t('voyageNomade')]);
      }
      else
      {
        setTabMenu([t('voyageSedentaire')]);
      }
    }
    else
    {
      setInitStartDate(new Date());
      setInitEndDate(new Date());
      setInitGuestAdultsInputValue(1);
      setSelectedInitDestinations([]);
      setInitMonnaie("EUR");
      setInitSymboleMonnaie("€");

      setStartDate(new Date());
      setEndDate(new Date());
      setGuestAdultsInputValue(1);
      setSelectedInitDestinations([]);
      setMonnaie("EUR");
      setSymboleMonnaie("€");
      setTabMenu([t('voyageNomade'),t('voyageSedentaire')]);
    }
  }, [voyageInfo]);

  const  diffdate = (d1: Date,d2: Date) => {
    var WNbJours = d2.getTime() - d1.getTime();
    return  Math.ceil(WNbJours/(1000*60*60*24));
  }

  const handleButtonSubmitModif = (selectedDestinations: Destination[],
    guestAdultsInputValue: number,
    startDate: Date | null,
    endDate: Date | null) => {

    //event.preventDefault();

    const modifVoyage = async () => {
      try {

    if(initStartDate !== null && initEndDate !== null)
    {
      
        if(selectedDestinations.length < 1)
        {
            alert(t('alertDestination'));
            return false;
        }
        if(startDate === null)
        {
            alert(t('alertDateDeb'));
            return false;
        }
        else if(endDate === null)
        {
            alert(t('alertDateFin'));
            return false;
        }
        else if(guestAdultsInputValue < 1)
        {
            alert(t('alertNbVoyageurs'));
            return false;
        }
        
    
        const diffInTime = endDate.getTime() - startDate.getTime();
        const diffInDays = Math.ceil(diffInTime / (1000 * 3600 * 24));
    
       if(diffInDays+1  > 365 )
        {
            alert(t('alertNbJoursMax'));
            return false;
        }
        else if(diffInDays+1 <= 1 )
        {
          alert(t('alertNbJoursMin'));
          return false;
        }

    
        var nbJoursInit=diffdate(initStartDate,initEndDate)+1; 
        var nouvNbJours=diffdate(startDate,endDate)+1;

        if(startDate > initStartDate && endDate < initEndDate)
        {
            const isConfirmed = window.confirm(
                t('confirmRepousser')
              );

            if(!isConfirmed)
            {
                    return false;
            }
        }
        else if(nouvNbJours === nbJoursInit && startDate?.toISOString() !== initStartDate?.toISOString())
        {
            const isConfirmed = window.confirm(
              t('confirmDecaler')
              );

            if(!isConfirmed)
            {
                    return false;
            }
        }
        else if(nouvNbJours < nbJoursInit && startDate <= initStartDate)
        {

            const isConfirmed = window.confirm(
              t('confirmDiminuer')
              );
            
            if(!isConfirmed)
            {
                    return false;
            }
        }

        if(startDate > initStartDate && nouvNbJours !== nbJoursInit)
        {
            closeModal();
            openModalDecalage(1);
        }
        else if(startDate < initStartDate && nouvNbJours !== nbJoursInit)
        {
            closeModal();
            openModalDecalage(2);
        }
        else 
        {
    
            enregistrerModifVoyage(false);
        }
    }
    } catch (error) {
      console.error(error);
      alert("Erreur lors de la modification !");
    }
  };

  const processModifVoyage = async () => {
    
    const verrouillageResultat = await verrouillageModif();

    if (voyageInfo?.etatVoyage === 0 || verrouillageResultat === 1) {
      modifVoyage();
    }
  };

  processModifVoyage();
  };

  const enregistrerModifVoyage = (decalage: boolean) =>
  {
   

    setIsLoadingModif(true); 

    const modifVoyage = async () => {
      try {
  
    const id_pays = Array.isArray(selectedDestinations)
    ? selectedDestinations.map((destination) => destination.code_pays).join("_")
    : "";
    const libelles_pays = Array.isArray(selectedDestinations)
    ? selectedDestinations.map((destination) => destination.pays).join("_")
    : "";
    const ville = Array.isArray(selectedDestinations)
    ? selectedDestinations
        .map((destination) => destination.ville)
        .filter((ville) => ville) // Filtrer les valeurs vides ou falsy
        .join("_")
    : "";
  
    let latitude = "";
    let longitude = "";
    let place_id = "";

    if(ville !== "")
    {

      latitude = Array.isArray(selectedDestinations)
    ? selectedDestinations
        .map((destination) => destination.latitude)
        .filter((latitude) => latitude) // Filtrer les valeurs vides ou falsy
        .join("_")
    : "";
  
      longitude = Array.isArray(selectedDestinations)
    ? selectedDestinations
        .map((destination) => destination.longitude)
        .filter((longitude) => longitude) // Filtrer les valeurs vides ou falsy
        .join("_")
    : "";
  
      place_id = Array.isArray(selectedDestinations)
      ? selectedDestinations
          .map((destination) => destination.place_id)
          .filter((place_id) => place_id) // Filtrer les valeurs vides ou falsy
          .join("_")
      : "";
    }

    voyageService.modifierVoyage({
        id_voyage: voyageInfo.id_voyage,
        id_pays: id_pays,
        libelles_pays: libelles_pays,
        date_debut: startDate?.toLocaleDateString("fr-FR") || "",
        date_fin: endDate?.toLocaleDateString("fr-FR") || "",
        nb_voyageurs: guestAdultsInputValue,
        ville: ville || null,
        latitude: latitude || null,
        longitude: longitude || null,
        place_id: place_id || null,
        monnaie: monnaie,
        decalage: decalage
    })
        .then((response) => {

        setIsLoadingModif(false); 
 
        if (response.data.message !== undefined && response.data.message !== null && response.data.message === 'ok') {
            window.location.reload();
        } 
        }) 
        .catch((error) => {
          if (error.response?.status === 401) {
            alert(t('alertMajConnecte'))
            localStorage.removeItem('xsrfToken');
            navigate(t('lienLogin'));
          }
          else
          {
            setIsLoadingModif(false); 
            alert(t('alertMajConnecte'));
          }
        });
      }
      catch (error) {
        console.error(error);
        alert("Erreur lors de la modification !");
      }
    }

      const processModifVoyage = async () => {
        
        const verrouillageResultat = await verrouillageModif();
    
        if (voyageInfo?.etatVoyage === 0 || verrouillageResultat === 1) {
          modifVoyage();
        }
      };

      processModifVoyage();
  }

  const handleButtonSubmit = (selectedDestinations: Destination[],
    guestAdultsInputValue: number,
    startDate: Date | null,
    endDate: Date | null) => {


      setIsLoading(true);
  
    const id_pays = Array.isArray(selectedDestinations)
    ? selectedDestinations.map((destination) => destination.code_pays).join("_")
    : "";
    const libelles_pays = Array.isArray(selectedDestinations)
    ? selectedDestinations.map((destination) => destination.pays).join("_")
    : "";
    const ville = Array.isArray(selectedDestinations)
    ? selectedDestinations
        .map((destination) => destination.ville)
        .filter((ville) => ville) // Filtrer les valeurs vides ou falsy
        .join("_")
    : "";
  
    let latitude = "";
    let longitude = "";
    let place_id = "";

    if(ville !== "")
    {

      latitude = Array.isArray(selectedDestinations)
    ? selectedDestinations
        .map((destination) => destination.latitude)
        .filter((latitude) => latitude) // Filtrer les valeurs vides ou falsy
        .join("_")
    : "";
  
      longitude = Array.isArray(selectedDestinations)
    ? selectedDestinations
        .map((destination) => destination.longitude)
        .filter((longitude) => longitude) // Filtrer les valeurs vides ou falsy
        .join("_")
    : "";
  
      place_id = Array.isArray(selectedDestinations)
      ? selectedDestinations
          .map((destination) => destination.place_id)
          .filter((place_id) => place_id) // Filtrer les valeurs vides ou falsy
          .join("_")
      : "";
    }
  
    const nb_voyageurs = (
      guestAdultsInputValue
    ).toString();
  
    if(id_pays === "")
    {
      setIsLoading(false);
      alert(t('alertDestination'));
      return false;
    }
    if(startDate === null)
    {
      setIsLoading(false);
      alert(t('alertDateDeb'));
      return false;
    }
    else if(endDate === null)
    {
      setIsLoading(false);
      alert(t('alertDateFin'));
      return false;
    }
    else if(guestAdultsInputValue < 1)
    {
      setIsLoading(false);
      alert(t('alertNbVoyageurs'));
      return false;
    }
    
  
    const diffInTime = endDate.getTime() - startDate.getTime();
    const diffInDays = Math.ceil(diffInTime / (1000 * 3600 * 24));
   
    if(diffInDays+1  > 365 )
    {
      setIsLoading(false);
      alert(t('alertNbJoursMax'));
      return false;
    }
    else if(diffInDays+1 <=1 )
    {
      setIsLoading(false);
      alert(t('alertNbJoursMin'));
      return false;
    }

    closeModal();
    
      voyageService.creerVoyage({
        id_pays: id_pays,
        libelles_pays: libelles_pays,
        ville: ville || null,
        date_debut: startDate?.toLocaleDateString("fr-FR") || "",
        date_fin: endDate?.toLocaleDateString("fr-FR") || "",
        nb_voyageurs: nb_voyageurs,
        latitude: latitude || null,
        longitude: longitude || null,
        place_id: place_id || null,
        checkboxBooking: 'M' // Mobile  
      })
        .then((response) => {
          const idVoyage = response.data.idVoyage;
          if (idVoyage && idVoyage !== 0) {

            setIsLoading(false);            

           // console.log("isSafari",isSafari);
           // console.log("isFirefox",isFirefox);

            navigate(`${t('lienPlanificateur')}/${idVoyage}`);

            //Marche en localhost mais pas en prod
           /* if (voyageInfo === null && bookingChecked) {
              let lienBooking='https://www.booking.com/';

              if(ville)
              {
                lienBooking+=`searchresults.html?latitude=${latitude}&longitude=${longitude}&utm_source=browser_redirect&aid=2040377`;
              }
              else
              {
                if(id_pays.split("_")[0] === 'ww')
                {
                  lienBooking+=`region/gb/england`;
                }
                else if(id_pays.split("_")[0] === 'xx')
                {
                  lienBooking+=`region/gb/scotland`;
                }
                else if(id_pays.split("_")[0] === 'yy')
                {
                  lienBooking+=`region/gb/northern-ireland`;
                }
                else if(id_pays.split("_")[0] === 'zz')
                {
                  lienBooking+=`region/gb/wales`;
                }
                else
                {
                  lienBooking+=`country/${id_pays.split("_")[0]}`;
                }
  
                lienBooking+='.fr.html?utm_source=browser_redirect&aid=2040377';
              }

              if (isMobile) {
                // Si c'est un appareil mobile (iOS/Android)
                if (isSafari) {
                  // Safari mobile : Ouvrir le planificateur dans un nouvel onglet et rediriger vers Booking
                  const link = document.createElement('a');
                  link.href = `${t('lienPlanificateur')}/${idVoyage}`;
                  link.target = '_blank'; // Force l'ouverture dans un nouvel onglet
                  link.rel = 'noopener noreferrer'; // Sécuriser l'ouverture du nouvel onglet
                  document.body.appendChild(link); // Ajoute le lien au DOM
                  link.click(); // Simule un clic sur le lien pour ouvrir le planificateur

                  // Ensuite, rediriger vers Booking.com dans le même onglet
                  setTimeout(() => {
                    window.location.href = lienBooking;
                  }, 500); // Laisser un délai pour éviter les interférences avec l'ouverture du nouvel onglet

                } else if (isFirefox) {
                  // Firefox mobile : Rediriger directement et ouvrir le planificateur dans un nouvel onglet
                  window.location.href = lienBooking;

                  setTimeout(() => {
                    window.open(`${t('lienPlanificateur')}/${idVoyage}`, '_blank');
                  }, 100);

                } else {
                  // Autres navigateurs mobiles : Ouvrir le planificateur et rediriger vers Booking
                  window.open(`${t('lienPlanificateur')}/${idVoyage}`, '_blank');

                  // Rediriger la page vers Booking.com
                  window.location.href = lienBooking;
                }

              } else {
                // Si ce n'est pas un appareil mobile, ouvrir normalement sur un ordinateur de bureau

                if (isSafari) {
                  // Safari desktop : Ouvrir le planificateur dans un nouvel onglet et rediriger vers Booking
                  const link = document.createElement('a');
                  link.href = `${t('lienPlanificateur')}/${idVoyage}`;
                  link.target = '_blank'; // Force l'ouverture dans un nouvel onglet
                  link.rel = 'noopener noreferrer'; // Sécuriser l'ouverture du nouvel onglet
                  document.body.appendChild(link);
                  link.click(); // Simule un clic sur le lien pour ouvrir le planificateur

                  // Ensuite, rediriger vers Booking.com dans le même onglet
                  setTimeout(() => {
                    window.location.href = lienBooking;
                  }, 500); // Laisser un délai pour éviter les interférences avec l'ouverture du nouvel onglet

                } else if (isFirefox) {
                  // Firefox desktop : Rediriger directement vers Booking et ouvrir le planificateur
                  window.location.href = lienBooking;

                  setTimeout(() => {
                    window.open(`${t('lienPlanificateur')}/${idVoyage}`, '_blank');
                  }, 100);

                } else {
                  // Autres navigateurs desktop : Ouvrir le planificateur et rediriger vers Booking
                  window.open(`${t('lienPlanificateur')}/${idVoyage}`, '_blank');
                  window.location.href = lienBooking;
                }
              }

            } else {
              // Si aucune condition n'est remplie, rediriger vers le planificateur
              navigate(`${t('lienPlanificateur')}/${idVoyage}`);
            }*/

          } else {
            // Gérer le cas où idVoyage est 0 ou null
          }
        })
        .catch((error) => {
          setIsLoading(false);
          // Gérer les erreurs de la requête
          console.error(error);
        });
        
  };

  
  const handleCheckboxChange = (value: boolean) => {
    setBookingChecked(value);
  }

  const renderButtonOpenModal = () => {
    return (
      <button
        onClick={openModal}
        className="relative flex items-center w-full border border-neutral-200 dark:border-neutral-6000 px-4 py-2 pr-11 rounded-full shadow-lg"
      >
        <GlobeAltIcon className="flex-shrink-0 w-5 h-5" />

        <div className="ml-3 flex-1 text-left overflow-hidden">
          <span className="block font-medium text-sm">{voyageInfo !== null ? t('detailVoyage') : t('creerItineraire')}</span>
          <span className="block mt-0.5 text-xs font-light text-neutral-500 dark:text-neutral-400 line-clamp-1">
            {t('pays')} • Dates • {t('nbVoyageurs')} {voyageInfo !== null ? "• "+t('monnaie') : ""}
          </span>
        </div>

        <span className="absolute right-2 top-1/2 transform -translate-y-1/2 w-9 h-9 flex items-center justify-center rounded-full border border-neutral-200 dark:border-neutral-6000 dark:text-neutral-300">
          <svg
            viewBox="0 0 16 16"
            aria-hidden="true"
            role="presentation"
            focusable="false"
            className="block w-4 h-4"
            fill="currentColor"
          >
            <path d="M5 8c1.306 0 2.418.835 2.83 2H14v2H7.829A3.001 3.001 0 1 1 5 8zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm6-8a3 3 0 1 1-2.829 4H2V4h6.17A3.001 3.001 0 0 1 11 2zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"></path>
          </svg>
        </span>
      </button>
    );
  };

  return (
    
    <div className="HeroSearchForm2Mobile">
      {renderButtonOpenModal()}
      <Transition appear show={showModal} as={Fragment}>
        <Dialog
          as="div"
          className="HeroSearchFormMobile__Dialog relative z-max"
          onClose={closeModal}
        >
          <div className="fixed inset-0 bg-neutral-100 dark:bg-neutral-900">
            <div className="flex h-full">
              <Transition.Child
                as={Fragment}
                enter="ease-out transition-transform"
                enterFrom="opacity-0 translate-y-52"
                enterTo="opacity-100 translate-y-0"
                leave="ease-in transition-transform"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-52"
              >
                <Dialog.Panel className="relative h-full overflow-hidden flex-1 flex flex-col justify-between ">
                  {showDialog && (
                    <Tab.Group manual>
                      <div className="absolute left-4 top-4">
                        <button className="" onClick={closeModal}>
                          <XMarkIcon className="w-5 h-5 text-black dark:text-white" />
                        </button>
                      </div>

                      <Tab.List className="pt-12 flex w-full justify-center font-semibold text-sm sm:text-base text-neutral-500 dark:text-neutral-400 space-x-6 sm:space-x-8">
                        {tabMenu.map(
                          (item, index) => (
                            <Tab key={index} as={Fragment}>
                              {({ selected }) => (
                                <div className="relative focus:outline-none focus-visible:ring-0 outline-none select-none">
                                  <div
                                    className={`${
                                      selected
                                        ? "text-black dark:text-white"
                                        : ""
                                    }  `}
                                  >
                                    {item}
                                  </div>
                                  {selected && (
                                    <span className="absolute inset-x-0 top-full border-b-2 border-black dark:border-white"></span>
                                  )}
                                </div>
                              )}
                            </Tab>
                          )
                        )}
                      </Tab.List>
                      <div className="flex-1 pt-3 px-1.5 sm:px-4 flex overflow-hidden">
                        <Tab.Panels className="flex-1 overflow-y-auto hiddenScrollbar py-4">
                        {voyageInfo === null || (voyageInfo !== null && !voyageInfo.ville)
                        ? (<Tab.Panel>
                          <div className="transition-opacity animate-[myblur_0.4s_ease-in-out]">
                            <VoyageNomadeSearchForm 
                            onSelectedDestinationsChange={setSelectedDestinations}
                            onStartDateChange={setStartDate}
                            onEndDateChange={setEndDate}
                            onGuestAdultsInputValueChange={setGuestAdultsInputValue}
                            onMonnaieChange={setMonnaie}
                            initDest={initSelectedDestinations}
                            initDateDeb={initStartDate}
                            initDateFin={initEndDate}
                            initNbVoyageurs={initGuestAdultsInputValue}
                            initMonnaie={initMonnaie}
                            initSymboleMonnaie={initSymboleMonnaie}
                            isPlanif={voyageInfo !== null ? true : false}
                            numDroit={voyageInfo !== null ? voyageInfo.num_droit : 99}
                            onClickPays={handleClickPays}/>
                          </div>
                        </Tab.Panel>) 
                        : null}
                          
                          {voyageInfo === null || (voyageInfo !== null && voyageInfo.ville)
                          ? (<Tab.Panel>
                            <div className="transition-opacity animate-[myblur_0.4s_ease-in-out]">
                              <VoyageSedentaireSearchForm 
                              onSelectedDestinationsChange={setSelectedDestinations}
                              onStartDateChange={setStartDate}
                              onEndDateChange={setEndDate}
                              onGuestAdultsInputValueChange={setGuestAdultsInputValue}
                              onMonnaieChange={setMonnaie}
                              initDest={initSelectedDestinations}
                              initDateDeb={initStartDate}
                              initDateFin={initEndDate}
                              initNbVoyageurs={initGuestAdultsInputValue}
                              initMonnaie={initMonnaie}
                              initSymboleMonnaie={initSymboleMonnaie}
                              isPlanif={voyageInfo !== null ? true : false}
                              numDroit={voyageInfo !== null ? voyageInfo.num_droit : 99}/>
                            </div>
                          </Tab.Panel>) 
                          : null}
                        </Tab.Panels>
                      </div>
                      {/* voyageInfo === null && (
                            <div>
                            <p className="text-center mb-4 mr-4 text-xs text-neutral-500">Rechercher un hébergement sur Booking.com  <input
                            id="checkboxBooking"
                            name="checkboxBooking"
                            type="checkbox"
                            className="focus:ring-action-primary h-3 w-3 text-primary-500 border-primary rounded border-neutral-500 bg-white dark:bg-neutral-700  dark:checked:bg-primary-500 focus:ring-primary-500"
                            checked={bookingChecked}
                            onChange={(e) => handleCheckboxChange(e.target.checked)}
                          /></p>
                            </div>
                      )*/}

                      {(voyageInfo === null || (voyageInfo !== null && (voyageInfo.num_droit === 1 || voyageInfo.num_droit === 0))) && (
                      <div className={`px-4 py-3 bg-white dark:bg-neutral-900 border-t border-neutral-200 dark:border-neutral-700 flex ${voyageInfo !== null ? "justify-end" : "justify-between"}`}>
                        {voyageInfo === null && (
                          <button
                            type="button"
                            className="underline font-semibold flex-shrink-0"
                            onClick={() => {
                              setShowDialog(false);
                              resetIsShowingDialog();
                            }}
                          >
                            {t('effacerTout')}
                          </button>
                        )}
                        <ButtonSubmit
                          isPlanif={voyageInfo !== null}
                          onClick={() => {
                            if (voyageInfo !== null) {
                              handleButtonSubmitModif(selectedDestinations, guestAdultsInputValue, startDate, endDate);
                            } else {
                              handleButtonSubmit(selectedDestinations, guestAdultsInputValue, startDate, endDate);
                            }
                          }}
                        />
                      </div>
                    )}
                    </Tab.Group>
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      {isModalDecalageOpen && (
        <Transition appear show={isModalDecalageOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={closeModalDecalage}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block py-8 w-full md:w-3/4 lg:w-2/3 xl:w-1/2">
                <div className="inline-flex pb-2 flex-col w-full md:w-3/4 lg:w-2/3 xl:w-3/5 text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <h3
                      className="text-lg font-medium leading-6 text-gray-900 dark:text-neutral-200"
                      id="headlessui-dialog-title-70"
                    >
                      { typeModalDecalage === 1 
                      ? "Vous repoussez le début de votre voyage"
                      : "Vous avancez le début de votre voyage"
                      }
                    </h3>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={closeModalDecalage} />
                    </span>
                  </div>
                  <div className="px-8 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
                  <div
                        className="flex items-center py-2.5 sm:py-4 lg:py-5 space-x-5 lg:space-x-8"
                      >
                        
                        <span>
                            { typeModalDecalage === 1
                            ? "Concernant les informations déjà enregistrées pour les jours qui précèdent votre nouvelle date de début, souhaitez-vous les décaler ou les perdre ?"
                            : "Concernant les informations précédemment enregistrées, souhaitez-vous les décaler vers cette nouvelle date de début ou les laisser aux dates actuelles ?"}
                        </span>
                      </div>
                      
                      <div
                        onClick={() => {
                            enregistrerModifVoyage(true);
                          }}
                        className="bouton flex items-center py-2.5 sm:py-4 lg:py-5 space-x-5 lg:space-x-8"
                      >
                        <CheckIcon className="w-5 h-5" aria-hidden="true"/>
                        <span>Décaler</span>
                      </div>
                      <div
                      onClick={() => {
                        enregistrerModifVoyage(false);
                      }}
                        className="bouton flex items-center py-2.5 sm:py-4 lg:py-5 space-x-5 lg:space-x-8"
                      >
                      <XMarkIcon className="w-5 h-5" aria-hidden="true"/>
                        <span>Ne pas décaler</span>
                      </div>
                      
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
      )}

{(isLoading || isLoadingModif) && (
  <Transition appear show={true} as={Fragment}>
    <Dialog
      as="div"
      className="fixed inset-0 z-[100] overflow-y-auto" // Plus élevé pour l'attente
      onClose={() => {}} // Pas de fermeture pour la modale d'attente
    >
      <div className="min-h-screen flex items-center justify-center px-4 text-center">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-60" />
        </Transition.Child>
        
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0 scale-95"
        >
          <div
            className="inline-block transition-all text-center transform rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl px-8 py-4"
          >
             <Lottie options={defaultOptions} height={200} width={200} />
            <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-neutral-200">
            {isLoadingModif ? (
              t('enregistrementMaj')+" "
            ) : (
              t('creation')+" "
            )}
              {t('enCours')}
            </h3>
          </div>
        </Transition.Child>
      </div>
    </Dialog>
  </Transition>
)}

      
{isModalIncontournablesOpen && (
       <ModalIncontournables
       onIncontournablesCloseModal={closeModalIncontournables} 
       codePays={codePaysClicked}
       isModif={voyageInfo !== null && (voyageInfo.num_droit === 1 || voyageInfo.num_droit === 0) ? true : false}
       nbJours={voyageInfo !== null ? voyageInfo.nb_jours : 0}
       />
       )}


    </div>
  );
};

export default HeroSearchForm2Mobile;
