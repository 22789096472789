import React, { FC, useState, useEffect } from "react";
import { JourneeType, EventType} from "data/types";
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from "react-dnd-html5-backend";
import { Chart } from 'react-google-charts';
import { Tooltip }  from "react-tooltip";
import 'react-tooltip/dist/react-tooltip.css';
import { useTranslation } from 'react-i18next';

export interface BudgetPageIntegreProps {
  eventCategoriesPage: JourneeType[];
  voyageKey: string;
  emailPartage: string;
  codePartage: string;
  symbole_monnaie: string;
  nb_jours: number;
  nb_voyageurs: number;
  numDroit: number;
  fraisVisa: number;
  fraisVaccins: number;
  fraisMateriel: number;
  fraisNourriture: number;
  fraisCarburant: number;
  fraisVehicule: number;
  fraisAutre: number;
  infosFrais: string;
  etatPaiementVisa: string;
  etatPaiementVaccins: string;
  etatPaiementMateriel: string;
  etatPaiementNourriture: string;
  etatPaiementCarburant: string;
  etatPaiementVehicule: string;
  etatPaiementAutre: string;
  fraisPerso: any[];
}


const BudgetPageIntegre: FC<BudgetPageIntegreProps> = ({
  eventCategoriesPage,
  symbole_monnaie,
  nb_jours,
  nb_voyageurs,
  numDroit,
  fraisVisa,
  fraisVaccins,
  fraisMateriel,
  fraisNourriture,
  fraisCarburant,
  fraisVehicule,
  fraisAutre,
  infosFrais,
  etatPaiementVisa,
  etatPaiementVaccins,
  etatPaiementMateriel,
  etatPaiementNourriture,
  etatPaiementCarburant,
  etatPaiementVehicule,
  etatPaiementAutre,
  fraisPerso,
}) => {
  const { t } = useTranslation();
  const [eventCategories, setEventCategories] = useState<JourneeType[]>([]);
  const [loading, setLoading] = useState(false);;
  const [tableauDiagramme, setTableauDiagramme] = useState<any[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [totalFrais, setTotalFrais] = useState<number>(0);
  const [totalDejaPaye, setTotalDejaPaye] = useState<number>(0);
  const [fraisVisaEdited, setFraisVisaEdited] = useState<number>(0);
  const [fraisVaccinsEdited, setFraisVaccinsEdited] = useState<number>(0);
  const [fraisMaterielEdited, setFraisMaterielEdited] = useState<number>(0);
  const [fraisNourritureEdited, setFraisNourritureEdited] = useState<number>(0);
  const [fraisCarburantEdited, setFraisCarburantEdited] = useState<number>(0);
  const [fraisVehiculeEdited, setFraisVehiculeEdited] = useState<number>(0);
  const [fraisAutreEdited, setFraisAutreEdited] = useState<number>(0);
  const [etatPaiementVisaEdited, setEtatPaiementVisaEdited] = useState<string>("");
  const [etatPaiementVaccinsEdited, setEtatPaiementVaccinsEdited] = useState<string>("");
  const [etatPaiementMaterielEdited, setEtatPaiementMaterielEdited] = useState<string>("");
  const [etatPaiementNourritureEdited, setEtatPaiementNourritureEdited] = useState<string>("");
  const [etatPaiementCarburantEdited, setEtatPaiementCarburantEdited] = useState<string>("");
  const [etatPaiementVehiculeEdited, setEtatPaiementVehiculeEdited] = useState<string>("");
  const [etatPaiementAutreEdited, setEtatPaiementAutreEdited] = useState<string>("");
  const [fraisPersoEdited, setFraisPersoEdited] = useState<any[]>([]);
  const [infosFraisEdited, setInfosFraisEdited] = useState<string>("");

  
useEffect(() => {

    if (JSON.stringify(eventCategories) !== JSON.stringify(eventCategoriesPage)) {

      setEventCategories(eventCategoriesPage);

      var totalDejaPayeTemp=0;
      var totalL=0;
      var totalT=0;
      var totalN=0;
      var totalA=0;
      var totalR=0;

      var now = new Date();
      var Onow = new Date(now.getFullYear(),now.getMonth(),now.getDate());
 
      eventCategoriesPage.forEach((journee: JourneeType) => {
       journee.evenements.forEach((evenement: EventType) => {
         if(evenement.typeEv === "L" && evenement.type !== "O" && evenement.prix !== null && parseFloat(evenement.prix) > 0)
         {
           totalL+=parseFloat(evenement.prix);
           if(evenement.paiement === "paye")
           {
            totalDejaPayeTemp+=parseFloat(evenement.prix);
           }
         }
         else if(evenement.typeEv === "L" && evenement.type === "O" && evenement.prix !== null && parseFloat(evenement.prix) > 0)
         {
           totalR+=parseFloat(evenement.prix);
           if(evenement.paiement === "paye")
           {
            totalDejaPayeTemp+=parseFloat(evenement.prix);
           }
         }
         else if(evenement.typeEv === "T" && evenement.prix !== null && parseFloat(evenement.prix) > 0)
         {
           totalT+=parseFloat(evenement.prix);
           if(evenement.paiement === "paye")
           {
            totalDejaPayeTemp+=parseFloat(evenement.prix);
           }
         }
         else if(evenement.typeEv === "N" && evenement.prix !== null && parseFloat(evenement.prix) > 0)
         {
           totalN+=parseFloat(evenement.prix);
           if(evenement.paiement === "paye")
           {
            totalDejaPayeTemp+=parseFloat(evenement.prix);
           }
           else if(evenement.paiement === "sur_place")
           {

                const dateJournee = journee.date_journee.split(' ')[1].split('/');
                const OdatePaiementYear = parseInt(dateJournee[2], 10);
                const OdatePaiementMonth = parseInt(dateJournee[1], 10) - 1;
                const OdatePaiementDay = parseInt(dateJournee[0], 10);
                var OdatePaiement = new Date(OdatePaiementYear,OdatePaiementMonth,OdatePaiementDay);

                if(OdatePaiement < Onow) {
   
                  totalDejaPayeTemp+=parseFloat(evenement.prix);
                }
           }
           else if(evenement.paiement === "acompte")
           {
            const dateJournee = journee.date_journee.split(' ')[1].split('/');
            const OdatePaiementYear = parseInt(dateJournee[2], 10);
            const OdatePaiementMonth = parseInt(dateJournee[1], 10) - 1;
            const OdatePaiementDay = parseInt(dateJournee[0], 10);
            var OdatePaiement = new Date(OdatePaiementYear,OdatePaiementMonth,OdatePaiementDay);

            if(OdatePaiement < Onow) {
              totalDejaPayeTemp+=parseFloat(evenement.prix);
            }
            else if(evenement.acompte_hebergement !== null)
            {
              totalDejaPayeTemp+=parseFloat(evenement.acompte_hebergement);
            }
           }
           else if(evenement.paiement === "prelev")
           {
        
              if(evenement.date_paiement_hebergement !== null)
              {
              
                var OdatePaiement = new Date(parseFloat(evenement.date_paiement_hebergement.split("/")[2]),parseFloat(evenement.date_paiement_hebergement.split("/")[1]),parseFloat(evenement.date_paiement_hebergement.split("/")[0]));
                if(OdatePaiement < Onow) {
             
                  totalDejaPayeTemp+=parseFloat(evenement.prix);
                }
              }
           }
         }
       });
      });

       [[fraisVehicule, etatPaiementVehicule], [fraisCarburant, etatPaiementCarburant]].forEach((frais: any, index) => {
          if(frais[0] >0)
          {
            totalT+=parseFloat(frais[0]);
            if(frais[1] === "paye")
            {
              totalDejaPayeTemp += parseFloat(frais[0]);
            }
          }
       });
       
       if(fraisNourriture >0)
       {
        totalR+=fraisNourriture;
        if(etatPaiementNourriture === "paye")
        {
          totalDejaPayeTemp += fraisNourriture;
        }
       }
       
       [[fraisVisa, etatPaiementVisa], [fraisVaccins, etatPaiementVaccins], [fraisMateriel, etatPaiementMateriel], [fraisAutre, etatPaiementAutre]].forEach((frais: any, index) => {
        if(frais[0] > 0) {
            totalA += parseFloat(frais[0]); // Concaténation des dépenses au lieu de les additionner
            if(frais[1] === "paye")
            {
              totalDejaPayeTemp += parseFloat(frais[0]);
            }
        }
      });

      fraisPerso.forEach((frais, index) => {
          if(frais.montant_frais > 0) {
              totalA += parseFloat(frais.montant_frais); // Concaténation des dépenses au lieu de les additionner
              if(frais.etat_paiement_frais === "paye")
              {
                totalDejaPayeTemp += parseFloat(frais.montant_frais);
              }
          }
      });
     
       if(totalL >0 || totalT >0 || totalN >0 || totalA >0 || totalR >0){
     
        const newTableauDiagramme = [
          ['Type', 'Total'],
          [t('activites')+' '+totalL+symbole_monnaie, totalL],
          [t('deplacements')+' '+totalT+symbole_monnaie, totalT],
          [t('hebergements')+' '+totalN+symbole_monnaie, totalN],
          [t('repas')+' '+totalR+symbole_monnaie, totalR],
          [t('autresFrais')+' '+totalA+symbole_monnaie, totalA]
        ];
        setTableauDiagramme(newTableauDiagramme);

        setTotal(totalL+totalT+totalN+totalR+totalA);
        setTotalDejaPaye(totalDejaPayeTemp);
     }
    }
  }, [eventCategoriesPage]);

  useEffect(() => {
  
        var totalDejaPayeTemp=0;
        var totalL=0;
        var totalT=0;
        var totalN=0;
        var totalA=0;
        var totalR=0;

        var now = new Date();
        var Onow = new Date(now.getFullYear(),now.getMonth(),now.getDate());
   
        eventCategoriesPage.forEach((journee: JourneeType) => {
         journee.evenements.forEach((evenement: EventType) => {
           if(evenement.typeEv === "L" && evenement.type !== "O" && evenement.prix !== null && parseFloat(evenement.prix) > 0)
           {
             totalL+=parseFloat(evenement.prix);
             if(evenement.paiement === "paye")
             {
              totalDejaPayeTemp+=parseFloat(evenement.prix);
             }
           }
           else if(evenement.typeEv === "L" && evenement.type === "O" && evenement.prix !== null && parseFloat(evenement.prix) > 0)
           {
             totalR+=parseFloat(evenement.prix);
             if(evenement.paiement === "paye")
             {
              totalDejaPayeTemp+=parseFloat(evenement.prix);
             }
           }
           else if(evenement.typeEv === "T" && evenement.prix !== null && parseFloat(evenement.prix) > 0)
           {
             totalT+=parseFloat(evenement.prix);
             if(evenement.paiement === "paye")
             {
              totalDejaPayeTemp+=parseFloat(evenement.prix);
             }
           }
           else if(evenement.typeEv === "N" && evenement.prix !== null && parseFloat(evenement.prix) > 0)
           {
             totalN+=parseFloat(evenement.prix);
             if(evenement.paiement === "paye")
             {
              totalDejaPayeTemp+=parseFloat(evenement.prix);
             }
             else if(evenement.paiement === "sur_place")
             {

                  const dateJournee = journee.date_journee.split(' ')[1].split('/');
                  const OdatePaiementYear = parseInt(dateJournee[2], 10);
                  const OdatePaiementMonth = parseInt(dateJournee[1], 10) - 1;
                  const OdatePaiementDay = parseInt(dateJournee[0], 10);
                  var OdatePaiement = new Date(OdatePaiementYear,OdatePaiementMonth,OdatePaiementDay);
  
                  if(OdatePaiement < Onow) {
        
                    totalDejaPayeTemp+=parseFloat(evenement.prix);
                  }
             }
             else if(evenement.paiement === "acompte")
             {

              const dateJournee = journee.date_journee.split(' ')[1].split('/');
              const OdatePaiementYear = parseInt(dateJournee[2], 10);
              const OdatePaiementMonth = parseInt(dateJournee[1], 10) - 1;
              const OdatePaiementDay = parseInt(dateJournee[0], 10);
              var OdatePaiement = new Date(OdatePaiementYear,OdatePaiementMonth,OdatePaiementDay);
              
  
              if(OdatePaiement < Onow) {
                totalDejaPayeTemp+=parseFloat(evenement.prix);
              }
              else if(evenement.acompte_hebergement !== null)
              {
                totalDejaPayeTemp+=parseFloat(evenement.acompte_hebergement);
              }
             }
             else if(evenement.paiement === "prelev")
             {
        
                if(evenement.date_paiement_hebergement !== null)
                {
 
                  var OdatePaiement = new Date(parseFloat(evenement.date_paiement_hebergement.split("/")[2]),parseFloat(evenement.date_paiement_hebergement.split("/")[1]),parseFloat(evenement.date_paiement_hebergement.split("/")[0]));
                  if(OdatePaiement < Onow) {

                    totalDejaPayeTemp+=parseFloat(evenement.prix);
                  }
                }
             }
           }
         });
        });

         let totalFraisTemp=0;
  
         [[fraisVehiculeEdited, etatPaiementVehiculeEdited], [fraisCarburantEdited, etatPaiementCarburantEdited]].forEach((frais: any, index) => {
          if(frais[0] >0)
          {
            totalT+=parseFloat(frais[0]);
            if(frais[1] === "paye")
            {
              totalDejaPayeTemp += parseFloat(frais[0]);
            }
            totalFraisTemp+=parseFloat(frais[0]);
          }
       });
       
       if(fraisNourritureEdited >0)
       {
        totalR+=fraisNourritureEdited;
        if(etatPaiementNourritureEdited === "paye")
        {
          totalDejaPayeTemp += fraisNourritureEdited;
        }
        totalFraisTemp+=fraisNourritureEdited;
       }
       
       [[fraisVisaEdited, etatPaiementVisaEdited], [fraisVaccinsEdited, etatPaiementVaccinsEdited], [fraisMaterielEdited, etatPaiementMaterielEdited], [fraisAutreEdited, etatPaiementAutreEdited]].forEach((frais: any, index) => {
        if(frais[0] > 0) {
            totalA += parseFloat(frais[0]); // Concaténation des dépenses au lieu de les additionner
            if(frais[1] === "paye")
            {
              totalDejaPayeTemp += parseFloat(frais[0]);
            }
            totalFraisTemp+=parseFloat(frais[0]);
        }
      });

      fraisPersoEdited.forEach((frais, index) => {
          if(frais.montant_frais > 0) {
              totalA += parseFloat(frais.montant_frais); // Concaténation des dépenses au lieu de les additionner
              if(frais.etat_paiement_frais === "paye")
              {
                totalDejaPayeTemp += parseFloat(frais.montant_frais);
              }
              totalFraisTemp+=parseFloat(frais.montant_frais);
          }
      });

         setTotalFrais(totalFraisTemp);

         if(totalL >0 || totalT >0 || totalN >0 || totalA >0 || totalR >0){
       
          const newTableauDiagramme = [
            ['Type', 'Total'],
            [t('activites')+' '+totalL+symbole_monnaie, totalL],
            [t('deplacements')+' '+totalT+symbole_monnaie, totalT],
            [t('hebergements')+' '+totalN+symbole_monnaie, totalN],
            [t('repas')+' '+totalR+symbole_monnaie, totalR],
            [t('autresFrais')+' '+totalA+symbole_monnaie, totalA]
          ];
          setTableauDiagramme(newTableauDiagramme);
  
          setTotal(totalL+totalT+totalN+totalR+totalA);
          setTotalDejaPaye(totalDejaPayeTemp);
      }

}, [fraisVisaEdited, fraisVaccinsEdited, fraisMaterielEdited, fraisNourritureEdited, fraisCarburantEdited, fraisVehiculeEdited, fraisAutreEdited, fraisPersoEdited,
    etatPaiementVisaEdited, etatPaiementVaccinsEdited, etatPaiementMaterielEdited, etatPaiementNourritureEdited, etatPaiementCarburantEdited, etatPaiementVehiculeEdited, etatPaiementAutreEdited]);


  const getFraisLabel = (index: number) => {
    switch (index){
      case 0 :
        return t('visas'); 
        case 1 :
        return t('vaccins'); 
        case 2 :
        return t('materiel'); 
        case 3 :
        return t('vehicule'); 
        case 4 :
        return t('carburant'); 
        case 5 :
        return t('nourriture'); 
        case 6 :
        return t('autres'); 
    }

  }

  useEffect(() => {
    if(fraisVisa !== fraisVisaEdited)
    {
      setFraisVisaEdited(fraisVisa);
    }
    if(fraisVaccins !== fraisVaccinsEdited)
    {
      setFraisVaccinsEdited(fraisVaccins);
    }
    if(fraisMateriel !== fraisMaterielEdited)
    {
      setFraisMaterielEdited(fraisMateriel);
    }
    if(fraisNourriture !== fraisNourritureEdited)
    {
      setFraisNourritureEdited(fraisNourriture);
    }
    if(fraisCarburant !== fraisCarburantEdited)
    {
      setFraisCarburantEdited(fraisCarburant);
    }
    if(fraisVehicule !== fraisVehiculeEdited)
    {
      setFraisVehiculeEdited(fraisVehicule);
    }
    if(fraisAutre !== fraisAutreEdited)
    {
      setFraisAutreEdited(fraisAutre);
    }
    if(fraisPerso !== fraisPersoEdited)
    {
      setFraisPersoEdited(fraisPerso);
    }
    if(etatPaiementVisa !== etatPaiementVisaEdited)
    {
      setEtatPaiementVisaEdited(etatPaiementVisa);
    }
    if(etatPaiementVaccins !== etatPaiementVaccinsEdited)
    {
      setEtatPaiementVaccinsEdited(etatPaiementVaccins);
    }
    if(etatPaiementMateriel !== etatPaiementMaterielEdited)
    {
      setEtatPaiementMaterielEdited(etatPaiementMateriel);
    }
    if(etatPaiementNourriture !== etatPaiementNourritureEdited)
    {
      setEtatPaiementNourritureEdited(etatPaiementNourriture);
    }
    if(etatPaiementCarburant !== etatPaiementCarburantEdited)
    {
      setEtatPaiementCarburantEdited(etatPaiementCarburant);
    }
    if(etatPaiementVehicule !== etatPaiementVehiculeEdited)
    {
      setEtatPaiementVehiculeEdited(etatPaiementVehicule);
    }
    if(etatPaiementAutre !== etatPaiementAutreEdited)
    {
      setEtatPaiementAutreEdited(etatPaiementAutre);
    }
    if(infosFrais !== infosFraisEdited)
    {
      setInfosFraisEdited(infosFrais);
    }

  }, [fraisVisa, fraisVaccins, fraisMateriel, fraisNourriture, fraisCarburant, fraisVehicule, fraisAutre, fraisPerso, infosFrais,
      etatPaiementVisa, etatPaiementVaccins, etatPaiementMateriel, etatPaiementNourriture, etatPaiementCarburant, etatPaiementVehicule, etatPaiementAutre])

  useEffect(() => {

    if (tableauDiagramme.length > 0) {
      setLoading(true);
    }
 
  }, [tableauDiagramme]);

  const pieChart = () => {
    if (tableauDiagramme.length === 0) {
      return null; // Ne rend rien si tableauDiagramme est vide
    }  
    return (
      <div className="listingSection__wrap  [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ]">
        {/* HEADING */}
        <div>
          <Chart
            width={'auto'} 
            height={'auto'}
            chartType="PieChart"
            loader={<div>{t('chargementDiagramme')}</div>}
            data={tableauDiagramme}
            options={{
              title: t('repartitionBudget'),
              is3D: false,
              sliceVisibilityThreshold: 0.001,
              titleTextStyle:{fontSize: "16",
                              lineHeight: "2rem",
                              fontWeight: 600 },
              pieSliceTextStyle: {
                color: '#FFFFFF',fontSize: "11"
              },
              legend:{textStyle:{ color: '#040E27'},fontSize: "13",
                      bold: "true"},
              slices: [{color: '#FF4A52'}, {color: '#1EC6B6'}, {color: '#040E27'}, {color: '#FDAE5C'} , {color: '#D58CF5'}]
            }}
            rootProps={{ 'data-testid': '1' }}
          />
        </div>
      </div>
    );
  }
  
  const renderFraisConsult = () => {
    let renderedLines = 0;
    return (
      <div className="listingSection__wrap  [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ]">
        {/* HEADING */}
        <div className="grid grid-cols-2">
          <div>
            <h2 className="text-md md:text-lg font-semibold">{t('frais')} </h2>
          </div>

        </div>

        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* CONTENT */}
        <div className="flow-root">
          <div className="text-xs sm:text-xs text-neutral-6000 dark:text-neutral-300 -mb-4">

          {[[fraisVisa, etatPaiementVisa], [fraisVaccins, etatPaiementVaccins], [fraisMateriel, etatPaiementMateriel], [fraisVehicule, etatPaiementVehicule], [fraisCarburant, etatPaiementCarburant], [fraisNourriture, etatPaiementNourriture], [fraisAutre, etatPaiementAutre]].map((frais, index) => (
            frais[0] > 0 && (
              <div
                key={index}
                className={`p-1 md:p-1 ${renderedLines++ % 2 % 2 === 0 ? "bg-neutral-100 dark:bg-neutral-800" : ""} flex justify-between items-center space-x-4 rounded-lg`}
              >
                {/* Affichage du nom du frais et de sa valeur */}
                <span>{getFraisLabel(index)}</span>
                <span>
                  {frais[0]} {symbole_monnaie}{" "}
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                </span>         
              </div>
            )
          ))}

          {fraisPerso.map((frais, index) => (
            frais.montant_frais > 0 && (
              <>
                <div
                  key={index + renderedLines}
                  className={`p-1 md:p-1 ${renderedLines++ % 2 % 2 === 0 ? "bg-neutral-100 dark:bg-neutral-800" : ""} flex justify-between items-center space-x-4 rounded-lg`}
                >
                  {/* Affichage du nom du frais et de sa valeur */}
                  <span>{frais.libelle}</span>
                  <span>
                    {frais.montant_frais} {symbole_monnaie}{" "}
                      <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                  </span>
                </div>
              </>
            )
          ))}

          </div>
        </div>
        <div>
        {infosFraisEdited && infosFraisEdited !== "" && (
            <>
              <h1 className="mt-5 mb-2 texteGras text-xs md:text-md">{t('informationsFrais')}</h1>
              <div className={`p-1 md:p-2 ${renderedLines++ % 2 === 0 ? "bg-neutral-100 dark:bg-neutral-800" : ""} items-center rounded-lg`}>
                  {infosFraisEdited.split('\n').map((line, index) => (  
                      <div className="text-xxs md:text-xs">{line}</div>
                  ))}
              </div>
            </>
          )}
        </div>
      </div>
    );
  };

  const renderSidebar = () => {
    return (
      <div className="listingSectionSidebar__wrap-integre shadow-xl  [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ]">
        {/* PRICE */}
        <div className="flex justify-between">
          <span className="text-sm md:text-lg font-semibold">
          {Math.round(total/nb_jours/nb_voyageurs)} {symbole_monnaie}
            <span className="ml-1 text-sm font-normal text-neutral-500 dark:text-neutral-400">
            {t('jourPersonne')}
            </span>
          </span>
        </div>

        {/* FORM */}

        {/* SUM */}
        <div className="flex flex-col space-y-2 md:space-y-4"> 
          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex justify-between font-semibold">
            <span className="text-sm md:text-lg">TOTAL</span>
            <span className="text-sm md:text-lg">{total.toFixed(2)} {symbole_monnaie}</span>
          </div>
          { nb_voyageurs > 1 && (
            <div className="flex justify-between font-semibold items-center ">
            <span className="text-xs md:text-sm">{t('parPersonne')}</span>
            <span className="text-sm md:text-lg">{(total/nb_voyageurs).toFixed(2)} {symbole_monnaie}</span>
          </div>
          )}
        </div>
      </div>
    );
  };

  const renderTotal = () => {
    return (
      <div className={`listingSection__wrap block ${(tableauDiagramme.length !== 0 || totalFrais > 0 || (infosFraisEdited && infosFraisEdited !== "")) ? "lg:hidden" : "" } [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ]`}>
        {/* PRICE */}
        <div className="flex justify-between">
          <span className="text-sm md:text-lg font-semibold">
          {Math.round(total/nb_jours/nb_voyageurs)} {symbole_monnaie}
            <span className="ml-1 text-sm font-normal text-neutral-500 dark:text-neutral-400">
            {t('jourPersonne')}
            </span>
          </span>
        </div>

        {/* FORM */}

        {/* SUM */}
        <div className="flex flex-col space-y-4">
          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex justify-between font-semibold">
            <span>TOTAL</span>
            <span>{total.toFixed(2)} {symbole_monnaie}</span>
          </div>
          { nb_voyageurs > 1 && (
          <div className="flex justify-between font-semibold items-center">
            <span className="text-xs md:text-sm">{t('parPersonne')}</span>
            <span>{(total/nb_voyageurs).toFixed(2)} {symbole_monnaie}</span>
          </div>
          )}
        </div>
      </div>
    );
  };


  return (
    
    <DndProvider backend={HTML5Backend}>


        <div className="nc-ListingStayDetailPage">

      {/* MAIN     <BackgroundSection /> */}
      <div className=" relative mt-11 flex flex-row ">
        {/* CONTENT */}
        

        <div className="w-full lg:w-3/5 xl:w-2/3 space-y-4 lg:space-y-4 pr-4 md:pr-10">
          {renderTotal()}
          {loading && pieChart()}
          {(totalFrais > 0 || (infosFraisEdited && infosFraisEdited !== "")) && renderFraisConsult()}
        </div>

        {/* SIDEBAR */}
        {(tableauDiagramme.length !== 0 || totalFrais > 0 || (infosFraisEdited && infosFraisEdited !== "")) && (
        <div className="hidden lg:block flex-grow mt-0">
          <div className="sticky top-28">{renderSidebar()}</div>
        </div>
        )}
      </div>
    </div>
   {/* <MobileFooterSticky />*/}
          
</DndProvider>
);
};

export default BudgetPageIntegre;
