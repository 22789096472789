import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: {
          "bienvenue": "👋 Welcome to tripkygo v2",
          "nbItineraires": "travels created since june 2020",
          "planificateur" : "Travel planning tool",
          "planificateur2" : "Planning tool",
          "description" : "Plan your own vacations, road trips or world tours by creating your travel itinerary.",
          "description1" : "Totally free, tripkygo is the travel planning tool you need to organize your holidays, road-trips or world tours. It allows you to calculate your budget, generate a map and edit travel diaries.",
          "description2": "In addition to the itinerary creator, our site includes section ",
          "description3": ", ", 
          "description4": " and a ", 
          "ouPartir" : "Where to go?",
          "ouPartirEn" : "Where to go in",
          "guidesPays" : "Country guides",
          "outilReservation" : "Booking tool",
          "voyagesPublics" : "Public travels",
          "infoVoyagesPublics" : "Only itineraries declared public by their creators are visible. Some information may be hidden.",
          "chargementVoyagesPublics" : "Loading public trips in progress, please wait...",
          "chargementGuide" : "Loading guide in progress, please wait...",
          "chargementVoyage" : "Loading itinerary in progress, please wait...",
          "plusVoyagesPublics" : "See more",
          "plusAimes" : "Most liked ❤️",
          "triPubCroi" : "Publication date decreasing",
          "triPubDecroi" : "Publication date increasing",
          "triCreaCroi" : "Creation date decreasing",
          "triCreaDecroi" : "Creation date increasing",
          "triNbJCroi" : "Number of days decreasing",
          "triNbJDecroi" : "Number of days increasing",
          "triNbLCroi" : "Number of likes decreasing",
          "triNbLDecroi" : "Number of likes increasing",
          "tri" : "Sort",
          "utilisateur" : "Username",
          "pays" : "Country",
          "paysS" : "countries",
          "autrePays" : "Other country?",
          "ville" : "City",
          "voyageNomade" : "Nomadic travel",
          "voyageSedentaire" : "Sedentary travel",
          "ouAllezVous" : "Where are you going?",
          "debutFin" : "Start - End",
          "voyageur" : "Traveler",
          "voyageurMin" : "traveler",
          "ajouterVoyageurs" : "Add traveler(s)",
          "nbVoyageurs" : "Nb of traveler(s)",
          "nombreVoyageurs" : "Number of traveler(s)",
          "creer" : "Create",
          "modifier" : "Modify",
          "jours" : "days",
          "jour" : "Day",
          "jourMin" : "day",
          "cree" : "Created",
          "par" : "by",
          "le" : "on",
          "a" : "at",
          "mesVoyages": "My travels",
          "mesDemandes": "Pending queries",
          "voyagesPartages": "Shared travels",
          "statistiques": "Statistics",
          "aide" : "Help",
          "guides" : "Guides",
          "contact" : "Contact",
          "creerVoyage" : "Create travel",
          "destinationsPopulaires" : "Popular destinations",
          "reserverTransport" : "Book transport",
          "reserverAvion" : "Book flight",
          "reserverHebergement" : "Book accomodation",
          "reserverActivite" : "Book activity",
          "louerVoiture" : "Rent a car",
          "aPropos" : "About us",
          "newsletter" : "Newsletter",
          "mentionsLegales" : "Legal notice",
          "creerItineraire" : "Create itinerary",
          "detailVoyage" : "Travel details",
          "monnaie" : "Currency",
          "enregistrement" : "Saving in progress, please wait...",
          "saisirPays" : "Enter country",
          "saisirVille" : "Enter city",
          "quandPartezVous" : "When are you traveling?",
          "aucune" : "none",
          "ajoutezDates" : "Add dates",
          "effacerTout" : "Clear all",
          "aucunResultat" : "No result",
          "resultat" : "Result",
          "hebergement" : "Accomodation",
          "vol" : "Flight",
          "transport" : "Train/bus",
          "activite" : "Activity",
          "voiture" : "Car",
          "monCompte" : "My account",
          "compte" : "Account",
          "compteMin" : "account",
          "reservation" : "Book",
          "accueil" : "Home",
          "rechercheTripkygo" : "Find on tripkygo",
          "guidesVoyage" : "Travel guides",
          "infoDestinations" : "All informations about your destinations",
          "infoDestination" : "All informations about this destination",
          "chercherDestination" : "Search destination",
          "consulter" : "Consult",
          "quandPartir" : "When to travel",
          "nosIncontournables" : "Our essentials",
          "conseilsVoyage" : "Our travel tips based on weather conditions and your desires",
          "formalites" : "Formalities",
          "notreSelection" : "Our selection of places not to be missed during a trip",
          "languesOfficielles" : "Officiel language(s)",
          "drapeau" : "Flag",
          "capitale" : "Capital",
          "plage" : "Beach",
          "nonConseille" : "Not recommended",
          "conseille" : "Recommended",
          "pasRecommandation" : "No recommendation for this destination.",
          "trouverDestination" : "We are going to help you to find you next travel destination",
          "souhaitPartir" : "When do you want to travel?",
          "janvier" : "January",
          "fevrier" : "February",
          "mars" : "March",
          "avril" : "April",
          "mai" : "May",
          "juin" : "June",
          "juillet" : "July",
          "aout" : "August",
          "septembre" : "September",
          "octobre" : "October",
          "novembre" : "November",
          "decembre" : "December",
          "en" : "in",
          "selectionOuPartir" : "Here is our selection to travel in",
          "enFonction" : "depending on the weather conditions and your desires",
          "introContact" : "A question, a suggestion, a bug report or something else? Contact us:",
          "nom" : "Name",
          "prenom" : "First name",
          "presentation" : "Presentation",
          "sujet" : "Subject",
          "envoyer" : "Send",
          "envoyerDemande" : "Send request",
          "_en" : "_en",
          "En" : "En",
          "nouveauVoyage" : "New travel",
          "aucunVoyageCree" : "None travel created",
          "aucunVoyagePartage" : "None travel shared",
          "voyagesCrees": "Created travels",
          "titreVoyagesPartages" : "Consultation trips by sharing",
          "general" : "General",
          "paysVisites" : "Visited countries",
          "paysVisiteMin" : "visited country",
          "depenses" : "Expenses",
          "deplacements" : "Traveling",
          "chargementStatistiques" : "Statistics calculation in progress, please wait...",
          "aucuneDepense" : "No expense",
          "aucunDeplacement" : "No traveling",
          "jaimeRecu" : "received like",
          "etape" : "stage",
          "voyage" : "travel",
          "depensesMin" : "spent",
          "parcourus" : "traveled",
          "dont" : "of which:",
          "repartition" : "Distribution",
          "avion" : "Plane",
          "bateau" : "Ship",
          "aPied" : "Walking",
          "velo" : "Bicycle",
          "nonRenseigne" : "Not specified",
          "jourPersonne" : "/day/person",
          "connexion" : "Log in",
          "deconnexion" : "Log out",
          "inscrire" : "Sign up",
          "ou" : "OR",
          "adresseEmail" : "Email address",
          "motDePasse" : "Password",
          "motDePasseOublie" : "Forgotten password?",
          "seConnecter" : "Log in",
          "creerCompte" : "Sign up",
          "pasInscrit" : "Not yet registered?",
          "reinitMdp" : "Password reset",
          "saisirEmail" : "Enter your email address",
          "inscription" : "Sign up",
          "resaisirEmail" : "Re-enter your email address",
          "dejaInscrit" : "Already registered?",
          "nouveauMdp" : "New password",
          "ancienMdp" : "Old password",
          "resaisirNouveauMdp" : "Re-enter your new password",
          "enregistrer" : "Save",
          "profil" : "Profile",
          "changerImage" : "Change picture",
          "voirProfil" : "See public profile",
          "accepteNewsletter" : "I agree to receive the newsletter",
          "nomUtilisateur" : "Username",
          "champsFacultatifs" : "Optional fields",
          "usernameInvalide" : "The username is invalid. It must be between 5 and 20 characters starting with a letter. Only letters, numbers, periods, hyphens and underscores are accepted!",
          "usernameDejaPris" : "This username is already taken, please choose another one!",
          "messageEmailInvalide1" : " Email used to create your ",
          "messageEmailInvalide2" : " is wrong, please ",
          "messageEmailInvalide3" : "contact us",
          "messageEmailInvalide4" : " to change it!",
          "nonVisible" : "(not visible on your public profile)",
          "infoPresentation" : "Introduce yourself to other users: your favorite destinations, your desires, your passions,...",
          "sitePersonnel" : "Personal website",
          "compteCreeLe" : "Account created on ",
          "gestionCompte" : "Account management",
          "confidentialite" : "Confidentiality",
          "confidentialiteVoyagesPublics": "Public travel confidentiality",
          "infoConfidentialiteVoyagesPublics": "Everyone has access to consult the trips that you decide to make public. Saved reservation confirmations and baggage checklists are never visible to visitors.",
          "info2ConfidentialiteVoyagesPublics": "You can decide whether or not to display the following information:",
          "affichageHebergements": "Display the name of the accommodations",
          "affichagePrix": "Display prices",
          "affichageBudget": "Display the budget and related costs",
          "affichagePhotos": "Display photos of the steps",
          "affichageDates": "Display dates for upcoming trips (end date not expired)",
          "affichageLiens": "Show links",
          "oui" : "Yes",
          "non" : "No",
          "changementMdp" : "Password change",
          "majMdp" : "Save password",
          "veuillezRemplirChamps" : "Please complete all fields.",
          "formatMdp": "The new password must contain at least one number, one lowercase letter, one uppercase letter and be at least 8 characters long.",
          "nouveauxMdpDifferents" : "The new passwords do not match.",
          "mdpDifferentAncien" : "The new password must be different from the old one.",
          "ancienMdpIncorrect" : "Wrong old password",
          "messageInscriptionGoogle" : "(Enter x if you signed up with Google)",
          "supprimerCompte" : "Delete my account",
          "suppressionEnCours" : "Deleting in progress...",
          "avertissementSuppression" : "By clicking the button, you will permanently delete your account and all the trips you have created!",
          "aPropos1" : "I am an IT developer living in Paris since 2005 and my greatest passion is travel!",
          "aPropos2" : "As a couple, we have visited more than 30 countries on 5 continents, you can find some of my travel diaries on my",
          "aPropos3" : "The idea of ​​a travel planning toolhad been bouncing around in my head for several months, tired of using impractical spreadsheets to organize our trips and not convinced by existing planners (too complicated, too long to fill out, not visual enough, etc...).",
          "aPropos4" : "It was thanks to the confinement of March 2020 that I was able to make this project a reality by developing this website during our long weekends and evenings in confinement. I wanted this planner to be as quick and visual as possible, allowing me to scan through the days in the blink of an eye.",
          "aPropos5" : "I constantly evolve the site based on the needs reported by users.",
          "aPropos6" : "In June 2024, for tripkygo's 4th anniversary, I launched this new, more modern, faster and more aesthetic site! Don't hesitate to send me your comments or ideas to improve the site.",
          "aPropos7" : "Good visit !",
          "inscriptionNewsletter" : "Subscribe to our newsletter",
          "infoNewsletter" : "to be informed of all the news on our site",
          "votre" : "Your",
          "sesVoyagesPublics" : "His/her public travels",
          "totalLikes" : "Number of likes on publics travels",
          "infosMasquees" : "Some information may be hidden.",
          "aucunVoyagePublic" : "None public travel.",
          "inscritEn" : "Registered on",
          "itinerairesPublics" : "Public itineraries",
          "reserverHotels" : "Book hotels, apartments,... with",
          "reserverTransports" : "Book your train or bus with",
          "reserverAvions" : "Book your flight with",
          "louerVoitureAvec" : "Rent a car with",
          "cliquerActivite" : "Click on activity to see other destinations on",
          "infoGetYourGuide" : "By using this tool and booking with our partner GetYourGuide, tripkygo will earn a commission without changing the price you pay. We hope this will keep our site 100% free!",
          "infoBooking" : "By using this tool and booking with our partner Booking, tripkygo will earn a commission without changing the price you pay. We hope this will keep our site 100% free!",
          "infoKiwi" : "By using this tool and booking with our partner Kiwi, tripkygo will earn a commission without changing the price you pay. We hope this will keep our site 100% free!",
          "infoDiscoverCars" : "By using this tool and booking with our partner DiscoverCars, tripkygo will earn a commission without changing the price you pay. We hope this will keep our site 100% free!",
          "titreItineraire" : "Travel title",
          "destinationEnregistree" : "Saved destination",
          "destinationsEnregistrees" : "Saved destinations",
          "resume" : "Summary",
          "carte" : "Map",
          "modeModif" : "Modification mode",
          "modeConsult" : "Consultation mode",
          "outils" : "Tools",
          "prive" : "Private",
          "lieu" : "Place",
          "repas" : "Meal",
          "trajet" : "Journey",
          "nuit" : "Night",
          "location" : "Rental",
          "masquees" : "hidden",
          "informationCookies1": "To display the reservation tool, you must accept 'Marketing' type cookies (",
          "informationCookies2": "see explanation",
          "informationCookies3": "To authorize 'Marketing' type cookies on the tripkygo site",
          "informationCookies4": ", please:",
          "informationCookies5": "1) click on the icon",
          "informationCookies6": "at the bottom right of your screen",
          "informationCookies7": "2) accept 'Marketing' type cookies",
          "informationCookies8": "3) click on 'Save' at the bottom of the window",
          "informationCookies9": "4) refresh the page",
          "informationCookies10": "Search tools will now be visible!",
          "informationCookies11": "By using these search tools, you will help keep our site 100% free!",
          "partage" : "Sharing",
          "copierLien" : "Copy link",
          "partagerFacebook" : "Share on Facebook",
          "partagerX" : "Share on X (Twitter)",
          "envoyerParMail" : "Send by email",
          "envoyerParSms" : "Senb by sms",
          "integrerBlog" : "Integrate on a site/blog",
          "itineraireVoyage" : "Travel itinerary",
          "messageRs" : "My travel itinerary crerated on @tripkygo_en",
          "messageRs2": "My travel itinerary may interest you",
          "lienCopie" : "Link copied",
          "enregistrementMaj" : "Saving",
          "creation" : "Creation",
          "enCours" : "in progress, please wait...",
          "aeroportGareVille"  : "Airport, station, city...",
          "compagnie"  : "Company",
          "refNumVol"  : "Ref, flight num,...",
          "debut" : "Start",
          "fin" : "End",
          "prix" : "Price",
          "paye?" : "Paid?",
          "paye" : "Paid",
          "nomVilleLieu"  : "Name of the city or the place",
          "activitesVisites" : "Activities, visits,...",
          "nomRestaurantLieu"  : "Name of the restaurant or the place",
          "descriptionRepas"  : "Description,...",
          "nomHebergement"  : "Name of the accommodation",
          "nomVille"  : "Name of the city",
          "prixNuit" : "Price/night",
          "reference"  : "Reference,...",
          "agenceBoutique" : "Agency, shop,...",
          "surPlace": "On site",
          "prelevement": "Debit",
          "acompte": "Deposit",
          "priseEnCharge": "Pick-up",
          "restitution": "Drop-off",
          "rattacherPEC": "Link pick-up",
          "cliquezCompleter" : "Click to add",
          "nuitsIncompletes" : "Incomplete night(s)",
          "nuitTransport" : "Night in transport",
          "lieuxVisites" : "Visited places",
          "dateDebut" : "Start date:",
          "nombreNuits" : "Number of nights:",
          "villeSejourner" : "City where you will stay",
          "villeNonRenseignee" : "City ​​not specified",
          "paysNonRenseigneCliquez": "Country not specified. Click on the city and select it from the choices offered for automatic selection of the country or select the country below.",
          "paysNonRenseigne" : "Country not specified",
          "situationApresEnregistrement" : "Situation after saving:",
          "du" : "from",
          "au" : "to",
          "liberee" : "free",
          "questionAjout" : "What do you want to add?",
          "questionAjoutCoCreateur" : "Who would you like to add as a co-creator?",
          "cliquezPourEchanger" : "Click to exchange content with the next day",
          "coordonneesGPSAbsentes" : "Missing GPS coordinates",
          "cliquezCarte" : "Click to see on map",
          "cliquezPlacement" : "Click to place on map",
          "recherche" : "Search",
          "blocNotes" : "Notepad",
          "confirmation" : "Confirmation",
          "coordonnees" : "Coordinates",
          "lien" : "Link",
          "suppression" : "Delete",
          "pointsInteret" : "Points of interest",
          "itineraire" : "Itinerary",
          "informations" : "Informations",
          "copie" : "Copy",
          "afficherStatistiques" : "Show statistics",
          "convertisseurMonnaies" : "Currencies convertor",
          "exporterItineraire" : "Export itinerary",
          "importerItineraire" : "Import itinerary",
          "editerPDF" : "Edit PDF",
          "integrerItineraire" : "Integrate itinerary on a blog/site",
          "faireMaValise" : "Make my suitcase",
          "partagerItineraire" : "Share itinerary",
          "chargementDiagramme" : "Diagram loading...",
          "repartitionBudget" : "Budget distribution",
          "activites" : "Activities",
          "hebergements" : "Accomodations",
          "autresFrais" : "Other costs",
          "frais" : "Costs",
          "fraisPersonnalises" : "Custom costs",
          "informationsFrais" : "Costs informations",
          "visas" : "Visa(s)",
          "vaccins" : "Vaccine(s)",
          "carburant" : "Fuel",
          "nourriture" : "Food",
          "vehicule" : "Véhicle",
          "autres" : "Others",
          "materiel" : "Material",
          "resteAPayer" : "Remains to pay",
          "dejaPaye" : "Already paid",
          "totalPour" : "Total for",
          "parPersonne" : "per person",
          "kmParcourus": "km traveled",
          "exportezItineraire" : "Export your itinerary to view it on other sites/applications (Google Maps, Maps.Me, OpenStreetMap,...).",
          "elementsExporter" : "Items to export",
          "tousExporter" : "All (places, meals, journeys, rentings and nights)",
          "seulementExporter" :"Only places and meals",
          "exporter" : "Export",
          "importezItineraire" : "Import map data in KML/KMZ format (Google Maps, Maps.Me, OpenStreetMap,...)",
          "kmlKmzAcceptes" : ".kml and kmz accepted",
          "lieuxImportes": "Imported places:",
          "selectionnezJour" : "Select a day",
          "selectionnezPositionnement" : "Select a positionning",
          "lieuAjoute" : "Day added",
          "integrationItineraire" : "Integration of the itinerary on your site/blog",
          "quelleVue" : "Which view do you want to display by default?",
          "previsualisezItineraire" : "Preview the integrated itinerary",
          "codeAUtiliser" : "Code to use:",
          "pourAjuster" : "To adjust the height of the window, read",
          "commentIntegrer" : "How to integrate a tripkygo itinerary on a site/blog?",
          "notificationEmail" : "Email notification 48h before departure?",
          "objet" : "Object",
          "ajouter" : "Add",
          "ajoutEnCours": "Adding in progress, please wait...",
          "echangeEnCours" : "Exchange in progress, please wait...",
          "copieEnCours" : "Copy in progress, please wait...",
          "gestionCoCreateurs" : "Co-creator management",
          "coCreateurs" : "Co-creator :",
          "envoiDemandeCoCreateurs" : "Sending request, please wait...",

          "alertPaysSaisi" : "You have already entered this country, please enter the next country or move on to entering the other criteria!",
          "alertDestination" : "You must select a destination!",
          "alertDateDeb" :"You must select a trip start date!",
          "alertDateFin" :"You must select a trip end date!",
          "alertNbVoyageurs" :"You must add at least 1 traveler!",
          "alertNbJoursMax" :"Your trip must not exceed 365 days! Please create multiple trips.",
          "alertNbJoursMin" :"Your trip must last at least two days.",
          "alertCopie" : "You must be logged in to copy a travel. \nDon't have an account? Subscribe, it's free !",
          "alertPartage" : "You must be logged in to share a travel. \nDon't have an account? Subscribe, it's free !",
          "alertCreateurs" : "You must be logged in to modify travel's creators. \nDon't have an account? Subscribe, it's free !",
          "alertMajConnecte" : "Error during update, you are no longer connected.",
          "alertSaisieRapide" : "Too fast...",
          "alertNom" : "You must enter a name!",
          "alertEmail" : "You must enter a valid email adress!",
          "alertSujet" : "You must enter a subject!",
          "alertMessage" : "You must enter a message of at least 30 characters!",
          "alertRecaptcha" : 'Please check the "I am not a robot" box.',
          "alertTechnique" : "Technical error!",
          "alertVerifReinit" : "Error while verifying reinit.",
          "alertMailIncorrect" : "Incorrect email address or reinit code",
          "alertDelaiReinit" : 'Reinit delay expired, please start the procedure again by clicking on "Forgotten password".',
          "alertEnvoiMail" : "Error during message sending",
          "alertPasCompte" : "No account for this email address!",
          "alertMailRecup" : "The password recovery email has been sent to your address",
          "alertMailRecupSuite" : ".\nYou have 1 hour to complete the reset procedure otherwise you will have to start again.\nIf you can't find it, remember to check your spam folder or contact us!",
          "alertMailRecupFin" : ".\nYou have 1 hour to carry out the reset procedure otherwise you will have to start again.\nIf you can't find it, remember to check your spam folder!\n\nIf your email is with orange.fr and you do not receive the email, please contact us because their server blocks our automatic email sending.",
          "alertMdpInvalide" : "Invalid password!\nPlease enter more than 5 characters with at least one number, one lowercase letter and one uppercase.",
          "alertMdpDifferents" : "Passwords entered are different!",
          "alertInscriptionImpossible" : "Registration impossible, there already exists an account for this email address!", 
          "alertDoubleMdp" : "You must complete both password fields!",
          "alertMdpModifie" : "Password changed!\nYou can now log in with your new password.",
          "alertErreurReinit" : "Error while resetting the password.",
          "alertUsernameInvalide" : "This username is invalid. It must be between 5 and 20 characters starting with a letter. Only letters, numbers, periods, hyphens and underscores are accepted!",
          "alertChampSiteInvalide": "The site is invalid. There cannot be a space in the URL address of a website.",
          "alertChampRSInvalide": "The username of a social network must therefore contain neither @ nor spaces.",
          "alertRecupProfil" : "Error while retrieving the profile",
          "alertEnregProfil" : "Error saving the profile, you are no longer connected.",
          "alertEnregPhoto" : "Error saving the picture, you are no longer connected.",
          "alertFormatFichier" : "Please select a JPEG, JPG or PNG file.",
          "alertTailleFichier" : "The file size must not exceed 7 MB.",
          "alertEnregMdp" : "Error saving the new password, you are no longer connected.",
          "alertErreurSuppCompte" : "Error while deleting account",
          "alertPlusConnecte" : ", you are no longer connected.",
          "alertErreurNewsletter" : "Error during newsletter subscribing",
          "alertNewsletterOK" : "Subscription OK !",
          "alertVoyagePublic" : "This itinerary is public, do you want to make it private?\n\nIt will no longer be visible on our homepage.",
          "alertVoyagePrive" : "This itinerary is in private mode, would you like to make it public?\n\nIt will be accessible to everyone from our home page, you can share it and even integrate it on your blog or site. Enter your Instagram account on your tripkygo profile and we will mention you in an Instagram story to present your itinerary.\n\nYou can hide certain information by adjusting the privacy settings for your public trips in your account.",
          "alertAccepterCoCreateur": "You are now co-creator of this itinerary. After refreshing the page, it will appear in the list of your trips.",
          "alertRefuserCoCreateur": "Do you confirm your refusal to become co-creator of the trip?",
          "alertSupprimerCoCreateur": "Do you confirm the revocation of co-creator rights to ",

          "confirmRepousser" : "Are you sure you want to postpone the start of your trip and bring forward the end date? You will lose the saved data for the deleted dates.",
          "confirmDecaler" : "You shift the dates of your trip by keeping the same number of days, so you will keep all previously recorded data that will be shifted.",
          "confirmDiminuer" : "Are you sure you want to reduce the duration of your trip? You will lose the data saved for the deleted days.",
          "confirmSuppressionCompte" : "Are you sure you want to permanently delete your account and your trips?",

          "lienPlanificateur" : "/en/travel-planning",
          "lienMesVoyages": "/en/my-travels",
          "lienVoyagesPartages": "/en/shared-travels",
          "lienStatistiques": "/en/my-statistics",
          "lienVoyageur": "/en/traveler",
          "lienAide" : "/en/help",
          "lienGuides" : "/en/country-guides",
          "lienGuide" : "/en/country-guide",
          "lienContact" : "/en/contact",
          "lienHome" : "/en",
          "lienAPropos" : "/en/about-us",
          "lienNewsletter" : "/en/about-us#newsletter",
          "lienMentionsLegales" : "/en/legal-notice",
          "lienVoyagesPublics" : "/en/travels/publics",
          "lienOuPartir" : "/en/where-to-go",
          "lienReservation" : "/en/book",
          "lienReservationTransport" : "/en/book-transport",
          "lienReservationAvion" : "/en/book-flight",
          "lienReservationHebergement" : "/en/book-accomodation",
          "lienReservationActivite" : "/en/book-activity",
          "lienLocationVoiture" : "/en/rent-car",
          "lienCompte": "/en/account",
          "lienLogin": "/en/login",
          "lienSignUp": "/en/signup",
          "lienCompteConfidentialite": "/en/account-confidentiality",
          "lienGestionCompte": "/en/account-management",
          "lienCompteMdp" : "/en/account-password",
          "lienItineraireIntegre" : "/en/travel-itinerary-embed",
        }
      },
      fr: {
        translation: {
          "bienvenue": "👋 Bienvenue sur tripkygo v2",
          "nbItineraires": "itinéraires crées depuis juin 2020",
          "planificateur" : "Planificateur de voyages",
          "planificateur2" : "Planificateur",
          "description" : "Planifiez vous-mêmes vos vacances, road-trips ou tours du monde en créant votre itinéraire de voyage.",
          "description1" : "Totalement gratuit, tripkygo est le planificateur de voyage qu'il vous faut pour organiser vos vacances, road-trips ou tours du monde. Il permet notamment de calculer votre budget, de générer une carte et d'éditer des carnets de voyages.",
          "description2": "En plus de l'outil de création d'itinéraires, notre site comprend une section ", 
          "description3": ", des ", 
          "description4": " et un ", 
          "ouPartir" : "Où partir ?",
          "ouPartirEn" : "Où partir en",
          "guidesPays" : "Guides par pays",
          "outilReservation" : "Outil de réservation",
          "reservation" : "Réservation",
          "voyagesPublics" : "Voyages publics",
          "infosMasquees" : "Certaines informations peuvent être masquées.",
          "infoVoyagesPublics" : "Seuls les itinéraires déclarés publics par leur créateur sont visibles. Certaines informations peuvent être masquées.",
          "chargementVoyagesPublics" : "Chargement des voyages publics en cours, merci de patienter...",
          "chargementGuide" : "Chargement du guide en cours, veuillez patienter...",
          "chargementVoyage" : "Chargement du voyage en cours, merci de patienter...",
          "plusVoyagesPublics" : "Voir + voyages",
          "plusAimes" : "Les + aimés ❤️",
          "triPubCroi" : "Date de publication déroissante",
          "triPubDecroi" : "Date de publication croissante",
          "triCreaCroi" : "Date de création décroissante",
          "triCreaDecroi" : "Date de création croissante",
          "triNbJCroi" : "Nombre de jours décroissant",
          "triNbJDecroi" : "Nombre de jours croissant",
          "triNbLCroi" : "Nombre de likes décroissant",
          "triNbLDecroi" : "Nombre de likes croissant",
          "tri" : "Tri",
          "utilisateur" : "Utilisateur",
          "pays" : "Pays",
          "paysS" : "pays",
          "autrePays" : "Autre pays ?",
          "ville" : "Ville",
          "voyageNomade" : "Voyage nomade",
          "voyageSedentaire" : "Voyage sédentaire",
          "ouAllezVous" : "Où allez-vous ?",
          "debutFin" : "Début - Fin",
          "voyageur" : "Voyageur",
          "voyageurMin" : "voyageur",
          "ajouterVoyageurs" : "Ajouter voyageur(s)",
          "nbVoyageurs" : "Nb de voyageur(s)",
          "nombreVoyageurs" : "Nombre de voyageur(s)",
          "creer" : "Créer",
          "modifier" : "Modifier",
          "jours" : "jours",
          "jour" : "Jour",
          "jourMin" : "jour",
          "cree" : "Créé",
          "par" : "par",
          "le" : "le",
          "a" : "à",
          "mesVoyages": "Mes voyages",
          "mesDemandes": "Demandes en attente",
          "voyagesPartages": "Voyages partagés",
          "statistiques": "Statistiques",
          "aide" : "Aide",
          "guides" : "Guides",
          "blog" : "Blog",
          "contact" : "Contact",
          "creerVoyage" : "Créer un voyage",
          "destinationsPopulaires" : "Destinations populaires",
          "reserverTransport" : "Réserver un transport",
          "reserverAvion" : "Réserver un vol",
          "reserverHebergement" : "Réserver un hôtel",
          "reserverActivite" : "Réserver une activité",
          "louerVoiture" : "Louer une voiture",
          "aPropos" : "À propos",
          "newsletter" : "Newsletter",
          "mentionsLegales" : "Mentions légales",
          "creerItineraire" : "Créer un itinéraire",
          "detailVoyage" : "Détail du voyage",
          "monnaie" : "Monnaie",
          "enregistrement" : "Enregistrement en cours, veuillez patienter...",
          "saisirPays" : "Saisir un pays",
          "saisirVille" : "Saisir une ville",
          "quandPartezVous" : "Quand partez-vous ?",
          "aucune" : "Aucune",
          "ajoutezDates" : "Ajoutez dates",
          "effacerTout" : "Effacer tout",
          "aucunResultat" : "Aucun résultat",
          "resultat" : "Résultat",
          "hebergement" : "Hébergement",
          "vol" : "Vol",
          "transport" : "Train/bus",
          "activite" : "Activité",
          "voiture" : "Voiture",
          "monCompte" : "Mon compte",
          "compte" : "Compte",
          "compteMin" : "compte",
          "accueil" : "Accueil",
          "rechercheTripkygo" : "Chercher sur tripkygo",
          "guidesVoyage" : "Guides de voyage",
          "infoDestinations" : "Toutes les informations sur vos destinations",
          "infoDestination" : "Toutes les informations sur cette destination",
          "chercherDestination" : "Chercher une destination",
          "consulter" : "Consulter",
          "quandPartir" : "Quand partir",
          "nosIncontournables" : "Nos incontournables",
          "conseilsVoyage" : "Nos conseils de voyage en fonction des conditions climatiques et de vos envies",
          "formalites" : "Formalités",
          "notreSelection" : "Notre sélection de lieux à ne pas râter lors d'un voyage",
          "languesOfficielles" : "Langue(s) officielle(s)",
          "drapeau" : "Drapeau",
          "capitale" : "Capitale",
          "plage" : "Plage",
          "nonConseille" : "Non conseillé",
          "conseille" : "Conseillé",
          "pasRecommandation" : "Pas de recommandation pour cette destination.",
          "trouverDestination" : "Nous allons vous aider à trouver votre prochaine destination de voyage",
          "souhaitPartir" : "Quand souhaitez-vous partir ?",
          "janvier" : "Janvier",
          "fevrier" : "Février",
          "mars" : "Mars",
          "avril" : "Avril",
          "mai" : "Mai",
          "juin" : "Juin",
          "juillet" : "Juillet",
          "aout" : "Août",
          "septembre" : "Septembre",
          "octobre" : "Octobre",
          "novembre" : "Novembre",
          "decembre" : "Décembre",
          "en" : "en",
          "selectionOuPartir" : "Voici notre sélection pour partir en",
          "enFonction" : "en fonction des conditions météorologiques et de vos envies",
          "introContact" : "Une question, une suggestion, un signalement de bug ou autre ? Contactez-nous :",
          "nom" : "Nom",
          "prenom" : "Prénom",
          "presentation" : "Présentation",
          "sujet" : "Sujet",
          "envoyer" : "Envoyer",
          "envoyerDemande" : "Envoyer une demande",
          "_en" : "",
          "En" : "",
          "nouveauVoyage" : "Nouveau voyage",
          "aucunVoyageCree" : "Aucun voyage créé",
          "aucunVoyagePartage" : "Aucun voyage ne vous a été partagé",
          "voyagesCrees": "Voyages créés",
          "titreVoyagesPartages" : "Voyages en consultation suite à un partage",
          "general" : "Général",
          "paysVisites" : "Pays visités",
          "paysVisiteMin" : "pays visité",
          "depenses" : "Dépenses",
          "deplacements" : "Déplacements",
          "chargementStatistiques" : "Calcul  des statistiques en cours, veuillez patienter...",
          "aucuneDepense" : "Aucune dépense",
          "aucunDeplacement" : "Aucune déplacement",
          "jaimeRecu" : "j'aime reçu",
          "etape" : "étape",
          "voyage" : "voyage",
          "depensesMin" : "dépensés",
          "parcourus" : "parcourus",
          "dont" : "dont :",
          "repartition" : "Répartition",
          "avion" : "Avion",
          "bateau" : "Bateau",
          "aPied" : "A pied",
          "velo" : "Vélo",
          "nonRenseigne" : "Non renseigné",
          "jourPersonne" : "/jour/personne",
          "connexion" : "Connexion",
          "deconnexion" : "Déconnexion",
          "inscrire" : "S'inscrire",
          "ou" : "OU",
          "adresseEmail" : "Adresse email",
          "motDePasse" : "Mot de passe",
          "motDePasseOublie" : "Mot de passe oublié ?",
          "seConnecter" : "Se connecter",
          "creerCompte" : "Créer un compte",
          "pasInscrit" : "Pas encore inscrit ?",
          "reinitMdp" : "Réinitialisation de mot de passe",
          "saisirEmail" : "Saisir votre adresse email",
          "inscription" : "Inscription",
          "resaisirEmail" : "Resaisissez votre mot de passe",
          "dejaInscrit" : "Déjà inscrit ?",
          "nouveauMdp" : "Nouveau mot de passe",
          "ancienMdp" : "Ancien mot de passe",
          "resaisirNouveauMdp" : "Resaisissez votre nouveau mot de passe",
          "enregistrer" : "Enregistrer",
          "profil" : "Profil",
          "changerImage" : "Changer l'image",
          "voirProfil" : "Voir votre profil public",
          "accepteNewsletter" : "J'accepte de recevoir la newsletter",
          "nomUtilisateur" : "Nom d'utilisateur",
          "champsFacultatifs" : "Champs facultatifs",
          "usernameInvalide" : "Le nom d'utilisateur n'est pas valide. Il doit faire entre 5 et 20 caractères en commençant par une lettre. Seuls les lettres, chiffres, points, tirets et tirets bas sont acceptés !",
          "usernameDejaPris" : "Ce nom d'utilisateur est déjà pris, veuillez en choisir un autre !",
          "messageEmailInvalide1" : " L'email utilisé pour créer votre ",
          "messageEmailInvalide2" : " est erroné, veuillez ",
          "messageEmailInvalide3" : "nous contacter",
          "messageEmailInvalide4" : " pour le rectifier !",
          "nonVisible" : "(non visible sur votre profil public)",
          "infoPresentation" : "Présentez-vous aux autres utilisateurs : vos destinations préférées, vos envies, vos passions,...",
          "sitePersonnel" : "Site internet personnel",
          "compteCreeLe" : "Compte créé le ",
          "gestionCompte" : "Gestion compte",
          "confidentialite" : "Confidentialité",
          "confidentialiteVoyagesPublics" : "Confidentialité des voyages publics",
          "infoConfidentialiteVoyagesPublics" : "Tout le monde a accès en consultation aux voyages que vous décidez de rendre publics. Les confirmations de réservation enregistrées et les check-lists de bagage ne sont jamais visibles par les visiteurs.",
          "info2ConfidentialiteVoyagesPublics" : "Vous pouvez décider de l'affichage ou non des informations suivantes :",
          "affichageHebergements" : "Afficher le nom des hébergements",
          "affichagePrix" : "Afficher les prix",
          "affichageBudget" : "Afficher le budget et les frais annexes",
          "affichagePhotos" : "Afficher les photos des étapes",
          "affichageDates" : "Afficher les dates pour les voyages à venir (date de fin non échue)",
          "affichageLiens" : "Afficher les liens",
          "oui" : "Oui",
          "non" : "Non",
          "changementMdp" : "Changement de mot de passe",
          "majMdp" : "Mettre à jour le mot de passe",
          "veuillezRemplirChamps" : "Veuillez remplir tous les champs.",
          "formatMdp": "Le nouveau mot de passe doit contenir au moins un chiffre, une lettre minuscule, une lettre majuscule et être d'au moins 8 caractères.",
          "nouveauxMdpDifferents" : "Les nouveaux mots de passe ne correspondent pas.",
          "mdpDifferentAncien" : "Le nouveau mot de passe doit être différent de l'ancien.",
          "ancienMdpIncorrect" : "Ancien mot de passe incorrect",
          "messageInscriptionGoogle" : "(Saisissez x si vous vous êtes inscrit(e) avec Google)",
          "supprimerCompte" : "Supprimer mon compte",
          "suppressionEnCours" : "Suppression en cours...",
          "avertissementSuppression" : "En cliquant sur le bouton, vous supprimerez définitivement votre compte et tous les voyages que vous avez créés !",
          "aPropos1" : "Je suis un informaticien vivant à Paris depuis 2005 et ma plus grande passion est le voyage !",
          "aPropos2" : "En couple, nous avons visité plus de 30 pays sur 5 continents, vous pouvez retrouvez une partie de mes carnets de voyages sur mon ",
          "aPropos3" : "L'idée du planificateur de voyages me trottait dans la tête depuis plusieurs mois, lassé d'utiliser des tableurs peu pratiques pour organiser nos voyages et non convaincu par les planificateurs existants (trop compliqués, trop longs à remplir, pas assez visuels,...).",
          "aPropos4" : "C'est grâce au confinement de Mars 2020 que j'ai pu concrétiser ce projet en développant ce site internet durant nos longs week-end et soirées confinés. J'ai voulu ce planificateur le plus rapide et le plus visuel possible en permettant de balayer les journées en un clin d'oeil.",
          "aPropos5" : "Je fais évoluer le site constamment en fonction des besoins remontés par les utilisateurs.",
          "aPropos6" : "En Juin 2024, pour les 4 ans de tripkygo, j'ai lancé ce nouveau site plus moderne, plus rapide et plus esthétique ! N'hesitez pas à m'envoyer vos remarques ou idées pour améliorer le site.",
          "aPropos7" : "Bonne visite !",
          "inscriptionNewsletter": "Inscrivez-vous à notre newsletter",
          "infoNewsletter" : "pour être informé(e) de toutes les nouveautés de notre site",
          "votre" : "Votre",
          "sesVoyagesPublics" : "Ses voyages publics",
          "totalLikes" : "Total du nombre de likes sur ses voyages publics",
          "aucunVoyagePublic" : "Aucun voyage public.",
          "inscritEn" : "Inscrit(e) en",
          "itinerairesPublics" : "Itinéraires publics",
          "reserverHotels" : "Réservez vos hôtels, appartements,... avec",
          "reserverTransports" : "Réservez vos billets de train ou de bus avec",
          "reserverAvions" : "Réservez vos billets d'avion avec",
          "louerVoitureAvec" : "Louez un véhicule avec",
          "cliquerActivite" : "Cliquez sur une activité pour consulter d'autres destinations sur",
          "infoGetYourGuide" : "En utilisant cet outil et en réservant chez notre partenaire GetYourGuide, tripkygo touchera une commission sans modifier le prix que vous paierez. Nous espérons que cela permettra de maintenir notre site 100% gratuit !",
          "infoBooking" : "En utilisant cet outil et en réservant chez notre partenaire Booking, tripkygo touchera une commission sans modifier le prix que vous paierez. Nous espérons que cela permettra de maintenir notre site 100% gratuit !",
          "infoKiwi" : "En utilisant cet outil et en réservant chez notre partenaire Kiwi, tripkygo touchera une commission sans modifier le prix que vous paierez. Nous espérons que cela permettra de maintenir notre site 100% gratuit !",
          "infoDiscoverCars" : "En utilisant cet outil et en réservant chez notre partenaire DiscoverCars, tripkygo touchera une commission sans modifier le prix que vous paierez. Nous espérons que cela permettra de maintenir notre site 100% gratuit !",
          "titreItineraire" : "Titre de l'itinéraire",
          "destinationEnregistree" : "Destination enregistrée",
          "destinationsEnregistrees" : "Destinations enregistrées",
          "resume" : "Résumé",
          "carte" : "Carte",
          "modeModif" : "Mode modification",
          "modeConsult" : "Mode consultation",
          "outils" : "Outils",
          "prive" : "Privé",
          "lieu" : "Lieu",
          "repas" : "Repas",
          "trajet" : "Trajet",
          "nuit" : "Nuit",
          "location" : "Location",
          "masquees" : "masquées",
          "informationCookies1" : "Pour afficher l'outil de réservation, vous devez accepter les cookies de type 'Marketing' (",
          "informationCookies2" : "voir l'explication",
          "informationCookies3" : "Pour autoriser les cookies de type 'Marketing' sur le site tripkygo",
          "informationCookies4" : ", veuillez :",
          "informationCookies5" : "1) cliquer sur l'icone ",
          "informationCookies6" : " en bas à droite de votre écran",
          "informationCookies7" : "2) accepter les cookies de type 'Marketing' ",
          "informationCookies8" : "3) cliquer sur 'Sauvegarder' en bas de la fenêtre",
          "informationCookies9" : "4) actualiser la page",
          "informationCookies10" : "Les outils de recherche seront désormais visibles !",
          "informationCookies11" : "En utilisant ces outils de recherche, vous contribuerez à maintenir notre site 100% gratuit !",
          "partage" : "Partage",
          "copierLien" : "Copier le lien",
          "partagerFacebook" : "Partager sur Facebook",
          "partagerX" : "Partager sur X (Twitter)",
          "envoyerParMail" : "Envoyer par mail",
          "envoyerParSms" : "Envoyer par sms",
          "integrerBlog" : "Intégrer sur un site/blog",
          "itineraireVoyage" : "Itinéraire de voyage",
          "messageRs" : "Mon itinéraire de voyage créé via @tripkygo",
          "messageRs2" : "Mon itinéraire de voyage peut t'intéresser",
          "lienCopie" : "Lien copié",
          "enregistrementMaj" : "Enregistrement",
          "creation" : "Création",
          "enCours" : "en cours, veuillez patienter...",
          "aeroportGareVille"  : "Aéroport, gare, ville...",
          "compagnie"  : "compagnie",
          "refNumVol"  : "Réf, n° de vol,...",
          "debut" : "Début",
          "fin" : "Fin",
          "prix" : "Prix",
          "paye?" : "Payé ?",
          "paye" : "Payé",
          "nomVilleLieu"  : "Nom de la ville ou du lieu",
          "activitesVisites" : "Activités, visites,...",
          "nomRestaurantLieu"  : "Nom du restaurant ou du lieu",
          "descriptionRepas"  : "Description,...",
          "nomHebergement"  : "Nom de l'hébergement",
          "nomVille"  : "Nom de la ville",
          "prixNuit" : "Prix par nuit",
          "reference"  : "Référence,...",
          "agenceBoutique" : "Agence, boutique,...",
          "surPlace" : "Sur place",
          "prelevement" : "Prélèvement",
          "acompte" : "Acompte",
          "priseEnCharge" : "Prise en charge",
          "restitution" : "Restitution",
          "rattachezPEC" : "Rattachez la prise en charge",
          "cliquezCompleter" : "Cliquez pour compléter",
          "nuitsIncompletes" : "Nuit(s) incomplète(s)",
          "nuitTransport" : "Nuit en transport",
          "lieuxVisites" : "Lieux visités",
          "dateDebut" : "Date de début :",
          "nombreNuits" : "Nombre de nuits :",
          "villeSejourner" : "Ville où vous séjournerez",
          "villeNonRenseignee" : "Ville non renseignée",
          "paysNonRenseigneCliquez" : "Pays non renseigné. Cliquez sur la ville et sélectionnez-la dans les choix proposés pour une sélection automatique du pays ou sélectionnez le pays ci-dessous.",
          "paysNonRenseigne" : "Pays non renseigné",
          "situationApresEnregistrement" : "Situation après enregistrement :",
          "du" : "du",
          "au" : "au",
          "liberee" : "libérée",
          "questionAjout" : "Que souhaitez-vous ajouter ?",
          "questionAjoutCoCreateur" : "Qui souhaitez-vous ajouter en co-créateur ?",
          "cliquezPourEchanger" : "Cliquez pour échanger le contenu avec le jour suivant",
          "coordonneesGPSAbsentes" : "Coordonnées GPS absentes",
          "cliquezCarte" : "Cliquez pour voir sur la carte",
          "cliquezPlacement" : "Cliquez pour placer sur la carte",
          "recherche" : "Recherche",
          "blocNotes" : "Bloc-notes",
          "confirmation" : "Confirmation",
          "coordonnees" : "Coordonnées",
          "lien" : "Lien",
          "suppression" : "Suppression",
          "pointsInteret" : "Points d'intérêts",
          "itineraire" : "Itinéraire",
          "informations" : "Informations",
          "copie" : "Copie",
          "afficherStatistiques" : "Afficher les statistiques",
          "convertisseurMonnaies" : "Convertisseur de monnaies",
          "exporterItineraire" : "Exporter l'itinéraire",
          "importerItineraire" : "Importer l'itinéraire",
          "editerPDF" : "Editer en PDF",
          "integrerItineraire" : "Intégrer l'itinéraire sur un blog/site",
          "faireMaValise" : "Faire ma valise",
          "partagerItineraire" : "Partager l'itinéraire",
          "chargementDiagramme" : "Chargement du diagramme...",
          "repartitionBudget" : "Répartition du budget",
          "activites" : "Activités",
          "hebergements" : "Hébergements",
          "autresFrais" : "Autres frais",
          "frais" : "Frais",
          "fraisPersonnalises" : "Frais personnalisés",
          "informationsFrais" : "Informations sur les frais",
          "ajoutezInformations" : "Ajoutez des informations",
          "nouveauTypeFrais" : "Nouveau type de frais...",
          "visas" : "Visa(s)",
          "vaccins" : "Vaccin(s)",
          "carburant" : "Carburant",
          "nourriture" : "Nourriture",
          "vehicule" : "Véhicule",
          "autres" : "Autres",
          "materiel" : "Matériel",
          "resteAPayer" : "Reste à payer",
          "dejaPaye" : "Déjà payé",
          "totalPour" : "Total pour",
          "parPersonne" : "par personne",
          "kmParcourus" : "km parcourus",
          "exportezItineraire" : "Exportez votre itinéraire pour le visualiser sur d'autres sites/applications (Google Maps, Maps.Me, OpenStreetMap,...).",
          "elementsExporter" : "Elements à exporter",
          "tousExporter" : "Tous (lieux, repas, trajets, locations et nuits)",
          "seulementExporter" :"Seulement les lieux et les repas",
          "exporter" : "Exporter",
          "importezItineraire" : "Importez des données cartographiques au format KML/KMZ (Google Maps, Maps.Me, OpenStreetMap,...)",
          "kmlKmzAcceptes" : ".kml et kmz acceptés",
          "lieuxImportes" : "Lieux importés :",
          "selectionnezJour" : "Sélectionnez un jour",
          "selectionnezPositionnement" : "Sélectionnez un positionnement",
          "lieuAjoute" : "Lieu ajouté",
          "integrationItineraire" : "Intégration de l'itinéraire sur votre site/blog",
          "quelleVue" : "Quelle vue souhaitez-vous afficher par défaut ?",
          "previsualisezItineraire" : "Prévisualisez l'itinéraire intégré",
          "codeAUtiliser" : "Code à utiliser :",
          "pourAjuster" : "Pour ajuster la hauteur de la fenêtre, lisez",
          "commentIntegrer" : "Comment intégrer un itinéraire tripkygo sur un site/blog ?",
          "notificationEmail" : "Notification email 48h avant le départ ?",
          "objet" : "Objet",
          "ajouter" : "Ajouter",
          "ajoutEnCours" : "Ajout en cours, veuillez patienter...",
          "echangeEnCours" : "Echange en cours, veuillez patienter...",
          "copieEnCours" : "Copie en cours, veuillez patienter...",
          "gestionCoCreateurs" : "Gestion des co-créateurs",
          "coCreateurs" : "Co-créateur(s) :",
          "envoiDemandeCoCreateurs" : "Envoi de la demande en cours, veuillez patienter...",

          "alertPaysSaisi" : "Vous avez déjà saisi ce pays, veuillez saisir le pays suivant ou passer à la saisie des autres critères !",
          "alertDestination" : "Vous devez sélectionner une destination !",
          "alertDateDeb" :"Vous devez sélectionner une date de début de voyage !",
          "alertDateFin" :"Vous devez sélectionner une date de fin de voyage !",
          "alertNbVoyageurs" :"Vous devez ajouter au moins 1 voyageur !",
          "alertNbJoursMax" :"Votre voyage ne doit pas dépasser 365 jours ! Merci de créer plusieurs voyages.",
          "alertNbJoursMin" :"Votre voyage doit durer au moins deux jours.",
          "alertCopie" : "Vous devez être connecté(e) pour copier un voyage. \nVous n'avez pas de compte ? Inscrivez-vous, c'est gratuit !",
          "alertPartage" : "Vous devez être connecté(e) pour partager un voyage. \nVous n'avez pas de compte ? Inscrivez-vous, c'est gratuit !",
          "alertCreateurs" : "Vous devez être connecté(e) pour modifier les créateurs d'un voyage. \nVous n'avez pas de compte ? Inscrivez-vous, c'est gratuit !",
          "alertMajConnecte" : "Erreur lors de la mise à jour, vous n'êtes plus connecté(e).",
          "alertSaisieRapide" : "Ne seriez-vous pas un peu rapide ?",
          "alertNom" : "Vous devez saisir un nom !",
          "alertEmail" : "Vous devez saisir une adresse email valide !",
          "alertSujet" : "Vous devez saisir un sujet !",
          "alertMessage" : "Vous devez saisir un message d'au moins 30 caractères !",
          "alertRecaptcha" : 'Veuillez cocher la case "Je ne suis pas un robot".',
          "alertTechnique" : "Erreur technique !",
          "alertVerifReinit" : "Erreur lors de la verif reinit.",
          "alertMailIncorrect" : "Adresse mail ou code de réinitalisation incorrect",
          "alertDelaiReinit" : 'Délai de réinitialisation expiré, veuillez recommencer la procédure en cliquant sur "Mot de passe oublié".',
          "alertEnvoiMail" : "Erreur lors de l'evoi du message.",
          "alertPasCompte" : "Il n'existe pas de compte pour cette adresse email !",
          "alertMailRecup" : "Le mail de récupération de mot de passe vous a été envoyé sur votre adresse",
          "alertMailRecupSuite" : ".\nVous avez 1h pour effectuer la procédure de réinitialisation sinon il faudra recommencer.\nSi vous ne le trouvez pas, pensez à vérifier votre dossier spams ou contactez-nous !",
          "alertMailRecupFin" : ".\nVous avez 1h pour effectuer la procédure de réinitialisation sinon il faudra recommencer.\nSi vous ne le trouvez pas, pensez à vérifier votre dossier spams !\n\nSi vous votre messagerie est chez orange.fr et que vous ne recevez pas le mail, merci de nous contacter car leur serveur bloque nos envois de mails automatiques.",
          "alertMdpInvalide" : "Mot de passe invalide !\nVeuillez saisir plus de 5 caractères avec au moins un chiffre, une lettre minuscule et une majuscule.",
          "alertMdpDifferents" : "Les mots de passe saisis ne sont pas identiques !",
          "alertInscriptionImpossible" : "Inscription impossible, il existe déjà un compte pour cette adresse email !", 
          "alertDoubleMdp" : "Vous devez remplir les deux champs de mot de passe !",
          "alertMdpModifie" : "Mot de passe modifié !\nVous pouvez désormais vous connecter avec votre nouveau mot de passe.",
          "alertErreurReinit" : "Erreur lors de la réinitialisation du mot de passe .",
          "alertUsernameInvalide" : "Le nom d'utilisateur n'est pas valide. Il doit faire entre 5 et 20 caractères en commençant par une lettre. Seuls les lettres, chiffres, points, tirets et tirets bas sont acceptés !",
          "alertChampSiteInvalide" : "Le site n'est pas valide. Il ne peut pas y avoir d'espace dans l'adresse url d'un site internet. Veuillez le corriger sinon il ne sera pas enregistré !",
          "alertChampRSInvalide" : "Le nom d'utilisateur d'un réseau social ne doit donc contenir ni @ ni espace.\nVeuillez le corriger sinon il ne sera pas enregistré !\n\nExemple :\n- https://www.facebook.com/tripkygo/ : saisir tripkygo\n- https://www.instagram.com/tripkygo : saisir tripkygo\n- https://www.x.com/tripkygo : saisir tripkygo\n- https://www.youtube.com/@tripkygo saisir tripkygo",
          "alertRecupProfil" : "Erreur lors de la récupération du profil",
          "alertEnregProfil" : "Erreur lors de l'enegistrement du profil, vous n'êtes plus connecté(e).",
          "alertEnregPhoto" : "Erreur lors de l'enegistrement de la photo, vous n'êtes plus connecté(e).",
          "alertFormatFichier" : "Veuillez sélectionner un fichier JPEG, JPG ou PNG.",
          "alertTailleFichier" : "La taille du fichier ne doit pas dépasser 7 Mo.",
          "alertEnregMdp" : "Erreur lors de l'enregistrement du nouveau mot de passe, vous n'êtes plus connecté(e).",
          "alertErreurSuppCompte" : "Erreur lors de la suppression du compte",
          "alertPlusConnecte" : ", vous n'êtes plus connecté(e)",
          "alertErreurNewsletter" : "Erreur lors de l'inscription à la newsletter",
          "alertNeswsletterOK" : "Inscription à la newsletter réussie !",
          "alertVoyagePublic" : "Ce voyage est public, voulez-vous le rendre privé ?\n\nIl ne sera plus visible sur notre page d'accueil.",
          "alertVoyagePrive" : "Ce voyage est en mode privé, souhaitez-vous le rendre public ?\n\nIl sera accessible par tous depuis notre page d'accueil, vous pourrez le partager et même l'intégrer sur votre blog ou site. Renseignez votre compte Instagram sur votre profil tripkygo et nous vous mentionnerons en story Instagram pour présenter votre itinéraire.\n\nVous pouvez masquer certaines informations en réglant les paramètres de confidentialité de vos voyages publics dans votre compte.",
          "alertAccepterCoCreateur" : "Vous êtes désormais co-créateur de cet itinéraire. Après actualisation de la page, il va apparaître dans la liste de vos voyages",
          "alertRefuserCoCreateur" : "Confirmez-vous votre refus pour devenir co-créateur du voyage ?",
          "alertSupprimerCoCreateur": "Confirmez-vous le retrait des droits de co-createur à ",

          "confirmRepousser" : "Etes-vous sûr de vouloir repousser le début de votre voyage et avancer la date de fin ? Vous perdrez les données enregistrées pour les dates supprimées.",
          "confirmDecaler" : "Vous décalez les dates de votre voyage en conservant le même nombre de jours, vous conserverez donc toutes les données précédemment enregistrées qui seront décalées.",
          "confirmDiminuer" : "Etes-vous sûr de vouloir diminuer la durée de votre voyage ? Vous perdrez les données enregistrées pour les jours supprimés.",
          "confirmSuppressionCompte" : "Êtes-vous sûr de vouloir définitivement supprimer votre compte et vos voyages ?",
          
          "lienPlanificateur" : "/planificateur-voyage",
          "lienMesVoyages": "/mes-voyages",
          "lienVoyagesPartages": "/voyages-partages",
          "lienStatistiques": "/mes-statistiques",
          "lienVoyageur": "/voyageur",
          "lienAide" : "/aide",
          "lienGuides" : "/guides-pays",
          "lienGuide" : "/guide-pays",
          "lienContact" : "/contact",
          "lienHome" : "/",
          "lienAPropos" : "/a-propos",
          "lienNewsletter" : "/a-propos#newsletter",
          "lienMentionsLegales" : "/mentions-legales",
          "lienVoyagesPublics" : "/voyages/publics",
          "lienOuPartir" : "/ou-partir",
          "lienReservation" : "/reservation",
          "lienReservationTransport" : "/reservation-transport",
          "lienReservationAvion" : "/reservation-vol",
          "lienReservationHebergement" : "/reservation-hebergement",
          "lienReservationActivite" : "/reservation-activite",
          "lienLocationVoiture" : "/location-voiture",
          "lienCompte": "/account",
          "lienLogin": "/login",
          "lienSignUp": "/signup",
          "lienCompteConfidentialite": "/compte-confidentialite",
          "lienGestionCompte": "/gestion-compte",
          "lienCompteMdp" : "/account-password",
          "lienItineraireIntegre" : "/itineraire-voyage-integre",
        }
      }
    },
    lng: 'fr', // langue par défaut
    fallbackLng: 'fr',
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
