import React, { FC, useState, useEffect } from "react";
import { LieuType } from "data/types";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import EvenementBtns2 from "components/EvenementBtns2";
import SymboleMonnaie from "components/SymboleMonnaie/SymboleMonnaie";
import { Tooltip }  from "react-tooltip";
import 'react-tooltip/dist/react-tooltip.css';
import Axios from '../../_services/caller.service';
import { useTranslation } from 'react-i18next';
 
export interface CardLieuIntegreBoxProps {
  className?: string;
  symbole_monnaie: string;
  author: LieuType;
  index: string;
  selected? : boolean;
  isMap?: boolean;
  onLocalisationClick?: () => void;
  numDroit: number;
}

const CardLieuIntegreBox: FC<CardLieuIntegreBoxProps> = ({
  className = "",
  symbole_monnaie,
  author,
  index,
  selected,
  isMap,
  onLocalisationClick,
  numDroit
}) => {
   const { t } = useTranslation();

  const { displayName, libelle2, prix, type, paiement, avatar, lat, longi } = author;
  const [lienVisible, setLienVisible] = useState("");
  const [lienItineraire, setLienItineraire] = useState("");
  const [photo, setPhoto] = useState("");
  const [showOptions, setShowOptions] = useState<boolean>(false);

  useEffect(() => {
    //gestion lien lien_modifie si createur ou visiteur et etat=R pour créateur géré par l'api
    if(author.lien !== undefined && author.lien !== "" && numDroit ===1)
    {
      setLienVisible(author.lien);
    }
    else if(author.lien !== undefined && author.lien !== "" && author.etat_lien ==="V")
    {
      setLienVisible(author.lien);
    } 
    else if(author.lien !== undefined && author.lien !== "" && author.etat_lien === "M" && author.lien_modifie !== undefined && author.lien_modifie !== "")
    {
      setLienVisible(author.lien_modifie);
    }
    
    if(author.lat !== "" && author.longi !== "")
    {
      setLienItineraire("https://www.google.com/maps/dir//"+author.lat+","+author.longi+"/");
    } 

  }, []);

  useEffect(() => {
   
   let imageURL: string | undefined;

    if(avatar !== "")
    {

      Axios.get(avatar, { responseType: 'blob' })
      .then((response) => {
        const blob = new Blob([response.data], { type: 'image/jpeg' });
        imageURL = URL.createObjectURL(blob);
        setPhoto(imageURL);

      })
      .catch((error) => console.error('Erreur lors du chargement de l\'image', error));
    }
    else
    {
      setPhoto("");
    }

    return () => {
      if (imageURL) {
        URL.revokeObjectURL(imageURL); // Révoquez l'URL seulement lorsque le composant est démonté
      }
    };
    
  }, [avatar]);

  const afficheOptions = () => {
    setShowOptions(true);
  }

  const cacheOptions = () => {
    setShowOptions(false);
  }

  return (
    <div
      
      className={`${selected ? "selectedCard" : ""} nc-CardLieuIntegreBox relative flex flex-col items-center justify-center text-center px-3 py-2 sm:px-3 sm:py-2  [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ] ${className}`}
      data-nc-id="CardLieuIntegreBox"
      onMouseOver={afficheOptions}
      onMouseLeave={cacheOptions}
    >
      <Badge
      taille="text-xxs py-05"
        className="absolute left-2 top-2"
        color={type === "" ? "gray" : "yellow"}
        name={type === "" ? t('lieu') : t('repas') }
      />
     
     { (author.bloc !== "" || author.document !== "" || author.place_id !== "" || lienVisible || lienItineraire) && (
      <EvenementBtns2 optionsVisibles={!isMap ? showOptions : false} className = "right-5 top-2 w-4 h-4"
      lienVisible={lienVisible}  placeId={author.place_id} placeNom={displayName} latitude={author.lat} longitude={author.longi} lienItineraire={lienItineraire} index={index || '' }/>
      )}
      {avatar !== '' && (
      <Avatar
        sizeClass={`w-${isMap ? '10' : '10'} h-${isMap ? '10' : '10'} text-2xl`}
        radius="rounded-full"
        imgUrl={photo}
        userName={displayName}
        onImageClick={cacheOptions}
      />
      )}
      <div className={`${avatar !== '' ? "mt-2" : "mt-5 md:mt-4"} w-full`}>
        
      <div className="flex items-center justify-center">
       {displayName !== '' && (
          <div className="w-full">
            <h2 className="champInputIntegre">
              <span>{displayName}</span>
            </h2>
          </div>
        )}  
        { isMap && displayName !== "" && (lat === "" || longi === "") && (
          <div className="mt-2 md:pr-4">
            <i className="las la-location-arrow text-rose-500" data-tooltip-id="myTooltip"
                                data-tooltip-content={t('coordonneesGPSAbsentes')}
                                data-tooltip-place="top"
                                onClick={(numDroit === 1 || numDroit === 0) ? () => alert("Pour placer ce lieu, veuillez passer en mode modification.") : undefined}
                              ></i>
                              <Tooltip id="myTooltip" style={{ zIndex: 9999 }}></Tooltip>
          </div>
          )}
        { isMap && displayName !== "" && lat !== "" && longi !== "" && (
          <div className="mt-2 md:pr-4">
            <i className="curseurPointeur las la-location-arrow text-green-500" data-tooltip-id="myTooltipLoc"
                                data-tooltip-content={t('cliquezCarte')}
                                data-tooltip-place="top"
                                onClick={onLocalisationClick}
                              ></i>
                              <Tooltip id="myTooltipLoc" style={{ zIndex: 9999 }}></Tooltip>
          </div>
          )}
          
        </div>
        {libelle2 !== '' && (
          <div
            className={`block mt-2 text-xxs text-neutral-500 dark:text-neutral-400`}
            style={{ whiteSpace: 'pre-line' }}
          >
            {libelle2}
          </div>
        )}
      </div>
      {!isMap && prix !== '' && Number(prix) !== 0 ? (
      <div className="py-1 px-2 mt-2 bg-neutral-100 dark:bg-neutral-800 rounded-full flex items-center justify-center ">
        <span className="text-xxs font-medium pt-[1px]">
          {prix}
        </span>
        <SymboleMonnaie symbole={symbole_monnaie}/>
     
        <span className="text-xs font-medium pt-[1px]">
          {paiement === 'paye' && "Payé "}
          {paiement === 'paye' && <i className='las la-check'></i>}
        </span>
      </div>
      ): null}
    </div>
  );
};

export default CardLieuIntegreBox;
