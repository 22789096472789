import React, { FC, useState } from "react";
import ButtonCircle from "shared/Button/ButtonCircle";
import rightImg from "images/hero-right-2.webp";
import NcImage from "shared/NcImage/NcImage";
import Input from "shared/Input/Input";
import { compteService } from '_services/compte.service';
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from 'react-i18next';

export interface SectionSubscribe2Props {
  className?: string;
}

const SectionSubscribe2: FC<SectionSubscribe2Props> = ({ className = "" }) => {
  const { t } = useTranslation();

  const [email, setEmail] = useState("");
  const [captchaToken, setCaptchaToken] = useState<string | null>(null);

  const validateEmail = (mail: string) => {
    //var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const re = /^(?=[a-zA-Z0-9._%+-]{1,64}@)([a-zA-Z0-9._%+-]+)@([a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
    return re.test(mail);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (!captchaToken) {
      alert(t('alertRecaptcha'));
      return;
    }
    if (validateEmail(email)) {
      try {
        await compteService.inscriptionNewsletter(email);
        alert(t('alertNewsletterOK'));
      } catch (error) {
        console.error("Erreur lors de l'inscription à la newsletter:", error);
        alert(t('alertErreurNewsletter'));
      }
    } else {
      alert(t('alertEmail'));
    }
  };

  const handleCaptchaChange = (token: string | null) => {
    setCaptchaToken(token);
  };

  return (
    <div
      className={`nc-SectionSubscribe2 relative flex flex-col lg:flex-row lg:items-center ${className}`}
      data-nc-id="SectionSubscribe2"
    >
      <div id="newsletter" className="flex-shrink-0 mb-10 lg:mb-0 lg:mr-10 lg:w-2/5 mt-10">
        <h2 className="font-semibold text-2xl lg:text-3xl">{t('inscriptionNewsletter')}</h2>
        <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
          {t('infoNewsletter')}
        </span>
        <form className="mt-10 relative max-w-sm" onSubmit={handleSubmit}>
          <Input
            required
            aria-required
            placeholder={`${t('votre')} email`}
            type="email"
            rounded="rounded-full"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <ButtonCircle
            type="submit"
            className="absolute transform top-1/2 -translate-y-1/2 right-[5px]"
          >
            <i className="las la-arrow-right text-xl"></i>
          </ButtonCircle>
        </form>
        <div className="md:ml-5 mt-5">
          <ReCAPTCHA
            sitekey="6Le6IGMaAAAAALCXjuRzgBKxIsm9cxa5akWkvExA" 
            onChange={handleCaptchaChange}
          />
        </div>
      </div>

      <div className="flex-grow">
        <NcImage src={rightImg} />
      </div>
    </div>
  );
};

export default SectionSubscribe2;
