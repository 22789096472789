import React, { FC, useEffect, useState } from "react";
import { voyageService } from '_services/voyage.service';
import { useNavigate } from "react-router-dom";

export interface NcSelectMonnaieProps {
  className?: string;
  defaultValue?: string;
  min?: number;
  max?: number;
  onChange: (value: string) => void;
  label?: string;
  desc?: string;
}

const NcSelectMonnaie: FC<NcSelectMonnaieProps> = ({
  className = "w-full",
  defaultValue,
  min = 0,
  max,
  onChange,
  label,
  desc,
}) => {
  const [value, setValue] = useState(defaultValue);
  const [monnaies, setMonnaies] = useState<any[]>([]);
  const navigate = useNavigate(); 

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  useEffect(() => {

    const fetchData = async () => {
      try {

        const response = await voyageService.getMonnaies();
        setMonnaies(response.data.monnaies);

      } catch (error: any) { 
    
        console.error("Error fetching data:", error);
        navigate('/');
      } 
    };
  
    fetchData();
    
  }, []);


  const renderLabel = () => {
    return (
      <div className="flex flex-col">
        <span className="font-medium text-neutral-800 dark:text-neutral-200">
          {label}
        </span>
        {desc && (
          <span className="text-xs text-neutral-500 dark:text-neutral-400 font-normal">
            {desc}
          </span>
        )}
      </div>
    );
  };

  return (
    <div
      className={`nc-NcInputNumber flex items-center justify-between space-x-5 ${className}`}
      data-nc-id="NcInputNumber"
    >
      {label && renderLabel()}

      <div
  className={`nc-NcInputNumber flex items-center justify-between 
  ${(monnaies.find(monn => monn.code_monnaie === value)?.symbole_monnaie.length > 2 
    && monnaies.find(monn => monn.code_monnaie === value)?.symbole_monnaie !== value) ? 'w-30' 
    : ( (monnaies.find(monn => monn.code_monnaie === value)?.symbole_monnaie !== value) ? 'w-29' 
      : 'w-27')
    }
  `}
>
        <select className="monnaie"
            onChange={(e) => {
              onChange(e.target.value);
            }}
            value={value}
            /*disabled={!isModifiable}*/
            >
             {
    monnaies && monnaies.length > 0 && (
      monnaies.map((monn: any) => (
        <option 
        key={monn.code_monnaie} 
        value={monn.code_monnaie}
      >
        {value === monn.code_monnaie ? (
          monn.symbole_monnaie !== monn.code_monnaie ? (
            `${monn.symbole_monnaie} - ${monn.code_monnaie}`
          ) : (
            monn.code_monnaie
          )
        ) : (
          monn.symbole_monnaie !== monn.code_monnaie ? (
            `${monn.libelle_monnaie} - ${monn.code_monnaie} - ${monn.symbole_monnaie}`
          ) : (
            `${monn.libelle_monnaie} - ${monn.code_monnaie}`
          )
      )}
      </option>
      ))
    )
  }
  </select>
      </div>
    </div>
  );
};

export default NcSelectMonnaie;
