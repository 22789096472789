import React, { FC, useEffect, useContext, useState } from "react";
import CommonLayout from "./CommonLayout";
import { Helmet } from "react-helmet";
import AppContext from 'AppContext';
import { useTranslation } from 'react-i18next';
import { InformationCookiesMarketing } from 'shared/InformationCookiesMarketing/InformationCookiesMarketing';


export interface ReservationTransportProps {
  className?: string;
}

const ReservationTransport: FC<ReservationTransportProps> = ({ className = "" }) => {
  const { t } = useTranslation();

  const { setVoyageInfo, setAfficheMenuMobile } = useContext(AppContext);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [cookiePartenaires, setCookiePartenaires] = useState<boolean>(true);

  // Fonction pour obtenir la valeur d'un cookie
  const getCookie = (name: string) => {
    const cookieValue = document.cookie
      .split('; ')
      .find(row => row.startsWith(name))
      ?.split('=')[1];
    return cookieValue || '';
  };

  useEffect(() => {
    setVoyageInfo(null);
    setAfficheMenuMobile(false);
  }, []);

  const handleInfoClick = () => {
    alert(
      t('infoKiwi')
    );
  };

useEffect(() => {
  const cookieValue = getCookie("tripkygoPartenaires");

  // Si le cookie existe et a la valeur 'O', met à jour isLiked à true
  if (cookieValue && cookieValue === 'O') {

    setCookiePartenaires(true);

  const script = document.createElement('script');
  script.src = 'https://widgets.kiwi.com/scripts/widget-search-iframe.js';
  script.async = true;

  script.dataset.passengers = '1-0-0';
  script.dataset.affilid = 'tripkygowidget';
  script.dataset.from = 'PARIS';
  script.dataset.to = '';
  script.dataset.departure = ''; //'2024-08-25';
  script.dataset.cabin = 'ECONOMY';
  script.dataset.transportTypes = 'BUS,TRAIN';
  script.dataset.stopNumber = '#0';
  script.dataset.limit = '500';
  script.dataset.primaryColor = '#ff4a52';
  script.dataset.backgroundPrimaryColor = '#ffffff';
  script.dataset.backgroundSecondaryColor = 'ffffff';
  script.dataset.foregroundPrimaryColor = '040e27';
  script.dataset.foregroundSecondaryColor = '4d2419';

  const widgetContainer = document.getElementById('widget-holder');
  if (widgetContainer) {
    widgetContainer.appendChild(script);
  }

  return () => {
    if (widgetContainer) {
      widgetContainer.removeChild(script);
    }
  };
}
else
{
  setCookiePartenaires(false);
}
  
}, [])

  return (
    <div className={`nc-ReservationTransport ${className}`} data-nc-id="ReservationTransport">
      <Helmet>
        <title>{t('reservation')}</title>
      </Helmet>
      <CommonLayout>
        <div className="text-center">
           <h1 className="font-medium leading-6 text-gray-900 dark:text-neutral-300 mb-6">
                  {t('reserverTransports')} <a href='https://www.kiwi.com/deep?affilid=tripkygolink&currency=EUR&lang=fr' rel="noreferrer" target="_blank"><u>Kiwi</u></a>
                  <sup>
                    <button type="button" onClick={handleInfoClick}>
                      <i className="las la-info"></i>
                    </button>
                  </sup>
                </h1>
            
                {!cookiePartenaires && (
            <InformationCookiesMarketing/>
          )}

          <div id="widget-holder" style={{ height: '100%' }}></div> 
               
        </div>
      </CommonLayout>
    </div>
  );
};

export default ReservationTransport;
