import React, { FC, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import { useTranslation } from 'react-i18next';

export interface ModalStatistiquesProps {
  onStatistiquesCloseModal: () => void;
  nbEtapes: string,
  budget: string,
  nbJours: number,
  monnaie: string,
  distance: string,
  distanceAvion: string,
  distanceBateau: string,
  distanceTrain: string,
  distanceBus: string,
  distanceTaxi: string,
  distanceVoiture: string,
  distanceMoto: string,
  distanceVelo: string,
  distancePied: string
}

const ModalStatistiques: FC<ModalStatistiquesProps> = ({
  onStatistiquesCloseModal,
  nbEtapes,
  budget,
  nbJours,
  monnaie,
  distance,
  distanceAvion,
  distanceBateau,
  distanceTrain,
  distanceBus,
  distanceTaxi,
  distanceVoiture,
  distanceMoto,
  distanceVelo,
  distancePied
}) => {

  const { t } = useTranslation();

  const modalRef = useRef(null);

  const handleCloseModalStatistiques = () => {
      onStatistiquesCloseModal();

  };
    return (
    <Transition appear show={true}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        onClose={handleCloseModalStatistiques}
        ref={modalRef}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
          </Transition.Child>

          <span className="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>

          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block py-8 w-full md:w-3/4 lg:w-2/3 xl:w-1/2">
                <div className="inline-flex pb-2 flex-col w-full md:w-3/4 lg:w-2/3 xl:w-3/5 text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <h3
                      className="text-lg font-medium leading-6 text-gray-900 dark:text-neutral-200"
                      id="headlessui-dialog-title-70"
                    >
                      {t('statistiques')}
                    </h3>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={handleCloseModalStatistiques} />
                    </span>
                  </div>
                  <div className="px-8 text-lg overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
                      <div className="mt-5 w-full text-center">
                        <i className="las la-calendar-alt"></i> <span className="couleurRose">{nbJours}</span> {t('jour')}s
                        &nbsp;&nbsp;&nbsp;
                        <i className="las la-map-marker"></i> <span className="couleurRose">{nbEtapes}</span> {t('etape')}s 
                      <br/><br/>
                        <i className="las la-wallet"></i> <span className="couleurRose">{budget}</span>&nbsp;<span>{monnaie}</span>{t('jourPersonne')}
                      </div>
                      <div className="items-center mt-5 py-2.5 sm:py-4 lg:py-5 space-x-5 lg:space-x-8">
                         <div>
                           <div className="text-center">
                            <i className="las la-ruler"></i> <span className="couleurRose">{distance}</span> {t('kmParcourus')} 
                          {(Number(distanceAvion) !== 0
                        || Number(distanceBateau) !== 0
                        || Number(distanceTrain) !== 0
                        || Number(distanceBus) !== 0
                        || Number(distanceTaxi) !== 0
                        || Number(distanceVoiture) !== 0
                        || Number(distanceMoto) !== 0
                        || Number(distanceVelo) !== 0 
                        || Number(distancePied) !== 0) && ( 
                            <span> {t('dont')} </span>
                          )}
                          </div>
                          <div className="mt-5 text-center">
                         {Number(distanceAvion) !== 0 && (
                            <div>
                              <i className="las la-plane"></i> {distanceAvion} km
                            </div>
                          )}
                          {Number(distanceBateau) !== 0 && (
                            <div>
                              <i className="las la-ship"></i> {distanceBateau} km
                            </div>
                          )}
                          {Number(distanceTrain) !== 0 && (
                            <div>
                              <i className="las la-train"></i> {distanceTrain} km
                            </div>
                          )}
                          {Number(distanceBus) !== 0 && (
                            <div>
                              <i className="las la-bus"></i> {distanceBus} km
                            </div>
                          )}
                          {Number(distanceTaxi) !== 0 && (
                            <div>
                              <i className="las la-taxi"></i> {distanceTaxi} km
                            </div>
                          )}
                          {Number(distanceVoiture) !== 0 && (
                            <div>
                              <i className="las la-car"></i> {distanceVoiture} km
                            </div>
                          )}
                          {Number(distanceMoto) !== 0 && (
                            <div>
                              <i className="las la-motorcycle"></i> {distanceMoto} km
                            </div>
                          )}
                          {Number(distanceVelo) !== 0 && (
                            <div>
                              <i className="las la-bicycle"></i> {distanceVelo} km
                            </div>
                          )}
                          {Number(distancePied) !== 0 && (
                            <div>
                              <i className="las la-walking"></i> {distancePied} km
                            </div>
                          )}
                          </div>
                        </div>  
                      </div>
                  </div>
                </div>
              </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ModalStatistiques;
