import { MapPinIcon } from "@heroicons/react/24/outline";
import React, { useState, useRef, useEffect, FC } from "react";
import ClearDataButton from "./ClearDataButton";
import {apiKeyGeoapify} from '_config/constantes'; 
import { useTranslation } from 'react-i18next';

export interface VilleInputProps {
  placeHolder?: string;
  desc?: string;
  className?: string;
  divHideVerticalLineClass?: string;
  autoFocus?: boolean;
  onSelectDestination?: (codePays: string, nom: string, ville: string, place_id: string, latitude: string, longitude: string) => void;
}

export type Destination = {
  code_pays: string;
  pays: string;
  ville: string;
  place_id: string,
  latitude: string,
  longitude: string,
};

type Ville = {
  country_code: string;
  country: string;
  village: string;
  city: string;
  name: string;
  state: string;
  county: string;
  address_line1: string;
  address_line2: string;
  place_id: string;
  latitude: string;
  longitude: string;
  result_type: string;
};


const VilleInput: FC<VilleInputProps> = ({
  autoFocus = false,
  placeHolder = "",
  desc = "",
  className = "nc-flex-1.5",
  divHideVerticalLineClass = "left-10 -right-0.5",
  onSelectDestination,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [value, setValue] = useState("");
  const [showPopover, setShowPopover] = useState(autoFocus);
  const [searchResults, setSearchResults] = useState<any[]>([]);

  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

  const { t } = useTranslation();

  useEffect(() => {
    setShowPopover(autoFocus);
  }, [autoFocus]);

  useEffect(() => {
    if (eventClickOutsideDiv) {
      document.removeEventListener("click", eventClickOutsideDiv);
    }
    showPopover && value && document.addEventListener("click", eventClickOutsideDiv);
    return () => {
      document.removeEventListener("click", eventClickOutsideDiv);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showPopover]);

  useEffect(() => {
    if (showPopover && inputRef.current) {
      inputRef.current.focus();
    }
  }, [showPopover]);

  useEffect(() => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    if (value && value.length > 1) {
      const id = setTimeout(() => {
        fetchSearchResults();
      }, 600);

      setTimeoutId(id);
    } else {
      setSearchResults([]);
    }

    // Cleanup function to cancel the timeout if component unmounts or `value` changes
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [value]);

  const eventClickOutsideDiv = (event: MouseEvent) => {
    if (!containerRef.current) return;
    if (!showPopover || containerRef.current.contains(event.target as Node)) {
      return;
    }
    setShowPopover(false);
  };

  const handleSelectLocation = (item: Ville) => {
    var ville = '';
    if (onSelectDestination) {
      var codePays = item.country_code;
      var pays = item.country;
      var etat = '';

      if (item.village !== undefined && item.village !== null) {
        ville = item.village;
      }
      else if (item.city !== undefined && item.city !== null) {
        ville = item.city;
      }
      else if (item.name !== undefined && item.name !== null) {
        ville = item.name;
      }
      else if (item.result_type === 'state') {
        ville = item.state;
      }
      else if (item.result_type === 'county') {
        ville = item.county;
      }
      else {
        ville = 'Lieu inconnu';
      }

      if (codePays === 'gb') {
        if (item.state !== null) {
          etat = item.state;

          switch (etat) {
            case 'Angleterre':
              codePays = 'ww';
              pays = etat;
              break;
            case 'Écosse':
              codePays = 'xx';
              pays = etat;
              break;
            case 'Irlande du Nord':
              codePays = 'yy';
              pays = etat;
              break;
            case 'Pays de Galles':
              codePays = 'zz';
              pays = etat;
              break;
            default:
          }
        }
      }
      else if (codePays === 'fr') {
        if (item.state !== null) {
          etat = item.state;

          switch (etat) {
            case 'Guadeloupe':
              codePays = 'gp';
              pays = etat;
              break;
            case 'Martinique':
              codePays = 'mq';
              pays = etat;
              break;
            case 'Réunion':
              codePays = 're';
              pays = etat;
              break;
            case 'Mayotte':
              codePays = 'yt';
              pays = etat;
              break;
            case 'Guyane':
              codePays = 'gf';
              pays = etat;
              break;
            case 'Guyane Française':
              codePays = 'gf';
              pays = etat;
              break;
            case 'Polynésie Française':
              codePays = 'pf';
              pays = etat;
              break;
            case 'Terres Australes Françaises':
              codePays = 'tf';
              pays = etat;
              break;
            case 'Saint-Pierre-et-Miquelon':
              codePays = 'pm';
              pays = etat;
              break;
            case 'Wallis et Futuna':
              codePays = 'wf';
              pays = etat;
              break;
            default:
          }
        }
      }


      onSelectDestination(codePays, pays, ville, item.place_id, item.latitude, item.longitude);
    }
    setValue(ville); // Écrase la valeur précédente de `value` avec la nouvelle ville sélectionnée
    setSearchResults([]);
    setShowPopover(false);
  };

  const fetchSearchResults = async () => {
    try {

      const response = await fetch(`https://api.geoapify.com/v1/geocode/autocomplete?text=${value}&lang=fr&limit=7&apiKey=${apiKeyGeoapify}&type=city`);
      const data = await response.json();


      const formattedResults: Ville[] = data.features.map((feature: any) => ({

        country_code: feature.properties?.country_code,
        country: feature.properties?.country,
        village: feature.properties?.village,
        city: feature.properties?.city,
        name: feature.properties?.name,
        state: feature.properties?.state,
        county: feature.properties?.county,
        address_line1: feature.properties?.address_line1,
        address_line2: feature.properties?.address_line2,
        place_id: feature.properties?.place_id,
        latitude: feature.properties?.lat,
        longitude: feature.properties?.lon,
        result_type: feature.properties?.result_type,
      }));

      setSearchResults(formattedResults);
    } catch (error) {
      console.error("Erreur lors de la récupération des résultats de recherche :", error);
    }
  };

  const renderSearchResults = () => {
    return (
      <>
        {searchResults.map((item) => {
     
          const address_line1 = item.address_line1 || "";
          const address_line2 = item.address_line2 || "";
          return (
            <span
              onClick={() => handleSelectLocation(item)}
              key={((item as unknown) as { code_pays: string }).code_pays}
              className="flex px-4 sm:px-8 items-center space-x-3 sm:space-x-4 py-4 hover:bg-neutral-100 dark:hover:bg-neutral-700 cursor-pointer"
            >
              <span className="block text-neutral-400">
                <MapPinIcon className="h-4 w-4 sm:h-6 sm:w-6" />
              </span>
              <span className="block font-medium text-neutral-700 dark:text-neutral-200 self-end">
                {address_line1}
              </span>
              {address_line2 && (
                <span className="block text-sm text-neutral-400 dark:text-neutral-500 self-end">
                  {address_line2}
                </span>
              )}
            </span>
          );
        })}
      </>
    );
  };

  return (
    <div className={`relative flex ${className}`} ref={containerRef}>
      <div
        onClick={() => setShowPopover(true)}
        className={`flex z-10 flex-1 relative [ nc-hero-field-padding ] flex-shrink-0 items-center space-x-3 cursor-pointer focus:outline-none text-left  ${
          showPopover ? "nc-hero-field-focused" : ""
          }`}
      >
        <div className="text-neutral-300 dark:text-neutral-400">
          <MapPinIcon className="w-5 h-5 lg:w-7 lg:h-7" />
        </div>
        <div className="flex-grow">
          <input
            className={`block w-full bg-transparent border-none focus:ring-0 p-0 focus:outline-none focus:placeholder-neutral-300 xl:text-lg font-semibold placeholder-neutral-800 dark:placeholder-neutral-200 truncate`}
            placeholder={t('ville')}
            value={value}
            autoFocus={showPopover}
            onChange={(e) => {
              setValue(e.currentTarget.value);
            }}
            ref={inputRef}
          />
          <span className="block mt-0.5 text-sm text-neutral-400 font-light ">
            <span className="line-clamp-1">{!!value ? t('ville') : t('ouAllezVous')}</span>
          </span>
          {value && showPopover && (
            <ClearDataButton
              onClick={() => {
                setValue("");
              }}
            />
          )}
        </div>
      </div>

      {showPopover && value && (
        <div className={`h-8 absolute self-center top-1/2 -translate-y-1/2 z-0 bg-white dark:bg-neutral-800 ${divHideVerticalLineClass}`}></div>
      )}

      {showPopover && value && (
        <div className="absolute left-0 z-40 w-full min-w-[300px] max-h-60 bg-white dark:bg-neutral-800 mt-20 rounded-[12px] shadow-xl overflow-hidden">
          {renderSearchResults()}
        </div>
      )}
    </div>
  );
};

export default VilleInput;
