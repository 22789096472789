import React, { Fragment, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import { FC } from "react";
import ClearDataButton from "./ClearDataButton";
import ButtonSubmit from "./ButtonSubmit";
import { UserPlusIcon } from "@heroicons/react/24/outline";
import { GuestsObject } from "./type";
import NcInputNumber from "components/NcInputNumber/NcInputNumber";
import { Destination } from "./PaysInput"
import { useNavigate } from "react-router-dom";
import { voyageService } from '_services/voyage.service'
import { useTranslation } from 'react-i18next';


export interface GuestsInputProps {
  fieldClassName?: string;
  className?: string;
  hasButtonSubmit?: boolean;
  selectedDestinations: Destination[];
  startDate: Date | null;
  endDate: Date | null;
  checkboxBooking?: boolean | null;
  onChangeLoading?: () => void;
}
const GuestsInput: FC<GuestsInputProps> = ({
  fieldClassName = "[ nc-hero-field-padding ]",
  className = "[ nc-flex-1 ]",
  hasButtonSubmit = true,
  selectedDestinations = [],
  startDate,
  endDate,
  checkboxBooking = false,
  onChangeLoading
}) => {
  const [guestAdultsInputValue, setGuestAdultsInputValue] = useState(1);
  const [guestChildrenInputValue, setGuestChildrenInputValue] = useState(0);
  const [guestInfantsInputValue, setGuestInfantsInputValue] = useState(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const isFirefox = /firefox/i.test(navigator.userAgent);

  const { t } = useTranslation();

  const handleChangeData = (value: number, type: keyof GuestsObject) => {

    let newValue = {
      guestAdults: guestAdultsInputValue,
      guestChildren: guestChildrenInputValue,
      guestInfants: guestInfantsInputValue,
    };
    if (type === "guestAdults") {
      setGuestAdultsInputValue(value);
      newValue.guestAdults = value;
    }
    if (type === "guestChildren") {
      setGuestChildrenInputValue(value);
      newValue.guestChildren = value;
    }
    if (type === "guestInfants") {
      setGuestInfantsInputValue(value);
      newValue.guestInfants = value;
    }
  };

  const handleButtonSubmit = (selectedDestinations: Destination[],
    guestChildrenInputValue: number,
    guestAdultsInputValue: number,
    guestInfantsInputValue: number,
    startDate: Date | null,
    endDate: Date | null,
    navigate: any) => {
  
      if(onChangeLoading)
      {
        onChangeLoading();
      }
  
    const id_pays = Array.isArray(selectedDestinations)
    ? selectedDestinations.map((destination) => destination.code_pays).join("_")
    : "";
    const libelles_pays = Array.isArray(selectedDestinations)
    ? selectedDestinations.map((destination) => destination.pays).join("_")
    : "";
    const ville = Array.isArray(selectedDestinations)
    ? selectedDestinations
        .map((destination) => destination.ville)
        .filter((ville) => ville) // Filtrer les valeurs vides ou falsy
        .join("_")
    : "";
  
    let latitude = "";
    let longitude = "";
    let place_id = "";
  
    if(ville !== "")
    {
      latitude = Array.isArray(selectedDestinations)
    ? selectedDestinations
        .map((destination) => destination.latitude)
        .filter((latitude) => latitude) // Filtrer les valeurs vides ou falsy
        .join("_")
    : "";
  
      longitude = Array.isArray(selectedDestinations)
    ? selectedDestinations
        .map((destination) => destination.longitude)
        .filter((longitude) => longitude) // Filtrer les valeurs vides ou falsy
        .join("_")
    : "";
  
      place_id = Array.isArray(selectedDestinations)
      ? selectedDestinations
          .map((destination) => destination.place_id)
          .filter((place_id) => place_id) // Filtrer les valeurs vides ou falsy
          .join("_")
      : "";
    }
  
    const nb_voyageurs = (
      guestChildrenInputValue + guestAdultsInputValue + guestInfantsInputValue
    ).toString();
  
    if(id_pays === "")
    {
if(onChangeLoading)
      {
        onChangeLoading();
      }
      alert(t('alertDestination'));
      return false;
    }
    if(startDate === null)
    {
if(onChangeLoading)
      {
        onChangeLoading();
      }
      alert(t('alertDateDeb'));
      return false;
    }
    else if(endDate === null)
    {
if(onChangeLoading)
      {
        onChangeLoading();
      }
      alert(t('alertDateFin'));
      return false;
    }
    else if(guestAdultsInputValue < 1)
    {
if(onChangeLoading)
      {
        onChangeLoading();
      }
      alert(t('alertNbVoyageurs'));
      return false;
    }
    
  
    const diffInTime = endDate.getTime() - startDate.getTime();
    const diffInDays = Math.ceil(diffInTime / (1000 * 3600 * 24));
   
    if(diffInDays+1 > 365 )
    {
      if(onChangeLoading)
      {
        onChangeLoading();
      }
      alert(t('alertNbJoursMax'));
      return false;
    }
    else if(diffInDays+1 <= 1 )
    {
      if(onChangeLoading)
      {
        onChangeLoading();
      }
      alert(t('alertNbJoursMin'));
      return false;
    }

    let valeurCheckboxBooking='';

    if(checkboxBooking)
    {
      valeurCheckboxBooking='X'
    }
    
      voyageService.creerVoyage({
        id_pays: id_pays,
        libelles_pays: libelles_pays,
        ville: ville || null,
        date_debut: startDate?.toLocaleDateString("fr-FR") || "",
        date_fin: endDate?.toLocaleDateString("fr-FR") || "",
        nb_voyageurs: nb_voyageurs,
        latitude: latitude || null,
        longitude: longitude || null,
        place_id: place_id || null,
        checkboxBooking: valeurCheckboxBooking || null
      })
        .then((response) => {
    if(onChangeLoading)
      {
        onChangeLoading();
      }

          const idVoyage = response.data.idVoyage;
          if (idVoyage && idVoyage !== 0) {
            
            if(checkboxBooking)
            { 
              let lienBooking='https://www.booking.com/';

              if(ville)
              {
                lienBooking+=`searchresults.html?latitude=${latitude}&longitude=${longitude}&utm_source=browser_redirect&aid=2040377`;
              }
              else
              {
                if(id_pays.split("_")[0] === 'ww')
                {
                  lienBooking+=`region/gb/england`;
                }
                else if(id_pays.split("_")[0] === 'xx')
                {
                  lienBooking+=`region/gb/scotland`;
                }
                else if(id_pays.split("_")[0] === 'yy')
                {
                  lienBooking+=`region/gb/northern-ireland`;
                }
                else if(id_pays.split("_")[0] === 'zz')
                {
                  lienBooking+=`region/gb/wales`;
                }
                else
                {
                  lienBooking+=`country/${id_pays.split("_")[0]}`;
                }
  
                lienBooking+='.fr.html?utm_source=browser_redirect&aid=2040377';
              }
              
              if (isSafari) {
                // Safari : On crée un lien dynamique pour ouvrir le planificateur dans un nouvel onglet
                const link = document.createElement('a');
                link.href = `${t('lienPlanificateur')}/${idVoyage}`;
                link.target = '_blank'; // Force l'ouverture dans un nouvel onglet
                document.body.appendChild(link); // Ajoute le lien au DOM
                link.click(); // Simule un clic sur le lien pour ouvrir le planificateur
            
                // Ensuite, redirige vers Booking.com dans le même onglet
                window.location.href = lienBooking;
              } else if (isFirefox) {

              // Rafraichir page avec Booking.com
              window.location.href = lienBooking;

              setTimeout(() => {
                window.open(`${t('lienPlanificateur')}/${idVoyage}`, '_blank');
              }, 100); 
             }
             else
             {

               window.open(`${t('lienPlanificateur')}/${idVoyage}`, '_blank');

                // Rafraichir page avec Booking.com
                window.location.href = lienBooking;
              
                
             }
            }
            else
            {
              
              // Redirect to the specific planificateur-voyage page with idVoyage
             navigate(`${t('lienPlanificateur')}/${idVoyage}`);
            }

          } else {
            // Gérer le cas où idVoyage est 0 ou null
          }
        })
        .catch((error) => {
    if(onChangeLoading)
      {
        onChangeLoading();
      }
          // Gérer les erreurs de la requête
          console.error("erreur recup voyage");
          console.error(error);
        });
  };
  

  const totalGuests =
    guestChildrenInputValue + guestAdultsInputValue + guestInfantsInputValue;

  const navigate = useNavigate(); 

  return (
    <Popover className={`flex relative ${className}`}>
      {({ open }) => (
        <>
          <div
            className={`flex-1 z-10 flex items-center focus:outline-none ${
              open ? "nc-hero-field-focused" : ""
            }`}
          >
            <Popover.Button
              className={`relative z-10 flex-1 flex text-left items-center ${fieldClassName} space-x-3 focus:outline-none`}
              onClickCapture={() => document.querySelector("html")?.click()}
            >
              <div className="text-neutral-300 dark:text-neutral-400">
                <UserPlusIcon className="w-5 h-5 lg:w-7 lg:h-7" />
              </div>
              <div className="flex-grow">
                <span className="block xl:text-lg font-semibold">
                  {totalGuests || ""} 
                </span>
                <span className="block mt-1 text-sm text-neutral-400 leading-none font-light">
                  {totalGuests === 1
                  ? t('voyageur')
                  : totalGuests > 1
                  ? t('voyageur')+"s"
                  : t('ajouterVoyageurs')}
                </span>
              </div>

              {!!totalGuests && open && (
                <ClearDataButton
                  onClick={() => {
                    setGuestAdultsInputValue(0);
                    setGuestChildrenInputValue(0);
                    setGuestInfantsInputValue(0);
                  }}
                />
              )}
            </Popover.Button>

            {/* BUTTON SUBMIT OF FORM */}
            {hasButtonSubmit && (
              <div className="pr-2 xl:pr-4">
                <ButtonSubmit
                  onClick={() => {
                    handleButtonSubmit(selectedDestinations, guestChildrenInputValue, guestAdultsInputValue, guestInfantsInputValue, startDate, endDate, navigate);
                  }}
                />

              </div>
            )}
          </div>

          {open && (
            <div className="h-8 absolute self-center top-1/2 -translate-y-1/2 z-0 -left-0.5 right-0 bg-white dark:bg-neutral-800"></div>
          )}
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute right-0 z-10 w-full sm:min-w-[340px] max-w-sm bg-white dark:bg-neutral-800 top-full mt-3 py-5 sm:py-6 px-4 sm:px-8 rounded-3xl shadow-xl">
              <NcInputNumber
                className="w-full"
                defaultValue={guestAdultsInputValue}
                onChange={(value) => handleChangeData(value, "guestAdults")}
                max={10}
                min={1}
                label={t('nbVoyageurs')}
              />
            {/* <NcInputNumber
                className="w-full mt-6"
                defaultValue={guestChildrenInputValue}
                onChange={(value) => handleChangeData(value, "guestChildren")}
                max={4}
                label="Children"
                desc="Ages 2–12"
              />

              <NcInputNumber
                className="w-full mt-6"
                defaultValue={guestInfantsInputValue}
                onChange={(value) => handleChangeData(value, "guestInfants")}
                max={4}
                label="Infants"
                desc="Ages 0–2"
            />*/}
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default GuestsInput;
