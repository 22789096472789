import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from 'react-i18next';
import { InformationCookiesMarketing } from 'shared/InformationCookiesMarketing/InformationCookiesMarketing';
import ButtonSecondary from "shared/Button/ButtonSecondary";

declare var Booking: any;

export interface ReservationProps {
  className?: string;
}

const Reservation: FC<ReservationProps> = ({ className = "" }) => {
  const { t } = useTranslation();

  const [cookiePartenaires, setCookiePartenaires] = useState<boolean>(true);

  const [menuClicked, setMenuClicked] = useState<string>("hebergement");

  // Fonction pour obtenir la valeur d'un cookie
  const getCookie = (name: string) => {
    const cookieValue = document.cookie
      .split('; ')
      .find(row => row.startsWith(name))
      ?.split('=')[1];
    return cookieValue || '';
  };
  
  const handleInfoClick = () => {
    alert(
      t('infoBooking')
    );
  };

  useEffect(() => {
    const cookieValue = getCookie("tripkygoPartenaires");

    // Si le cookie existe et a la valeur 'O', met à jour isLiked à true
    if (cookieValue && cookieValue === 'O') {

      setCookiePartenaires(true);

      const script = document.createElement('script');

      if(menuClicked === "hebergement")
      {
        script.src = 'https://www.booking.com/affiliate/prelanding_sdk';
        script.onload = () => {
          if (typeof Booking !== 'undefined' && Booking.AffiliateWidget) {
            new Booking.AffiliateWidget({
              iframeSettings: {
                selector: 'bookingAffiliateWidget_755a63ab-c7b3-4279-a5c9-584b78bd4b18',
                responsive: true,
              },
              widgetSettings: {
                ss: "Paris, Île-de-France, France",
                latitude: 48.8566,
                longitude: 2.3522,
                checkin: "",
                checkout: "",
                zoom: 9,
              },
            });
          } else {
            console.error("Le script du widget affilié Booking n'a pas été chargé ou est indéfini.");
          }
        };
      }
      else if(menuClicked === "vol")
      {
          script.src = 'https://www.booking.com/affiliate/prelanding_sdk';
          script.onload = () => {
            if (typeof Booking !== 'undefined' && Booking.AffiliateWidget) {
              new Booking.AffiliateWidget({
                iframeSettings: {
                  selector: 'bookingAffiliateWidget_edb02e38-7ca4-4d1f-9477-d75e107f0c37',
                  responsive: true,
                },
              widgetSettings: {
                
                },
              });
              
        } else {
          console.error("Le script du widget affilié Booking n'a pas été chargé ou est indéfini.");
        }
        };
      }
      else if(menuClicked === "voiture")
      {
        
    const existingScript = document.getElementById('dchwidget');
    if (existingScript) {
      existingScript.remove();
    }

        const script = document.createElement('script');
        script.id = 'dchwidget';
        script.src = 'https://www.discovercars.com/wg.js';
        script.async = true;

        script.dataset.devEnv = 'com';
        script.dataset.location = `france/paris`;
        script.dataset.lang = 'fr';
        script.dataset.utmSource = 'tripkygo';
        script.dataset.utmMedium = 'widget';
        script.dataset.affCode = 'tripkygo';
        script.dataset.autocomplete = 'on';
        script.dataset.styleSubmitBgColor = '#FF4A52';
        script.dataset.styleSubmitFontColor = '#FFFFFF';
        script.dataset.styleFormBgColor = '#f2f2f2';
        script.dataset.styleFormFontColor = '#000000';
        script.dataset.styleSubmitText = 'Rechercher';

        const widgetContainer = document.getElementById('discoverCarsWidgetContainer');
        if (widgetContainer) {
          widgetContainer.appendChild(script);
        }

        return () => {
          if (widgetContainer) {
            widgetContainer.removeChild(script);
          }
        };
          }
     
  
      document.body.appendChild(script);
  
      return () => {
        document.body.removeChild(script);
      }; 
    } else {
      setCookiePartenaires(false);
    }
  }, [menuClicked]);

  return (
    <div className={`nc-Reservation ${className}`} data-nc-id="Reservation" id="divReservation">
      <Helmet>
        <title>{t('reservation')}</title>
      </Helmet>
        <div className="text-center" >
          
        <ButtonSecondary
         sizeClass="px-2 py-2 sm:px-2"
         fontSize = "text-sm sm:text-sm font-medium" 
          onClick={() => setMenuClicked("hebergement")}>
              <i className="las la-bed text-md"></i>
                <span className="ml-1">{t('hebergement')}</span>
        </ButtonSecondary>
        &nbsp;
        <ButtonSecondary
         sizeClass="px-2 py-2 sm:px-2"
         fontSize = "text-sm sm:text-sm font-medium"
         onClick={() => setMenuClicked("vol")}>  
              <i className="las la-plane text-md"></i>
                <span className="ml-1">{t('vol')}</span>
        </ButtonSecondary>
        &nbsp;
        <ButtonSecondary
         sizeClass="px-2 py-2 sm:px-2"
         fontSize = "text-sm sm:text-sm font-medium"
         onClick={() => setMenuClicked("voiture")}>  
              <i className="las la-car text-md"></i>
                <span className="ml-1">{t('voiture')}</span>
        </ButtonSecondary>
        &nbsp;
        <ButtonSecondary
         sizeClass="px-2 py-2 sm:px-2"
         fontSize = "text-sm sm:text-sm font-medium"
         onClick={() => setMenuClicked("activite")}>  
              <i className="las la-landmark text-md"></i>
                <span className="ml-1">{t('activite')}</span>
        </ButtonSecondary>
        <br/><br/>
           { menuClicked === "hebergement" && (
            <>
            <h1 className="font-medium leading-6 text-gray-900 dark:text-neutral-300 mb-6 relative">
            {t('reserverHotels')} <a href='https://www.booking.com/index.html?aid=2040377' rel="noreferrer" target="_blank"><u>Booking.com</u></a>
            <sup>
              <button type="button" onClick={handleInfoClick}>
                <i className="las la-info"></i>
              </button>
            </sup>
          </h1>
          {!cookiePartenaires && (
           <InformationCookiesMarketing/>
          )}
           <div id="bookingAffiliateWidget_755a63ab-c7b3-4279-a5c9-584b78bd4b18"></div>
           </>
          )}
          { menuClicked === "vol" && (
            <>
            <h1 className="font-medium leading-6 text-gray-900 dark:text-neutral-300 mb-6 relative">
            {t('reserverAvions')} <a href='https://www.booking.com/index.html?aid=2040377' rel="noreferrer" target="_blank"><u>Booking.com</u></a>
            <sup>
              <button type="button" onClick={handleInfoClick}>
                <i className="las la-info"></i>
              </button>
            </sup>
          </h1>
          {!cookiePartenaires && (
           <InformationCookiesMarketing/>
          )}
           <div id="bookingAffiliateWidget_edb02e38-7ca4-4d1f-9477-d75e107f0c37">&nbsp;</div>
           </>
          )}

          { menuClicked === "activite" && (
            <>
            <h1 className="font-medium leading-6 text-gray-900 dark:text-neutral-300 mb-6 relative">
             {t('cliquerActivite')} <a href='https://www.getyourguide.fr/?partner_id=SX74NSV' rel="noreferrer" target="_blank"><u>GetYourGuide</u></a>
             <sup>
               <button type="button" onClick={handleInfoClick}>
                 <i className="las la-info"></i>
               </button>
             </sup>
           </h1>
                      
           { cookiePartenaires ? (
               <div className="px-8 mb-5 pt-2 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
            
                <iframe
                loading="lazy"
                src={`https://widget.getyourguide.com/default/activities.frame?locale_code=fr-FR&widget=activities&number_of_items=20&partner_id=SX74NSV&website=http://tripkygo.com`}
                frameBorder="0"
                scrolling="yes"
                title="GetYourGuide Widget"
                width="100%"
                height="800px"
                className="relative"
              ></iframe>
            </div>
              ) : (
                <InformationCookiesMarketing/>
              
              
              )}
              </>
          )}
            { menuClicked === "voiture" && (
              <>
                <h1 className="font-medium leading-6 text-gray-900 dark:text-neutral-300 mb-6 relative">
           {t('louerVoitureAvec')} <a href='https://www.discovercars.com/?a_aid=tripkygo' rel="noreferrer" target="_blank"><u>DiscoverCars</u></a>
                  <sup>
                    <button type="button" onClick={handleInfoClick}>
                      <i className="las la-info"></i>
                    </button>
                  </sup>
                </h1>
            
                <div id="discoverCarsWidgetContainer" className="relative"> </div> {/* Conteneur du widget */}

            { !cookiePartenaires && (
                <InformationCookiesMarketing/>
            )}

              </>
              )}

        </div>
    </div>
  );
};

export default Reservation;
