import React, { Fragment, useEffect, useState, FC } from "react";
import { Dialog, Transition } from "@headlessui/react";
import ButtonClose from 'shared/ButtonClose/ButtonClose';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import ButtonCircleSmall from "shared/Button/ButtonCircleSmall";
import ModalPartage from 'components/ModalPartage';
import { voyageService } from '_services/voyage.service';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { accountService } from '_services/account.service';

export interface ModalCreateursProps {
  onClose: () => void;
  keyVoyage: number;
  deleteCoCreateur: (identifiant: any) => void;
}

const ModalCreateurs: FC<ModalCreateursProps> = ({ onClose, keyVoyage, deleteCoCreateur }) => {

  const { t } = useTranslation();
  
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState<string>("");
  const [cocreateurs, setCocreateurs] = useState<any>(null);

  const [isModalPartageOpen, setIsModalPartageOpen] = useState(false);

  let navigate = useNavigate();

  const closeModal = () => {
    if (onClose) {
      onClose();
    }
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  }

  const handleEnvoyerDemande = () => {
    console.log("email",email);
    console.log("validateEmail(email)",validateEmail(email));
   if (email === "" || !validateEmail(email)) {
      alert("Vous devez saisir une adresse email valide !");
    } else {
      setIsLoading(true);
      voyageService.ajouterCoCreateurVoyage(keyVoyage, {
        email: email
      })
        .then((response: any) => {
          setIsLoading(false); // Arrêter le chargement
          closeModal();
          alert("La demande d'ajout a été envoyée !")
        })
        .catch((error: any) => {
          setIsLoading(false); // Arrêter le chargement
          if (error.response?.status === 401) {
            alert("Erreur lors de l'ajout du créateur, vous devez être connecté.");
            navigate("/login");
          } else if (error.response?.status === 406 && error.response.data.message !== undefined) {
            console.log(error.response);
            alert(error.response.data.message);
          }
          else {
            alert("Erreur lors de l'ajout du créateur.");
          }
          console.log('ajout KO', error);
        });
    }
  };


  const handleSupprimeCocreateur = (index: number) => {
    if(window.confirm(
      t('alertSupprimerCoCreateur')+cocreateurs.rowCoCreateurs[index].username+' ?'
    ))
    {
       voyageService.supprimerCoCreateurVoyage(keyVoyage, {
         identifiant: cocreateurs.rowCoCreateurs[index].id_utilisateur
       })
         .then((response: any) => {
          deleteCoCreateur(cocreateurs.rowCoCreateurs[index].id_utilisateur);
            // Créez une nouvelle liste de demandes en excluant l'élément à l'index
          const nouveauxCoCreateurs = cocreateurs.rowCoCreateurs.filter((_: any, i: any) => i !== index);

          // Mettez à jour l'état avec la nouvelle liste de demandes
          setCocreateurs((prevState: any) => ({
            ...prevState,
            rowCoCreateurs: nouveauxCoCreateurs,
          }));
         })
         .catch((error: any) => {
           if (error.response?.status === 401) {
             alert("Erreur lors de la suprression du cocréateur, vous devez être connecté.");
             navigate("/login");
           } else if (error.response?.status === 406 && error.response.data.message !== undefined) {
             console.log(error.response);
             alert(error.response.data.message);
           }
           else {
             alert("Erreur lors de la suppression du cocréateur.");
           }
           console.log('ajout KO', error);
         });
     }
   };

  useEffect(() => {
       voyageService.getCoCreateursVoyage(keyVoyage)
         .then((response: any) => {
           console.log("response.data", response.data)
           setCocreateurs(response.data);
         })
         .catch((error: any) => {
           setIsLoading(false); // Arrêter le chargement
           if (error.response?.status === 401) {
             alert("Erreur lors de la récupération des cocréateurs, vous devez être connecté.");
             navigate("/login");
           } else if (error.response?.status === 406 && error.response.data.message !== undefined) {
             console.log(error.response);
             alert(error.response.data.message);
           }
           else {
             alert("Erreur lors de la récupération des cocréateurs.");
           }
           console.log('ajout KO', error);
         });
    }, []);

  const validateEmail = (mail: string) => {
    //var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const re = /^(?=[a-zA-Z0-9._%+-]{1,64}@)([a-zA-Z0-9._%+-]+)@([a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
    return re.test(mail);
  }

  const openModalPartage = () => {
    if(accountService.isLogged())
    {
      setIsModalPartageOpen(true);
    }
    else
    {
      alert(t('alertPartage'));
    }
  };

  const closeModalPartage = () => {
    setIsModalPartageOpen(false);
  };

  return (
    <>
   <Transition appear show={true} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={closeModal}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0 scale-95"
            >
             <div className="inline-block py-8 w-full md:w-3/4 lg:w-2/3 xl:w-1/2">
                <div className="inline-flex pb-2 flex-col w-full md:w-3/4 lg:w-2/3 xl:w-3/5 text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <h3
                      className="text-lg font-medium leading-6 text-gray-900  dark:text-neutral-200"
                      id="headlessui-dialog-title-70"
                    >
                    {t('gestionCoCreateurs')}
                  </h3>
                  <span className="absolute left-3 top-3">
                    <ButtonClose onClick={closeModal} />
                  </span>
                </div>
                <div className="px-8 text-neutral-700 dark:text-neutral-300"> 
                {cocreateurs && cocreateurs.rowCoCreateurs && cocreateurs.rowCoCreateurs.length > 0 && (
                  <>
                  <div className="mt-5 flex justify-center"> 
                  <p className="text-sm">{t('coCreateurs')}</p>
                </div>
                {cocreateurs.rowCoCreateurs.map((cocreateur: any, index: number) => (
                  <div className="mt-1 flex justify-center"> 
                    <span className={`text-base font-medium w-full `}>
                    <ButtonCircleSmall size="w-6 h-6 sm:w-6 sm:h-6" onClick={() => handleSupprimeCocreateur(index)}><i className="text-lg las la-user-minus"></i></ButtonCircleSmall> {cocreateur.username}
                    </span>
                </div>
                ))}
                
                </>
                )}
                  
                </div>

              <div className="px-8 text-neutral-700 dark:text-neutral-300"> 
                    <div className="mt-5 flex justify-center"> 
                              <p className="text-sm">{t('questionAjoutCoCreateur')}</p>
                    </div>
                    <div className="mt-5 flex justify-center"> 
                        <h2 className={`text-base font-medium w-full `}>
                          <input
                            type="email"
                            onChange={handleEmailChange}
                            className="champAutocomplete"
                            placeholder={t('adresseEmail')}
                            maxLength={50}
                          />
                        </h2>
                    </div>
                   
                <div className="flex justify-center mt-5 mb-5 "> 
                  <ButtonPrimary onClick={handleEnvoyerDemande}>
                    {t('envoyerDemande')}
                  </ButtonPrimary>
                </div>
                <div className="mt-5 flex justify-center"> 
                              <p className="text-xxs pb-3"><i className="las la la-warning couleurRose text-sm texteGras"></i> Les co-créateurs ont accès à toutes les informations du voyage. Ils peuvent le modifier et le partager mais ils ne peuvent pas le supprimer ni ajouter d'autres co-créateurs.<br/>Si vous souhaitez donner un accès uniquement en consultation, utilisez plutôt la <span className="pointeurRose" onClick={openModalPartage}><u>fonctionnalité de partage</u></span>.</p>
                    </div>
              </div>
            </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>

{isLoading && (
  <Transition appear show={true} as={Fragment}>
    <Dialog
      as="div"
      className="fixed inset-0 z-[100] overflow-y-auto" // Plus élevé pour l'attente
      onClose={() => {}} // Pas de fermeture pour la modale d'attente
    >
      <div className="min-h-screen flex items-center justify-center px-4 text-center">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-60" />
        </Transition.Child>
        
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0 scale-95"
        >
          <div
            className="inline-block transition-all transform rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl px-8 py-4"
          >
            <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-neutral-200">
              {t('envoiDemandeCoCreateurs')}
            </h3>
          </div>
          
        </Transition.Child>
      </div>
    </Dialog>
  </Transition>
  
)}

{ isModalPartageOpen && (
        <ModalPartage  onClose={closeModalPartage} keyVoyage={keyVoyage}/>
      )}
</>
    
  );
};

export default ModalCreateurs;
