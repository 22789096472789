import React, { useEffect, useState } from "react";
import { FC } from "react";

export interface SymboleMonnaieProps {
  symbole: string;
}

const SymboleMonnaie: FC<SymboleMonnaieProps> = ({
 symbole
}) => {
  return (
    <span className="w-5 h-5 text-xs text-amber-500 ml-2 flex items-center justify-center border border-amber-500 rounded-full">
  { symbole && symbole.length === 3 
  ?  <span className="text-monnaie3c">{symbole}</span>
  :  <span className="text-monnaie1c">{symbole}</span>
}
</span>
  );
};

export default SymboleMonnaie;
