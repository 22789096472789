import React, { FC, useState, useEffect, useRef } from "react";
import { TrajetType, GeoapifyType, AeroportType, PecType, MonnaieType } from "data/types";
import { useNavigate } from "react-router-dom";
import Badge from "shared/Badge/Badge";
import EvenementBtnsModif from "components/EvenementBtnsModif";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import OutlinedInputClasses from '@mui/material/OutlinedInput/outlinedInputClasses'
import { format, parse } from "date-fns";
import { voyageService } from '_services/voyage.service'
import { distanceService } from '_services/distance.service'
import { autocompleteService } from '_services/autocomplete.service'
import CheckboxSmall from "shared/CheckboxSmall/CheckboxSmall";
import AutoCompleteList from "components/AutocompleteList";
import SelectPaiementHebergement from "shared/SelectPaiementHebergement/SelectPaiementHebergement";
import SymboleMonnaie from "components/SymboleMonnaie/SymboleMonnaie";
import { Tooltip }  from "react-tooltip";
import 'react-tooltip/dist/react-tooltip.css';
import {apiKeyGeoapify} from '_config/constantes';
import { useTranslation } from 'react-i18next'; 

export interface CardTrajetModifProps {
  className?: string;
  author: TrajetType;
  index: string;
  id: string;
  onSuppressionClick?: (typeEvenement: string, idEvenement: number) => void;
  idVoyage: string;
  id_pays: string;
  idJournee: number;
  onDisplayElementChange?: (journeeId: number, eventId: number, typeEvenement: string, valeur: string, typeElement:string) => void;
  onAddTrajetLendemain?: (journeeId: number) => void;
  onDeleteTrajetLendemain?: (journeeId: number, idTrajet: number) => void;
  listePecPlanificateur: PecType[];
  onDisplayPecChange: (idPec: string, idRest :string) => void;
  onChangeTypeLocation: (idRattach: string, nouvType :string) => void;
  onLienClick?: (typeEvenement: string, idEvenement: number) => void;
  onBlocNotesClick: (typeEvenement: string, idEvenement: number) => void;
  onCoordClick: (typeEvenement: string, idEvenement: number) => void;
  onConfirmClick: (typeEvenement: string, idEvenement: number) => void;
  onRechercheClick: (typeEvenement: string, idEvenement: number) => void;
  isMap?: boolean;
  onLocalisationClick?: (type: String) => void;
  onPlacementClick?: (type: String) => void;
  selected?: boolean;
  symbole_monnaie: string;
}

const CardTrajetModif: FC<CardTrajetModifProps> = ({
  className = "",
  author,
  index,
  id,
  onSuppressionClick,
  idVoyage,
  onDisplayElementChange,
  id_pays,
  idJournee,
  onAddTrajetLendemain,
  onDeleteTrajetLendemain,
  listePecPlanificateur,
  onDisplayPecChange,
  onChangeTypeLocation,
  onLienClick,
  onBlocNotesClick,
  onCoordClick,
  onConfirmClick,
  isMap,
  onLocalisationClick,
  onPlacementClick,
  onRechercheClick,
  selected,
  symbole_monnaie
}) => {
  const { i18n, t } = useTranslation();

  const langue = i18n.language;

  const { debut, fin, compagnie, ref, location } = author;
  const [editedTypeTransport, setEditedTypeTransport] = useState("");
  const [oldValeurInput, setOldValeurInput] = useState("");
  const [editedDisplayPrix, setEditedDisplayPrix] = useState("");
  const [oldDisplayPrix, setOldDisplayPrix] = useState("");
  const [editedPaiement, setEditedPaiement] = useState("");
  const [editedDistance, setEditedDistance] = useState("");
  const [editedDuree, setEditedDuree] = useState("");
  const [editedInvisible1, setEditedInvisible1] = useState("");
  const [editedInvisible2, setEditedInvisible2] = useState("");
  const [editedMoment, setEditedMoment] = useState("");
  const [oldMoment, setOldMoment] = useState("");
  const [editedRattachement, setEditedRattachement] = useState("");
  const [editedLocation, setEditedLocation] = useState("");
  const [editedDisplayDepart, setEditedDisplayDepart] = useState("");
  const [editedDisplayArrivee, setEditedDisplayArrivee] = useState("");
  const [suggestions, setSuggestions] = useState<GeoapifyType[]>([]);
  const [suggestionsArrivee, setSuggestionsArrivee ] = useState<GeoapifyType[]>([]);
  const [suggestionsAeroports, setSuggestionsAeroports] = useState<AeroportType[]>([]);
  const [suggestionsAeroportsArrivee, setSuggestionsAeroportsArrivee ] = useState<AeroportType[]>([]);
  const [isMouseOver, setIsMouseOver] = useState(false); // État pour suivre si la souris est sur la div
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalArriveeOpen, setIsModalArriveeOpen] = useState(false);
  const [lang, setLang] = useState<string>("fr");
  const [listePays, setListePays] = useState<string>("");
  const [oldDisplayDepart, setOldDisplayDepart] = useState("");
  const [oldDisplayArrivee, setOldDisplayArrivee] = useState("");

  const [editedLatitude1, setEditedLatitude1] = useState("");
  const [editedLongitude1, setEditedLongitude1] = useState("");
  const [editedLatitude2, setEditedLatitude2] = useState("");
  const [editedLongitude2, setEditedLongitude2] = useState("");

  const [isLibelleSaisi, setIsLibelleSaisi] = useState(false);

  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

  const [initialLoad, setInitialLoad] = useState(true);

  let navigate=useNavigate();

  // Fonction pour convertir la chaîne "hh:mm" en objet Date
  const parseTimeString = (timeString: string): Date | null => {
    if(timeString !== "")
    {
      const parsedTime = parse(timeString, "HH:mm", new Date());
      return parsedTime;
    }
    else{
      return null;
    }
  };

  const dateDeb = parseTimeString(debut);
  const dateFin = parseTimeString(fin);

  const suggestionSelectedRef = useRef(false);
  const suggestionArriveeSelectedRef = useRef(false);

  const handleMouseLeave = () => {
    setIsMouseOver(false);
    setIsModalOpen(false);
    setIsModalArriveeOpen(false);
  };

  const handleMouseEnter = () => {
    setIsMouseOver(true);
  };

  const handleFocusDepart = () => {
    suggestionSelectedRef.current = false;
    setOldDisplayDepart(editedDisplayDepart);
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
  
    if (editedDisplayDepart !== "" && editedDisplayDepart.length >2 ) {
      fetchAeroports(editedDisplayDepart, '');
      const id = setTimeout(() => {
        fetchSuggestions(editedDisplayDepart, '');
      }, 600);

      setTimeoutId(id);
    }
  };

  const handleFocusArrivee = () => {
    suggestionArriveeSelectedRef.current = false;
    setOldDisplayArrivee(editedDisplayArrivee);
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
  
    if (editedDisplayArrivee !== "" && editedDisplayArrivee.length >2 ) {
      fetchAeroports(editedDisplayArrivee, 'arrivee');
      const id = setTimeout(() => {
        fetchSuggestions(editedDisplayArrivee, 'arrivee');
      }, 600);

      setTimeoutId(id);
    }

  };

  const handleDisplayDepartChange = (event: React.ChangeEvent<HTMLInputElement>) => {

    setEditedDisplayDepart(event.target.value);

    if (timeoutId) {
      clearTimeout(timeoutId);
    }
  
    if (event.target.value !== "" && event.target.value.length > 2) {

      fetchAeroports(event.target.value, '');

      const id = setTimeout(() => {
        fetchSuggestions(event.target.value, '');
      }, 1000);
  
      setTimeoutId(id);
    } else {
      // Si la valeur est vide ou trop courte, effacer les suggestions
      setSuggestions([]);
      setSuggestionsAeroports([]);
    }
  };

  const handleDisplayArriveeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  
    setEditedDisplayArrivee(event.target.value);

    if (timeoutId) {
      clearTimeout(timeoutId);
    }
  
    if (event.target.value !== "" && event.target.value.length > 2) {

      fetchAeroports(event.target.value, 'arrivee');

      const id = setTimeout(() => {
        fetchSuggestions(event.target.value, 'arrivee');
      }, 1000);
  
      setTimeoutId(id);
    } else {
      // Si la valeur est vide ou trop courte, effacer les suggestions
      setSuggestionsArrivee([]);
      setSuggestionsAeroportsArrivee([]);
    }
  };

    
  useEffect(() => {
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [timeoutId]);

  const fetchAeroports = async (value: string, type: string) => {
    try {


      const response = await autocompleteService.getAeroport(value, langue.toUpperCase());
      const data = await response.data;


      if(type === '')
      {
        setSuggestionsAeroports(data);
        setIsModalOpen(true);
      }
      else
      {
        setSuggestionsAeroportsArrivee(data);
        setIsModalArriveeOpen(true);
      }
      

    } catch (error) {
      console.error("Erreur lors de la récupération des résultats de recherche :", error);
    }
  };


  const fetchSuggestions = async (value: string, type: string) => {


    const limit = 7; // Limite de suggestions

    var bias=listePays;
    if(listePays.length<14 && !listePays.includes("fr"))
    {
      bias+=",fr";
    }

    var complementUrl = `&bias=countrycode:${bias}`;

    if(listePays.length === 0)
    {
      complementUrl = "";
    }

    try {

      const response = await fetch(
        `https://api.geoapify.com/v1/geocode/autocomplete?text=${value}&lang=${lang}&limit=${limit}&apiKey=${apiKeyGeoapify}${complementUrl}`
      );

      if (response.ok) {
        const data = await response.json();

        if(type === '')
        {
          setSuggestions(data.features);
          setIsModalOpen(true);
        }
        else
        {
          setSuggestionsArrivee(data.features);
          setIsModalArriveeOpen(true);
        }
      }
    } catch (error) {
      console.error("Error fetching autocomplete suggestions:", error);
    }
  };

  const handleSelectSuggestion = (suggestion: GeoapifyType) => {
    suggestionSelectedRef.current = true;
    onPlaceChanged(suggestion, 'depart');
    setSuggestions([]);
    setSuggestionsAeroports([]);
  };
  
  const handleSelectSuggestionArrivee = (suggestion: GeoapifyType) => {
    suggestionArriveeSelectedRef.current = true;
    onPlaceChanged(suggestion, 'arrivee');
    setSuggestionsArrivee([]);
    setSuggestionsAeroportsArrivee([]);
  };

  
  const handleSelectSuggestionAeroport = (suggestion: AeroportType) => {
    suggestionSelectedRef.current = true;
    onPlaceChangedAeroport(suggestion, 'depart');
    setSuggestionsAeroports([]);
    setSuggestions([]);
  };
  
  const handleSelectSuggestionAeroportArrivee = (suggestion: AeroportType) => {
    suggestionArriveeSelectedRef.current = true;
    onPlaceChangedAeroport(suggestion, 'arrivee');
    setSuggestionsAeroportsArrivee([]);
    setSuggestionsArrivee([]);
  };

  function onPlaceChanged(place: GeoapifyType, type: string)
{
  var latitude = place.properties.lat;
  var longitude = place.properties.lon;

  var libellePlace="";

  if(place.properties.name !== undefined && place.properties.name !== null)
  {
    libellePlace=place.properties.name;
  }
  else if(place.properties.village !== undefined && place.properties.village !== null)
  {
    libellePlace=place.properties.village;
  }
  else if(place.properties.city !== undefined && place.properties.city !== null)
  {
    libellePlace=place.properties.city;
  }
  else if(place.properties.county !== undefined && place.properties.county !== null)
  {
    libellePlace=place.properties.county;
  }
  else if(place.properties.state !== undefined && place.properties.state !== null)
  {
    libellePlace=place.properties.state;
  }
  else if(place.properties.country !== undefined && place.properties.country !== null)
  {
    libellePlace=place.properties.country;
  }
  else
  {
    libellePlace=place.properties.formatted;
  }

  var champ="";
  if(type === 'depart')
  {
    setEditedDisplayDepart(libellePlace);
    champ='libelle1';
    
  }
  else
  {
    setEditedDisplayArrivee(libellePlace);
    champ='libelle2';
  }
  
  if(onDisplayElementChange)
      {
        onDisplayElementChange(idJournee, Number(index.split('-')[1]), index?.split('-')[0], libellePlace, champ);
        if(type === 'depart')
        {
          onDisplayElementChange(idJournee, Number(index.split('-')[1]), index?.split('-')[0], latitude, 'lat');
          onDisplayElementChange(idJournee, Number(index.split('-')[1]), index?.split('-')[0], longitude, 'longi');
          setEditedLatitude1(latitude);
          setEditedLongitude1(longitude);
        }
        else
        {
          onDisplayElementChange(idJournee, Number(index.split('-')[1]), index?.split('-')[0], latitude, 'lat2');
          onDisplayElementChange(idJournee, Number(index.split('-')[1]), index?.split('-')[0], longitude, 'longi2')
          setEditedLatitude2(latitude);
          setEditedLongitude2(longitude);        
        }
      }

      //enregistrement de la modification
      voyageService.modifierEvenement(idVoyage, {
        typeEvenement: index?.split('-')[0],
        idEvenement: index?.split('-')[1],
        colonne: type+"_trajet",
        contenu: libellePlace,
        colonne1: 'lat_'+type+'_trajet',
        colonne2: 'long_'+type+'_trajet',
        contenu1: latitude,
        contenu2: longitude,
        placeId: place.properties.place_id
      })
      .then((response: any) => {

        if(type === 'depart')
        {
          if(latitude !== '' && longitude !== '' && editedLatitude2 !== '' && editedLongitude2 !== '' && editedTypeTransport !== '')
          {
           calculerDistance(latitude, longitude, editedLatitude2, editedLongitude2, editedTypeTransport);
          }
        }
        else
        {
          if(editedLatitude1 !== '' && editedLongitude1 !== '' && latitude !== '' && longitude !== '' && editedTypeTransport !== '')
          {
            calculerDistance(editedLatitude1, editedLongitude1, latitude, longitude, editedTypeTransport);
          }
        }
        
      })
      .catch((error: any) =>
      {
        if (error.response?.status === 401) {
          alert("Erreur lors de l'enregistrement de la modification, vous n'êtes plus connecté(e).")
          localStorage.removeItem('xsrfToken');
          navigate('/login');
        }
        else
        {
          alert("Erreur lors de l'enregistrement de la modification de l'évenement");
        }
      });
}


function onPlaceChangedAeroport(aeroport: AeroportType, type: string)
{
  var latitude = aeroport.lat_aeroport;
  var longitude = aeroport.long_aeroport;
  var nomAeroport = aeroport.nom_aeroport_fr;
  var codeAeroport = aeroport.code_aeroport_3c;

  if (latitude.length > 20) 
	{
		latitude=latitude.substring(0, 20);
  }
  if (longitude.length > 20) 
	{
		longitude=longitude.substring(0, 20);
  }
  
  var champ="";
  if(type === 'depart')
  {
    setEditedDisplayDepart(nomAeroport);
    champ='libelle1';
    
  }
  else
  {
    setEditedDisplayArrivee(nomAeroport);
    champ='libelle2';
  }
  
  if(onDisplayElementChange)
  {
        onDisplayElementChange(idJournee, Number(index.split('-')[1]), index?.split('-')[0], nomAeroport, champ);

        if(type === 'depart')
        {
          onDisplayElementChange(idJournee, Number(index.split('-')[1]), index?.split('-')[0], latitude, 'lat');
          onDisplayElementChange(idJournee, Number(index.split('-')[1]), index?.split('-')[0], longitude, 'longi');
          setEditedLatitude1(latitude);
          setEditedLongitude1(longitude);
        }
        else
        {
          onDisplayElementChange(idJournee, Number(index.split('-')[1]), index?.split('-')[0], latitude, 'lat2');
          onDisplayElementChange(idJournee, Number(index.split('-')[1]), index?.split('-')[0], longitude, 'longi2')
          setEditedLatitude2(latitude);
          setEditedLongitude2(longitude);        
        }
  }

      //enregistrement de la modification
      voyageService.modifierEvenement(idVoyage, {
        typeEvenement: index?.split('-')[0],
        idEvenement: index?.split('-')[1],
        colonne: type+"_trajet",
        contenu: nomAeroport,
        colonne1: 'lat_'+type+'_trajet',
        colonne2: 'long_'+type+'_trajet',
        contenu1: latitude,
        contenu2: longitude,
        codeAeroport: codeAeroport
      })
      .then((response: any) => {

        if(type === 'depart')
        {
          if(latitude !== '' && longitude !== '' && editedLatitude2 !== '' && editedLongitude2 !== '' && editedTypeTransport !== '')
          {
            calculerDistance(latitude, longitude, editedLatitude2, editedLongitude2, editedTypeTransport);
          }
        }
        else
        {

          if(editedLatitude1 !== '' && editedLongitude1 !== '' && latitude !== '' && longitude !== '' && editedTypeTransport !== '')
          {
            calculerDistance(editedLatitude1, editedLongitude1, latitude, longitude, editedTypeTransport);
          }
        }
        
      })
      .catch((error: any) =>
      {
        if (error.response?.status === 401) {
          alert("Erreur lors de l'enregistrement de la modification, vous n'êtes plus connecté(e).")
          localStorage.removeItem('xsrfToken');
          navigate('/login');
        }
        else
        {
          alert("Erreur lors de l'enregistrement de la modification de l'évenement");
        }
      });

}

  useEffect(() => {
    
  var alphabetDiff=false;

  if(id_pays !== null)
  {
        var tab=id_pays.split('_');

        for(var i= 0; i < tab.length; i++)
        {
            if(tab[i].toLowerCase() === 'ww' || tab[i].toLowerCase() === 'xx' || tab[i].toLowerCase() === 'yy' || tab[i].toLowerCase() === 'zz')
            {
                tab[i]='gb';
            }
            else if(tab[i].toLowerCase() === 'yt' || tab[i].toLowerCase() === 'gf' || tab[i].toLowerCase() === 'pf' || tab[i].toLowerCase() === 'tf' 
                 || tab[i].toLowerCase() === 'gp' || tab[i].toLowerCase() === 'mq' || tab[i].toLowerCase() === 're' || tab[i].toLowerCase() === 'pm'
                 || tab[i].toLowerCase() === 'wf')
            {
                tab[i]='fr';
            }
            else if(tab[i].toLowerCase() === 'as' || tab[i].toLowerCase() === 'mp' || tab[i].toLowerCase() === 'vi')
            {
                tab[i]='us';
            }
            else if(tab[i].toLowerCase() === 'bv' || tab[i].toLowerCase() === 'sj')
            {
                tab[i]='no';
            }
            else if(tab[i].toLowerCase() === 'cc' || tab[i].toLowerCase() === 'hm' || tab[i].toLowerCase() === 'nf')
            {
                tab[i]='au';
            }
        }

      var listePaysAlphabetDiff=['hk','af','am','bh','bd','by','bt','mm','bg','kh','cn','cy','eg','er','ge','gr','in','ir','iq','il','jp','jo','kz','kw','kg','la','ly','mk','mr','mn','ma','np','kp','om','pk','qa','ru','sa','cs','kr','lk','sd','sy','tj','tw','th','tn','ua','ye','ae','ps','mo','rs'];
     
      switch (tab.length) {
          case 1:
            setListePays(tab[0].toLowerCase());
            if(listePaysAlphabetDiff.indexOf(tab[0].toLowerCase()) > -1)
            {
              alphabetDiff=true;
            }
            break;
          case 2:
            setListePays(tab[0].toLowerCase()+','+tab[1].toLowerCase());
            if(listePaysAlphabetDiff.indexOf(tab[0].toLowerCase()) > -1 || listePaysAlphabetDiff.indexOf(tab[1].toLowerCase()) > -1)
            {
              alphabetDiff=true;
            }
            break;
          case 3:
            setListePays(tab[0].toLowerCase()+','+tab[1].toLowerCase()+','+tab[2].toLowerCase());
            if(listePaysAlphabetDiff.indexOf(tab[0].toLowerCase()) > -1 || listePaysAlphabetDiff.indexOf(tab[1].toLowerCase()) > -1 || listePaysAlphabetDiff.indexOf(tab[2].toLowerCase()) > -1)
            {
              alphabetDiff=true;
            }
            break;
          case 4:
            setListePays(tab[0].toLowerCase()+','+tab[1].toLowerCase()+','+tab[2].toLowerCase()+','+tab[3].toLowerCase());
            if(listePaysAlphabetDiff.indexOf(tab[0].toLowerCase()) > -1 || listePaysAlphabetDiff.indexOf(tab[1].toLowerCase()) > -1 || listePaysAlphabetDiff.indexOf(tab[2].toLowerCase()) > -1 || listePaysAlphabetDiff.indexOf(tab[3].toLowerCase()) > -1)
            {
              alphabetDiff=true;
            }
            break;
          case 5:
            setListePays(tab[0].toLowerCase()+','+tab[1].toLowerCase()+','+tab[2].toLowerCase()+','+tab[3].toLowerCase()+','+tab[4].toLowerCase());
            if(listePaysAlphabetDiff.indexOf(tab[0].toLowerCase()) > -1 || listePaysAlphabetDiff.indexOf(tab[1].toLowerCase()) > -1 || listePaysAlphabetDiff.indexOf(tab[2].toLowerCase()) > -1 || listePaysAlphabetDiff.indexOf(tab[3].toLowerCase()) > -1 || listePaysAlphabetDiff.indexOf(tab[4].toLowerCase()) > -1)
            {
              alphabetDiff=true;
            }
            break;
          default:
            setListePays("");
            alphabetDiff=true;
        }
        if(listePays.includes("undefined"))
        {
          setListePays("");
          alphabetDiff=true;
        }
    }

    if(alphabetDiff)
    {
      setLang("en");
    }
    else
    {
      setLang("fr");
    }
}, [id_pays]);

const handleBlurDepart = () => {

  setTimeout(() => {
    const trimmedDisplayDepart = editedDisplayDepart.trim();

    if ( trimmedDisplayDepart !== oldDisplayDepart && !suggestionSelectedRef.current ) {

      if(onDisplayElementChange)
      {
        onDisplayElementChange(idJournee, Number(index.split('-')[1]), index?.split('-')[0], editedDisplayDepart, 'libelle1');
      } 

      var conservation=false;
      if(editedDisplayDepart !== "" && oldDisplayDepart !== "")
      {
        const isConfirmed = window.confirm(
          'Vous avez modifié le nom du lieu de départ sans avoir sélectionné un élement de la liste déroulante, nous n\'avons donc pas pu récupérer sa localisation.\n\nSouhaitez-vous conserver les coordoonnées GPS éventuellement déjà enregistrées pour cet élément ?\n- "OK" pour les conserver\n- "Annuler" pour les effacer'
        );
      
        // Vérifiez si l'utilisateur a confirmé
        if (isConfirmed) {
          conservation=true;
        }
      }
      
      if (!conservation) {
          
        effacerDistance();

        if(onDisplayElementChange)
        {
          onDisplayElementChange(idJournee, Number(index.split('-')[1]), index?.split('-')[0], "", 'lat');
          onDisplayElementChange(idJournee, Number(index.split('-')[1]), index?.split('-')[0], "", 'longi');
          setEditedLatitude1("");
          setEditedLongitude1("");
        }
        
      }

      //enregistrement de la modification
      voyageService.modifierEvenement(idVoyage, {
        typeEvenement: index?.split('-')[0],
        idEvenement: index?.split('-')[1],
        colonne: "depart_trajet",
        contenu: editedDisplayDepart,
        colonne1: '',
        colonne2: '',
        contenu1: '',
        contenu2: '',
        placeId: '',
        conservation
      })
      .then((response: any) => {
       
      })
      .catch((error: any) =>
      {
        if (error.response?.status === 401) {
          alert("Erreur lors de l'enregistrement de la modification, vous n'êtes plus connecté(e).")
          localStorage.removeItem('xsrfToken');
          navigate('/login');
        }
        else
        {
          alert("Erreur lors de l'enregistrement de la modification de l'évenement");
        }
      });

      // Effacez la liste de suggestions après l'enregistrement
      setSuggestions([]);
      setSuggestionsAeroports([]);
    
    }
  }, 200);
};

const handleBlurArrivee = () => {

  setTimeout(() => {
    const trimmedDisplayArrivee = editedDisplayArrivee.trim();
  

    if ( trimmedDisplayArrivee !== oldDisplayArrivee && !suggestionArriveeSelectedRef.current ) {

      if(onDisplayElementChange)
      {
        onDisplayElementChange(idJournee, Number(index.split('-')[1]), index?.split('-')[0], editedDisplayArrivee, 'libelle2');
      } 

      var conservation=false;

      if(editedDisplayArrivee !== ""  && oldDisplayArrivee !== "")
      {
        const isConfirmed = window.confirm(
          'Vous avez modifié le nom du lieu d\'arrivée sans avoir sélectionné un élement de la liste déroulante, nous n\'avons donc pas pu récupérer sa localisation.\n\nSouhaitez-vous conserver les coordoonnées GPS éventuellement déjà enregistrées pour cet élément ?\n- "OK" pour les conserver\n- "Annuler" pour les effacer'
        );
      
        // Vérifiez si l'utilisateur a confirmé
        if (isConfirmed) {
          conservation=true;
        }
      }

      if (!conservation) {
          
        effacerDistance();

        if(onDisplayElementChange)
        {
          onDisplayElementChange(idJournee, Number(index.split('-')[1]), index?.split('-')[0], "", 'lat2');
          onDisplayElementChange(idJournee, Number(index.split('-')[1]), index?.split('-')[0], "", 'longi2');
          setEditedLatitude2("");
          setEditedLongitude2("");
        }
       
        
      }
      
      //enregistrement de la modification
      voyageService.modifierEvenement(idVoyage, {
        typeEvenement: index?.split('-')[0],
        idEvenement: index?.split('-')[1],
        colonne: "arrivee_trajet",
        contenu: editedDisplayArrivee,
        colonne1: '',
        colonne2: '',
        contenu1: '',
        contenu2: '',
        placeId: '',
        conservation
      })
      .then((response: any) => {       

      })
      .catch((error: any) =>
      {
        if (error.response?.status === 401) {
          alert("Erreur lors de l'enregistrement de la modification, vous n'êtes plus connecté(e).")
          localStorage.removeItem('xsrfToken');
          navigate('/login');
        }
        else
        {
          alert("Erreur lors de l'enregistrement de la modification de l'évenement");
        }
      });

      // Effacez la liste de suggestions après l'enregistrement
      setSuggestionsArrivee([]);
      setSuggestionsAeroportsArrivee([]);
    }
  }, 200);
};


  const handleChangeHeure = (heure: Date | null, type: string) => {
  
    var formattedTime = "";
    if (heure) {
      formattedTime = format(heure, "HH:mm");
      
    }

    if(onDisplayElementChange)
    {  
      onDisplayElementChange(idJournee, Number(index.split('-')[1]), index?.split('-')[0], formattedTime, type);
    }
      //enregistrement de la modification
      voyageService.modifierEvenement(idVoyage, {
        typeEvenement: index?.split('-')[0],
        idEvenement: index?.split('-')[1],
        colonne: type+"_trajet",
        contenu: formattedTime,
        conservation: false
      })
      .then((response: any) => {
      
      })
      .catch((error: any) =>
      {
        if (error.response?.status === 401) {
          alert("Erreur lors de l'enregistrement de la modification, vous n'êtes plus connecté(e).")
          localStorage.removeItem('xsrfToken');
          navigate('/login');
        }
        else
        {
          alert("Erreur lors de l'enregistrement de la modification de l'évenement");
        }
      });
  };

  const handleTypeTransport = (typeSelected: string | null) => {
   
    if (!typeSelected) {
      typeSelected="";
    }

    setEditedTypeTransport(typeSelected);

    if(onDisplayElementChange)
    {  
      onDisplayElementChange(idJournee, Number(index.split('-')[1]), index?.split('-')[0], typeSelected, "type");
    }
  
      //enregistrement de la modification
      voyageService.modifierEvenement(idVoyage, {
        typeEvenement: index?.split('-')[0],
        idEvenement: index?.split('-')[1],
        colonne: "type_trajet",
        contenu: typeSelected,
        conservation: false
      })
      .then((response: any) => {
       
        if(typeSelected === "")
        {
          effacerDistance();
        }
        else if(editedLatitude1 !== '' && editedLongitude1 !== '' && editedLatitude2 !== '' && editedLongitude2 !== '' && typeSelected !== '')
        {
         
          calculerDistance(editedLatitude1, editedLongitude1, editedLatitude2, editedLongitude2, typeSelected); 
        }
      })
      .catch((error: any) =>
      {
        if (error.response?.status === 401) {
          alert("Erreur lors de l'enregistrement de la modification, vous n'êtes plus connecté(e).")
          localStorage.removeItem('xsrfToken');
          navigate('/login');
        }
        else
        {
          alert("Erreur lors de l'enregistrement de la modification de l'évenement");
        }
      });
  };

  const handleFocusInput = (event: React.FocusEvent<HTMLInputElement>) => {
  
    setOldValeurInput(event.target.value.trim());
  }

  const handleBlurInput = (event: React.FocusEvent<HTMLInputElement>, type: string) => {

    var newValue = event.target.value.trim();  
  
    event.target.value=newValue;

    if(newValue !== oldValeurInput)
    {
  
      if(onDisplayElementChange)
      {
        // Appeler la fonction de rappel pour propager les changements
        onDisplayElementChange(idJournee, Number(index.split('-')[1]), index?.split('-')[0], newValue, type);
      }  

      //enregistrement de la modification
      voyageService.modifierEvenement(idVoyage, {
        typeEvenement: index?.split('-')[0],
        idEvenement: index?.split('-')[1],
        colonne: type+"_trajet",
        contenu: newValue,
        conservation: false
      })
      .then((response: any) => {
      
      })
      .catch((error: any) =>
      {
        if (error.response?.status === 401) {
          alert("Erreur lors de l'enregistrement de la modification, vous n'êtes plus connecté(e).")
          localStorage.removeItem('xsrfToken');
          navigate('/login');
        }
        else
        {
          alert("Erreur lors de l'enregistrement de la modification de l'évenement");
        }
      });
    }
  };

  const handleFocusPrix = (event: React.FocusEvent<HTMLInputElement>) => {
    setOldDisplayPrix(event.target.value);
  };

  const handleBlurPrix = (event: React.FocusEvent<HTMLInputElement>, colonne: string) => {

    var newValue = event.target.value;  
  
    newValue = newValue.replace(",", ".")
    event.target.value=newValue;
  
    if(newValue !== oldDisplayPrix)
    {
  
      try
      {
        if(isNaN(Number(newValue)) || newValue.lastIndexOf(" ") !== -1 )
        {
          alert('Cette zone ne peut contenir que des chiffres.');
          event.target.value='';
          event.target.focus();
          return false;
        }
        else if(Number(newValue) > 99999999.99)
        {
          alert('Le prix ne peut pas être supérieur à 99999999.99');
          event.target.value='';
          event.target.focus();
          return false;
        }

        setEditedDisplayPrix(newValue);
        if(onDisplayElementChange)
        {
          // Appeler la fonction de rappel pour propager les changements
          onDisplayElementChange(idJournee, Number(index.split('-')[1]), index?.split('-')[0], newValue, 'prix');
        }    

        if (newValue === "" || Number(newValue) === 0) {
          setEditedPaiement("no");
        }
      }
      catch(error)
      {
        alert('Cette zone ne peut contenir que des chiffres.');
        event.target.value='';
        event.target.focus();
        return false;
      }
  
      //enregistrement de la modification
      voyageService.modifierEvenement(idVoyage, {
        typeEvenement: index?.split('-')[0],
        idEvenement: index?.split('-')[1],
        colonne: colonne,
        contenu: newValue,
        conservation: true
      })
      .then((response: any) => {
       
      })
      .catch((error: any) =>
      {
        if (error.response?.status === 401) {
          alert("Erreur lors de l'enregistrement de la modification, vous n'êtes plus connecté(e).")
          localStorage.removeItem('xsrfToken');
          navigate('/login');
        }
        else
        {
          alert("Erreur lors de l'enregistrement de la modification de l'évenement");
        }
      });
    }
  };
  
  const handleChangeCheckbox = (checked: boolean) => {
  
    var newValue = "";  
  
    if(onDisplayElementChange)
    {
      if(checked)
      {
        newValue = "paye"
      }
      // Appeler la fonction de rappel pour propager les changements
      onDisplayElementChange(idJournee, Number(index.split('-')[1]), index?.split('-')[0], newValue, 'paiement');
    }  

    setEditedPaiement(newValue);
  
    //enregistrement de la modification
    voyageService.modifierEvenement(idVoyage, {
      typeEvenement: index?.split('-')[0],
      idEvenement: index?.split('-')[1],
      colonne: "etat_paiement_trajet",
      contenu: newValue,
      conservation: true
    })
    .then((response: any) => {
   
    })
    .catch((error: any) =>
    {
      if (error.response?.status === 401) {
        alert("Erreur lors de l'enregistrement de la modification, vous n'êtes plus connecté(e).")
        localStorage.removeItem('xsrfToken');
        navigate('/login');
      }
      else
      {
        alert("Erreur lors de l'enregistrement de la modification de l'évenement");
      }
    });
  };

  const effacerDistance = () => {

    if(onDisplayElementChange)
    {
      onDisplayElementChange(idJournee, Number(index.split('-')[1]), index?.split('-')[0], "", "distance");
      onDisplayElementChange(idJournee, Number(index.split('-')[1]), index?.split('-')[0], "", "duree");
    }

    //enregistrement de la modification
    voyageService.enregistrerDistance(idVoyage, {
      idEvenement: index?.split('-')[1],
      distance: '',
      duree: ''
    })
    .then((response: any) => {
    
      setEditedDuree("");
      setEditedDistance("");
    })
    .catch((error: any) =>
    {
      if (error.response?.status === 401) {
        alert("Erreur lors de l'enregistrement de la modification, vous n'êtes plus connecté(e).")
        localStorage.removeItem('xsrfToken');
        navigate('/login');
      }
      else
      {
        alert("Erreur lors de l'enregistrement de la modification de l'évenement");
      }
    });
  }

  const calculerDistance = (latitude: any | null, longitude: any | null, latitude2: any | null, longitude2: any | null, typeTransport: string | null) => {

    var apiRouteOK=false;

    var type_trajet=typeTransport;  
    var distanceTrajet=distanceService.distance(latitude, longitude, latitude2, longitude2, 'K');
    var dureeTrajet='';

    if(distanceTrajet<1000)
    {
      distanceTrajet=parseFloat(distanceTrajet.toFixed(1));
    }
    else
    {
      distanceTrajet=parseInt(distanceTrajet.toFixed(0));
    }


    if(type_trajet === 'car' || type_trajet === 'bus' || type_trajet === 'taxi' || type_trajet === 'motorcycle' || (type_trajet === 'walking' && distanceTrajet<=100) || (type_trajet === 'bicycle' && distanceTrajet<=100)) 
    {
      var modeTransport='';
      switch(type_trajet) {
        case 'car':
          modeTransport='drive';
          break;
        case 'walking':
          modeTransport='walk';
          break;
        case 'bicycle':
          modeTransport='bicycle';
          break;
        case 'bus':
          modeTransport='truck';
          break;
        case 'taxi':
          modeTransport='drive';
          break;
        case 'motorcycle':
          modeTransport='drive';
          break;
        default:
          modeTransport='drive';
        }

        try{

        distanceService.apiRoute(modeTransport, latitude, longitude, latitude2, longitude2).then(function(data) {
                          
        if(data.sources_to_targets[0][1].distance !== null)
        {
          distanceTrajet=data.sources_to_targets[0][1].distance;
          dureeTrajet=data.sources_to_targets[0][1].time;
          apiRouteOK=true;
        }
        else
        {
          if(data.sources_to_targets[1][0].distance !== null && data.sources_to_targets[1][0].distance !== 0)
          {
            distanceTrajet=data.sources_to_targets[1][0].distance;
            dureeTrajet=data.sources_to_targets[1][0].time;
            apiRouteOK=true;
          }
          else
          {
            dureeTrajet='';
          }
        }

        if(dureeTrajet !== '')
        {
          dureeTrajet=distanceService.secondsToHm(dureeTrajet);
        }

        if(distanceTrajet !== 0)
        {
          if(apiRouteOK)
          {
            distanceTrajet=distanceTrajet/1000;
            if(distanceTrajet<1000)
            {
              distanceTrajet=parseFloat(distanceTrajet.toFixed(1));
            }
            else
            {
              distanceTrajet=parseInt(distanceTrajet.toFixed(0));
            }
          }

          var valeurDistance=distanceTrajet.toString();
          if(distanceTrajet === 0)
          {
            valeurDistance='';
          }

          setEditedDistance(valeurDistance);
          setEditedDuree(dureeTrajet);
                         
          if(onDisplayElementChange)
          {
            onDisplayElementChange(idJournee, Number(index.split('-')[1]), index?.split('-')[0], valeurDistance, "distance");
            onDisplayElementChange(idJournee, Number(index.split('-')[1]), index?.split('-')[0], dureeTrajet, "duree");
          }

          //enregistrement de la modification
          voyageService.enregistrerDistance(idVoyage, {
            idEvenement: index?.split('-')[1],
            distance: valeurDistance,
            duree: dureeTrajet
          })
          .then((response: any) => {
           
          })
          .catch((error: any) =>
          {
            if (error.response?.status === 401) {
              alert("Erreur lors de l'enregistrement de la modification, vous n'êtes plus connecté(e).")
              localStorage.removeItem('xsrfToken');
              navigate('/login');
            }
            else
            {
              alert("Erreur lors de l'enregistrement de la modification de l'évenement");
            }
          });
        }
        else
        {
          effacerDistance();
        }
       });
      }
      catch(error)
      {
        console.log("erreur api route", error)
      }
    }
    else
    {
      var valeurDistance=distanceTrajet.toString();
          if(distanceTrajet === 0)
          {
            valeurDistance='';
          }

          setEditedDistance(valeurDistance);
          setEditedDuree(dureeTrajet);
                         
          if(onDisplayElementChange)
          {
            onDisplayElementChange(idJournee, Number(index.split('-')[1]), index?.split('-')[0], valeurDistance, "distance");
            onDisplayElementChange(idJournee, Number(index.split('-')[1]), index?.split('-')[0], dureeTrajet, "duree");
          }

      //enregistrement de la modification
      voyageService.enregistrerDistance(idVoyage, {
        idEvenement: index?.split('-')[1],
        distance: valeurDistance,
        duree: ''
      })
      .then((response: any) => {
 
      })
      .catch((error: any) =>
      {
        if (error.response?.status === 401) {
          alert("Erreur lors de l'enregistrement de la modification, vous n'êtes plus connecté(e).")
          localStorage.removeItem('xsrfToken');
          navigate('/login');
        }
        else
        {
          alert("Erreur lors de l'enregistrement de la modification de l'évenement");
        }
      });
    }
  }

  const handleClickJourSuivant = () => {

    const isConfirmed = window.confirm(
      "Ce trajet se termine-t-il le jour suivant ?\n\nEn cliquant sur OK, l'élément nuit de cette journée sera supprimé et l'arrivée du trajet devra être saisie sur la journée suivante."
    );

    // Vérifiez si l'utilisateur a confirmé
    if (isConfirmed) {
      //enregistrement de la modification
      voyageService.gererTrajetLendemain(idVoyage, {
        idTrajet: index?.split('-')[1],     
      })
      .then((response: any) => {

        setEditedDisplayPrix("");
        setEditedPaiement("");
        setEditedLatitude2("");
        setEditedLongitude2("");
        setEditedDistance("");
        setEditedDuree("");
        setEditedInvisible2("O");
        setEditedDisplayArrivee("");

        if(onAddTrajetLendemain)
        {
          onAddTrajetLendemain(idJournee);
        }

        if(onDisplayElementChange)
        {
          onDisplayElementChange(idJournee, Number(index.split('-')[1]), index?.split('-')[0], "", 'prix');
          onDisplayElementChange(idJournee, Number(index.split('-')[1]), index?.split('-')[0], "", 'paiement');
          onDisplayElementChange(idJournee, Number(index.split('-')[1]), index?.split('-')[0], "", 'lat2');
          onDisplayElementChange(idJournee, Number(index.split('-')[1]), index?.split('-')[0], "", 'longi2');
          onDisplayElementChange(idJournee, Number(index.split('-')[1]), index?.split('-')[0], "", 'distance');
          onDisplayElementChange(idJournee, Number(index.split('-')[1]), index?.split('-')[0], "", 'duree');
          onDisplayElementChange(idJournee, Number(index.split('-')[1]), index?.split('-')[0], "O", 'invisible2');
          onDisplayElementChange(idJournee, Number(index.split('-')[1]), index?.split('-')[0], "", 'libelle2');
          if(response.data.idNuit !== "")
          {
            onDisplayElementChange(idJournee, response.data.idNuit, "nuit", "O", 'invisible1');
            onDisplayElementChange(idJournee, response.data.idNuit, "nuit", t('nuitTransport'), 'libelle1');
            onDisplayElementChange(idJournee, response.data.idNuit, "nuit", "", 'pays');
            onDisplayElementChange(idJournee, response.data.idNuit, "nuit", "", 'nom_pays');
          }
        }

      })
      .catch((error: any) =>
      {
        
        if (error.response?.status === 401) {
          alert("Erreur lors de l'enregistrement de la modification, vous n'êtes plus connecté(e).")
          localStorage.removeItem('xsrfToken');
          navigate('/login');
        }
        else if (error.response?.status === 410 && error.response.data.message !== undefined) {
          alert(error.response.data.message);
        }
        else
        {
          alert("Erreur lors de l'enregistrement de la modification de l'évenement");
        }
      });
    }

  }

  const handleClickAnnuleJourSuivant = () => {

    const isConfirmed = window.confirm(
      "Ce trajet se termine-t-il le jour même ?\n\nEn cliquant sur OK, l'élément nuit de cette journée va réapparaître et vous devrez supprimer l'élément trajet sur la journée suivante si cela n'a pas déjà été fait."
    );

    // Vérifiez si l'utilisateur a confirmé
    if (isConfirmed) {
      //enregistrement de la modification
      voyageService.gererTrajetLendemain(idVoyage, {
        idTrajetFin: index?.split('-')[1],     
      })
      .then((response: any) => {

        setEditedInvisible2("O");

        if(onDeleteTrajetLendemain && response.data.idTrajetSup !== "")
        {
          onDeleteTrajetLendemain(idJournee, response.data.idTrajetSup);
        }

        if(onDisplayElementChange)
        {
          onDisplayElementChange(idJournee, Number(index.split('-')[1]), index?.split('-')[0], "", 'invisible2');
          if(response.data.idNuit !== "")
          {
            onDisplayElementChange(idJournee, response.data.idNuit, "nuit", "", 'invisible1');
            onDisplayElementChange(idJournee, response.data.idNuit, "nuit", "", 'libelle1');
          }
        }
      })
      .catch((error: any) =>
      {
        if (error.response?.status === 401) {
          alert("Erreur lors de l'enregistrement de la modification, vous n'êtes plus connecté(e).")
          localStorage.removeItem('xsrfToken');
          navigate('/login');
        }
        else
        {
          alert("Erreur lors de l'enregistrement de la modification de l'évenement");
        }
      });
    }

  }

  const handleSelectLocation = (valeur: string) => {

    if(valeur !== oldMoment)
    {
      //enregistrement de la modification
      voyageService.modifierEvenement(idVoyage, {
        typeEvenement: "trajet",
        idEvenement: index?.split('-')[1],
        colonne: "moment_location",
        contenu: valeur,
        conservation: false
      })
      .then((response: any) => {
    
        setEditedMoment(valeur);
        setOldMoment(valeur);
        setEditedDisplayPrix("");
        setEditedPaiement("");
        setEditedTypeTransport("");

        var idRattach=editedRattachement;
        
        if(onDisplayElementChange)
        {
          onDisplayElementChange(idJournee, Number(index.split('-')[1]), index?.split('-')[0], valeur, 'moment');
          onDisplayElementChange(idJournee, Number(index.split('-')[1]), index?.split('-')[0], "", 'prix');
          onDisplayElementChange(idJournee, Number(index.split('-')[1]), index?.split('-')[0], "", 'paiement');
          onDisplayElementChange(idJournee, Number(index.split('-')[1]), index?.split('-')[0], "", 'rattachement');
          onDisplayElementChange(idJournee, Number(index.split('-')[1]), index?.split('-')[0], "", 'type');

          if(idRattach !== "")
          {
            onChangeTypeLocation(idRattach, valeur);

            setEditedRattachement("");
          }
        }

      })
      .catch((error: any) =>
      {
        if (error.response?.status === 401) {
          alert("Erreur lors de l'enregistrement de la modification, vous n'êtes plus connecté(e).")
          localStorage.removeItem('xsrfToken');
          navigate('/login');
        }
        else
        {
          alert("Erreur lors de l'enregistrement de la modification de l'évenement");
        }
      });
    }
    
  }

  const handleSelectPec = (valeur: string) => {

      //enregistrement de la modification
      voyageService.modifierEvenement(idVoyage, {
        typeEvenement: "trajet",
        idEvenement: index?.split('-')[1],
        colonne: "id_pec",
        contenu: valeur,
        conservation: false
      })
      .then((response: any) => {
        setEditedRattachement(valeur);
        setEditedTypeTransport(response.data.typeTrajet);

        if(onDisplayElementChange)
        {
          onDisplayElementChange(idJournee, Number(index.split('-')[1]), index?.split('-')[0], valeur, 'rattachement');
          onDisplayElementChange(idJournee, Number(index.split('-')[1]), index?.split('-')[0], response.data.typeTrajet, 'type');

          onDisplayPecChange(valeur, index.split('-')[1]);
          
        }

      })
      .catch((error: any) =>
      {
        if (error.response?.status === 401) {
          alert("Erreur lors de l'enregistrement de la modification, vous n'êtes plus connecté(e).")
          localStorage.removeItem('xsrfToken');
          navigate('/login');
        }
        else
        {
          alert("Erreur lors de l'enregistrement de la modification de l'évenement");
        }
      });
    
  }

  useEffect(() => {
    setEditedTypeTransport(author.type || "");
    setEditedDisplayPrix(author.prix === '0.00' ? "" : author.prix);
    setEditedPaiement(author.paiement || "");
    setEditedDisplayDepart(author.displayName || "");
    setEditedDisplayArrivee(author.libelle2 || "");
   
    setEditedDistance(author.distance || "");
    setEditedDuree(author.duree || "");
    setEditedInvisible1(author.invisible1 || "");
    setEditedInvisible2(author.invisible2 || "");
    setEditedRattachement(author.rattachement || "");
    setEditedMoment(author.moment || "");
    setEditedLocation(author.location || "");
    setOldMoment(author.moment || "");
    setEditedLatitude1(author.lat || "");
    setEditedLongitude1(author.longi || "");
    setEditedLatitude2(author.lat2 || "");
    setEditedLongitude2(author.longi2 || "");

  }, [author.invisible2, author.lat, author.longi, author.lat2, author.longi2]);

  useEffect(() => {

    if(!initialLoad)
    {
      if(author.latModif !=="" && author.longiModif !== "" && author.lat2Modif !== "" && author.longi2Modif !== "" && editedTypeTransport !== "")
      {
        calculerDistance(author.latModif, author.longiModif, author.lat2Modif, author.longi2Modif, editedTypeTransport);
      }
      else
      {
        setEditedDuree("");
        setEditedDistance("");
      }
    } 
    else 
    {
      // Mettez à jour l'état initialLoad après le premier rendu
      setInitialLoad(false);
    }
  }, [author.latModif, author.longiModif, author.lat2Modif, author.longi2Modif]);

  
  useEffect(() => {
    if(editedDisplayDepart !== "" || editedDisplayArrivee !== "")
    {
      setIsLibelleSaisi(true);
    }
    else
    {
      setIsLibelleSaisi(false);
    }
  
  }, [editedDisplayDepart, editedDisplayArrivee]);

  return (
    <div
      
    className={`${selected ? "selectedCard" : ""} nc-CardTrajetModif relative flex flex-col items-center justify-center text-center mt-2 px-3 ${
      editedLocation === 'O' && editedMoment === 'R' ? 'pt-3' : 'py-3 sm:py-3'
    } sm:px-3  [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ] ${className}`}
    data-nc-id="CardTrajetModif"
      onMouseLeave={handleMouseLeave}
      onMouseEnter={handleMouseEnter}
    >
        <Badge
        taille="text-xxs py-1"
          className="absolute left-1 top-3"
          color={location === "" ? "green" : "purple"}
          name={location === "" ? t('trajet') : t('location')}
        />
       <EvenementBtnsModif libelleSaisi={isLibelleSaisi} onSuppressionClick={onSuppressionClick} onLienClick={onLienClick} onBlocNotesClick={onBlocNotesClick} onCoordClick={onCoordClick} onConfirmClick={onConfirmClick} onRechercheClick={onRechercheClick} index={index || '' }/>
       { editedInvisible1 === "" && (
      <div className={editedLocation === 'O' ? 'mt-6' : 'mt-3'}>
        {editedLocation === 'O' && (
        <SelectPaiementHebergement
            value={editedMoment}
            onChange={(event) => handleSelectLocation(event.target.value)}
            className="pointeur mb-3"
          >
              <option value="P">{t('priseEnCharge')}&nbsp;&nbsp;</option>
              <option value="R">{t('restitution')}</option>
           </SelectPaiementHebergement>

       )}
       <LocalizationProvider dateAdapter={AdapterDateFns}>
          <TimePicker
            label={(editedLocation === 'O' && editedMoment === 'R') ? t('fin') : t('debut')}
            defaultValue={dateDeb}
            ampm={false}
            className="horaires"
            onChange={(heure) => { handleChangeHeure(heure, "debut")}}
            slotProps={{
              textField: {
                sx: {
                  borderRadius: 6,
                  fieldset: { borderRadius: 6 },
                /* Use FilledInputClasses if your TextField variant changed to "filled" otherwise use below */
                [`.${OutlinedInputClasses.root}`]: {
                   height: 25,
                   width: 100,
                },
                /* If you change the default height, you need to adjust the Input Label position as well */
                '& .MuiInputLabel-root': { lineHeight: 1 }
               }        
              },
            }}
          />
        </LocalizationProvider>
      </div>
      )}
      { editedInvisible1 === "" && (
      <div className="flex items-center justify-center w-full">
      <div className={`text-base font-medium w-full`}>
        <input
          type="text"
          value={editedDisplayDepart}
          onChange={handleDisplayDepartChange}
          className="champAutocomplete"
          placeholder={editedLocation === 'O' ? t('agenceBoutique') : t('aeroportGareVille')}
          maxLength={50}
          onBlur={handleBlurDepart}
          onFocus={handleFocusDepart}
        />
        
         {/* Afficher la liste de suggestions en tant que pop-up */}
        {isModalOpen && (suggestions.length > 0 || suggestionsAeroports.length > 0) && (
          <AutoCompleteList
          suggestionsAeroports={suggestionsAeroports}
          suggestions={suggestions}
          onSelect={handleSelectSuggestion}
          onSelectAeroport={handleSelectSuggestionAeroport}
          />
        )}  
      </div>

        { isMap && editedDisplayDepart !== "" && editedLatitude1 !== "" && editedLongitude1 !== "" && (
          <div className="mt-2">
            <i className="curseurPointeur las la-location-arrow text-green-500" data-tooltip-id="myTooltipLoc"
                                data-tooltip-content={t('cliquezCarte')}
                                data-tooltip-place="top"
                                onClick={() => onLocalisationClick && onLocalisationClick("depart")}
                              ></i>
                              <Tooltip id="myTooltipLoc" style={{ zIndex: 9999 }}></Tooltip>
          </div>
          )}
      { isMap && editedDisplayDepart !== "" && (editedLatitude1 === "" || editedLongitude1 === "") && (
      <div>
        <i className="las la-location-arrow text-rose-500" data-tooltip-id="myTooltip"
                            data-tooltip-content={t('cliquezPlacement')}
                            data-tooltip-place="top"
                            onClick={() => onPlacementClick && onPlacementClick("depart")}
                            
                           ></i>
                           <Tooltip id="myTooltip" style={{ zIndex: 9999 }}></Tooltip>
      </div>
      )}
      { !isMap && editedDisplayDepart !== "" && (editedLatitude1 === "" || editedLongitude1 === "") && (
      <div>
        <i className="las la-location-arrow text-rose-500" data-tooltip-id="myTooltip"
                            data-tooltip-content={t('coordonneesGPSAbsentes')}
                            data-tooltip-place="top"
                           ></i>
                           <Tooltip id="myTooltip" style={{ zIndex: 9999 }}></Tooltip>
      </div>
      )}
      </div>
      )}


      { editedLocation === 'O' && editedMoment === "R" && (
        <SelectPaiementHebergement
            //value={editedMoment}
            onChange={(event) => handleSelectPec(event.target.value)}
            className="pointeur mt-2"
            value={listePecPlanificateur.find(pec => pec.rattachement === index?.split('-')[1])?.id_evenement || ""}
          >
            <option value="">{t('rattacherPEC')}</option>
           {listePecPlanificateur.map((pec) => (
            pec.rattachement === index?.split('-')[1] || pec.rattachement === "" ? (
              <option 
              //selected={pec.rattachement === index?.split('-')[1]} 
              value={pec.id_evenement}  key={pec.id_evenement} >
                {pec.date_journee.split(' ')[1]}
                {pec.debut !== "" && ` à ${pec.debut}`}
                {pec.type_transport === "car" && " - Voiture"}
                {pec.type_transport === "motorcycle" && " - Moto/scooter"}
                {pec.type_transport === "bicycle" && " - Vélo"}
              </option>
            ) : null
            )
          )}
           </SelectPaiementHebergement>

       )}
      { editedInvisible1 === "O" && (
        <div className="mt-4">
        </div>
      )}
      <div className="mt-3">
        <div className="grid grid-cols-2 md:grid-cols-2">
          <div>
          { (editedLocation !== "O" || editedMoment !== "R") && (
            <div>
            { editedTypeTransport && editedTypeTransport !== "" && ( 
              <>
            <i className={`las la-${editedTypeTransport} text-xxl ${editedLocation === 'O' ? 'text-purple-500' : 'text-green-500'} iconeTransport`} onClick={() => handleTypeTransport(null)}></i>
            <h2 className="champAnnexe text-neutral-900 dark:text-neutral-200"
            data-tooltip-id="myTooltipDistance"
            data-tooltip-content="Estimation à vol d'oiseau ou selon le mode de transport si la durée est affichée"
            data-tooltip-place="bottom">
              { editedDistance !== '' && <span>{editedDistance} km</span>}
            </h2>
            <Tooltip id="myTooltipDistance" style={{ zIndex: 9999 }}></Tooltip>              
      
            <div className="champAnnexe text-neutral-900 dark:text-neutral-200">
              {editedDuree}
            </div>
            </>
            )}
            { (!editedTypeTransport || editedTypeTransport === "") && ( 
            <div>
              { editedLocation !== "O" && (
                <>
              <i className={`las la-plane text-xl iconeTransport`} onClick={() => handleTypeTransport("plane")}></i>
              <i className={`las la-ship text-xl iconeTransport`} onClick={() => handleTypeTransport("ship")}></i>
              <i className={`las la-train text-xl iconeTransport`} onClick={() => handleTypeTransport("train")}></i>
              <br/>
              <i className={`las la-bus text-xl iconeTransport`} onClick={() => handleTypeTransport("bus")}></i>
              <i className={`las la-taxi text-xl iconeTransport`} onClick={() => handleTypeTransport("taxi")}></i>
              </>
              )}
              <i className={`las la-car text-xl iconeTransport`} onClick={() => handleTypeTransport("car")}></i>
              { editedLocation !== "O" && (
              <br/>
              )}
              <i className={`las la-motorcycle text-xl iconeTransport`} onClick={() => handleTypeTransport("motorcycle")}></i>
              <i className={`las la-bicycle text-xl iconeTransport`} onClick={() => handleTypeTransport("bicycle")}></i>
              { editedLocation !== "O" && (
              <i className={`las la-walking text-xl iconeTransport`} onClick={() => handleTypeTransport("walking")}></i>
              )}
            </div>)}
            </div>
            )}
          </div>
          { (editedLocation !== "O" || editedMoment !== "R") && (
          <div className="">
           <div className="">
           <h2 className={`text-base font-small`}>
            <input
              type="text"
              defaultValue={compagnie}
              className="champRef"
              placeholder={t('compagnie')}
              maxLength={20}
              onBlur={(event) => handleBlurInput(event, "compagnie")}
              onFocus={(event) => handleFocusInput(event)}
            />    
          </h2>
           </div>
           <div className="mt-1">
           <h2 className={`text-base font-small`}>
            <input
              type="text"
              defaultValue={ref}
              className="champRef"
              placeholder={editedLocation === 'O' ? t('reference') : t('refNumVol')}
              maxLength={20}
              onBlur={(event) => handleBlurInput(event, "ref")}
              onFocus={(event) => handleFocusInput(event)}
            />    
          </h2>
           </div>
          </div>
          )}
        </div>
        { editedInvisible2 !== "O" && editedLocation !== "O" && (
        <div className="mt-1 ml-4">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <TimePicker
              label={t('fin')}
              defaultValue={dateFin}
              ampm={false}
              className="horaires"
              onChange={(heure) => { handleChangeHeure(heure, "fin")}}
              slotProps={{
                textField: {
                  sx: {
                    borderRadius: 6,
                    fieldset: { borderRadius: 6 },
                  /* Use FilledInputClasses if your TextField variant changed to "filled" otherwise use below */
                  [`.${OutlinedInputClasses.root}`]: {
                    height: 25,
                    width: 100,
                  },
                  /* If you change the default height, you need to adjust the Input Label position as well */
                  '& .MuiInputLabel-root': { lineHeight: 1 }     
                }        
                },
              }}
            />
          </LocalizationProvider>
          { editedInvisible1 !== "O" && (
          <span>&nbsp;
          <i className="iconeTransport las la-calendar-plus" onClick={ () => handleClickJourSuivant()}></i>
          </span>
        )}
        </div>
        )}
        { editedInvisible2 === "O" && (
        <div>
        <i className="iconeTransport las la-calendar-minus"  onClick={ () => handleClickAnnuleJourSuivant()}></i>
        </div>
        )}
        </div>
        { editedInvisible2 !== "O" && editedLocation !== "O" && (
  
      <div className="flex items-center justify-center w-full">
      <div className={`text-base font-medium w-full`}>
        <input
          type="text"
          value={editedDisplayArrivee}
          onChange={handleDisplayArriveeChange}
          className="champAutocomplete"
          placeholder={t('aeroportGareVille')}
          maxLength={50}
          onBlur={handleBlurArrivee}
          onFocus={handleFocusArrivee}
        />
        
        {/* Afficher la liste de suggestions en tant que pop-up */}
        {isModalArriveeOpen && (suggestionsArrivee.length > 0 || suggestionsAeroportsArrivee.length > 0) && (
          <AutoCompleteList
          suggestionsAeroports={suggestionsAeroportsArrivee}
          suggestions={suggestionsArrivee}
          onSelect={handleSelectSuggestionArrivee}
          onSelectAeroport={handleSelectSuggestionAeroportArrivee}
          />
        )}
      </div>
      { isMap && editedDisplayArrivee !== "" && editedLatitude2 !== "" && editedLongitude2 !== "" && (
          <div className="mt-2">
            <i className="curseurPointeur las la-location-arrow text-green-500" data-tooltip-id="myTooltipLocArr"
                                data-tooltip-content={t('cliquezCarte')}
                                data-tooltip-place="top"
                                onClick={() => onLocalisationClick && onLocalisationClick("arrivee")}
                              ></i>
                              <Tooltip id="myTooltipLocArr" style={{ zIndex: 9999 }}></Tooltip>
          </div>
      )}
      { isMap && editedDisplayArrivee !== "" && (editedLatitude2 === "" || editedLongitude2 === "") && (
      <div>
        <i className="las la-location-arrow text-rose-500" data-tooltip-id="myTooltip2"
                            data-tooltip-content={t('cliquezPlacement')}
                            data-tooltip-place="top"
                            onClick={() => onPlacementClick && onPlacementClick("arrivee")}
                            
                          ></i>
                          <Tooltip id="myTooltip2" style={{ zIndex: 9999 }}></Tooltip>
      </div>
      )}
       { !isMap && editedDisplayArrivee !== "" && (editedLatitude2 === "" || editedLongitude2 === "") && (
      <div>
        <i className="las la-location-arrow text-rose-500" data-tooltip-id="myTooltip2"
                            data-tooltip-content="Cordonnées GPS absentes"
                            data-tooltip-place="top"
                            
                          ></i>
                          <Tooltip id="myTooltip2" style={{ zIndex: 9999 }}></Tooltip>
      </div>
      )}
      </div>
      )}
      { !isMap && editedInvisible2 !== "O" && editedMoment !== "R" && (
      <div className="py-2 px-3 mt-2 bg-neutral-100 dark:bg-neutral-800 rounded-full flex items-center justify-center ">
          <div><input
            type="text"
            defaultValue={editedDisplayPrix}
            className="text-xs font-medium text-center"
            style={{ maxWidth: '100px' }} 
            maxLength={11}
            onBlur={(event) => handleBlurPrix(event, "prix_trajet")}
            onFocus={(event) => handleFocusPrix(event)}
            placeholder={t('prix')}
          />
          </div>
          <div>
          <SymboleMonnaie symbole={symbole_monnaie}/>
          </div>
          <div>
          { editedDisplayPrix !== '' && Number(editedDisplayPrix) !== 0 && (
           < CheckboxSmall label={t('paye?')} name="paye" 
            onChange={(checked) => handleChangeCheckbox(checked)} 
            checked={editedPaiement === 'paye'} />
          )}
           </div>
        </div>
      )}
    </div>
  );
};

export default CardTrajetModif;
