import Axios from './caller.service';

let getVoyage = (idVoyage, emailPartage, codePartage, langue) => {
    if(emailPartage !== "" && codePartage !== "")
    {
      return Axios.get('/voyage/' + idVoyage + '/langue/' + langue + '/email/' + emailPartage + '/code/' + codePartage);
    }
    else
    {
      return Axios.get('/voyage/' + idVoyage + '/langue/' + langue);
    }
  }

  let getVoyageIntegre = (idVoyage) => {
   
    return Axios.get('/voyage/integre/' + idVoyage);

  }

let getStatistiques = (idVoyage, emailPartage, codePartage) => {

   if(emailPartage !== "" && codePartage !== "")
    {
      return Axios.get('/voyage/' + idVoyage + '/email/' + emailPartage + '/code/' + codePartage +'/statistiques');
    }
    else
    {
      return Axios.get('/voyage/' + idVoyage + '/statistiques');
    }
  }

let exporterVoyage = (idVoyage, format, type) => {
    return Axios.get('/voyage/' + idVoyage + '/export/format/' + format + '/type/' + type);
}

let getContenuBagage = (idVoyage) => {
    return Axios.get('/voyage/' + idVoyage + '/bagage');
  }

  let ajouterFraisPerso = (idVoyage, contenu) => {
    return Axios.put('/voyage/'+idVoyage+'/frais/ajout', contenu);
  }

let supprimerFraisPerso = (idVoyage, contenu) => {
  return Axios.put('/voyage/'+idVoyage+'/frais/suppression', contenu);
}

let ajouterContenuBagage = (idVoyage, contenu) => {
    return Axios.put('/voyage/'+idVoyage+'/bagage/ajout', contenu);
  }

let supprimerContenuBagage = (idVoyage, contenu) => {
  return Axios.put('/voyage/'+idVoyage+'/bagage/suppression', contenu);
}

let modifierContenuBagage = (idVoyage, contenu) => {
  return Axios.put('/voyage/'+idVoyage+'/bagage/modification', contenu);
}

let modifierNotifBagage = (idVoyage) => {
  return Axios.put('/voyage/'+idVoyage+'/bagage/notification');
}

let getMap = (idVoyage) => {
    return Axios.get('/voyage/' + idVoyage+'/map');
  }

let getVoyagesPublics = (langue, debut) => {
    return Axios.get('/voyages/' + langue + '/debut/' + debut);
  }

let getVoyagesPublicsPays = (codePays) => {
    return Axios.get('/voyages/pays/' + codePays);
  }
  
  
let creerVoyage = (voyage) => {
    return Axios.post('/voyage/', voyage);
  }

  let modifierVoyage = (voyage) => {
    return Axios.put('/voyage/', voyage);
  }

let ajouterEvenement = (idVoyage, evenement) => {
    return Axios.put('/voyage/'+idVoyage+'/ajoutevenement', evenement);
  }

let deplacerEvenement = (idVoyage, evenement) => {
  return Axios.put('/voyage/'+idVoyage+'/deplacementevenement', evenement);
}

let likeVoyage = (idVoyage, config = {}) => {
    return Axios.post('/voyage/like/',idVoyage, config);
  }

let dislikeVoyage = (idVoyage, config = {}) => {
    return Axios.delete('/voyage/like/'+idVoyage, config);
  }
  
  let supprimerEvenement = (idVoyage, evenement) => {
    return Axios.put('/voyage/'+idVoyage+'/suppressionevenement', evenement);
  }
  
  let modifierEvenement = (idVoyage, evenement) => {
    return Axios.put('/voyage/'+idVoyage+'/modificationevenement', evenement);
  }
  
  let modifierNuits = (idVoyage, nuits) => {
    return Axios.put('/voyage/'+idVoyage+'/modificationnuits', nuits);
  }

  let echangerNuits = (idVoyage, nuits) => {
    return Axios.put('/voyage/'+idVoyage+'/echangernuits', nuits);
  }

  let enregistrerDistance = (idVoyage, evenement) => {
    return Axios.put('/voyage/'+idVoyage+'/distance', evenement);
  }

  let gererTrajetLendemain = (idVoyage, evenement) => {
    return Axios.put('/voyage/'+idVoyage+'/gestiontrajet', evenement);
  }
  
  let echangerJournees = (idVoyage, evenement) => {
    return Axios.put('/voyage/'+idVoyage+'/echangejournees', evenement);
  }

  let getLien = (idVoyage, typeEvenement, idEvenement, emailPartage, codePartage) => {
    if(emailPartage !== "" && codePartage !== "")
    {
      return Axios.get('/voyage/' + idVoyage + '/email/' + emailPartage + '/code/' + codePartage +'/lien/'+typeEvenement+'/'+idEvenement, );
    }
    else
    {
      return Axios.get('/voyage/' + idVoyage+'/lien/'+typeEvenement+'/'+idEvenement, );
    }
    
  }

  let enregistrerLien = (idVoyage, evenement) => {
    return Axios.put('/voyage/'+idVoyage+'/lien', evenement);
  }

  let getBlocNotes = (idVoyage, typeEvenement, idEvenement, emailPartage, codePartage) => {
    if(emailPartage !== "" && codePartage !== "")
    {
      return Axios.get('/voyage/' + idVoyage + '/email/' + emailPartage + '/code/' + codePartage +'/blocnotes/'+typeEvenement+'/'+idEvenement, );
    }
    else
    {
      return Axios.get('/voyage/' + idVoyage+'/blocnotes/'+typeEvenement+'/'+idEvenement, );
    }
  }

  let enregistrerBlocNotes = (idVoyage, evenement) => {
    return Axios.put('/voyage/'+idVoyage+'/blocnotes', evenement);
  }

  let getCoordonnees = (idVoyage, typeEvenement, idEvenement) => {

    return Axios.get('/voyage/' + idVoyage+'/coordonnees/'+typeEvenement+'/'+idEvenement, );
  }

  let getTrajet = (idVoyage, idEvenement) => {

    return Axios.get('/voyage/' + idVoyage+'/trajet/'+idEvenement, );
  }

  let enregistrerCodeAeroport = (idVoyage, evenement) => {

    return Axios.put('/voyage/'+idVoyage+'/codeaeroport', evenement);
  }

  let enregistrerCoordonnees= (idVoyage, evenement) => {

    return Axios.put('/voyage/'+idVoyage+'/coordonnees', evenement);
  }


  let enregistrerCoordonnees2= (idVoyage, evenement) => {

    return Axios.put('/voyage/'+idVoyage+'/coordonnees2', evenement);
  }

  let uploadPhoto = (idVoyage, formData, config) => {
    return Axios.post('/upload/photo/'+idVoyage, formData, config);
  }

  let supprimerPhoto = (idVoyage, nomPhoto) => {
    return Axios.delete('/upload/photo/'+idVoyage+'/'+nomPhoto);
  }

  let getConfirm = (idVoyage, typeEvenement, idEvenement, emailPartage, codePartage) => {

    if(emailPartage !== "" && codePartage !== "")
    {
      return Axios.get('/upload/confirm/' + idVoyage+'/email/' + emailPartage + '/code/' + codePartage+'/'+typeEvenement+'/'+idEvenement );
    }
    else
    {
      return Axios.get('/upload/confirm/' + idVoyage+'/'+typeEvenement+'/'+idEvenement );

    }
}

  let uploadConfirm = (idVoyage, formData, config) => {
    return Axios.post('/upload/confirm/'+idVoyage, formData, config);
  }

  let uploadCouverture = (idVoyage, formData, config) => {
    return Axios.post('/upload/couverture/'+idVoyage, formData, config);
  }

  let supprimerCouverture = (idVoyage, nomCouverture) => {
    return Axios.delete('/upload/couverture/'+idVoyage+'/'+nomCouverture);
  }

  let supprimerConfirm = (idVoyage, nomConfirm) => {
    return Axios.delete('/upload/confirm/'+idVoyage+'/'+nomConfirm);
  }

  let copierNuit = (idVoyage, evenement) => {
    return Axios.put('/voyage/'+idVoyage+'/copieNuit', evenement);
  }

  let copierVoyage = (idVoyage, nouvDate) => {
    return Axios.put('/voyage/'+idVoyage+'/copie', nouvDate);
  }

  let supprimerVoyage = (idVoyage) => {
    return Axios.delete('/voyage/'+idVoyage+'/suppression');
}

let partagerVoyage = (idVoyage, infosPartage) => {
  return Axios.put('/voyage/'+idVoyage+'/partage', infosPartage);
}

let getCoCreateursVoyage = (idVoyage) => {
  return Axios.get('/voyage/'+idVoyage+'/cocreateurs');
}

let supprimerCoCreateurVoyage = (idVoyage, infosCoCreateur) => {
  return Axios.put('/voyage/'+idVoyage+'/suppressioncocreateur', infosCoCreateur);
}

let ajouterCoCreateurVoyage = (idVoyage, infosCoCreateur) => {
  return Axios.put('/voyage/'+idVoyage+'/ajoutcocreateur', infosCoCreateur);
}

let accepterCoCreateurVoyage = (idVoyage) => {
  return Axios.put('/voyage/'+idVoyage+'/acceptationcocreateur');
}

let refuserCoCreateurVoyage = (idVoyage) => {
  return Axios.put('/voyage/'+idVoyage+'/refuscocreateur');
}

let verrouillerVoyage = (idVoyage) => {
  return Axios.put('/voyage/'+idVoyage+'/verrouiller');
}

let deverrouillerVoyage = (idVoyage) => {
  return Axios.put('/voyage/'+idVoyage+'/deverrouiller');
}

let getEditionPDF = (idVoyage, affichage) => {
  return Axios.put('/fichiers/pdf/' + idVoyage, affichage);
}

let getEditionPDFPhotos = (idVoyage, affichage) => {
  return Axios.put('/fichiers/pdfphotos/' + idVoyage, affichage);
}

let getLiens = () => {
  return Axios.get('/voyages/liens');
}

let validerLien = (idVoyage, lien) => {
  return Axios.put('/voyage/'+idVoyage+'/validation/lien', lien);
}


let getMonnaies = () => {
   
  return Axios.get('/voyage/monnaies/');

}

export const voyageService = {
    getVoyage,
    getVoyagesPublics,
    getVoyagesPublicsPays,
    creerVoyage,
    modifierVoyage,
    ajouterEvenement,
    likeVoyage,
    dislikeVoyage,
    deplacerEvenement,
    supprimerEvenement,
    modifierEvenement,
    modifierNuits,
    enregistrerDistance,
    gererTrajetLendemain,
    echangerJournees,
    getLien,
    enregistrerLien,
    getBlocNotes,
    enregistrerBlocNotes,
    getCoordonnees,
    getTrajet,
    enregistrerCoordonnees,
    enregistrerCoordonnees2,
    uploadPhoto,
    supprimerPhoto,
    getConfirm,
    uploadConfirm,
    supprimerConfirm,
    uploadCouverture,
    supprimerCouverture,
    getMap,
    copierNuit,
    getStatistiques,
    getContenuBagage,
    ajouterContenuBagage,
    supprimerContenuBagage,
    modifierContenuBagage,
    modifierNotifBagage,
    exporterVoyage,
    ajouterFraisPerso,
    supprimerFraisPerso,
    enregistrerCodeAeroport,
    copierVoyage,
    supprimerVoyage,
    partagerVoyage,
    getCoCreateursVoyage,
    supprimerCoCreateurVoyage,
    ajouterCoCreateurVoyage,
    accepterCoCreateurVoyage,
    refuserCoCreateurVoyage,
    getEditionPDF,
    getEditionPDFPhotos,
    echangerNuits,
    getVoyageIntegre,
    getLiens,
    validerLien,
    verrouillerVoyage,
    deverrouillerVoyage,
    getMonnaies
  }