import React, { FC, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import ButtonClose from 'shared/ButtonClose/ButtonClose';
import {apiKeyGeoapify, apiKeyKiwi } from '_config/constantes'; 
import { voyageService } from '_services/voyage.service';
import { distanceService } from '_services/distance.service'
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { InformationCookiesMarketing } from 'shared/InformationCookiesMarketing/InformationCookiesMarketing';


declare var Booking: any;

export interface ModalBookingVolsProps {
  onBookingVolsCloseModal: () => void;
  idEvenement: number;
  nbVoyageurs: number;
  voyageKey: string;
}

const ModalBookingVols: FC<ModalBookingVolsProps> = ({
  onBookingVolsCloseModal,
  idEvenement,
  nbVoyageurs,
  voyageKey
}) => {

  let navigate=useNavigate();
  const { t } = useTranslation();

  const [cookiePartenaires, setCookiePartenaires] = useState<boolean>(true);

    // Fonction pour obtenir la valeur d'un cookie
    const getCookie = (name: string) => {
      const cookieValue = document.cookie
        .split('; ')
        .find(row => row.startsWith(name))
        ?.split('=')[1];
      return cookieValue || '';
    };

  const handleCloseModalBookingVols = () => {
    onBookingVolsCloseModal();
  };

  const handleInfoClick = () => {
    alert(
      "En cliquant sur le bouton 'Réserver' et en réservant chez notre partenaire Booking, tripkygo touchera une commission sans modifier le prix que vous paierez. Nous espérons que cela permettra de maintenir notre site 100% gratuit !"
    );
  };


  useEffect(() => {
  
   const cookieValue = getCookie("tripkygoPartenaires");

    // Si le cookie existe et a la valeur 'O', met à jour isLiked à true
    if (cookieValue && cookieValue === 'O') {
  
      setCookiePartenaires(true);
      const script = document.createElement('script');
      script.src = 'https://www.booking.com/affiliate/prelanding_sdk';
      script.onload = () => {
        if (typeof Booking !== 'undefined' && Booking.AffiliateWidget) {
          new Booking.AffiliateWidget({
            iframeSettings: {
              selector: 'bookingAffiliateWidget_edb02e38-7ca4-4d1f-9477-d75e107f0c37',
              responsive: true,
            },
           widgetSettings: {
           //  depart: "2025-08-27",
           //  return: "2025-08-29",
             
         //    from:"CDG.AIRPORT",
         //    to:"MAD.AIRPORT"
             //return: dateArrivee,
             //from=PAR.CITY&to=MAD.CITY
             //type=ROUNDTRIP&adults=1&cabinClass=ECONOMY&children=&from=CDG.AIRPORT&to=MAD.AIRPORT
  
            },
          });
          
        } else {
          console.error("Le script du widget affilié Booking n'a pas été chargé ou est indéfini.");
        }
      };
  
      document.body.appendChild(script);
  
      return () => {
        document.body.removeChild(script);
      };

   /* voyageService.getTrajet(voyageKey, idEvenement)
      .then((response: any) => {
              
        if(response.data.message === 'ok')
        {

          var date = new Date()
          var dateDuJour=date.getFullYear()+('0'+(date.getMonth()+1)).slice(-2)+('0'+date.getDate()).slice(-2);
          var dateDepartComp=response.data.dateDepart.substring(6,10)+response.data.dateDepart.substring(3,5)+response.data.dateDepart.substring(0,2);

          var dateDepart=date.getFullYear()+'-'+('0'+(date.getMonth()+1)).slice(-2)+'-'+('0'+date.getDate()).slice(-2);

          
          if(dateDepartComp >= dateDuJour)
          {
            dateDepart=response.data.dateDepart.substring(6,10)+'-'+response.data.dateDepart.substring(3,5)+'-'+response.data.dateDepart.substring(0,2);
          }

          var typeTransport='';
          var typeLocation='';
          var distanceRecherche='';

          switch(response.data.typeTrajet) {
            case 'plane':
                typeTransport='FLIGHT';
                typeLocation='airport';
                distanceRecherche='5';
              break;
            case 'bus':
                typeTransport='BUS';
                typeLocation='city';
                distanceRecherche='5';
              break;
            case 'train':
                typeTransport='TRAIN';
                typeLocation='station';
                distanceRecherche='0.2';
                break;
            default:
                typeTransport='FLIGHT,BUS,TRAIN';
                typeLocation='city';
                distanceRecherche='5';
          }

          var codeAeroportDepart='';
          var codeAeroportArrivee='';

          if((response.data.codeAeroportDepart !== '' && response.data.codeAeroportArrivee !== '' && response.data.codeAeroportDepart !== null && response.data.codeAeroportArrivee !== null) 
          || (response.data.codeAeroportDepart !== '' && response.data.codeAeroportDepart !== null && (response.data.codeAeroportArrivee === '' || response.data.codeAeroportArrivee === null) && (response.data.placeIdArrivee === '' || response.data.placeIdArrivee === null || response.data.placeIdArrivee === 'x') && response.data.latitude2 === '' && response.data.longitude2 === '')
          || ((response.data.codeAeroportDepart === '' || response.data.codeAeroportDepart !== null) && response.data.codeAeroportArrivee !== '' && response.data.codeAeroportArrivee !== null  && (response.data.placeIdDepart === '' || response.data.placeIdDepart === null || response.data.placeIdDepart === 'x') && response.data.latitude1 === '' && response.data.longitude1 === ''))
          {   

            codeAeroportDepart=response.data.codeAeroportDepart;
            codeAeroportArrivee=response.data.codeAeroportArrivee;  

            widgetWiki(codeAeroportDepart, codeAeroportArrivee, dateDepart, typeTransport);
   
          }
          else if((response.data.codeAeroportDepart === '' || response.data.codeAeroportDepart === null)  && response.data.placeIdDepart !== '' && response.data.placeIdDepart !== null && response.data.placeIdDepart !== 'x' && (response.data.codeAeroportArrivee === '' || response.data.codeAeroportArrivee === null) && response.data.placeIdArrivee !== '' && response.data.placeIdArrivee !== 'x' && response.data.placeIdArrivee !== null)
          {           

            apiCodeAeroport(response.data.placeIdDepart).then((data: any) => {
               data.features.forEach((feature: any) => {


                   if(feature.properties.airport !== undefined)
                   {

                       codeAeroportDepart=feature.properties.airport.iata;
 
                       apiCodeAeroport(response.data.placeIdArrivee).then((data2: any) => {
                           data2.features.forEach((feature2: any) => {
                                                          
                               if(feature2.properties.airport !== undefined)
                               {
 
                                   codeAeroportArrivee=feature2.properties.airport.iata;
 
                                   widgetWiki(codeAeroportDepart, codeAeroportArrivee, dateDepart, typeTransport);

                                  voyageService.enregistrerCodeAeroport(voyageKey, {
                                    codeDepart: codeAeroportDepart,
                                    codeArrivee: codeAeroportArrivee,
                                    idEvenement: idEvenement 
                                  })
                                  .then((response: any) => {
                                    
                              
                                  })
                                  .catch((error: any) => {
                              
                                    if (error.response?.status === 401) {
                                      navigate('/');
                                    }
                               
                                  })
                               }
                               else
                               {
                                  recupWikiLocationArrivee(response.data.latitude2, response.data.longitude2, typeLocation, distanceRecherche, codeAeroportDepart, codeAeroportArrivee, dateDepart, typeTransport);                       
                               }
 
                           });
                       });
                   }
                   else
                   {
                       apiCodeAeroport(response.data.placeIdArrivee).then((data2: any) => {
                           data2.features.forEach((feature2: any) => {
                               if(feature2.properties.airport !== undefined)
                               {
                                   var codeAeroportArrivee=feature2.properties.airport.iata;
                                 
                                  recupWikiLocationDepart(response.data.latitude1, response.data.longitude1, typeLocation, distanceRecherche, codeAeroportDepart, codeAeroportArrivee, dateDepart, typeTransport);                       

                                   voyageService.enregistrerCodeAeroport(voyageKey, {
                                    codeDepart: codeAeroportDepart,
                                    codeArrivee: codeAeroportArrivee,
                                    idEvenement: idEvenement 
                                  })
                                  .then((response: any) => {
                                    
                              
                                  })
                                  .catch((error: any) => {
                              
                                    if (error.response?.status === 401) {
                                      navigate('/');
                                    }
                               
                                  })
                               }
                               else
                               {
                                  recupWikiLocations(response.data.latitude1, response.data.longitude1, response.data.latitude2, response.data.longitude2, typeLocation, distanceRecherche, codeAeroportDepart, codeAeroportArrivee, dateDepart, typeTransport);                       
                               }
 
                           });
                       });
                   }
 
               });
           });
         }
         else if((response.data.codeAeroportArrivee === '' || response.data.codeAeroportArrivee === null) && response.data.placeIdArrivee !== '' && response.data.placeIdArrivee !== null && response.data.placeIdArrivee !== 'x' ){           
          apiCodeAeroport(response.data.placeIdArrivee).then((data: any) => {
              data.features.forEach((feature:any) => {
                  if(feature.properties.airport !== undefined)
                  {
                      var codeAeroportArrivee=feature.properties.airport.iata;
                  
                      widgetWiki(response.data.codeAeroportDepart, codeAeroportArrivee, dateDepart, typeTransport);

                      voyageService.enregistrerCodeAeroport(voyageKey, {
                        codeDepart: codeAeroportDepart,
                        codeArrivee: codeAeroportArrivee,
                        idEvenement: idEvenement 
                      })
                      .then((response: any) => {
                        
                  
                      })
                      .catch((error: any) => {
                  
                        if (error.response?.status === 401) {
                          navigate('/');
                        }
                  
                      })
                  }
                  else if(response.data.latitude2 !== '' && response.data.latitude2 !== null && response.data.longitude2 !== '' && response.data.longitude2 !== null)
                  {

                      recupWikiLocationArrivee(response.data.latitude2, response.data.longitude2, typeLocation, distanceRecherche, response.data.codeAeroportDepart, response.data.codeAeroportArrivee, dateDepart, typeTransport);
                  }
                  else
                  {
 
                      widgetWiki(response.data.codeAeroportDepart, response.data.codeAeroportArrivee, dateDepart, typeTransport);
                  }
                  
              });
          });
        }
        else{ 
              if(response.data.latitude1 !== '' && response.data.latitude1 !== null && response.data.longitude1 !== '' && response.data.longitude1 !== null && response.data.latitude2 !== '' && response.data.latitude2 !== null && response.data.longitude2 !== '' && response.data.longitude2 !== null)
              {
                  recupWikiLocations(response.data.latitude1, response.data.longitude1, response.data.latitude2, response.data.longitude2, typeLocation, distanceRecherche, response.data.codeAeroportDepart, response.data.codeAeroportArrivee, dateDepart, typeTransport);                                                     
              }
              else if(response.data.latitude1 !== '' && response.data.longitude1 !== '')
              {
                  recupWikiLocationDepart(response.data.latitude1, response.data.longitude1, typeLocation, distanceRecherche, response.data.odeAeroportDepart, response.data.codeAeroportArrivee, dateDepart, typeTransport);
              }
              else if(response.data.latitude2 !== '' && response.data.longitude2 !== '')
              {
                  recupWikiLocationArrivee(response.data.latitude2, response.data.longitude2, typeLocation, distanceRecherche, response.data.codeAeroportDepart, response.data.codeAeroportArrivee, dateDepart, typeTransport);
              }
              else
              {
                  widgetWiki(response.data.codeAeroportDepart, response.data.codeAeroportArrivee, dateDepart, typeTransport);
              }

        }
        }
        else
        {
          alert("Erreur lors de la récupération du trajet");
        }

      })
      .catch((error: any) => {

        if (error.response?.status === 401) {
          alert("Erreur lors de la récupération du trajet, vous n'êtes pas connecté(e) avec le compte du créateur du voyage.")
          navigate('/');
        }
        else
        {
          alert("Erreur lors de la récupération du trajet");
        }
        
        console.log('error',error);
      })*/
    }
    else {
      setCookiePartenaires(false);
    }
   
  }, [nbVoyageurs]);


  function widgetWiki(codeAeroportDepart: string, codeAeroportArrivee: string, dateDepart: string, typeTransport: string) {

console.log("dateDepart",dateDepart);
console.log("codeAeroportDepart",codeAeroportDepart);
console.log("codeAeroportArrivee",codeAeroportArrivee);
console.log("typeTransport",typeTransport);
    setCookiePartenaires(true);
    const script = document.createElement('script');
    script.src = 'https://www.booking.com/affiliate/prelanding_sdk';
    script.onload = () => {
      if (typeof Booking !== 'undefined' && Booking.AffiliateWidget) {
        new Booking.AffiliateWidget({
          iframeSettings: {
            selector: 'bookingAffiliateWidget_edb02e38-7ca4-4d1f-9477-d75e107f0c37',
            responsive: true,
          },
         widgetSettings: {
           depart: "2025-08-27",
           return: "2025-08-29",
           
           from:"CDG.AIRPORT",
           to:"MAD.AIRPORT"
           //return: dateArrivee,
           //from=PAR.CITY&to=MAD.CITY
           //type=ROUNDTRIP&adults=1&cabinClass=ECONOMY&children=&from=CDG.AIRPORT&to=MAD.AIRPORT
            /*ss: getBookingLocation(),
            latitude: latitude || latitudeHeb || 48.8566,
            longitude: longitude || latitudeHeb || 2.3522,
            checkin: "2024-08-29",
            checkout: "2024-08-30",
            zoom: 9,*/
          },
        });
        
      } else {
        console.error("Le script du widget affilié Booking n'a pas été chargé ou est indéfini.");
      }
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };

   /* const script = document.createElement('script');
    script.src = 'https://widgets.kiwi.com/scripts/widget-search-iframe.js';
    script.async = true;

    script.dataset.passengers = nbVoyageurs+'-0-0';
    script.dataset.affilid = 'tripkygowidget';
    script.dataset.from = codeAeroportDepart;
    script.dataset.to = codeAeroportArrivee;
    script.dataset.departure = dateDepart; //'2024-08-25';
    script.dataset.cabin = 'ECONOMY';
    script.dataset.transportTypes = typeTransport;//'FLIGHT,BUS,TRAIN';
    script.dataset.stopNumber = '#0';
    script.dataset.limit = '500';
    script.dataset.primaryColor = '#ff4a52';
    script.dataset.backgroundPrimaryColor = '#ffffff';
    script.dataset.backgroundSecondaryColor = 'ffffff';
    script.dataset.foregroundPrimaryColor = '040e27';
    script.dataset.foregroundSecondaryColor = '4d2419';

    const widgetContainer = document.getElementById('widget-holder');
    if (widgetContainer) {
      widgetContainer.appendChild(script);
    }

    return () => {
      if (widgetContainer) {
        widgetContainer.removeChild(script);
      }
    };*/
    
  }

  function apiCodeAeroport(placeId: string) {
  
     return new Promise(function(resolve, reject) {
      var url = `https://api.geoapify.com/v2/place-details?id=${placeId}&lang=fr&apiKey=${apiKeyGeoapify}`;
       fetch(url)
       .then(response => {
         // check if the call was successful
         if (response.ok) {
           response.json().then(data => resolve(data));
         } else {
           response.json().then(data => reject(data));
         }
       });
     });
   }

   function appelApiKiwiLocation(latitude: string, longitude: string, typeLocation: string, distanceRecherche: string) {

    return new Promise(function(resolve, reject) {
     var url  = 'https://tequila-api.kiwi.com/locations/radius?lat='+latitude+'&lon='+longitude+'&radius='+distanceRecherche+'&locale=fr-FR&limit=2&active_only=true&location_types='+typeLocation;
     var myHeaders = new Headers();
     myHeaders.append("Origin", "*");
     myHeaders.append("apikey", apiKeyKiwi);
     fetch(url, {mode: 'cors', headers: myHeaders})
      .then(response => {
        // check if the call was successful
        if (response.ok) {
          response.json().then(data => resolve(data));
        } else {
          response.json().then(data => reject(data));
        }
      });
    });
  }

function recupWikiLocationDepart(latDepart: any, longDepart: any, typeLocation: any, distanceRecherche: any, codeAeroportDepart: any, codeAeroportArrivee: any, dateDepart: any, typeTransport: string)
{
      appelApiKiwiLocation(latDepart, longDepart, typeLocation, distanceRecherche).then((data: any) => {
          if(data.locations.length === 1)
          {
              data.locations.forEach((location: any) => {
                  widgetWiki(location.code, codeAeroportArrivee, dateDepart, typeTransport);                             
              });
          }
          else if(data.locations.length === 2)
          {
              var codeDepart1='';
              var codeDepart2='';
              var lat1='';
              var long1='';
              var lat2='';
              var long2='';
              var distanceLocationCalc1=0;
              var distanceLocationCalc2=0;

              data.locations.forEach((location: any, index: number) => {
                  if(index === 0)
                  {
                      codeDepart1=location.code;

                      lat1=location.lat;
                      long1=location.lon;     
                      
                      distanceLocationCalc1=distanceService.distance(lat1, long1, latDepart, longDepart, 'K');
                  }  
                  else if(index === 1)
                  {
                      codeDepart2=location.code;

                      lat2=location.lat;
                      long2=location.lon;      
                      
                      distanceLocationCalc2=distanceService.distance(lat2, long2, latDepart, longDepart, 'K');

                      if(distanceLocationCalc2 < distanceLocationCalc1)
                      {
                          widgetWiki(codeDepart2, codeAeroportArrivee, dateDepart, typeTransport);
                      }
                      else
                      {
                          widgetWiki(codeDepart1, codeAeroportArrivee, dateDepart, typeTransport);
                      }
                  }     
              });
          }
          else
          {
              appelApiKiwiLocation(latDepart, longDepart, 'city', '5').then((data: any) => {
                  if(data.locations.length === 1)
                  {
                      data.locations.forEach((location: any) => {
                          widgetWiki(location.code, codeAeroportArrivee, dateDepart, typeTransport);                             
                      });
                  }
                  else if(data.locations.length === 2 )
                  {
                      var codeDepart1='';
                      var codeDepart2='';
                      var lat1='';
                      var long1='';
                      var lat2='';
                      var long2='';
                      var distanceLocationCalc1=0;
                      var distanceLocationCalc2=0;

                      data.locations.forEach((location: any, index: number) => {
                          if(index === 0)
                          {
                              codeDepart1=location.code;

                              lat1=location.lat;
                              long1=location.lon;     
                              
                              distanceLocationCalc1=distanceService.distance(lat1, long1, latDepart, longDepart, 'K');
                          }  
                          else if(index === 1)
                          {
                              codeDepart2=location.code;

                              lat2=location.lat;
                              long2=location.lon;      
                              
                              distanceLocationCalc2=distanceService.distance(lat2, long2, latDepart, longDepart, 'K');

                              if(distanceLocationCalc2 < distanceLocationCalc1)
                              {
                                  widgetWiki(codeDepart2, codeAeroportArrivee, dateDepart, typeTransport);
                              }
                              else
                              {
                                  widgetWiki(codeDepart1, codeAeroportArrivee, dateDepart, typeTransport);
                              }
                          }     
                                                      
                      });
                  }
                  else if(data.locations.length > 2 )
                  {
                      data.locations.forEach((location: any, index: number) => {
                          if(index === 0)
                          {
                             var codeDepart=location.code;
                              widgetWiki(codeDepart, codeAeroportArrivee, dateDepart, typeTransport);                                    
                          }
                                                      
                      });
                  }
                  else
                  {
                      widgetWiki(codeAeroportDepart, codeAeroportArrivee, dateDepart, typeTransport);  
                  }
              });   
                                       
          }
      });
}
    
function recupWikiLocationArrivee(latArrivee: any, longArrivee: any, typeLocation: any, distanceRecherche: any, codeAeroportDepart: any, codeAeroportArrivee: any, dateDepart: any, typeTransport: string)
{

  appelApiKiwiLocation(latArrivee, longArrivee, typeLocation, distanceRecherche).then((data: any) => {

      if(data.locations.length === 1)
      {
          data.locations.forEach((location: any) => {
              widgetWiki(codeAeroportDepart, location.code, dateDepart, typeTransport);                             
          });
      }
      else if(data.locations.length === 2)
          {
              var codeArrivee1='';
              var codeArrivee2='';
              var lat1='';
              var long1='';
              var lat2='';
              var long2='';
              var distanceLocationCalc1=0;

              data.locations.forEach((location: any, index: number) => {
                  if(index === 0)
                  {
                      codeArrivee1=location.code;

                      lat1=location.lat;
                      long1=location.lon;     
                      
                      distanceLocationCalc1=distanceService.distance(lat1, long1, latArrivee, longArrivee, 'K');
                  }  
                  else if(index === 1)
                  {
                      codeArrivee2=location.code;

                      lat2=location.lat;
                      long2=location.lon;      
                      
                      var distanceLocationCalc2=distanceService.distance(lat2, long2, latArrivee, longArrivee, 'K');

                      if(distanceLocationCalc2 < distanceLocationCalc1)
                      {
                          widgetWiki(codeAeroportDepart, codeArrivee2, dateDepart, typeTransport);
                      }
                      else
                      {
                          widgetWiki(codeAeroportDepart, codeArrivee1, dateDepart, typeTransport);
                      }
                  }     
              });
          }
      else
      {
          appelApiKiwiLocation(latArrivee, longArrivee, 'city', '5').then((data: any) => {
              if(data.locations.length === 1)
              {
                  data.locations.forEach((location: any, index: number) => {
                      if(index === 0)
                      {
                          var codeArrivee=location.code;
                          widgetWiki(codeAeroportDepart, codeArrivee, dateDepart, typeTransport);                                    
                      }
                                                  
                  });
              }
          else if(data.locations.length === 2)
          {
              codeArrivee1='';
              codeArrivee2='';
              lat1='';
              long1='';
              lat2='';
              long2='';

              var distanceLocationCalc1=0;

              data.locations.forEach((location: any, index: number) => {
                  if(index === 0)
                  {
                      codeArrivee1=location.code;

                      lat1=location.lat;
                      long1=location.lon;     
                      
                      distanceLocationCalc1=distanceService.distance(lat1, long1, latArrivee, longArrivee, 'K');
                  }  
                  else if(index === 1)
                  {
                      codeArrivee2=location.code;

                      lat2=location.lat;
                      long2=location.lon;      
                      
                      var distanceLocationCalc2=distanceService.distance(lat2, long2, latArrivee, longArrivee, 'K');

                      if(distanceLocationCalc2 < distanceLocationCalc1)
                      {
                          widgetWiki(codeAeroportDepart, codeArrivee2, dateDepart, typeTransport);
                      }
                      else
                      {
                          widgetWiki(codeAeroportDepart, codeArrivee1, dateDepart, typeTransport);
                      }
                  }     
              });
             }
             else if(data.locations.length > 2 )
             {
                 data.locations.forEach((location: any, index: number) => {
                     if(index === 0)
                     {
                         var codeArrivee=location.code;
                         widgetWiki(codeAeroportDepart, codeArrivee, dateDepart, typeTransport);                                    
                     }
                                                 
                 });
             }
              else
              {
                  widgetWiki(codeAeroportDepart, codeAeroportArrivee, dateDepart, typeTransport);  
              }
          });   
                                   
      }
  });
}


function recupWikiLocations(latDepart: any, longDepart: any, latArrivee: any, longArrivee: any, typeLocation: any, distanceRecherche: any, codeAeroportDepart: any, codeAeroportArrivee: any, dateDepart: any, typeTransport: string)
{
      var codeDepart='';
      appelApiKiwiLocation(latDepart, longDepart, typeLocation, distanceRecherche).then((data: any) => {
          if(data.locations.length === 1)
          {
              data.locations.forEach((location: any) => {
                   codeDepart=location.code;
                   recupWikiLocationArrivee(latArrivee, longArrivee, typeLocation, distanceRecherche, codeDepart, codeAeroportArrivee, dateDepart, typeTransport);                           
              });
          }
          else if(data.locations.length === 2)
          {
              var codeDepart1='';
              var codeDepart2='';
              var lat1='';
              var long1='';
              var lat2='';
              var long2='';
              var distanceLocationCalc1=0;
              var distanceLocationCalc2=0;

              data.locations.forEach((location: any, index: number) => {
                  if(index === 0)
                  {
                      codeDepart1=location.code;

                      lat1=location.lat;
                      long1=location.lon;     
                      
                      distanceLocationCalc1=distanceService.distance(lat1, long1, latDepart, longDepart, 'K');
                  }  
                  else if(index === 1)
                  {
                      codeDepart2=location.code;

                      lat2=location.lat;
                      long2=location.lon;      
                      
                      distanceLocationCalc2=distanceService.distance(lat2, long2, latDepart, longDepart, 'K');

                      if(distanceLocationCalc2 < distanceLocationCalc1)
                      {
                          recupWikiLocationArrivee(latArrivee, longArrivee, typeLocation, distanceRecherche, codeDepart2, codeAeroportArrivee, dateDepart, typeTransport);                                                                            
                      }
                      else
                      {
                          recupWikiLocationArrivee(latArrivee, longArrivee, typeLocation, distanceRecherche, codeDepart1, codeAeroportArrivee, dateDepart, typeTransport);                                                                                          
                      }
                  }     
              });
          }
          else {
          appelApiKiwiLocation(latDepart, longDepart, 'city', '5').then((data: any) => {

              if(data.locations.length === 1)
              {
                  data.locations.forEach((location: any) => {
                      recupWikiLocationArrivee(latArrivee, longArrivee, typeLocation, distanceRecherche, location.code, codeAeroportArrivee, dateDepart, typeTransport);                                                                                                                      
                  });
              }
              else if(data.locations.length === 2 )
              {
                  var codeDepart1='';
                  var codeDepart2='';
                  var lat1='';
                  var long1='';
                  var lat2='';
                  var long2='';
                  var distanceLocationCalc1=0;
                  var distanceLocationCalc2=0;

                  data.locations.forEach((location: any, index: number) => {
                      if(index === 0)
                      {
                          codeDepart1=location.code;

                          lat1=location.lat;
                          long1=location.lon;     
                          
                          distanceLocationCalc1=distanceService.distance(lat1, long1, latDepart, longDepart, 'K');
                      }  
                      else if(index === 1)
                      {
                          codeDepart2=location.code;

                          lat2=location.lat;
                          long2=location.lon;      
                          
                          distanceLocationCalc2=distanceService.distance(lat2, long2, latDepart, longDepart, 'K');

                          if(distanceLocationCalc2 < distanceLocationCalc1)
                          {
                              recupWikiLocationArrivee(latArrivee, longArrivee, typeLocation, distanceRecherche, codeDepart2, codeAeroportArrivee, dateDepart, typeTransport);                                                                                          
                          }
                          else
                          {
                              recupWikiLocationArrivee(latArrivee, longArrivee, typeLocation, distanceRecherche, codeDepart1, codeAeroportArrivee, dateDepart, typeTransport);                                                                                          
                          }
                      }     
                                                  
                  });
              }
              else if(data.locations.length > 2 )
              {
                  data.locations.forEach((location: any, index: number) => {
                      if(index === 0)
                      {
                          var codeDepart=location.code;
                          recupWikiLocationArrivee(latArrivee, longArrivee, typeLocation, distanceRecherche, codeDepart, codeAeroportArrivee, dateDepart, typeTransport);                                                                                                                           
                      }
                                                  
                  });
              }
              else
              {
                  recupWikiLocationArrivee(latArrivee, longArrivee, typeLocation, distanceRecherche, codeDepart, codeAeroportArrivee, dateDepart, typeTransport);                                                                                          
              }
          }); 
       }
      });
}

  return (
    <Transition appear show={true}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 flex justify-center items-center overflow-y-auto" // Centrage vertical
        onClose={handleCloseModalBookingVols}
      >
        <div className="w-full h-[90vh] px-4 flex flex-col justify-center items-center"> {/* Flex pour centrage */}
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
          </Transition.Child>

          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0 scale-95"
          >
            <div
              className="inline-block w-full text-left align-middle transition-all transform overflow-y-auto rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl"
              style={{ height: '90vh', overflowY: 'auto' }} // Autoriser le défilement
            >
              <div className="relative px-3 py-3 border-b border-neutral-200 dark:border-neutral-800 text-center">
                <h2 className="font-medium leading-6 text-gray-900 dark:text-neutral-300">
                  {t('reserverAvions')} <a href='https://www.booking.com/flights/index.fr.html?aid=2040377' rel="noreferrer" target="_blank"><u>Booking.com</u></a>
                  <sup>
                    <button type="button" onClick={handleInfoClick}>
                      <i className="las la-info"></i>
                    </button>
                  </sup>
                </h2>

                <span className="absolute left-3 top-3">
                  <ButtonClose onClick={handleCloseModalBookingVols} />
                </span>
              </div>

              {!cookiePartenaires && (
            <InformationCookiesMarketing/>
          )}

              <div
                className="px-3 py-3 overflow-y-auto" // Autoriser le défilement
              >

                <div id="bookingAffiliateWidget_edb02e38-7ca4-4d1f-9477-d75e107f0c37">&nbsp;</div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ModalBookingVols;
