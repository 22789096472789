import React, { FC, SelectHTMLAttributes, useState, useEffect, useRef, Fragment } from "react";
import { CircleStackIcon, CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";
import ButtonSubmit from "./ButtonSubmit";
import { useNavigate } from "react-router-dom";
import { voyageService } from '_services/voyage.service';
import { Dialog, Transition } from "@headlessui/react";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import { useTranslation } from 'react-i18next';

export interface SelectMonnaieProps extends SelectHTMLAttributes<HTMLSelectElement> {
  className?: string;
  sizeClass?: string;
  hasButtonSubmit?: boolean;
  isModifiable?: boolean;
  idVoyage: number;
  titre: string;
  monnaie?: string;
  id_pays: string;
  libelles_pays: string;
  startDateInit: Date | null;
  endDateInit: Date | null;
  startDate: Date | null;
  endDate: Date | null;
  nbVoyageurs: number;
  verrouillageModif: () => Promise<number>;
}

const SelectMonnaie: FC<SelectMonnaieProps> = ({
  className = "",
  sizeClass = "",
  children,
  hasButtonSubmit,
  isModifiable,
  idVoyage,
  titre,
  monnaie,
  id_pays,
  libelles_pays,
  startDateInit,
  endDateInit,
  startDate,
  endDate,
  nbVoyageurs,
  verrouillageModif,
  ...args
}) => {

  const { t } = useTranslation();
  
    const [showPopover, setShowPopover] = useState(false);
    const [isModalDecalageOpen, setIsModalDecalageOpen] = useState(false);
    const [typeModalDecalage, setTypeModalDecalage] = useState<number>(0);
    const [isLoading, setIsLoading] = useState(false);
    const [monnaies, setMonnaies] = useState<any[]>([]);

  const containerRef = useRef<HTMLDivElement>(null);

  const [value, setValue] = useState(monnaie);
  
  const navigate = useNavigate(); 

  useEffect(() => {

    const fetchData = async () => {
      try {

        const response = await voyageService.getMonnaies();
        setMonnaies(response.data.monnaies);

        console.log("response.data.monnaies",response.data.monnaies);

      } catch (error: any) { 
    
        console.error("Error fetching data:", error);
        navigate('/');
      } 
    };
  
    fetchData();
    
  }, []);

  useEffect(() => {

    const eventClickOutsideDiv = (event: MouseEvent) => {

      if (!containerRef.current) return;
      if (!showPopover || containerRef.current.contains(event.target as Node)) {
        return;
      }
      setShowPopover(false);
    };

    document.addEventListener("click", eventClickOutsideDiv);

    return () => {
      document.removeEventListener("click", eventClickOutsideDiv);
    };
    
  }, [showPopover]);


  const openModalDecalage = (type: number) => {
    setTypeModalDecalage(type);
    setIsModalDecalageOpen(true);
  };

  const closeModalDecalage = () => {
    setTypeModalDecalage(0);
    setIsModalDecalageOpen(false);
  };

const  diffdate = (d1: Date,d2: Date) => {
    var WNbJours = d2.getTime() - d1.getTime();
    return  Math.ceil(WNbJours/(1000*60*60*24));
  }
  
const handleButtonSubmit = (event: React.MouseEvent<HTMLAnchorElement>,
    nbVoyageurs: number,
    startDate: Date | null,
    endDate: Date | null,
    navigate: any) => {

      event.preventDefault();

      const modifVoyage = async () => {
        try {

          if(startDateInit !== null && endDateInit !== null)
          {
            
              if(id_pays === "")
              {
                alert(t('alertDestination'));
                  return false;
              }
              if(startDate === null)
              {
                alert(t('alertDateDeb'));
                  return false;
              }
              else if(endDate === null)
              {
                alert(t('alertDateFin'));
                  return false;
              }
              else if(nbVoyageurs < 1)
              {
                alert(t('alertNbVoyageurs'));
                  return false;
              }
              
          
              const diffInTime = endDate.getTime() - startDate.getTime();
              const diffInDays = Math.ceil(diffInTime / (1000 * 3600 * 24));

              if(diffInDays+1  > 365 )
              {
                alert(t('alertNbJoursMax'));
                  return false;
              }
              else if(diffInDays+1  <= 1 )
              {
                alert(t('alertNbJoursMin'));
                return false;
              }

          
              var nbJoursInit=diffdate(startDateInit,endDateInit)+1; 
              var nouvNbJours=diffdate(startDate,endDate)+1;

              if(startDate > startDateInit && endDate < endDateInit)
              {
                  const isConfirmed = window.confirm(
                    t('confirmRepousser')
                    );

                  if(!isConfirmed)
                  {
                          return false;
                  }
              }
              else if(nouvNbJours === nbJoursInit && startDate?.toISOString() !== startDateInit?.toISOString())
              {
                  const isConfirmed = window.confirm(
                    t('confirmDecaler')
                    );

                  if(!isConfirmed)
                  {
                          return false;
                  }
              }
              else if(nouvNbJours < nbJoursInit && startDate <= startDateInit)
              {

                  const isConfirmed = window.confirm(
                    t('confirmDiminuer')
                    );
                  
                  if(!isConfirmed)
                  {
                          return false;
                  }
              }
              if(startDate > startDateInit && nouvNbJours !== nbJoursInit)
              {
                  openModalDecalage(1);
              }
              else if(startDate < startDateInit && nouvNbJours !== nbJoursInit)
              {
                  openModalDecalage(2);
              }
              else 
              {
                  enregistrerModifVoyage(false);
              }
          }
        } catch (error) {
          console.error(error);
          alert("Erreur lors de la modification !");
        }
      };

      const processModifVoyage = async () => {
        
        const verrouillageResultat = await verrouillageModif();
    
        if (verrouillageResultat === 1) {
          modifVoyage();
        }
      };

      processModifVoyage();

  };

  const enregistrerModifVoyage = (decalage: boolean) =>
  {
    setIsLoading(true); 

    voyageService.modifierVoyage({
        id_voyage: idVoyage,
        titre: titre,
        id_pays: id_pays,
        libelles_pays: libelles_pays,
        date_debut: startDate?.toLocaleDateString("fr-FR") || "",
        date_fin: endDate?.toLocaleDateString("fr-FR") || "",
        nb_voyageurs: nbVoyageurs,
        monnaie: value,
        decalage: decalage
    })
        .then((response) => {

        setIsLoading(false); 

        if (response.data.message !== undefined && response.data.message !== null && response.data.message === 'ok') {
            window.location.reload();
        }
        }) 
        .catch((error) => {
          if (error.response?.status === 401) {
            alert(t('alertMajConnecte'));
            localStorage.removeItem('xsrfToken');
            navigate(t('lienLogin'));
          }
          else
          {
            alert(t('alertMajConnecte'));
          }
        });
  }

  return (

<div className={`relative flex ${className}`} ref={containerRef}>
    <div className="flex-1 z-10 flex items-center focus:outline-none ">
      <div
        onClick={() => setShowPopover(true)}
        className={`flex z-10 flex-1 relative [ nc-hero-field-padding ] flex-shrink-0 items-center space-x-3 cursor-pointer focus:outline-none text-left  ${
          showPopover ? "nc-hero-field-focused" : ""
        }`}
      >
        <div className="text-neutral-300 dark:text-neutral-400">
          <CircleStackIcon className="w-5 h-5 lg:w-7 lg:h-7" />
        </div>
        <div className="flex-grow">
            <select {...args} className={`monnaie 
            ${(value && monnaies.find(monn => monn.code_monnaie === value)?.symbole_monnaie.length > 2 
    && monnaies.find(monn => monn.code_monnaie === value)?.symbole_monnaie !== value) ? 'w-30' 
    : ( (value && monnaies.find(monn => monn.code_monnaie === value)?.symbole_monnaie !== value) ? 'w-29' 
      : ( value ? 'w-27'
          : 'auto'))
    }
  `}
            onChange={(e) => {
                setValue(e.target.value);
                setShowPopover(false);
            }}
            value={value}
            disabled={!isModifiable}
            >
             {
    monnaies && monnaies.length > 0 && (
      monnaies.map((monn: any) => (
        <option 
        key={monn.code_monnaie} 
        value={monn.code_monnaie}
      >
       {value === monn.code_monnaie && !showPopover ? (
          monn.symbole_monnaie !== monn.code_monnaie ? (
            `${monn.symbole_monnaie} - ${monn.code_monnaie}`
          ) : (
            monn.code_monnaie
          )
        ) : (
          monn.symbole_monnaie !== monn.code_monnaie ? (
            `${monn.libelle_monnaie} - ${monn.code_monnaie} - ${monn.symbole_monnaie}`
          ) : (
            `${monn.libelle_monnaie} - ${monn.code_monnaie}`
          )
      )}

      </option>
      ))
    )
  }
           
            </select>
          
           <span className="block mt-0.5 text-sm text-neutral-400 font-light ">
              <span className="line-clamp-1">{t('monnaie')}</span>
            </span>
          
        </div>
        </div>
     {/* BUTTON SUBMIT OF FORM */}
     {hasButtonSubmit && (
              <div className="pr-2 xl:pr-4">
                <ButtonSubmit
                  onClick={(e) => {
                    handleButtonSubmit(e, nbVoyageurs, startDate, endDate, navigate);
                  }}
                />

              </div>
            )}
      </div>

      {isModalDecalageOpen && (
        <Transition appear show={isModalDecalageOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={closeModalDecalage}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block py-8 w-full md:w-3/4 lg:w-2/3 xl:w-1/2">
                <div className="inline-flex pb-2 flex-col w-full md:w-3/4 lg:w-2/3 xl:w-3/5 text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <h3
                      className="text-lg font-medium leading-6 text-gray-900 dark:text-neutral-200"
                      id="headlessui-dialog-title-70"
                    >
                      { typeModalDecalage === 1 
                      ? "Vous repoussez le début de votre voyage"
                      : "Vous avancez le début de votre voyage"
                      }
                    </h3>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={closeModalDecalage} />
                    </span>
                  </div>
                  <div className="px-8 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
                  <div
                        className="flex items-center py-2.5 sm:py-4 lg:py-5 space-x-5 lg:space-x-8"
                      >
                        
                        <span>
                            { typeModalDecalage === 1
                            ? "Concernant les informations déjà enregistrées pour les jours qui précèdent votre nouvelle date de début, souhaitez-vous les décaler ou les perdre ?"
                            : "Concernant les informations précédemment enregistrées, souhaitez-vous les décaler vers cette nouvelle date de début ou les laisser aux dates actuelles ?"}
                        </span>
                      </div>
                      
                      <div
                        onClick={() => {
                            enregistrerModifVoyage(true);
                          }}
                        className="bouton flex items-center py-2.5 sm:py-4 lg:py-5 space-x-5 lg:space-x-8"
                      >
                        <CheckIcon className="w-5 h-5" aria-hidden="true"/>
                        <span>Décaler</span>
                      </div>
                      <div
                      onClick={() => {
                        enregistrerModifVoyage(false);
                      }}
                        className="bouton flex items-center py-2.5 sm:py-4 lg:py-5 space-x-5 lg:space-x-8"
                      >
                      <XMarkIcon className="w-5 h-5" aria-hidden="true"/>
                        <span>Ne pas décaler</span>
                      </div>
                      
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
      )}

{isLoading && (
  <Transition appear show={true} as={Fragment}>
    <Dialog
      as="div"
      className="fixed inset-0 z-[100] overflow-y-auto" // Plus élevé pour l'attente
      onClose={() => {}} // Pas de fermeture pour la modale d'attente
    >
      <div className="min-h-screen flex items-center justify-center px-4 text-center">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-60" />
        </Transition.Child>
        
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0 scale-95"
        >
          <div
            className="inline-block transition-all transform rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl px-8 py-4"
          >
            <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-neutral-200">
              {t('enregistrement')}
            </h3>
          </div>
        </Transition.Child>
      </div>
    </Dialog>
  </Transition>
)}

</div>

  );
};

export default SelectMonnaie;
