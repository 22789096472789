import { MapPinIcon } from "@heroicons/react/24/outline";
import React, { useState, useRef, useEffect, FC } from "react";
import ClearDataButton from "./ClearDataButton";
import {apiKeyGeoapify} from '_config/constantes'; 
import { useTranslation } from 'react-i18next';

export interface VilleInputProps {
  placeHolder?: string;
  desc?: string;
  className?: string;
  divHideVerticalLineClass?: string;
  autoFocus?: boolean;
  onSelectDestination?: (codePays: string, nom: string, ville: string, place_id: string, latitude: string, longitude: string) => void;
  ville: string;
  numDroit: number;
}

export type Destination = {
  code_pays: string;
  pays: string;
  ville: string;
  place_id: string,
  latitude: string,
  longitude: string,
};

type Ville = {
  country_code: string;
  country: string;
  village: string;
  city: string;
  name: string;
  state: string;
  county: string;
  address_line1: string;
  address_line2: string;
  place_id: string;
  latitude: string;
  longitude: string;
  result_type: string;
};


const VilleInput: FC<VilleInputProps> = ({
  autoFocus = false,
  placeHolder = "Ville",
  desc = "Où allez-vous ?",
  className = "nc-flex-1.5",
  divHideVerticalLineClass = "left-10 -right-0.5",
  onSelectDestination,
  ville,
  numDroit
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();

  return (
    <div className={`relative flex ${className}`} ref={containerRef}>
      <div
        className="flex z-10 flex-1 relative [ nc-hero-field-padding ] flex-shrink-0 items-center space-x-3 cursor-pointer focus:outline-none text-left"
      >
        <div className="text-neutral-300 dark:text-neutral-400">
          <MapPinIcon className="w-5 h-5 lg:w-7 lg:h-7" />
        </div>
        <div className="flex-grow" onClick={numDroit === 1 || numDroit === 2 ? () => alert("Vous ne pouvez pas modifier la destination d'un voyage sédentaire") : () => null}>
          <input
          disabled
            className={`block w-full bg-transparent border-none focus:ring-0 p-0 focus:outline-none focus:placeholder-neutral-300 xl:text-lg font-semibold placeholder-neutral-800 dark:placeholder-neutral-200 truncate`}
            placeholder={t('ville')}
            value={ville}
            ref={inputRef}
          />
          <span className="block mt-0.5 text-sm text-neutral-400 font-light ">
            <span className="line-clamp-1">{t('ville')}</span>
          </span>
        </div>
      </div>
    </div>
  );
};

export default VilleInput;
